import env from "../../env";
import React from "react";
import { connect } from "react-redux";
import { MDBInput, MDBBtn, MDBTooltip } from "mdb-react-ui-kit";
import EmojiPicker from "../EmojiPicker";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";

class Chat extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * cooldown: Number - Seconds left in chat cooldown
       * cooldownInterval: false | Interval that decrements the cooldown by 1 every second
       * text: String - Text in the text input
       * scrollToBottom: Boolean - Whether the chatbox will scroll to the bottom when a new chat is received
       */
      cooldown: 0,
      cooldownInterval: false,
      text: "",
      scrollToBottom: true,
    };
  }

  // run scrollToBottom method on mount
  componentDidMount() {
    this.scrollToBottom();
  }

  /**
   * When a new chat is received, run scrollToBottom method
   */
  componentDidUpdate(prevProps) {
    if (prevProps.streamChat.length !== this.props.streamChat.length)
      this.scrollToBottom();
  }

  /**
   * Button text indicates the number of seconds left if there is cooldown, shows paper plane button if none
   * Transition between div/section so that framer-motion animation plays
   */
  getButtonText = () => {
    switch (this.state.cooldown) {
      case 0:
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            <i className="fas fa-paper-plane" />
          </motion.div>
        );
      case 1:
        return (
          <motion.section
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            1
          </motion.section>
        );
      case 2:
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            2
          </motion.div>
        );
      case 3:
        return (
          <motion.article
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            3
          </motion.article>
        );
      default:
        console.log("oob button text", this.state.cooldown);
        return (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
          >
            <i className="fas fa-paper-plane" />
          </motion.div>
        );
    }
  };

  /**
   *
   * @returns cursor position in the text input
   */
  getCursorPosition = () => {
    const field = document.getElementById(`chat-input-${this.props.chat}`);
    var position = 0;

    if (document.selection) {
      field.focus();
      var selection = document.selection.createRange();
      selection.moveStart("character", -field.value.length);
      position = selection.text.length;
    } else if (field.selectionStart || field.selectionStart == "0")
      position =
        field.selectionDirection == "backward"
          ? field.selectionStart
          : field.selectionEnd;

    return position;
  };

  /**
   * Triggered when the user clicks the Submit button
   * Validates the message and emits it via socket
   */
  submit = () => {
    if (!this.state.cooldown && this.state.text) {
      if (this.state.text.length > 500) alert("Please enter a shorter message");
      else {
        if (this.props.chat === "self")
          this.props.socket.emit("send-stream-chat-self", {
            avatar: this.props.userInfo.avatar,
            message: this.state.text,
          });
        else
          this.props.socket.emit("send-stream-chat-other", {
            avatar: this.props.userInfo.avatar,
            message: this.state.text,
            userID: this.props.profileInfo?.user?._id,
          });
        this.setState((curr) => ({
          ...curr,
          text: "",
          cooldown: 3,
          cooldownInterval: setInterval(this.decrementCooldown, 1000),
        }));
      }
    }
  };

  /**
   * Triggered by the cooldownInterval
   * Decrements the cooldown by 1
   */
  decrementCooldown = () =>
    this.setState(
      (curr) => ({
        ...curr,
        cooldown: this.state.cooldown - 1,
      }),
      () => {
        if (!this.state.cooldown) clearInterval(this.state.cooldownInterval);
      }
    );

  /**
   *
   * @param {Object} e - Emohi object
   *
   * Triggered when the user selects an emoji
   * Determine the cursor location, insert an emoji in that position
   */
  selectEmoji = (e) => {
    const position = this.getCursorPosition();
    this.setState((curr) => ({
      ...curr,
      text: [
        ...curr.text.split("").filter((letter, i) => i < position),
        e.char,
        ...curr.text.split("").filter((letter, i) => i >= position),
      ].join(""),
    }));
  };

  /**
   * Handles text change in the text input
   */
  changeHandler = (e) =>
    this.setState((curr) => ({
      ...curr,
      text: e.target.value,
    }));

  pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") this.submit();
  };

  /**
   * If state.scrollToBottom, scroll to bottom
   * scrollBehavior is normal for first scroll, set to smooth for subsequent scrolls
   * Execute after 0.1s
   */
  scrollToBottom = (force) =>
    this.setState(
      (curr) => ({
        ...curr,
        scrollToBottom: force ? true : this.state.scrollToBottom,
      }),
      () => {
        if (this.state.scrollToBottom) {
          const container = document.getElementById(
            `stream-chat-container-${this.props.chat}`
          );
          container.scrollTop = container.scrollHeight;
          container.style.scrollBehavior = "smooth";
        }
      }
    );

  /**
   * Fired when the user scrolls through the chatbox
   * If the scrollbar is not at the bottom, set state.scrollToBottom to false so that it won't scroll to the bottom when new messages come in
   */
  scrollHandler = (e) => {
    if (
      e.currentTarget.scrollHeight ===
      e.currentTarget.scrollTop + e.currentTarget.clientHeight
    ) {
      if (!this.state.scrollToBottom)
        this.setState((curr) => ({
          ...curr,
          scrollToBottom: true,
        }));
    } else if (this.state.scrollToBottom)
      this.setState((curr) => ({
        ...curr,
        scrollToBottom: false,
      }));
  };

  render() {
    return (
      <div className="position-absolute d-flex flex-column stream-chat">
        <div
          onTouchMove={this.scrollHandler}
          onWheel={this.scrollHandler}
          style={{ scrollBehavior: "auto" }}
          id={`stream-chat-container-${this.props.chat}`}
          className="fg-1 overflow-y-auto d-flex flex-column scrollbar-slim position-relative"
        >
          <div className="flex-grow-1"></div>
          <div>
            {this.props.streamChat.map((message) => (
              <motion.div
                className="mb-1 p-2 rounded-6 bg-stream-chat stream-chat-messages"
                transition={t.transition}
                exit={t.fade_out_minimize}
                animate={t.normalize}
                initial={t.fade_out_minimize}
                key={String(message.timestamp)}
              >
                <div className="d-flex">
                  <div className="chat-avatars">
                    <div
                      className="fit-images  fit-round"
                      style={{
                        backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${message.avatar.thumbnail}")`,
                        borderRadius: "50%",
                      }}
                    ></div>
                  </div>
                  <div style={{ width: 0 }} className="flex-grow-1 ps-2">
                    <p className="m-0 fw-bold">@{message.user}</p>
                    <p className="m-0 text-break">{message.message}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          {!this.state.scrollToBottom && (
            <motion.div
              transition={t.transition}
              initial={t.fade_out}
              animate={t.normalize}
              exit={t.fade_out_scale_1}
              className="position-absolute"
              style={{
                bottom: "10px",
                right: "10px",
              }}
            >
              <MDBBtn
                onClick={() => this.scrollToBottom(true)}
                color="link"
                rippleColor="primary"
              >
                <i className="fas fa-chevron-down" />
              </MDBBtn>
            </motion.div>
          )}
        </div>
        {this.props.userInfo._id ? (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_minimize}
            animate={t.normalize}
            initial={t.fade_out_minimize}
            className="d-flex p-3 rounded-6 align-items-center bg-stream-chat stream-chat-input"
          >
            <div className="flex-grow-1">
              <MDBInput
                value={this.state.text}
                label="Chat"
                onChange={this.changeHandler}
                onKeyPress={this.pressEnter}
                id={`chat-input-${this.props.chat}`}
                autoComplete="off"
              />
            </div>
            <div className="d-flex justify-content-end align-items-center stream-chat-actions">
              <EmojiPicker
                emojiID={`chat-${this.props.chat}`}
                className="mx-1 p-2"
                trigger={
                  <MDBTooltip
                    tag="span"
                    wrapperProps={{
                      className: `emoji-triggers-chat-${this.props.chat}-timeout`,
                    }}
                    title="Emoji"
                  >
                    <i
                      style={{ fontSize: "1.75em" }}
                      className="fas fa-smile fa-lg"
                    ></i>
                  </MDBTooltip>
                }
                onEmojiSelect={this.selectEmoji}
              />
              <div className="d-flex justify-content-center align-items-center">
                <MDBBtn
                  onClick={this.submit}
                  disabled={this.state.cooldown || !this.state.text}
                  color="success"
                >
                  {this.getButtonText()}
                </MDBBtn>
              </div>
            </div>
          </motion.div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {})(Chat);
