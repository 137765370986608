import env from "../../env";
import React from "react";
import { connect } from "react-redux";
import {
  set_poll_modal,
  update_emission,
  set_token,
  set_click_pass,
} from "../../redux/actions";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import EmissionDetailed from "../../components/emission/EmissionDetailed";
import { MDBBtn } from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import LogoLoader from "../../components/LogoLoader";

class Emissions extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.setState((curr) => ({
      ...curr,
      loaded: true,
    }));
  }

  render() {
    const {
      profileInfo,
      clickEmissionBody,
      vote,
      polls,
      submitVotes,
      pollsSubmitting,
      selectFile,
      signalBoost,
      like,
      copyEmissionLink,
      emissionCopied,
      reply,
      userInfo,
      route,
      report,
      remove,
      restore,
      seeMore,
      loadingMore,
      set_poll_modal,
      update_emission,
      handlePin,
      set_click_pass,
      clickPass,
      token,
      set_token,
    } = this.props;
    const { loaded } = this.state;
    return (
      <>
        {profileInfo.emissions.items.filter((emission) => !emission.replyID)
          .length ? (
          <>
            {profileInfo.emissions.items
              .filter((emission) => emission.pinned)
              .sort((a, b) => b.emissionID - a.emissionID)
              .map((emission, e) => (
                <EmissionDetailed
                  emission={emission}
                  loaded={loaded}
                  animation={t.fade_out_right}
                  clickEmissionBody={clickEmissionBody}
                  vote={vote}
                  polls={polls}
                  submitVotes={submitVotes}
                  pollsSubmitting={pollsSubmitting}
                  selectFile={selectFile}
                  signalBoost={signalBoost}
                  like={like}
                  copyEmissionLink={copyEmissionLink}
                  emissionCopied={emissionCopied}
                  reply={reply}
                  index={e}
                  userInfo={userInfo}
                  route={route}
                  key={emission.emissionID}
                  report={report}
                  remove={remove}
                  restore={restore}
                  profileInfo={profileInfo}
                  updateEmission={update_emission}
                  setPollModal={set_poll_modal}
                  showPin={true}
                  handlePin={handlePin}
                  setClickPass={set_click_pass}
                  clickPass={clickPass}
                  token={token}
                  set_token={set_token}
                />
              ))}
            <div id="scroll-top"></div>
            {profileInfo.emissions.items
              .filter((emission) => !emission.replyID && !emission.pinned)
              .sort((a, b) => b.emissionID - a.emissionID)
              .map((emission, e) => (
                <EmissionDetailed
                  emission={emission}
                  loaded={loaded}
                  animation={t.fade_out_right}
                  clickEmissionBody={clickEmissionBody}
                  vote={vote}
                  polls={polls}
                  submitVotes={submitVotes}
                  pollsSubmitting={pollsSubmitting}
                  selectFile={selectFile}
                  signalBoost={signalBoost}
                  like={like}
                  copyEmissionLink={copyEmissionLink}
                  emissionCopied={emissionCopied}
                  reply={reply}
                  index={e}
                  userInfo={userInfo}
                  route={route}
                  key={emission._id}
                  remove={remove}
                  restore={restore}
                  report={report}
                  profileInfo={profileInfo}
                  updateEmission={update_emission}
                  setPollModal={set_poll_modal}
                  showPin={true}
                  handlePin={handlePin}
                  setClickPass={set_click_pass}
                  clickPass={clickPass}
                  token={token}
                  set_token={set_token}
                />
              ))}
            {profileInfo.emissions.items.filter((emission) => !emission.replyID)
              .length >= profileInfo.emissions.totalNoReply ? (
              <></>
            ) : (
              <div className="py-4">
                {loadingMore ? (
                  <MDBBtn
                    size="lg"
                    disabled
                    className="d-block mx-auto w-50 border-transparent"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    <Spinner color="primary" size="sm" className="me-2" />
                    Please Wait
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    size="lg"
                    onClick={seeMore}
                    className="d-block mx-auto w-50"
                    rippleColor="primary"
                    outline
                    color="primary"
                    rounded
                  >
                    See More
                  </MDBBtn>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {this.props.updating ? (
              <motion.div
                initial={t.fade_out}
                animate={t.normalize}
                exit={t.fade_out_scale_1}
                transition={t.transition}
              >
                <LogoLoader />
              </motion.div>
            ) : (
              <motion.h5
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
                className="text-center display-6 mt-4"
              >
                {profileInfo?.user?._id === userInfo._id
                  ? "You have "
                  : `${profileInfo?.user?.displayName} has `}
                not made any{" "}
                <span className="text-lowercase">{env.EMISSION_PLURAL}</span>
              </motion.h5>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  update_emission,
  set_poll_modal,
  set_token,
  set_click_pass,
})(Emissions);
