import React from "react";
import { connect } from "react-redux";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import Chat from "../../../../components/goLiveModal/Chat";
import LogoLoader from "../../../../components/LogoLoader";
import { stream_clear } from "../../../../redux/actions";
import Count from "../../../../components/Count";
import { LinearProgress } from "@mui/material";

class LiveStreamModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * peer: false | Peer object
       * stream: false | MediaStream of streamer
       * videoDimensions: Object - The height and width of the video stream
       * peerConnection: false | RtcConnection - The RTC connection to the live stream server
       */
      peer: false,
      stream: false,
      videoDimensions: {
        height: false,
        width: false,
      },
      peerConnection: false,
      dataConnection: false,
      networkProblems: false,
      streamPeerID: "",
      peerOpen: false,
      closeCall: false,
      retryTimeout: false,
      streamReset: false,
      callConnection: false,
    };
  }

  /**
   * Initialize peer
   *
   */
  componentDidMount() {
    if (this.props.socket) this.initializeSocket();
    this.setState(
      (curr) => ({
        ...curr,
        peer: new window.Peer(undefined, {
          host: process.env.REACT_APP_PEER_HOST,
          port: Number(process.env.REACT_APP_PEER_PORT),
          secure: Number(process.env.REACT_APP_PEER_PORT) === 443,
        }),
      }),
      () => {
        /**
         * When peer opens, set peer status to "open" in state, and if modal is displayed, connect to stream
         */
        this.state.peer.on("open", () =>
          this.setState(
            (curr) => ({
              ...curr,
              peerOpen: true,
            }),
            () => {
              this.state.peer.on("connection", (connection) => {
                try {
                  if (connection?.metadata?.kick) {
                    this.retryConnection(true);
                    connection.close();
                  } else if (connection?.metadata?.fromStreamChild) {
                    connection.on("open", () => {
                      try {
                        connection.on("data", (data) => {
                          try {
                            if (
                              ["device-change", "device-change-child"].includes(
                                data.event
                              )
                            ) {
                              if (data.device === "camera" && !data.enabled)
                                this.checkVideoDimensions({
                                  height: 0,
                                  width: 0,
                                });
                              else this.checkVideoDimensions();
                            }
                          } catch (err) {
                            console.log("connection data error", err);
                          }
                        });
                        connection.send({
                          ready: true,
                        });
                      } catch (err) {
                        console.log("streamChild open error", err);
                      }
                    });
                  } else connection.close();
                } catch (err) {
                  console.log("connection error", err);
                }
              });
              if (this.props.modalShown && !this.state.stream) {
                this.viewStream();
              }
            }
          )
        );

        /**
         * Stream server will call the user with the live stream when the user makes a peer connection
         * Answer with nothing
         */
        this.state.peer.on("call", (call) => {
          clearTimeout(this.state.retryTimeout);
          if (this.state.peerConnection?.oniceconnectionstatechange)
            this.state.peerConnection.oniceconnectionstatechange = () => {};

          this.setState(
            (curr) => ({
              ...curr,
              peerConnection: false,
            }),
            () => {
              call.answer();
              /**
               * Get video dimensions, if any
               * Set stream, peer connection, and dimensions into state
               * Pipe stream to <video>
               */
              call.on("stream", async (stream) => {
                try {
                  this.setState(
                    (curr) => ({
                      ...curr,
                      stream: stream,
                      peerConnection:
                        this.state.peerConnection || call.peerConnection,
                      closeCall: this.state.closeCall || call.close,
                      networkProblems: false,
                    }),
                    () => {
                      try {
                        /**
                         * On disconnect
                         *
                         * Hide modal
                         * Clear stream
                         * Reset state
                         */
                        this.checkVideoDimensions();
                        this.state.peerConnection.oniceconnectionstatechange = (
                          e
                        ) => {
                          try {
                            if (
                              ["failed", "disconnected"].indexOf(
                                call.peerConnection.iceConnectionState
                              ) > -1 &&
                              this.props.profileInfo?.user?.live
                            )
                              this.setState((curr) => ({
                                ...curr,
                                networkProblems: true,
                              }));
                          } catch (err) {
                            console.log(
                              "oniceconnectionstatechange error",
                              err,
                              e
                            );
                          }
                        };
                      } catch (err) {
                        console.log("stream setState callback error", err);
                      }
                    }
                  );
                } catch (err) {
                  console.log("stream error", err);
                }
              });
            }
          );
        });
      }
    );
  }

  /**
   * Get stream if modal is displayed while profile is already streaming
   */
  componentDidUpdate(prevProps) {
    if (
      !prevProps.modalShown &&
      this.props.modalShown &&
      !this.state.stream &&
      this.state.peer.id &&
      this.state.peerOpen
    ) {
      this.viewStream();
    }
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    // this.checkVideoDimensions();
  }

  componentWillUnmount() {
    if (this.props.socket) this.props.socket.off("view-hang");
    this.props.stream_clear();
    if (this.state.peer?.close) this.state.peer.close();
    if (this.state.peerConnection?.close) this.state.peerConnection.close();
    if (this.state.closeCall) this.state.closeCall();
  }

  checkVideoDimensions = async (preset) => {
    try {
      if (this.state.stream) {
        const videoDimensions =
          preset ||
          (await h.getStreamDimensions(
            this.state.stream,
            this.props.ping || 0
          ));
        if (
          videoDimensions.height !== this.state.videoDimensions.height ||
          videoDimensions.width !== this.state.videoDimensions.width
        )
          this.setState(
            (curr) => ({
              ...curr,
              videoDimensions,
            }),
            () => {
              setTimeout(() => {
                try {
                  const player = document.getElementById("streamer-viewer");
                  if (player) {
                    player.srcObject = this.state.stream;
                    player.play();
                    if (videoDimensions.width && videoDimensions.height) {
                      const secondaryVideo = document.getElementById(
                        "streamer-viewer-background"
                      );
                      if (secondaryVideo) {
                        secondaryVideo.srcObject = this.state.stream;
                        secondaryVideo.play();
                      }
                    }
                  } else {
                    console.log("player element not found", player);
                    this.setState((curr) => ({
                      ...curr,
                      screen: "error",
                      error: "An unknown error occurred",
                    }));
                  }
                } catch (err) {
                  console.log("stream setState callback setTimeout error", err);
                }
              }, 300);
            }
          );
      }
    } catch (err) {
      console.log("checkVideoDimensions error", err);
    }
  };

  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.off("view-hang");

    this.props.socket.on("view-hang", (data) => {
      try {
        this.retryConnection(data);
      } catch (err) {
        console.log("view-hang error", err, data);
      }
    });
  };

  viewStream = () => {
    clearTimeout(this.state.retryTimeout);
    this.props.socket.emit(
      "view-stream",
      this.props.profileInfo?.user?._id,
      this.state.peer.id
    );
    this.setState((curr) => ({
      ...curr,
      retryTimeout: setTimeout(() => {
        this.viewStream();
      }, 3000),
    }));
  };

  retryConnection = (instant) => {
    this.setState(
      (curr) => ({
        ...curr,
        stream: false,
      }),
      () =>
        setTimeout(
          () => {
            try {
              if (
                this.props.profileInfo?.user?.live &&
                this.state.peer.id &&
                this.state.peerOpen
              ) {
                this.viewStream();
              }
            } catch (err) {
              console.log("view-hang error", err);
            }
          },
          instant ? 0 : 1000
        )
    );
  };

  /**
   * Stream video meant to fit the container
   * Larger dimension will span 100% of the container, smaller one auto
   *
   * @returns String - CSS class
   */
  getVideoClasses = () => {
    const windowRatio = window.innerWidth / window.innerHeight;
    const cameraRatio =
      (this.state.videoDimensions.width || 0) /
      (this.state.videoDimensions.height || 0);
    if (windowRatio > cameraRatio) return "h-100";
    else return "w-100";
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog size="fullscreen">
              <MDBModalContent>
                <MDBModalBody className="position-relative p-0 h-100 w-100 overflow-x-hidden overflow-y-hidden">
                  {this.state.stream ? (
                    <>
                      {this.state.stream.getVideoTracks().length &&
                      this.state.videoDimensions.height &&
                      this.state.videoDimensions.width ? (
                        <>
                          <motion.div
                            transition={t.transition}
                            exit={t.fade_out_scale_1}
                            animate={t.normalize}
                            initial={t.fade_out}
                            className="h-100 w-100 position-absolute"
                            dangerouslySetInnerHTML={{
                              __html: `
                                                <video style="object-fit: cover;" class="h-100 w-100" muted autoplay id="streamer-viewer-background" />
                                            `,
                            }}
                            id="streamer-background-container"
                          ></motion.div>
                          {this.state.networkProblems ? (
                            <motion.section
                              transition={t.transition}
                              initial={t.fade_out}
                              animate={t.normalize}
                              exit={t.fade_out_scale_1}
                              className="h-100 w-100 position-absolute d-flex justify-content-center align-items-center"
                            >
                              <MDBContainer className="text-white">
                                <h5 className="text-center mb-5 display-6">
                                  Disconnected from stream server. Attempting to
                                  reconnect
                                </h5>
                                <LinearProgress
                                  className="mt-5"
                                  color="inherit"
                                />
                              </MDBContainer>
                            </motion.section>
                          ) : (
                            <motion.div
                              transition={t.transition}
                              exit={t.fade_out_scale_1}
                              animate={t.normalize}
                              initial={t.fade_out}
                              className="h-100 w-100 d-flex justify-content-center align-items-center position-absolute"
                              id="streamer-video-container"
                              dangerouslySetInnerHTML={{
                                __html: `
                                                <video class="${this.getVideoClasses()}" autoplay playsinline id="streamer-viewer" />
                                            `,
                              }}
                            ></motion.div>
                          )}
                        </>
                      ) : (
                        <motion.section
                          transition={t.transition}
                          exit={t.fade_out_scale_1}
                          animate={t.normalize}
                          initial={t.fade_out}
                          className="h-100 w-100 d-flex justify-content-center align-items-center m-0"
                        >
                          <audio autoPlay id="streamer-viewer"></audio>
                          <i class="fas fa-volume-up fa-10x d-block mx-auto text-center"></i>
                        </motion.section>
                      )}
                    </>
                  ) : (
                    <MDBContainer>
                      <h5 className="text-center display-6 my-4">Connecting</h5>
                      <LogoLoader />
                    </MDBContainer>
                  )}
                  {this.state.stream ? <Chat chat="other" /> : <></>}
                  <div className="position-absolute d-flex live-viewers-close">
                    <div>
                      <div className="rounded-pill d-flex align-items-center me-4 p-3 bg-viewers w-max-content mb-4">
                        <h5 className="m-0">
                          <Count value={this.props.viewers} />
                        </h5>
                        <i className="fas fa-eye d-block ms-2 text-primary fa-lg"></i>
                      </div>
                      <small
                        style={{ backgroundColor: "rgba(238, 238, 238, 0.5)" }}
                        className="rounded-4 text-wrap p-2 text-dark"
                      >
                        {this.props.profileInfo?.user?.live?.streamTitle}
                      </small>
                    </div>
                    <MDBBtn
                      color="none"
                      className="btn-close"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </div>
                </MDBModalBody>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { stream_clear })(LiveStreamModal);
