import env from "../../env";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBBtn,
  MDBTooltip,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBProgressBar,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import Spinner from "../Spinner";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import axios from "axios";
import PollExpiration from "../PollExpiration";
import { v4 as uuid } from "uuid";
import Url from "url-parse";
import Count from "../Count";

class Emission extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * showRemoveDetails: Boolean - Whether the MDBCollapse showing the emission removal details is open
       * highlightRemoved: Boolean - Whether the green card background has been removed
       */
      showRemoveDetails: false,
      highlightRemoved: false,
      pollReset: false,
      pollCheck: false,
      transitionRemoved: false,
      rippleDuration: 500,
    };
    this.rippleID = uuid();
    this.rippleRef = React.createRef(null);
  }

  // Set highlight to be removed after 1.5 seconds
  componentDidMount() {
    setTimeout(this.removeHighlight, 1500);
    this.setState((curr) => ({
      ...curr,
      pollCheck: setInterval(this.pollCheck, 1000),
    }));
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.clickPass &&
      this.props.clickPass &&
      this.props.clickPass.id === this.rippleID
    ) {
      switch (this.props.clickPass.action) {
        case "reply":
          h.hideToolTips();
          this.props.reply(this.props.emission);
          break;
        case "profile":
          this.props.route(`/${this.props.emission.username}`);
          break;
        case "emission":
          this.props.route(`/e/${this.props.emission.signalBoost.emissionID}`);
          break;
        default:
          console.log("oob clickpass", this.props.clickPass);
      }
      this.props.setClickPass(false);
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.pollCheck);
  }

  pollCheck = () => {
    if (
      !this.state.pollReset &&
      this.props.emission.pollData &&
      this.props.emission.pollData.expirationDate &&
      new Date().getTime() >
        new Date(this.props.emission.pollData.expirationDate)
    )
      this.setState((curr) => ({
        ...curr,
        pollReset: true,
      }));
  };

  /**
   * Removes the highlight
   *
   */
  removeHighlight = () =>
    this.setState(
      (curr) => ({
        ...curr,
        highlightRemoved: true,
      }),
      () =>
        setTimeout(
          () =>
            this.setState((curr) => ({
              ...curr,
              transitionRemoved: true,
            })),
          1000
        )
    );

  /**
   *
   * @param {Click Event} e
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks an emission id
   *
   * If the emission is already on the page, scroll to it
   * Else, route to /e/emissionID
   */
  scrollToEmission = (e, emissionID) => {
    e.preventDefault();
    let emission = document.getElementById(`emission-${emissionID}`);
    if (!emission)
      emission = document.getElementById(`emission-${emissionID}-signalboost`);
    if (!emission)
      emission = document.getElementById(
        `emission-${emissionID}-with-children`
      );
    if (!emission)
      emission = document.getElementById(
        `emission-${emissionID}-signalboost-with-children`
      );
    if (emission) emission.scrollIntoView();
    else this.props.route(`/e/${emissionID}`);
  };

  /**
   * Toggles the display of removal details
   */
  toggleRemoveDetails = () =>
    this.setState((curr) => ({
      ...curr,
      showRemoveDetails: !this.state.showRemoveDetails,
    }));

  /**
   * @param {Click Event} e
   *
   * Triggered when the user clicks a profile
   *
   * If the user is already on that profile page, scroll to the top of the feed
   * Else route to that profile page
   */
  clickProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      window.location.pathname.split("/")[1].split("?")[0].toLowerCase() ===
      this.props.emission.username.toLowerCase()
    ) {
      document.getElementById("feed-top").scrollIntoView();
    } else {
      this.props.route(`/${this.props.emission.username}`);
    }
  };

  /**
   * Returns a card background color based on the state of the emission
   *
   * If highlighted, light green
   * If author is blocked or emission is removed, light pink
   * If author blocks the user, light purple
   * If author has privated their account, light teal
   *
   * @returns A CSS background class
   */
  getCardBackground = (removeText) => {
    if (this.props.highlight && !this.state.highlightRemoved)
      return "bg-litegreen scale-minor-hover";
    else if (this.props.emission.isBlocked)
      return "bg-litepurple scale-minor-hover";
    else if (this.props.emission.remove.removed)
      return "bg-litepink scale-minor-hover";
    else if (this.props.emission.blocksMe)
      return "bg-litepurple scale-minor-hover";
    else if (this.props.emission.private)
      return "bg-liteteal scale-minor-hover";
    else return "scale-minor-hover" + (removeText ? "bg-emphasis" : "");
  };

  /**
   *
   * Special emissions have gold numbers.
   * The following Emission emissionIDs are special:
   * * Emission 1
   * * Any emission over 10 with all the same numbers (i.e. 666)
   * * Any emission over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the emission is a special emission
   */
  checkSpecialEmission = () => {
    if (this.props.emission.emissionID === 1) return true;
    const split = String(this.props.emission.emissionID).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  /**
   * Triggered when the user removes or restores their own emission
   *
   * Removes/restores the emission
   * Updates the emission
   */
  removeRestore = () => {
    if (!this.state.removing)
      this.setState(
        (curr) => ({
          ...curr,
          removing: true,
        }),
        () =>
          axios
            .get(
              `${process.env.REACT_APP_LAMBDA_EMISSIONS}/remove-restore-self/${this.props.emission.emissionID}`,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  removing: false,
                }),
                () => this.props.updateEmission(res.data.emission)
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  removing: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user pins or unpins their own emission
   *
   * Pins/unpins the emission
   * Updates the emission
   */
  togglePin = () => {
    if (!this.state.pinning)
      this.setState(
        (curr) => ({
          ...curr,
          pinning: true,
        }),
        () =>
          axios
            .get(
              `${process.env.REACT_APP_LAMBDA_EMISSIONS}/pin-unpin/${this.props.emission.emissionID}`,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  pinning: false,
                }),
                () => {
                  if (this.props.handlePin)
                    this.props.handlePin(res.data.emission);
                  else
                    this.props.updateEmission(h.setMetadata(res.data.emission));
                }
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  pinning: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  selectPollOption = (option) => {
    this.props.setPollModal({
      shown: true,
      voters: this.props.emission.pollData.voters.filter(
        (voter) => voter.votes?.indexOf(option.id) > -1
      ),
      option: option.option,
      emissionID: this.props.emission.emissionID,
    });
  };

  clickElsewhere = (e) => {
    e.stopPropagation();
    if (
      !e.target.classList.contains("no-route") &&
      !e.target.classList.contains("dropdown-item")
    ) {
      this.props.route(`/e/${this.props.emission.emissionID}`);
    }
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickEmissionBody = (e, emissionID) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (["SPAN", "H5"].indexOf(e.target.tagName) > -1)
      element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (!href) {
        console.log("no link found", element, e.target);
        return;
      }

      const url = new Url(href);
      if (url?.hostname === window.location.hostname)
        this.props.route(url.pathname);
      else {
        window.location = href;
      }
    } else if (emissionID) this.props.route("/e/" + emissionID);
  };

  killRipple = () =>
    this.setState((curr) => ({
      ...curr,
      rippleDuration: 0,
    }));

  getFileThumbnail = (file) => {
    switch (file.type) {
      case "audio":
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route cursor-pointer skip-ripple">
            <i className="fas fa-volume-up fa-3x d-block no-route skip-ripple"></i>
          </div>
        );
      case "image":
      case "video":
        return (
          <div
            className="fit-images no-route cursor-pointer rounded-3 skip-ripple"
            style={{
              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${file.thumbnail}")`,
            }}
          ></div>
        );
      default:
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route skip-ripple cursor-pointer">
            <h6 className="file-labels-generic no-route skip-ripple">
              {file.main.split(".")[file.main.split(".").length - 1]}
            </h6>
          </div>
        );
    }
  };

  render() {
    if (!this.props.emission) return <></>;

    // Obviously needs to be reworked. Found late in the game extremely close to ship and don't have time to recalculate everything + test.
    const liked = Array.isArray(this.props.userInfo.likes)
      ? this.props.userInfo.likes.find(
          (like) => like === this.props.emission.emissionID
        )
      : false;

    const boostInfo = this.props.userInfo.boosts.find(
      (boost) => boost.boostID === this.props.emission.emissionID
    );

    return (
      <motion.div
        id={`emission-${this.props.emission.emissionID}${
          this.props.signalBoosted ? "-signalboost" : ""
        }${this.props.emission.children?.length ? "-with-children" : ""}`}
        className={`emission-wrappers ${this.props.index ? "" : "mt-4"} mb-2 `}
        key={this.props.emission._id}
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={
          this.props.loaded ? this.props.animation || t.fade_out : t.normalize
        }
        onClick={this.clickElsewhere}
      >
        <MDBCard
          id={`card-${this.props.emission.emissionID}`}
          style={{ marginBottom: "0.75rem" }}
          className={`${
            this.props.signalBoosted ? "shadow-5-strong" : "shadow-3"
          } ${this.getCardBackground()} ${
            this.state.tooltipHover ? "scale-minor-hover-active" : ""
          } ${
            this.props.highlight && !this.state.transitionRemoved
              ? "transition-1"
              : "transition-25"
          } ${!this.props.highlight ? "cursor-pointer" : ""} ${
            !this.props.emissionChild ? "list-card emission-cards" : ""
          }`}
        >
          <MDBRipple
            onClick={(e) => {
              if (
                !e.target.classList.contains("skip-click") &&
                !e.target.classList.contains("skip-ripple")
              )
                this.clickElsewhere(e);
            }}
            onMouseDown={(e) => {
              if (!e.metaKey) {
                e.stopPropagation();
                if (!e.target.classList.contains("skip-ripple"))
                  this.setState(
                    (curr) => ({
                      ...curr,
                      rippleDuration: 500,
                    }),
                    () => {
                      const mouseDownEvent = new MouseEvent("mousedown", {
                        bubbles: true,
                        clientX: e.clientX,
                        clientY: e.clientY,
                        metaKey: true,
                      });
                      this.rippleRef.current?.dispatchEvent(mouseDownEvent);
                    }
                  );
                else
                  this.setState((curr) => ({
                    ...curr,
                    rippleDuration: 0,
                  }));
              }
            }}
            rippleColor="primary"
            className="w-100"
            rippleTag="div"
            key={this.rippleID}
            ref={this.rippleRef}
            rippleDuration={this.state.rippleDuration}
          >
            <MDBCardBody>
              {this.props.highlight ? (
                <>
                  <h5
                    id={`highlight-${this.props.emission.emissionID}`}
                    className={`text-${
                      this.state.highlightRemoved ? "success" : "light"
                    } transition-1`}
                  >
                    <i className="fas fa-highlighter me-2"></i>
                    Highlighted{" "}
                    <span className="text-capitalize">{env.EMISSION_NAME}</span>
                  </h5>
                  <hr></hr>
                </>
              ) : (
                <>
                  {this.props.signalBoosted ? (
                    <>
                      <h5 className="text-blusteel">
                        <i className="fas fa-retweet me-2 text-danger"></i>
                        <span className="text-capitalize">
                          {env.EMISSION_NAME}{" "}
                        </span>
                        <span className="text-capitalize">
                          {env.SIGNALBOOST_PAST}
                        </span>
                      </h5>
                      <hr></hr>
                    </>
                  ) : (
                    <>
                      {this.props.emission.pinned && this.props.showPin ? (
                        <>
                          <h5 className="text-blusteel">
                            <i className="fas fa-thumbtack me-2 text-primary"></i>
                            Pinned
                          </h5>
                          <hr></hr>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
              <div className="d-flex">
                <div
                  style={{ width: 0 }}
                  className="d-flex emission-headers flex-grow-1"
                >
                  <Link
                    onClick={this.clickProfile}
                    id={"avatar-" + this.rippleID}
                    className="h-max-content w-max-content no-route cursor-pointer skip-ripple emission-avatars"
                    to={`/${this.props.emission.username}`}
                  >
                    <div className="d-flex justify-content-center align-items-center square-4 cursor-pointer no-route force-ripple">
                      <div
                        className="fit-images rounded-circle fit-round no-route cursor-pointer force-ripple"
                        style={{
                          backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${this.props.emission.avatar.thumbnail}")`,
                        }}
                      ></div>
                    </div>
                  </Link>
                  <div style={{ width: 0 }} className="flex-grow-1 px-2">
                    <div className="d-flex emission-name-info align-items-center">
                      <Link
                        onClick={this.clickProfile}
                        className="max-w-max-content d-block no-route cursor-pointer skip-ripple emission-display-names"
                        to={`/${this.props.emission.username}`}
                        id={"dn-" + this.rippleID}
                      >
                        <h5 className="mb-0 text-default mx-0 text-break no-route cursor-pointer force-ripple">
                          {h.getBadge(
                            this.props.emission,
                            "me-2 badge-h5 force-ripple"
                          )}
                          {this.props.emission.displayName}
                        </h5>
                      </Link>
                      {this.props.emission.signalBoost ? (
                        <small className="text-blusteel force-ripple">
                          <Link
                            onClick={(e) => {
                              this.scrollToEmission(
                                e,
                                this.props.emission.replyID
                              );
                            }}
                            className="text-blusteel no-route cursor-pointer skip-ripple sb"
                            to={`/e/${this.props.emission.signalBoost.emissionID}`}
                            id={"sb-" + this.rippleID}
                          >
                            {this.props.emission.html &&
                            this.props.emission.html !== "<p><br></p>" ? (
                              <>
                                <i className="fas fa-quote-left me-2 ms-4 force-ripple"></i>
                                Quote Replying to #
                                <span className="text-gb force-ripple">
                                  {this.props.emission.signalBoost.emissionID}
                                </span>
                              </>
                            ) : (
                              <>
                                <i className="fas fa-retweet me-2 ms-4 force-ripple"></i>
                                <span className="text-capitalize force-ripple">
                                  {env.SIGNALBOOST_CURRENT}{" "}
                                </span>
                                #
                                <span className="text-gb force-ripple">
                                  {this.props.emission.signalBoost.emissionID}
                                </span>
                              </>
                            )}
                          </Link>
                        </small>
                      ) : (
                        <></>
                      )}
                      {this.props.emission.replyID ? (
                        <small>
                          <Link
                            to={`/e/${this.props.emission.replyID}`}
                            onClick={(e) => {
                              this.scrollToEmission(
                                e,
                                this.props.emission.replyID
                              );
                            }}
                            className="text-blusteel no-route cursor-pointer skip-ripple reps"
                            id={"rep-" + this.rippleID}
                          >
                            <i className="fas fa-reply me-2 ms-4 skip-ripple"></i>
                            Replying to #
                            <span className="text-gb skip-ripple">
                              {this.props.emission.replyID}
                            </span>
                          </Link>
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Link
                      onClick={this.clickProfile}
                      className="max-w-max-content d-block no-route cursor-pointer skip-ripple skip-click ats"
                      to={`/${this.props.emission.username}`}
                      id={"at-" + this.rippleID}
                    >
                      <p className="text-blusteel mx-0 text-break skip-ripple skip-click">
                        @{this.props.emission.username}
                      </p>
                    </Link>
                    {h.getMediaFromHtml(this.props.emission.html)}
                    <div
                      className="emission-text-regular no-route skip-click w-100 cursor-pointer"
                      dangerouslySetInnerHTML={{
                        __html: this.props.emission.html,
                      }}
                      onClick={(e) => {
                        this.clickEmissionBody(
                          e,
                          this.props.emission.emissionID
                        );
                      }}
                    ></div>
                  </div>
                </div>
                <div className="max-w-33">
                  <h5
                    className={`text-end mb-0 d-block ms-auto pe-2 text-nowrap no-route`}
                  >
                    <Link
                      onClick={(e) => {
                        this.props.route(
                          `/e/${this.props.emission.emissionID}`
                        );
                      }}
                      to={`/e/${this.props.emission.emissionID}`}
                      className="no-route skip-click cursor-pointer"
                    >
                      <span
                        className={`text-gb text-${
                          this.checkSpecialEmission() ? "gold" : "default"
                        }`}
                      >
                        #{this.props.emission.emissionID}
                      </span>
                    </Link>
                  </h5>
                  {this.props.emission.remove.removed && (
                    <>
                      {this.props.emission.remove.user.userID ===
                      this.props.userInfo._id ? (
                        <>
                          {this.props.emission.remove.details ? (
                            <>
                              {h.checkJanny(this.props.userInfo) ? (
                                <MDBBtn
                                  rippleColor="danger"
                                  onClick={() => {
                                    this.toggleRemoveDetails();
                                  }}
                                  color="link"
                                  className="text-danger p-1 text-end no-route cursor-pointer d-block ms-auto text-break skip-ripple"
                                >
                                  You removed this {env.EMISSION_NAME}
                                  {this.props.emission.remove.reason
                                    ? ` (${this.props.emission.remove.reason})`
                                    : ""}
                                </MDBBtn>
                              ) : (
                                <p className="text-danger ms-1 mb-0 text-btn pe-2 text-end text-break no-route cursor-default">
                                  You removed this {env.EMISSION_NAME}
                                  {this.props.emission.remove.reason
                                    ? ` (${this.props.emission.remove.reason})`
                                    : ""}
                                </p>
                              )}
                            </>
                          ) : (
                            <p className="text-danger ms-1 mb-0 text-btn text-end text-break pe-2 no-route cursor-default">
                              You removed this {env.EMISSION_NAME}
                              {this.props.emission.remove.reason
                                ? ` (${this.props.emission.remove.reason})`
                                : ""}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          {this.props.emission.remove.details ? (
                            <>
                              {h.checkJanny(this.props.userInfo) ? (
                                <MDBBtn
                                  rippleColor="danger"
                                  onClick={(e) => {
                                    this.toggleRemoveDetails(e);
                                  }}
                                  color="link"
                                  className="text-danger p-1 text-end no-route cursor-pointer d-block ms-auto text-break skip-ripple"
                                >
                                  {env.EMISSION_NAME} removed
                                  {this.props.emission.remove.reason
                                    ? `: ${this.props.emission.remove.reason}`
                                    : "by an admin"}
                                </MDBBtn>
                              ) : (
                                <p className="text-danger ms-1 mb-0 text-btn pe-2 text-end text-break no-route skip-ripple cursor-default">
                                  {env.EMISSION_NAME} removed
                                  {this.props.emission.remove.reason
                                    ? `: ${this.props.emission.remove.reason}`
                                    : ` by @${this.props.emission.username}`}
                                </p>
                              )}
                            </>
                          ) : (
                            <p className="text-danger ms-1 mb-0 text-btn text-end text-break pe-2 no-route skip-ripple cursor-default">
                              {env.EMISSION_NAME} removed
                              {this.props.emission.remove.reason
                                ? `: ${this.props.emission.remove.reason}`
                                : ` by @${this.props.emission.username}`}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {h.checkJanny(this.props.userInfo) ? (
                    <Collapse
                      in={this.state.showRemoveDetails}
                      className="no-route skip-ripple"
                    >
                      <div
                        className={
                          "p-2 transition-25 text-break no-route skip-ripple" +
                          this.getCardBackground()
                        }
                      >
                        {this.props.emission.remove.details}
                      </div>
                    </Collapse>
                  ) : (
                    <></>
                  )}
                  <p className="mb-0 text-end d-block ms-auto px-2 text-nowrap">
                    {h.makeDateHR(this.props.emission.timestamp)}
                  </p>
                  <p className="mb-0 text-blusteel text-end d-block ms-auto px-2 text-nowrap">
                    {h.getTimeHR(this.props.emission.timestamp)}
                  </p>
                  {String(env.READ_ONLY) === "true" &&
                  !h.checkChadmin(this.props.userInfo) ? (
                    <></>
                  ) : (
                    <>
                      {this.props.emission.userID === this.props.userInfo._id &&
                      !this.props.userInfo.ban.banned ? (
                        <></>
                      ) : (
                        <>
                          {h.checkJanny(this.props.userInfo) &&
                          !(
                            !h.checkChadmin(this.props.userInfo) &&
                            ["Chadmin", "Janny"].indexOf(
                              this.props.emission.role
                            ) > -1
                          ) ? (
                            <>
                              {this.props.emission.remove.removed ? (
                                <MDBBtn
                                  onClick={() => {
                                    this.props.restore(this.props.emission);
                                  }}
                                  className="text-blusteel d-block ms-auto px-2 no-route cursor-pointer skip-ripple"
                                  color="link"
                                >
                                  <i className="far fa-check-circle me-2 no-route cursor-pointer skip-ripple"></i>
                                  Restore
                                </MDBBtn>
                              ) : (
                                <MDBBtn
                                  onClick={() => {
                                    this.props.remove(this.props.emission);
                                  }}
                                  className="text-blusteel d-block ms-auto px-2 no-route skip-ripple cursor-pointer"
                                  color="link"
                                >
                                  <i className="fas fa-trash-alt me-2 no-route cursor-pointer"></i>
                                  Remove
                                </MDBBtn>
                              )}
                            </>
                          ) : (
                            <>
                              {this.props.emission.remove.removed ||
                              this.props.userInfo.ban.banned ||
                              this.props.emission.isBlocked ||
                              this.props.emission.blocksMe ||
                              (this.props.emission.private &&
                                !h.canSeePrivate(
                                  this.props.userInfo,
                                  this.props.emission
                                )) ? (
                                <></>
                              ) : (
                                <>
                                  {this.props.emission.reported ? (
                                    <motion.h5
                                      transition={t.transition}
                                      exit={t.fade_out_minimize}
                                      animate={t.normalize}
                                      initial={t.fade_out_minimize}
                                      className="text-danger no-route"
                                    >
                                      <span className="text-capitalize no-route">
                                        {env.EMISSION_NAME}
                                      </span>{" "}
                                      Reported
                                    </motion.h5>
                                  ) : (
                                    <MDBBtn
                                      onClick={() => {
                                        this.props.report(this.props.emission);
                                      }}
                                      className="text-blusteel d-block ms-auto px-2 no-route cursor-pointer skip-ripple"
                                      color="link"
                                    >
                                      <i className="fas fa-flag me-2 no-route cursor-pointer"></i>
                                      Report
                                    </MDBBtn>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                      {this.props.emission.userID === this.props.userInfo._id &&
                      !this.props.userInfo.ban.banned ? (
                        <div className="d-flex justify-content-end">
                          <MDBDropdown className="ms-2 no-route cursor-pointer skip-ripple">
                            <MDBDropdownToggle
                              rippleColor="primary"
                              color="link"
                              className="profile-bubbles no-route cursor-pointer skip-ripple"
                            >
                              <i
                                style={{ lineHeight: "1.5" }}
                                className="fas fa-ellipsis-v fa-lg no-route cursor-pointer skip-ripple"
                              ></i>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="no-route cursor-pointer skip-ripple">
                              {this.props.emission.pinned ? (
                                <MDBDropdownItem
                                  onClick={() => {
                                    this.togglePin();
                                  }}
                                  link
                                  className={
                                    "no-route skip-ripple" +
                                    (!this.state.pinning
                                      ? " cursor-pointer"
                                      : "")
                                  }
                                >
                                  <h6
                                    className={
                                      "m-1 no-route skip-ripple" +
                                      (!this.state.pinning
                                        ? " cursor-pointer"
                                        : "")
                                    }
                                  >
                                    {this.state.pinning ? (
                                      <>
                                        <Spinner
                                          color="primary"
                                          className="me-2 no-route skip-ripple"
                                          size="sm"
                                        />
                                        Unpinning
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-thumbtack me-2 text-primary no-route cursor-pointer skip-ripple"></i>
                                        Unpin
                                      </>
                                    )}
                                  </h6>
                                </MDBDropdownItem>
                              ) : (
                                <MDBDropdownItem
                                  onClick={() => {
                                    this.togglePin();
                                  }}
                                  link
                                  className={
                                    "no-route skip-ripple" +
                                    (!this.state.pinning
                                      ? " cursor-pointer"
                                      : "")
                                  }
                                >
                                  <h6
                                    className={
                                      "m-1 no-route skip-ripple" +
                                      (!this.state.pinning
                                        ? " cursor-pointer"
                                        : "")
                                    }
                                  >
                                    {this.state.pinning ? (
                                      <>
                                        <Spinner
                                          className="me-2 no-route skip-ripple"
                                          size="sm"
                                          color="primary"
                                        />
                                        Pinning
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-thumbtack me-2 text-primary no-route cursor-pointer skip-ripple"></i>
                                        Pin
                                      </>
                                    )}
                                  </h6>
                                </MDBDropdownItem>
                              )}
                              {this.props.emission.remove.removed ? (
                                <>
                                  {this.props.emission.remove.user.userID ===
                                  this.props.userInfo._id ? (
                                    <MDBDropdownItem
                                      onClick={() => {
                                        this.removeRestore();
                                      }}
                                      className={
                                        "no-route skip-ripple" +
                                        (!this.state.removing
                                          ? " cursor-pointer"
                                          : "")
                                      }
                                      link
                                    >
                                      <h6
                                        style={{
                                          opacity: this.state.removing
                                            ? 0.7
                                            : 1,
                                        }}
                                        className={
                                          "m-1 no-route skip-ripple" +
                                          (!this.state.removing
                                            ? " cursor-pointer"
                                            : "")
                                        }
                                      >
                                        {this.state.removing ? (
                                          <>
                                            <Spinner
                                              color="success"
                                              size="sm"
                                              className="me-2 no-route skip-ripple"
                                            />
                                            Restoring
                                          </>
                                        ) : (
                                          <>
                                            <i className="far fa-check-circle me-2 text-success no-route cursor-pointer skip-ripple"></i>
                                            Restore
                                          </>
                                        )}
                                      </h6>
                                    </MDBDropdownItem>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>
                                  <MDBDropdownItem
                                    onClick={() => {
                                      this.removeRestore();
                                    }}
                                    link
                                    className={
                                      "no-route skip-ripple" +
                                      (!this.state.removing
                                        ? " cursor-pointer"
                                        : "")
                                    }
                                  >
                                    <h6
                                      style={{
                                        opacity: this.state.removing ? 0.7 : 1,
                                      }}
                                      className={
                                        "m-1 no-route skip-ripple" +
                                        (!this.state.removing
                                          ? " cursor-pointer"
                                          : "")
                                      }
                                    >
                                      {this.state.removing ? (
                                        <>
                                          <Spinner
                                            color="danger"
                                            className="me-2 no-route skip-ripple"
                                            size="sm"
                                          />
                                          Removing
                                        </>
                                      ) : (
                                        <>
                                          <i className="far fa-trash-alt me-2 text-danger no-route cursor-pointer skip-ripple"></i>
                                          Remove
                                        </>
                                      )}
                                    </h6>
                                  </MDBDropdownItem>
                                </>
                              )}
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div
                className="emission-text-mobile skip-click w-100 cursor-pointer"
                dangerouslySetInnerHTML={{ __html: this.props.emission.html }}
                onClick={(e) => {
                  this.props.clickEmissionBody(
                    e,
                    this.props.emission.emissionID
                  );
                }}
              ></div>
              {this.props.emission.files.length ? (
                <>
                  <hr />
                  <div className="bg-emphasis p-3 rounded-6">
                    <h5 className="text-blusteel">
                      <i className="fas fa-photo-video me-2 text-success"></i>
                      Media
                    </h5>
                    <hr />
                    <MDBContainer fluid>
                      <div
                        key={this.props.emission.files.length}
                        className="row"
                      >
                        {this.props.emission.files.map((file, index) => (
                          <div
                            key={index}
                            className={`col-12-400 col-6 col-md-4 col-lg-3 col-xxl-2 rounded-2 mt-2 px-1 d-flex justify-content-center no-route cursor-pointer`}
                          >
                            <MDBRipple
                              rippleColor="primary"
                              tagName="div"
                              onClick={() => {
                                this.props.selectFile(
                                  file,
                                  this.props.emission.files,
                                  this.props.emission
                                );
                              }}
                              className="square-10 cursor-pointer hover-generic p-2 no-route skip-ripple rounded-3 bg-file"
                              rippleDuration={500}
                            >
                              {this.getFileThumbnail(file)}
                            </MDBRipple>
                          </div>
                        ))}
                      </div>
                    </MDBContainer>
                  </div>
                </>
              ) : (
                <></>
              )}
              {this.props.emission.signalBoost ? (
                <Emission
                  emission={this.props.emission.signalBoost}
                  loaded={this.props.loaded}
                  animation={this.props.animation}
                  clickEmissionBody={this.props.clickEmissionBody}
                  vote={this.props.vote}
                  polls={this.props.polls}
                  submitVotes={this.props.submitVotes}
                  pollsSubmitting={this.props.pollsSubmitting}
                  selectFile={this.props.selectFile}
                  signalBoost={this.props.signalBoost}
                  like={this.props.like}
                  copyEmissionLink={this.props.copyEmissionLink}
                  emissionCopied={this.props.emissionCopied}
                  reply={this.props.reply}
                  signalBoosted={true}
                  userInfo={this.props.userInfo}
                  remove={this.props.remove}
                  restore={this.props.restore}
                  report={this.props.report}
                  route={this.props.route}
                  loadingMore={this.props.loadingMore}
                  seeMore={this.props.seeMore}
                  setPollModal={this.props.setPollModal}
                  updateEmission={this.props.updateEmission}
                  clickPass={this.props.clickPass}
                  setClickPass={this.props.setClickPass}
                  token={this.props.token}
                  set_token={this.props.set_token}
                  emissionChild={true}
                />
              ) : (
                <></>
              )}
              {this.props.emission.pollData ? (
                <React.Fragment key={this.state.pollReset}>
                  <hr />
                  <div className="bg-emphasis p-3 rounded-6">
                    <h5 className="text-blusteel">
                      <i className="fas fa-poll me-2 text-secondary"></i>
                      Poll
                    </h5>
                    <hr />
                    <h5 className="text-center">
                      {this.props.emission.pollData.question}
                    </h5>
                    {this.props.emission.pollData.voted ||
                    (String(env.READ_ONLY) === "true" &&
                      !h.checkChadmin(this.props.userInfo)) ||
                    (this.props.emission.pollData.expirationDate &&
                      new Date().getTime() >
                        new Date(
                          this.props.emission.pollData.expirationDate
                        ).getTime()) ? (
                      <motion.div
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                      >
                        <p
                          title={
                            h.numberWithCommas(
                              this.props.emission.pollData.options.reduce(
                                (prev, curr) => prev + curr.votes,
                                0
                              )
                            ) +
                            " Vote" +
                            (this.props.emission.pollData.options.reduce(
                              (prev, curr) => prev + curr.votes,
                              0
                            ) === 1
                              ? ""
                              : "s")
                          }
                          className="m-0"
                        >
                          {h.compiledNumber(
                            this.props.emission.pollData.options.reduce(
                              (prev, curr) => prev + curr.votes,
                              0
                            )
                          )}{" "}
                          Vote
                          {this.props.emission.pollData.options.reduce(
                            (prev, curr) => prev + curr.votes,
                            0
                          ) === 1
                            ? ""
                            : "s"}
                        </p>
                        <PollExpiration
                          pollData={this.props.emission.pollData}
                        />
                        {this.props.emission.pollData.options.map((option) => {
                          const totalVotes =
                            this.props.emission.pollData.options.reduce(
                              (prev, curr) => prev + curr.votes,
                              0
                            );
                          const width = (option.votes / totalVotes) * 100;
                          const userVotes =
                            this.props.emission.pollData.voters.find(
                              (voter) =>
                                voter.userID === this.props.userInfo._id
                            );
                          const voted = userVotes
                            ? userVotes.votes?.indexOf(option.id) > -1
                            : false;
                          return (
                            <div
                              className="py-2 no-route cursor-pointer skip-ripple"
                              onClick={() => {
                                this.selectPollOption(option);
                              }}
                              key={option.id}
                            >
                              <div className="mt-4 skip-ripple d-flex justify-content-between no-route cursor-pointer">
                                <label
                                  htmlFor={`vote-option-${option.id}`}
                                  className="no-route skip-ripple cursor-pointer"
                                  title={`${h.numberWithCommas(
                                    option.votes
                                  )} vote${option.votes === 1 ? "" : "s"}
                                  `}
                                >
                                  {option.option} (
                                  {h.compiledNumber(option.votes)} vote
                                  {option.votes === 1 ? "" : "s"})
                                </label>
                                {voted && (
                                  <p className="m-0 text-success no-route skip-ripple cursor-pointer">
                                    You
                                    <i className="fas fa-check-circle ms-2 no-route skip-ripple cursor-pointer" />
                                  </p>
                                )}
                              </div>
                              <MDBProgress
                                className="mt-1 no-route cursor-pointer skip-ripple"
                                id={`vote-option-${option.id}`}
                              >
                                <MDBProgressBar
                                  bgColor={voted ? "success" : "primary"}
                                  className="no-route cursor-pointer skip-ripple"
                                  width={width}
                                  valuemin={0}
                                  valuemax={totalVotes}
                                ></MDBProgressBar>
                              </MDBProgress>
                            </div>
                          );
                        })}
                      </motion.div>
                    ) : (
                      <motion.section
                        transition={t.transition}
                        initial={t.fade_out}
                        animate={t.normalize}
                        exit={t.fade_out_scale_1}
                      >
                        <p className="m-0">
                          Votes Allowed:{" "}
                          {this.props.emission.pollData.votesAllowed}
                        </p>
                        <PollExpiration
                          pollData={this.props.emission.pollData}
                        />
                        <MDBListGroup className="no-route skip-ripple">
                          {this.props.emission.pollData.options.map(
                            (option) => (
                              <MDBRipple
                                onClick={() => {
                                  this.props.vote(
                                    this.props.emission,
                                    option.id
                                  );
                                }}
                                className="cursor-pointer no-route skip-ripple override-ripple"
                                key={option.id}
                                rippleDuration={500}
                                rippleColor="#262626"
                              >
                                <MDBListGroupItem
                                  className="cursor-pointer no-route skip-ripple override-ripple"
                                  action
                                  active={
                                    this.props.polls.find(
                                      (poll) =>
                                        poll.emissionID ===
                                        this.props.emission.emissionID
                                    ) &&
                                    this.props.polls
                                      .find(
                                        (poll) =>
                                          poll.emissionID ===
                                          this.props.emission.emissionID
                                      )
                                      .votes.indexOf(option.id) > -1
                                  }
                                >
                                  {option.option}
                                </MDBListGroupItem>
                              </MDBRipple>
                            )
                          )}
                        </MDBListGroup>
                        <div className="d-flex justify-content-center mt-4 no-route skip-ripple">
                          <MDBBtn
                            onClick={() => {
                              this.props.submitVotes(this.props.emission, true);
                            }}
                            className={
                              "me-2 no-route skip-ripple" +
                              (!this.props.pollsSubmitting.find(
                                (poll) =>
                                  poll.emissionID ===
                                  this.props.emission.emissionID
                              )
                                ? " cursor-pointer"
                                : "")
                            }
                            color="success"
                            disabled={this.props.pollsSubmitting.find(
                              (poll) =>
                                poll.emissionID ===
                                this.props.emission.emissionID
                            )}
                          >
                            {this.props.pollsSubmitting.find(
                              (poll) =>
                                poll.emissionID ===
                                this.props.emission.emissionID
                            ) &&
                            this.props.pollsSubmitting.find(
                              (poll) =>
                                poll.emissionID ===
                                this.props.emission.emissionID
                            ).participating ? (
                              <>
                                <Spinner
                                  size="sm"
                                  className="me-2 cursor-pointer no-route skip-ripple"
                                />
                                Voting
                              </>
                            ) : (
                              <>
                                <i className="fas fa-vote-yea me-2 cursor-pointer no-route skip-ripple"></i>
                                Vote
                              </>
                            )}
                          </MDBBtn>
                          <MDBBtn
                            onClick={() => {
                              this.props.submitVotes(
                                this.props.emission,
                                false
                              );
                            }}
                            className={
                              "bg-darkblu ms-2 no-route skip-ripple" +
                              (!this.props.pollsSubmitting.find(
                                (poll) =>
                                  poll.emissionID ===
                                  this.props.emission.emissionID
                              )
                                ? " cursor-pointer"
                                : "")
                            }
                            disabled={this.props.pollsSubmitting.find(
                              (poll) =>
                                poll.emissionID ===
                                this.props.emission.emissionID
                            )}
                          >
                            {this.props.pollsSubmitting.find(
                              (poll) =>
                                poll.emissionID ===
                                this.props.emission.emissionID
                            ) &&
                            !this.props.pollsSubmitting.find(
                              (poll) =>
                                poll.emissionID ===
                                this.props.emission.emissionID
                            ).participating ? (
                              <>
                                <Spinner
                                  size="sm"
                                  className="me-2 no-route skip-ripple"
                                />
                                Getting Results
                              </>
                            ) : (
                              <>
                                <i className="fas fa-eye me-2 no-route cursor-pointer skip-ripple"></i>
                                View Results
                              </>
                            )}
                          </MDBBtn>
                        </div>
                      </motion.section>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <></>
              )}
            </MDBCardBody>
            <MDBCardFooter className="transition-1 emission-footers">
              {this.props.emission.remove.removed ||
              this.props.userInfo.ban.banned ||
              this.props.emission.isBlocked ||
              this.props.emission.blocksMe ||
              (this.props.emission.private &&
                !h.canSeePrivate(this.props.userInfo, this.props.emission)) ? (
                <></>
              ) : (
                <div className="d-flex">
                  {String(env.READ_ONLY) === "true" &&
                  !h.checkChadmin(this.props.userInfo) ? (
                    <></>
                  ) : (
                    <>
                      <MDBTooltip
                        wrapperProps={{
                          className:
                            "text-nowrap emission-footer-buttons no-route cursor-pointer skip-ripple emission-reply-buttons",
                          color: "link",
                          rippleColor: "primary",
                          onClick: (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            h.hideToolTips();
                            this.props.reply(this.props.emission);
                          },
                          id: "reply-" + this.rippleID,
                        }}
                        title="Reply"
                        className="no-route cursor-pointer skip-ripple"
                        onMouseLeave={h.hideToolTips}
                      >
                        <span
                          className="emission-stats no-route cursor-pointer skip-ripple"
                          style={{ fontSize: "1.25em" }}
                        >
                          <i className="far fa-comments emission-stat-icons cursor-pointer no-route skip-ripple"></i>
                          <Count value={this.props.emission.replies} />
                        </span>
                      </MDBTooltip>
                      {this.props.emission.signalBoost ? (
                        <></>
                      ) : (
                        <MDBTooltip
                          wrapperProps={{
                            color: "link",
                            className: `text-nowrap no-route cursor-pointer emission-footer-buttons skip-ripple ${
                              boostInfo ? " text-success" : ""
                            }`,
                            rippleColor: "success",
                            onClick: (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (boostInfo)
                                this.props.route("/e/" + boostInfo.emissionID);
                              else {
                                h.hideToolTips();
                                this.props.signalBoost(this.props.emission);
                              }
                            },
                          }}
                          title={
                            boostInfo
                              ? `You ${env.SIGNALBOOST_PAST} (${boostInfo.emissionID})`
                              : env.SIGNALBOOST_VERB
                          }
                          className="text-capitalize no-route cursor-pointer skip-ripple"
                          onMouseLeave={h.hideToolTips}
                        >
                          <span
                            className="emission-stats no-route cursor-pointer skip-ripple"
                            style={{ fontSize: "1.25em" }}
                          >
                            <i className="fas fa-retweet emission-stat-icons no-route cursor-pointer skip-ripple"></i>
                            <Count value={this.props.emission.signalBoosts} />
                          </span>
                        </MDBTooltip>
                      )}
                      <MDBTooltip
                        wrapperProps={{
                          color: "link",
                          className:
                            "text-nowrap emission-footer-buttons no-route cursor-pointer skip-ripple",
                          rippleColor: "#fc45df",
                          onClick: (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            h.hideToolTips();
                            this.props.like(this.props.emission.emissionID);
                          },
                        }}
                        title="Like"
                        className="no-route cursor-pointer skip-ripple"
                        onMouseLeave={h.hideToolTips}
                      >
                        <span
                          className={`emission-stats cursor-pointer no-route skip-ripple ${
                            liked ? " text-pink" : ""
                          }`}
                          style={{ fontSize: "1.25em" }}
                        >
                          <i
                            className={`fa${
                              liked ? "s" : "r"
                            } fa-heart emission-stat-icons no-route cursor-pointer skip-ripple`}
                          ></i>
                          <Count value={this.props.emission.likes} />
                        </span>
                      </MDBTooltip>
                    </>
                  )}
                  <MDBTooltip
                    className="min-w-max-content no-route skip-ripple"
                    wrapperProps={{
                      color: "link",
                      className:
                        "cursor-default text-nowrap emission-footer-buttons no-route skip-ripple",
                      rippleColor: "primary",
                    }}
                    title="Views"
                    onMouseLeave={h.hideToolTips}
                  >
                    <span
                      className="emission-stats no-route skip-ripple"
                      style={{ fontSize: "1.25em" }}
                    >
                      <i className="fas fa-chart-bar emission-stat-icons no-route skip-ripple"></i>
                      <Count value={this.props.emission.views} />
                    </span>
                  </MDBTooltip>
                  <MDBTooltip
                    className="min-w-max-content no-route cursor-pointer skip-ripple"
                    onMouseLeave={h.hideToolTips}
                    wrapperProps={{
                      className:
                        "emission-footer-buttons no-route cursor-pointer skip-ripple",
                      color: "link",
                      rippleColor: "primary",
                      onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.copyEmissionLink(
                          this.props.emission.emissionID
                        );
                      },
                    }}
                    title={
                      this.props.emissionCopied ===
                      this.props.emission.emissionID
                        ? "Link Copied"
                        : "https://" +
                          window.location.host +
                          "/e/" +
                          this.props.emission.emissionID
                    }
                  >
                    <span
                      className="emission-stats no-route cursor-pointer skip-ripple"
                      style={{ fontSize: "1.25em" }}
                    >
                      <i className="fas fa-share-alt emission-stat-icons no-route cursor-pointer skip-ripple"></i>
                      <span className="emission-copy-copy no-route cursor-pointer skip-ripple">
                        Copy{" "}
                      </span>
                      Link
                    </span>
                  </MDBTooltip>
                </div>
              )}
              {this.props.emission.children?.length ? (
                <>
                  <hr />
                  <div className="bg-emphasis p-3 rounded-6">
                    <h5 className="text-blusteel">
                      <i
                        style={{ color: "rgb(255, 171, 0)" }}
                        className="fas fa-comments me-2"
                      ></i>
                      Replies to
                      <span className="text-gb ms-2">
                        #{this.props.emission.emissionID}
                      </span>
                    </h5>
                    {this.props.emission.children.map((emission, e) => (
                      <Emission
                        emission={emission}
                        loaded={this.props.loaded}
                        animation={this.props.animation}
                        clickEmissionBody={this.props.clickEmissionBody}
                        vote={this.props.vote}
                        polls={this.props.polls}
                        submitVotes={this.props.submitVotes}
                        pollsSubmitting={this.props.pollsSubmitting}
                        selectFile={this.props.selectFile}
                        signalBoost={this.props.signalBoost}
                        like={this.props.like}
                        copyEmissionLink={this.props.copyEmissionLink}
                        emissionCopied={this.props.emissionCopied}
                        reply={this.props.reply}
                        index={e}
                        key={emission._id}
                        userInfo={this.props.userInfo}
                        remove={this.props.remove}
                        restore={this.props.restore}
                        report={this.props.report}
                        route={this.props.route}
                        loadingMore={this.props.loadingMore}
                        seeMore={this.props.seeMore}
                        setPollModal={this.props.setPollModal}
                        updateEmission={this.props.updateEmission}
                        clickPass={this.props.clickPass}
                        setClickPass={this.props.setClickPass}
                        token={this.props.token}
                        set_token={this.props.set_token}
                        emissionChild={true}
                      />
                    ))}
                    {this.props.emission.children.length <
                    this.props.emission.replies ? (
                      <div className="py-4 no-route">
                        {this.props.loadingMore?.indexOf(
                          this.props.emission.emissionID
                        ) > -1 ? (
                          <MDBBtn
                            size="lg"
                            disabled
                            className="d-block mx-auto w-50 no-route skip-ripple border-transparent"
                            rippleColor="primary"
                            outline
                            color="primary"
                            rounded
                          >
                            <Spinner
                              color="primary"
                              size="sm"
                              className="me-2 no-route skip-ripple"
                            />
                            Please Wait
                          </MDBBtn>
                        ) : (
                          <MDBBtn
                            size="lg"
                            onClick={() => {
                              if (this.props.feedPage)
                                this.props.route(
                                  "/e/" + this.props.emission.emissionID
                                );
                              else
                                this.props.seeMore(
                                  this.props.emission.emissionID
                                );
                            }}
                            className="d-block mx-auto w-50 no-route cursor-pointer skip-ripple"
                            rippleColor="primary"
                            outline
                            color="primary"
                            rounded
                          >
                            See More
                            {!this.props.feedPage && (
                              <>
                                {" "}
                                Replies to{" "}
                                <span className="text-gb no-route cursor-pointer skip-ripple">
                                  #{this.props.emission.emissionID}
                                </span>
                              </>
                            )}
                          </MDBBtn>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </MDBCardFooter>
          </MDBRipple>
        </MDBCard>
      </motion.div>
    );
  }
}

export default Emission;
