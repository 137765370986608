import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import { route } from "../redux/actions";

/**
 * This is a generic page that the user comes to for various messages
 * * After submitting a contact form
 * * After submitting a password reset request
 * * 404 Page
 */

const Message = ({ message, route }) => (
  <motion.div
    className="page-container pt-5"
    transition={t.transition}
    exit={t.fade_out_scale_1}
    animate={t.normalize}
    initial={t.fade_out}
  >
    <MDBContainer>
      <h1 className="display-5 mt-5 text-center">{message}</h1>
      <div className="d-flex justify-content-center mt-4">
        <MDBBtn color="link" rippleColor="primary" onClick={() => route("/")}>
          <i className="fas fa-home me-2" />
          Return
        </MDBBtn>
      </div>
    </MDBContainer>
  </motion.div>
);

const mapStateToProps = () => {};

export default connect(mapStateToProps, { route })(Message);
