import env from "../../../env";
import React from "react";
import { connect } from "react-redux";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBContainer,
  MDBRipple,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import h from "../../../utilities/helpers";
import t from "../../../utilities/transitions";
import { motion } from "framer-motion";
import UserActionModal from "./profileInfo/UserActionModal";
import MessageModal from "./profileInfo/MessageModal";
import ReportModal from "./profileInfo/ReportModal";
import axios from "axios";
import {
  follow,
  unfollow,
  set_temp_action,
  clear_temp_action,
  block,
  unblock,
  route,
  set_profile_reported,
  set_token,
  notify,
} from "../../../redux/actions";
import LiveStreamModal from "./profileInfo/LiveStreamModal";
import Ripple from "../../../components/Ripple";
import Spinner from "../../../components/Spinner";
import Url from "url-parse";
import Count from "../../../components/Count";

class ProfileInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * userActionModalShown: Boolean - Whether the User Action (edit/ban) modal is shown
       * messageModalShown: Boolean - Whether the Direct Message modal is shown
       * liveStreamModalShown: Boolean - Whether the Live Stream modal is shown
       * reportModalShown: Boolean - Whether the Report modal is shown
       * working: Boolean - Whether the user is in the process of following or unfollowing the profile
       * showUnfollowText: Boolean - Whether the Unfollow prompt should be shown
       * blocking: Boolean - Whether the user is in the process of blocking the profile
       * unblocking: Boolean - Whether the user is in the process of unblocking the profile
       */
      userActionModalShown: false,
      messageModalShown: false,
      liveStreamModalShown: false,
      reportModalShown: false,
      working: false,
      showUnfollowText: false,
      blocking: false,
      unblocking: false,
      streamReset: false,
    };
  }

  /**
   * If the user logs in from a login modal after attempting to perform an action, perform that action
   */
  componentDidMount() {
    if (
      this.props.userInfo._id !== this.props.profileInfo?.user?._id &&
      this.props.tempAction &&
      ["follow", "message"].indexOf(this.props.tempAction.label) > -1
    )
      this.triggerTempAction();
  }

  /**
   * If the user logs in from a login modal after attempting to perform an action, perform that action
   * If the profile is doing a live stream and then ends their stream, notify the user that the stream has ended
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.liveStreamModalShown &&
      !this.props.profileInfo?.user?.live &&
      (prevState.liveStreamModalShown || prevProps.profileInfo?.user?.live)
    )
      this.setState((curr) => ({
        ...curr,
        streamReset: !this.state.streamReset,
      }));
    if (
      prevProps.userInfo._id !== this.props.userInfo._id &&
      this.props.userInfo._id !== this.props.profileInfo?.user?._id &&
      this.props.tempAction &&
      ["follow", "message"].indexOf(this.props.tempAction.label) > -1
    )
      this.triggerTempAction();

    if (
      prevProps.profileInfo?.user &&
      this.props.profileInfo?.user &&
      (!prevProps.profileInfo?.user?.live ||
        !this.props.profileInfo?.user?.live) &&
      (prevProps.profileInfo?.user?.live ||
        this.props.profileInfo?.user?.live) &&
      !(this.props.userInfo._id === this.props.profileInfo?.user?._id)
    ) {
      if (this.props.profileInfo?.user?.live)
        this.props.notify(
          <i className="fas fa-broadcast-tower me-2 text-primary"></i>,
          `@${this.props.profileInfo?.user?.username} is streaming live`
        );
      else {
        this.setLiveStreamModal(false);
        this.props.notify(
          <i className="fas fa-broadcast-tower me-2 text-danger"></i>,
          `@${this.props.profileInfo?.user?.username} has ended their stream`
        );
      }
    }
  }

  /**
   * Execute the appropriate temp action upon logging in
   */
  triggerTempAction = () => {
    if (!this.props.userInfo.ban.banned) {
      switch (this.props.tempAction.label) {
        case "follow":
          if (!this.props.profileInfo.following) this.follow();
          break;
        case "message":
          setTimeout(this.message, 500);
          break;
        default:
          console.log("oob temp action", this.props.tempAction);
          break;
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );

    this.props.clear_temp_action();
  };

  toggleUserActionModal = () =>
    this.setState((curr) => ({
      ...curr,
      userActionModalShown: !this.state.userActionModalShown,
    }));

  setUserActionModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      userActionModalShown: option,
    }));

  toggleLiveStreamModal = () =>
    this.setState((curr) => ({
      ...curr,
      liveStreamModalShown: !this.state.liveStreamModalShown,
    }));

  setLiveStreamModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      liveStreamModalShown: option,
    }));

  toggleMessageModal = () =>
    this.setState((curr) => ({
      ...curr,
      messageModalShown: !this.state.messageModalShown,
    }));

  setMessageModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      messageModalShown: option,
    }));

  toggleReportModal = () =>
    this.setState((curr) => ({
      ...curr,
      reportModalShown: !this.state.reportModalShown,
    }));

  setReportModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      reportModalShown: option,
    }));

  /**
   * Triggered when the user clicks the Follow button
   * Follows the profile if logged in and not banned.
   */
  follow = (retry) => {
    if (!this.props.userInfo.ban.banned) {
      if (this.props.userInfo._id) {
        if (!this.state.working || retry)
          this.setState(
            (curr) => ({
              ...curr,
              working: true,
            }),
            () =>
              axios
                .get(
                  `${process.env.REACT_APP_LAMBDA_PROFILE}/follow/${this.props.profileInfo?.user?.user_id}`,
                  {
                    headers: {
                      Authorization: this.props.token,
                    },
                  }
                )
                .then((res) => {
                  this.props.set_token(res.data.token);
                  console.log("res", res.data.followData);
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                    }),
                    () => this.props.follow(res.data.followData)
                  );
                })
                .catch((err) => {
                  console.log(err);
                  setTimeout(() => this.follow(true), 1000);
                })
          );
      } else {
        this.props.toggleLoginModal();
        this.props.set_temp_action({
          label: "follow",
          tab: this.props.tabSelected,
        });
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   * Triggered when the user clicks the Unollow button
   * Unollows the profile
   */
  unfollow = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () =>
          axios
            .get(
              `${process.env.REACT_APP_LAMBDA_PROFILE}/unfollow/${this.props.profileInfo?.user?.user_id}`,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => this.props.follow(res.data.followData)
              );
            })
            .catch((err) => {
              console.log(err);
              setTimeout(this.unfollow, 1000);
            })
      );
  };

  showUnfollowText = () =>
    this.setState((curr) => ({
      ...curr,
      showUnfollowText: true,
    }));

  hideUnfollowText = () =>
    this.setState((curr) => ({
      ...curr,
      showUnfollowText: false,
    }));

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks the Send Message button
   * Opens the Message modal if the user is logged in. Otherwise, opens the Login modal.
   */
  message = (e) => {
    if (e) e.preventDefault();
    if (!this.props.userInfo.ban.banned) {
      if (this.props.userInfo._id) this.toggleMessageModal();
      else {
        this.props.toggleLoginModal();
        this.props.set_temp_action({
          label: "message",
          tab: this.props.tabSelected,
        });
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   * Returns a card background color based on the state of the emission
   *
   * If highlighted, light green
   * If author is blocked or emission is removed, light pink
   * If author blocks the user, light purple
   * If author has privated their account, light teal
   *
   * @returns A CSS background class
   */
  getCardBackground = () => {
    if (this.props.profileInfo?.user?.deleted) return "bg-litepink";
    else if (this.props.profileInfo.isBlocked) return "bg-litepurple";
    else if (this.props.profileInfo?.user?.ban?.banned) return "bg-litepink";
    else if (this.props.profileInfo.blocksMe) return "bg-litepurple";
    else if (this.props.profileInfo?.user?.private) return "bg-liteteal";
    else return "";
  };

  /**
   *
   * @param {Boolean} retry - Whether the method is being called from a previous failed call
   * @param {Click Event} e
   *
   * Triggered when the user clicks the Block button
   * Blocks the profile
   */
  block = (retry, e) => {
    if (e) e.preventDefault();
    if (!this.state.blocking && !retry)
      this.setState(
        (curr) => ({
          ...curr,
          blocking: true,
        }),
        () =>
          axios
            .post(
              process.env.REACT_APP_LAMBDA_PROFILE + "/block",
              {
                user_id: this.props.profileInfo?.user?.user_id,
                _id: this.props.profileInfo?.user?._id,
                username: this.props.profileInfo?.user?.username,
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  blocking: false,
                }),
                this.props.block
              );
            })
            .catch((err) => {
              console.log("block error", err);
              setTimeout(() => this.block(true), 1000);
            })
      );
  };

  /**
   *
   * @param {Boolean} retry - Whether the method is being called from a previous failed call
   * @param {Click Event} e
   *
   * Triggered when the user clicks the Block button
   * Unblocks the profile
   */
  unblock = (retry, e) => {
    if (e) e.preventDefault();
    if (!this.state.unblocking && !retry)
      this.setState(
        (curr) => ({
          ...curr,
          unblocking: true,
        }),
        () =>
          axios
            .post(
              process.env.REACT_APP_LAMBDA_PROFILE + "/unblock",
              {
                user_id: this.props.profileInfo?.user?.user_id,
                _id: this.props.profileInfo?.user?._id,
                username: this.props.profileInfo?.user?.username,
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  unblocking: false,
                }),
                this.props.unblock
              );
            })
            .catch((err) => {
              console.log("unblock error", err);
              setTimeout(() => this.unblock(true), 1000);
            })
      );
  };

  /**
   * A message will be displayed at the bottom of the main profile card if any of the following are true:
   * * User blocks the profile
   * * User is blocked by the profile
   * * Profile is banned
   * * Profile is private
   *
   * @returns The profile footer
   */
  getFooter = () => {
    if (this.props.profileInfo?.user?.deleted)
      return (
        <MDBContainer>
          <motion.section
            key="isBlocked"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">
              @{this.props.profileInfo?.user?.username} has deleted their
              account
            </h5>
          </motion.section>
        </MDBContainer>
      );
    else if (this.props.updating)
      return (
        <MDBContainer>
          <motion.div
            key="updating"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <div className="d-flex justify-content-center align-items-center">
              <Spinner color="primary" size="sm" className="me-2" />
              <h5 className="m-0">Updating Profile</h5>
            </div>
          </motion.div>
        </MDBContainer>
      );
    else if (this.props.profileInfo.isBlocked)
      return (
        <MDBContainer>
          <motion.section
            key="isBlocked"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">
              @{this.props.profileInfo?.user?.username} is blocked
            </h5>
          </motion.section>
        </MDBContainer>
      );
    else if (this.props.profileInfo.blocksMe)
      return (
        <MDBContainer>
          <motion.article
            key="blocksMe"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">You are blocked</h5>
          </motion.article>
        </MDBContainer>
      );
    else if (this.props.profileInfo?.user?.ban?.banned)
      return (
        <MDBContainer>
          <motion.div
            key="banned"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6">
              @{this.props.profileInfo?.user?.username} is banned
            </h5>
            <div className="bg-emphasis p-2 rounded-6">
              <h5>
                Ban reason:{" "}
                <span className="text-transform-capitalize">
                  {this.props.profileInfo?.user?.ban?.reason}
                </span>
              </h5>
              <p>{this.props.profileInfo?.user?.ban?.details}</p>
              <p className="text-center">{this.getBanExpiry()}</p>
            </div>
          </motion.div>
        </MDBContainer>
      );
    else if (this.props.profileInfo?.user?.private)
      return (
        <MDBContainer>
          <motion.section
            key="private"
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
          >
            <hr></hr>
            <h5 className="text-center display-6 mt-4 mb-2">
              @{this.props.profileInfo?.user?.username} has made their profile
              private
            </h5>
          </motion.section>
        </MDBContainer>
      );
    else return <></>;
  };

  /**
   *
   * @returns The ban expiration data of a banned user
   */
  getBanExpiry = () => {
    if (this.props.profileInfo?.user?.ban?.duration === "permanent")
      return "Ban is permanent";
    else {
      const expiry = new Date(this.props.profileInfo?.user?.ban?.timestamp);
      switch (this.props.profileInfo?.user?.ban?.duration) {
        case "day":
          expiry.setDate(expiry.getDate() + 1);
          break;
        case "week":
          expiry.setDate(expiry.getDate() + 7);
          break;
        case "month":
          expiry.setMonth(expiry.getMonth() + 1);
          break;
        default:
          console.log("ban is permanent");
      }
      return `Ban will expire on ${h.makeDateHR(expiry)} at ${h.getTimeHR(
        expiry
      )}`;
    }
  };

  /**
   *
   * Special profiles have gold numbers.
   * The following User user_ids are special:
   * * User 1
   * * Any user over 10 with all the same numbers (i.e. 666)
   * * Any user over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the user is a special user
   */
  checkSpecialProfile = () => {
    if (this.props.profileInfo?.user?.user_id === 1) return true;
    const split = String(this.props.profileInfo?.user?.user_id).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the profile's bio
   * If the user clicked a link, route to the href
   */
  clickBio = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (href) {
        const url = new Url(href);
        if (url.hostname === window.location.hostname)
          this.props.route(url.pathname);
        else window.location = href;
      }
    }
  };

  setReported = () =>
    this.setState(
      (curr) => ({
        ...curr,
        reportModalShown: false,
      }),
      () => {
        this.props.notify(
          <i className="fas fa-flag me-2 text-danger"></i>,
          `You have reported @${this.props.profileInfo?.user?.username}`
        );
        this.props.set_profile_reported();
      }
    );

  render() {
    return (
      <>
        {this.props.profileInfo?.user ? (
          <UserActionModal
            setShowModal={this.setUserActionModal}
            toggleShowModal={this.toggleUserActionModal}
            modalShown={this.state.userActionModalShown}
            googleReCaptchaProps={this.props.googleReCaptchaProps}
            notify={this.props.notify}
          />
        ) : (
          <></>
        )}
        {this.props.userInfo._id !== this.props.profileInfo?.user?._id && (
          <MessageModal
            setShowModal={this.setMessageModal}
            toggleShowModal={this.toggleMessageModal}
            modalShown={this.state.messageModalShown}
          />
        )}

        <ReportModal
          setShowModal={this.setReportModal}
          toggleShowModal={this.toggleReportModal}
          modalShown={this.state.reportModalShown}
          setReported={this.setReported}
        />
        {!this.props.userInfo.live && (
          <LiveStreamModal
            setShowModal={this.setLiveStreamModal}
            toggleShowModal={this.toggleLiveStreamModal}
            modalShown={this.state.liveStreamModalShown}
            notify={this.props.notify}
            key={this.state.streamReset}
          />
        )}
        <MDBCard
          className={`cards-full-width mt-4 ${this.getCardBackground()}`}
        >
          <MDBCardBody className="profile-cards">
            <div className="position-relative">
              <div
                rippleColor="light"
                className="w-100"
                style={{
                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.profileInfo?.user?.background.main}")`,
                }}
                id="background-image-profile"
              ></div>
              <div id="avatar-border-profile">
                <div
                  className="fit-round"
                  rippleColor="primary"
                  id="avatar-image-profile"
                  style={{
                    backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.profileInfo?.user?.avatar.main}")`,
                  }}
                ></div>
              </div>
            </div>
            <MDBContainer className="px-0 profile-info" fluid>
              <div className="row mx-0 align-items-start">
                {(!this.props.profileInfo?.user?.private ||
                  h.checkJanny(this.props.userInfo) ||
                  h.canSeePrivate(this.props.userInfo, {
                    ...this.props.profileInfo.user,
                    followsMe:
                      this.props.profileInfo.user.followsMe ||
                      this.props.profileInfo.followsYou,
                    blocksMe:
                      this.props.profileInfo.user.blocksMe ||
                      this.props.profileInfo.blocksMe,
                  })) &&
                !this.props.profileInfo?.user?.deleted ? (
                  <div className="col-8 col-xl-6 offset-0 offset-xl-2 px-0 d-flex justify-content-center">
                    {this.props.profileInfo?.user?.location && (
                      <p className="text-blusteel text-break profile-fact">
                        <i className="fas fa-map-marker-alt me-2"></i>
                        {this.props.profileInfo?.user?.location}
                      </p>
                    )}
                    {this.props.profileInfo?.user?.website && (
                      <a
                        className="ms-4 text-break profile-fact"
                        href={
                          this.props.profileInfo?.user?.website.startsWith(
                            "http://"
                          ) ||
                          this.props.profileInfo?.user?.website.startsWith(
                            "https://"
                          )
                            ? this.props.profileInfo?.user?.website
                            : `//${this.props.profileInfo?.user?.website}`
                        }
                      >
                        <p>
                          <i className="fas fa-link me-2"></i>
                          {this.props.profileInfo?.user?.website}
                        </p>
                      </a>
                    )}
                    <p className="text-blusteel ms-4 profile-fact">
                      <i className="far fa-calendar-alt me-2"></i>Joined{" "}
                      {h.getNiceDate(
                        this.props.profileInfo?.user?.creationDate
                      )}
                    </p>
                  </div>
                ) : (
                  <div className="col-8 col-xl-6 offset-0 offset-xl-2 px-0 d-flex justify-content-center">
                    <p className="invis">Private</p>
                  </div>
                )}
                {String(env.READ_ONLY) === "true" &&
                !h.checkChadmin(this.props.userInfo) ? (
                  <></>
                ) : (
                  <>
                    {((!this.props.profileInfo?.user?.private &&
                      !this.props.profileInfo?.user?.ban?.banned &&
                      !this.props.profileInfo.blocksMe) ||
                      h.checkJanny(this.props.userInfo) ||
                      h.canSeePrivate(this.props.userInfo, {
                        ...this.props.profileInfo.user,
                        followsMe:
                          this.props.profileInfo.user.followsMe ||
                          this.props.profileInfo.followsYou,
                        blocksMe:
                          this.props.profileInfo.user.blocksMe ||
                          this.props.profileInfo.blocksMe,
                      })) &&
                      !this.props.profileInfo?.user?.deleted && (
                        <div className="col-4 ps-1 pe-0 d-flex justify-content-end profile-action-buttons">
                          {!this.props.profileInfo.isBlocked && (
                            <>
                              {this.props.profileInfo.following ? (
                                <>
                                  {this.state.working ? (
                                    <MDBBtn
                                      className="text-nowrap profile-action-buttons"
                                      onMouseEnter={this.showUnfollowText}
                                      onMouseLeave={this.hideUnfollowText}
                                      disabled
                                      color="dark"
                                    >
                                      <Spinner className="me-2" size="sm" />
                                      {env.UNFOLLOW_CURRENT}
                                    </MDBBtn>
                                  ) : (
                                    <MDBBtn
                                      onMouseEnter={this.showUnfollowText}
                                      onMouseLeave={this.hideUnfollowText}
                                      onClick={this.unfollow}
                                      color="dark"
                                      className="text-nowrap profile-action-buttons"
                                    >
                                      {this.state.showUnfollowText ? (
                                        <motion.p
                                          key="unfollow-shown"
                                          className="m-0"
                                          transition={t.transition}
                                          exit={t.fade_out_minimize}
                                          animate={t.normalize}
                                          initial={t.fade_out_minimize}
                                        >
                                          <i className="fas fa-user-minus me-2"></i>
                                          {env.UNFOLLOW_VERB}
                                        </motion.p>
                                      ) : (
                                        <motion.div
                                          key="unfollow-hide"
                                          transition={t.transition}
                                          exit={t.fade_out_minimize}
                                          animate={t.normalize}
                                          initial={t.fade_out_minimize}
                                        >
                                          <i className="fas fa-user-check me-2"></i>
                                          {env.FOLLOW_CURRENT}
                                        </motion.div>
                                      )}
                                    </MDBBtn>
                                  )}
                                </>
                              ) : (
                                <>
                                  {this.state.working ? (
                                    <MDBBtn
                                      className="bg-darkblu text-nowrap profile-action-buttons"
                                      onMouseEnter={this.showUnfollowText}
                                      onMouseLeave={this.hideUnfollowText}
                                      disabled
                                    >
                                      <Spinner className="me-2" size="sm" />
                                      {env.FOLLOW_CURRENT}
                                    </MDBBtn>
                                  ) : (
                                    <MDBBtn
                                      className="bg-darkblu text-nowrap profile-action-buttons"
                                      onMouseEnter={this.showUnfollowText}
                                      onMouseLeave={this.hideUnfollowText}
                                      onClick={this.follow}
                                    >
                                      <i className="fas fa-user-plus me-2"></i>
                                      {env.FOLLOW_VERB}
                                    </MDBBtn>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          <MDBDropdown className="ms-2">
                            <MDBDropdownToggle
                              rippleColor="primary"
                              color="link"
                              className="profile-bubbles"
                            >
                              <i
                                style={{ lineHeight: "1.5" }}
                                className="fas fa-ellipsis-v fa-lg"
                              ></i>
                            </MDBDropdownToggle>
                            <MDBDropdownMenu>
                              {(!this.props.profileInfo?.user
                                ?.messagesDisabled &&
                                !this.props.profileInfo.isBlocked) ||
                              h.checkJanny(this.props.userInfo) ? (
                                <MDBDropdownItem onClick={this.message} link>
                                  <h6 className="m-1">
                                    <i className="fas fa-envelope me-2 text-primary"></i>
                                    Message
                                  </h6>
                                </MDBDropdownItem>
                              ) : (
                                <></>
                              )}
                              {this.props.profileInfo?.user?.user_id !== 1 ? (
                                <MDBDropdownItem
                                  onClick={
                                    this.props.profileInfo.reported
                                      ? () => {}
                                      : this.toggleReportModal
                                  }
                                  link
                                  className={
                                    this.props.profileInfo.reported
                                      ? "cursor-default-child"
                                      : ""
                                  }
                                >
                                  <h6 className="m-1">
                                    <i
                                      className={`${
                                        this.props.profileInfo.reported
                                          ? "far fa-check-circle"
                                          : "fas fa-flag"
                                      } me-2 text-secondary`}
                                    ></i>
                                    Report
                                    {this.props.profileInfo.reported
                                      ? "ed"
                                      : ""}
                                  </h6>
                                </MDBDropdownItem>
                              ) : (
                                <></>
                              )}

                              {this.props.userInfo._id ? (
                                <>
                                  {this.props.profileInfo.isBlocked ? (
                                    <MDBDropdownItem
                                      onClick={(e) => this.unblock(false, e)}
                                      link
                                    >
                                      <h6
                                        style={{
                                          opacity: this.state.unblocking
                                            ? 0.7
                                            : 1,
                                        }}
                                        className="text-success m-1"
                                      >
                                        {this.state.unblocking ? (
                                          <>
                                            <Spinner
                                              color="success"
                                              size="sm"
                                              className="me-2"
                                            />
                                            Unblocking
                                          </>
                                        ) : (
                                          <>
                                            <i className="fas fa-user-check me-2"></i>
                                            Unblock @
                                            {
                                              this.props.profileInfo?.user
                                                ?.username
                                            }
                                          </>
                                        )}
                                      </h6>
                                    </MDBDropdownItem>
                                  ) : (
                                    <MDBDropdownItem
                                      onClick={(e) => this.block(false, e)}
                                      link
                                    >
                                      <h6
                                        style={{
                                          opacity: this.state.blocking
                                            ? 0.7
                                            : 1,
                                        }}
                                        className="text-danger m-1"
                                      >
                                        {this.state.blocking ? (
                                          <>
                                            <Spinner
                                              color="danger"
                                              size="sm"
                                              className="me-2"
                                            />
                                            Blocking
                                          </>
                                        ) : (
                                          <>
                                            <i className="fas fa-user-minus me-2"></i>
                                            Block @
                                            {
                                              this.props.profileInfo?.user
                                                ?.username
                                            }
                                          </>
                                        )}
                                      </h6>
                                    </MDBDropdownItem>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </div>
                      )}
                  </>
                )}
              </div>
            </MDBContainer>
            <div className="d-flex justify-content-between profile-profile-info">
              <div className="d-flex flex-column align-self-stretch min-w-20">
                <h5 className="mb-0">
                  {h.getBadge(this.props.profileInfo?.user, "me-2 badge-h5")}
                  {this.props.profileInfo?.user?.displayName}
                </h5>
                <p className="text-blusteel mb-1">
                  @{this.props.profileInfo?.user?.username}
                </p>
                {this.props.profileInfo.followsYou &&
                  !this.props.profileInfo.blocksMe && (
                    <motion.h6
                      className="m-0 text-success"
                      transition={t.transition}
                      exit={t.fade_out_minimize}
                      animate={t.normalize}
                      initial={t.fade_out_minimize}
                    >
                      {env.FOLLOW_VERB}s You
                    </motion.h6>
                  )}
                <div className="d-flex flex-column justify-content-end flex-grow-1">
                  {String(env.READ_ONLY) === "true" &&
                  !h.checkChadmin(this.props.userInfo) ? (
                    <></>
                  ) : (
                    <>
                      {h.checkJanny(this.props.userInfo) &&
                        !this.props.profileInfo?.user?.deleted &&
                        !(
                          !h.checkChadmin(this.props.userInfo) &&
                          h.checkJanny(this.props.profileInfo?.user)
                        ) && (
                          <MDBRipple
                            onClick={this.toggleUserActionModal}
                            className="cursor-pointer pe-1 py-1 rounded w-max-content"
                            rippleColor="primary"
                            id="edit-profile-button"
                          >
                            <h6 className="text-primary">
                              <i className="fas fa-edit me-2"></i>
                              Manage User
                            </h6>
                          </MDBRipple>
                        )}
                    </>
                  )}

                  {(!(
                    this.props.profileInfo?.user?.ban?.banned ||
                    this.props.profileInfo.blocksMe ||
                    this.props.profileInfo.isBlocked ||
                    this.props.profileInfo?.user?.private
                  ) ||
                    this.props.userInfo._id ===
                      this.props.profileInfo?.user?._id ||
                    h.checkJanny(this.props.userInfo) ||
                    h.canSeePrivate(this.props.userInfo, {
                      ...this.props.profileInfo.user,
                      followsMe:
                        this.props.profileInfo.user.followsMe ||
                        this.props.profileInfo.followsYou,
                      blocksMe:
                        this.props.profileInfo.user.blocksMe ||
                        this.props.profileInfo.blocksMe,
                    })) &&
                    !this.props.profileInfo?.user?.deleted && (
                      <div className="d-flex">
                        <p className="me-2 mb-0">
                          <span
                            title={
                              h.numberWithCommas(
                                this.props.profileInfo?.user?.followers
                              ) +
                              " " +
                              (this.props.profileInfo?.user?.followers === 1
                                ? env.FOLLOW_NAME
                                : env.FOLLOW_PLURAL)
                            }
                            className="fw-bold"
                          >
                            <Count
                              value={this.props.profileInfo?.user?.followers}
                              className="fw-bold"
                            />
                          </span>{" "}
                          {this.props.profileInfo?.user?.followers === 1 ? (
                            <span className="text-capitalize text-blusteel">
                              {env.FOLLOW_NAME}
                            </span>
                          ) : (
                            <span className="text-capitalize text-blusteel">
                              {env.FOLLOW_PLURAL}
                            </span>
                          )}
                        </p>
                        <p
                          title={
                            h.numberWithCommas(
                              this.props.profileInfo?.user?.following
                            ) +
                            " " +
                            env.FOLLOW_CURRENT
                          }
                          className="mb-0"
                        >
                          <span className="fw-bold">
                            <Count
                              value={this.props.profileInfo?.user?.following}
                              className="fw-bold"
                            />
                          </span>{" "}
                          <span className="text-blusteel">
                            {env.FOLLOW_CURRENT}
                          </span>
                        </p>
                      </div>
                    )}
                </div>
              </div>
              {this.props.profileInfo?.user?.bio &&
              h.checkHTMLLength(this.props.profileInfo?.user?.bio, true) &&
              (!this.props.profileInfo?.user?.private ||
                h.checkJanny(this.props.profileInfo) ||
                h.canSeePrivate(this.props.userInfo, {
                  ...this.props.profileInfo.user,
                  followsMe:
                    this.props.profileInfo.user.followsMe ||
                    this.props.profileInfo.followsYou,
                  blocksMe:
                    this.props.profileInfo.user.blocksMe ||
                    this.props.profileInfo.blocksMe,
                })) &&
              !this.props.profileInfo?.user?.deleted ? (
                <div className="flex-grow-1 align-self-stretch bg-emphasis mt-4 mx-2 p-2 rounded-6 text-break min-w-50">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.profileInfo?.user?.bio,
                    }}
                    onClick={this.clickBio}
                  ></div>
                </div>
              ) : (
                <div className="flex-grow-1 align-self-stretch mt-4 mx-2 p-2 rounded-6 text-break min-w-50"></div>
              )}
              {((!this.props.profileInfo?.user?.ban?.banned &&
                !this.props.profileInfo.blocksMe &&
                !this.props.profileInfo?.user?.deleted &&
                !this.props.profileInfo?.user?.private) ||
                h.checkJanny(this.props.userInfo) ||
                h.canSeePrivate(this.props.userInfo, {
                  ...this.props.profileInfo.user,
                  followsMe:
                    this.props.profileInfo.user.followsMe ||
                    this.props.profileInfo.followsYou,
                  blocksMe:
                    this.props.profileInfo.user.blocksMe ||
                    this.props.profileInfo.blocksMe,
                })) && (
                <>
                  {this.props.profileInfo.stats ? (
                    <div className="min-w-20">
                      <h5
                        className={`mb-0 text-gb text-end mt-3 text-${
                          this.checkSpecialProfile() ? "gold" : "default"
                        }`}
                      >
                        #{this.props.profileInfo?.user?.user_id}
                      </h5>
                      {(!this.props.profileInfo.isBlocked ||
                        h.checkJanny(this.props.userInfo)) &&
                        !this.props.profileInfo?.user?.deleted && (
                          <motion.div
                            className="p-2"
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                          >
                            <div className="d-flex justify-content-between">
                              <i className="fas fa-chart-line fa-lg text-success d-block"></i>
                              <h5
                                title={
                                  h.numberWithCommas(
                                    Number(
                                      this.props.profileInfo.emissions
                                        .totalNoReply
                                    ) +
                                      Number(
                                        this.props.profileInfo.emissions
                                          .totalReplies
                                      )
                                  ) +
                                  " " +
                                  (Number(
                                    this.props.profileInfo.emissions
                                      .totalNoReply
                                  ) +
                                    Number(
                                      this.props.profileInfo.emissions
                                        .totalReplies
                                    ) ===
                                  1
                                    ? env.EMISSION_NAME
                                    : env.EMISSION_PLURAL)
                                }
                                className="m-0"
                              >
                                <Count
                                  value={
                                    Number(
                                      this.props.profileInfo.emissions
                                        .totalNoReply
                                    ) +
                                    Number(
                                      this.props.profileInfo.emissions
                                        .totalReplies
                                    )
                                  }
                                />{" "}
                                {Number(
                                  this.props.profileInfo.emissions.totalNoReply
                                ) +
                                  Number(
                                    this.props.profileInfo.emissions
                                      .totalReplies
                                  ) ===
                                1 ? (
                                  <span className="text-capitalize">
                                    {env.EMISSION_NAME}
                                  </span>
                                ) : (
                                  <span className="text-capitalize">
                                    {env.EMISSION_PLURAL}
                                  </span>
                                )}
                              </h5>
                            </div>
                            <hr className="my-1"></hr>
                            <div className="d-flex justify-content-between profile-stat-container">
                              <div className="me-2">
                                <p className="m-0 profile-likes">
                                  <span
                                    title={
                                      h.numberWithCommas(
                                        this.props.profileInfo.likes.total
                                      ) +
                                      " Like" +
                                      (this.props.profileInfo.likes.total === 1
                                        ? ""
                                        : "s")
                                    }
                                    className="fw-bold"
                                  >
                                    <Count
                                      value={this.props.profileInfo.likes.total}
                                      className="fw-bold"
                                    />
                                  </span>{" "}
                                  Like
                                  {this.props.profileInfo.likes.total === 1
                                    ? ""
                                    : "s"}
                                </p>
                                <p
                                  title={
                                    h.numberWithCommas(
                                      this.props.profileInfo.stats.likes
                                    ) + " Liked by Others"
                                  }
                                  className="m-0 profile-likes"
                                >
                                  <span className="fw-bold">
                                    <Count
                                      value={this.props.profileInfo.stats.likes}
                                      className="fw-bold"
                                    />
                                  </span>{" "}
                                  Liked by Others
                                </p>
                              </div>
                              <div className="ms-2">
                                <p
                                  title={
                                    h.numberWithCommas(
                                      this.props.profileInfo.stats.signalBoosts
                                    ) +
                                    " " +
                                    (this.props.profileInfo.stats
                                      .signalBoosts === 1
                                      ? env.SIGNALBOOST_NAME
                                      : env.SIGNALBOOST_PLURAL)
                                  }
                                  className="m-0 text-end"
                                >
                                  <span className="fw-bold">
                                    <Count
                                      value={
                                        this.props.profileInfo.stats
                                          .signalBoosts
                                      }
                                      className="fw-bold"
                                    />
                                  </span>{" "}
                                  {this.props.profileInfo.stats.signalBoosts ===
                                  1 ? (
                                    <span className="text-capitalize">
                                      {env.SIGNALBOOST_NAME}
                                    </span>
                                  ) : (
                                    <span className="text-capitalize">
                                      {env.SIGNALBOOST_PLURAL}
                                    </span>
                                  )}
                                </p>
                                <p
                                  title={
                                    h.numberWithCommas(
                                      this.props.profileInfo.stats.signalBoosted
                                    ) +
                                    " " +
                                    env.SIGNALBOOST_PAST +
                                    " by Others"
                                  }
                                  className="m-0 text-end"
                                >
                                  <span className="fw-bold">
                                    <Count
                                      value={
                                        this.props.profileInfo.stats
                                          .signalBoosted
                                      }
                                      className="fw-bold"
                                    />
                                  </span>{" "}
                                  <span className="text-capitalize">
                                    {env.SIGNALBOOST_PAST}
                                  </span>{" "}
                                  by Others
                                </p>
                              </div>
                            </div>
                          </motion.div>
                        )}
                    </div>
                  ) : (
                    <div className="min-w-20">
                      <h5 className="mb-0 text-gb text-end mt-3">
                        #{this.props.profileInfo?.user?.user_id}
                      </h5>
                      <section className="p-2 invis">
                        <div className="d-flex justify-content-between">
                          <i className="fas fa-chart-line fa-lg text-success d-block"></i>
                          <h5 className="m-0">0 Emissions</h5>
                        </div>
                        <hr className="my-1"></hr>
                        <div className="d-flex justify-content-between profile-stat-container">
                          <div className="me-2">
                            <p className="m-0 profile-likes">
                              <span className="fw-bold">0</span> Likes
                            </p>
                            <p className="m-0 profile-likes">
                              <span className="fw-bold">0</span> Liked by Others
                            </p>
                          </div>
                          <div className="ms-2">
                            <p className="m-0 text-end">
                              <span className="fw-bold">0</span>{" "}
                              <span className="text-capitalize">
                                {env.SIGNALBOOST_PLURAL}
                              </span>
                            </p>
                            <p className="m-0 text-end">
                              <span className="fw-bold">0</span>{" "}
                              <span className="text-capitalize">
                                {env.SIGNALBOOST_PAST}
                              </span>{" "}
                              by Others
                            </p>
                          </div>
                        </div>
                      </section>
                    </div>
                  )}
                </>
              )}
            </div>
            {this.getFooter()}
          </MDBCardBody>
          {this.props.profileInfo?.user?.live &&
          ((!this.props.userInfo.live &&
            !this.props.profileInfo.isBlocked &&
            !this.props.profileInfo.blocksMe &&
            !this.props.profileInfo?.user?.ban?.banned &&
            !this.props.profileInfo?.user?.private &&
            !this.props.profileInfo?.user?.deleted) ||
            h.checkJanny(this.props.userInfo) ||
            h.canSeePrivate(this.props.userInfo, {
              ...this.props.profileInfo.user,
              followsMe:
                this.props.profileInfo.user.followsMe ||
                this.props.profileInfo.followsYou,
              blocksMe:
                this.props.profileInfo.user.blocksMe ||
                this.props.profileInfo.blocksMe,
            })) ? (
            <motion.span
              transition={t.transition}
              exit={t.fade_out_minimize}
              animate={t.normalize}
              initial={t.fade_out_minimize}
            >
              <MDBCardFooter
                onClick={this.toggleLiveStreamModal}
                className="bg-pink text-nowrap cursor-pointer"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <Ripple />
                  <p className="mb-0 ms-2 text-light">Live now</p>
                </div>
              </MDBCardFooter>
            </motion.span>
          ) : (
            <></>
          )}
        </MDBCard>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  follow,
  unfollow,
  set_temp_action,
  clear_temp_action,
  block,
  unblock,
  route,
  set_profile_reported,
  set_token,
  notify,
})(ProfileInfo);
