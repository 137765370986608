import env from "../env";
import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import {
  route,
  set_unread_mod_logs,
  set_token,
  set_redirect_url,
  set_cache,
} from "../redux/actions";
import {
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBCard,
  MDBCardBody,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";
import LogoLoader from "../components/LogoLoader";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";

let loadingMore = false;

class ModLogs extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    if (props.history?.location?.state?.currRoute.split("#")[0] === "/logs") {
      cachedData = props.cache.find((c) => c.page === "logs");
      this.initialScrollTop = cachedData?.scrollTop;
    }
    this.state = cachedData
      ? cachedData.state
      : {
          /**
           * loaded: Boolean - Whether the initial data has been loaded
           * modLogs: Array - List of mod logs
           * endReached: Boolean - Whether the last mod log is listed on the page
           * loadingMore: Boolean - Whether the user is currently loading more mod logs
           */
          loaded: false,
          rendered: false,
          modLogs: [],
          endReached: true,
          loadingMore: false,
          redirecting: false,
          retry: true,
        };
  }

  /**
   * If the user is not logged in, redirect to the login page
   * Load the initial data
   */
  componentDidMount() {
    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
    setTimeout(this.update, 5000);
    document.getElementById("root").addEventListener("scroll", this.scroll);
    if (!this.props.userInfo._id || !h.checkChadmin(this.props.userInfo)) {
      if (this.props.verificationDetails)
        this.props.history.push("/validate-email");
      else
        this.setState(
          (curr) => ({
            ...curr,
            redirecting: true,
          }),
          () => {
            this.props.set_redirect_url("/logs");
            this.props.history.push("/login");
          }
        );
    } else this.load();
  }

  /**
   * If the user logs out, redirect to the login page
   * If a new mod log is received, load updates from the server
   */
  componentDidUpdate(prevProps) {
    if (!this.props.userInfo._id && !this.state.redirecting)
      this.setState(
        (curr) => ({
          ...curr,
          redirecting: true,
        }),
        () => {
          this.props.route("/login");
        }
      );
    if (prevProps.unreadModLogs !== this.props.unreadModLogs) this.update();
  }

  componentWillUnmount() {
    this.props.set_cache({
      page: "logs",
      state: this.state,
      scrollTop: document.getElementById("root")?.scrollTop,
    });
    document.getElementById("root").removeEventListener("scroll", this.scroll);
  }

  scroll = (e) => {
    if (
      !loadingMore &&
      this.state.modLogs.length &&
      !this.state.loadingMore &&
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        0.1 * e.target.clientHeight
    ) {
      loadingMore = true;
      this.more();
      setTimeout(() => (loadingMore = false), 500);
    }
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, destination) => {
    e.preventDefault();
    this.props.route(destination);
  };

  /**
   * Loads the initial list of mod logs
   * If there are fewer than 100, then the end has already been reached
   */
  load = () =>
    axios
      .get(process.env.REACT_APP_LAMBDA_API_REPORTS + "/mod-logs", {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState(
          (curr) => ({
            ...curr,
            modLogs: h.updateArrayItems(this.state.modLogs, res.data.modLogs),
            loaded: true,
            endReached: res.data.modLogs.length < 100,
          }),
          () =>
            this.setState(
              (curr) => ({
                ...curr,
                rendered: true,
              }),
              this.readAll
            )
        );
      })
      .catch((err) => {
        console.log("mod log load err", err);
        if (!this.state.redirecting) setTimeout(this.load, 1000);
      });

  /**
   * Triggered when the user clicks the "View More" button at the bottom of the page
   * Loads the next 100 mod logs
   */
  more = (retry) => {
    if (!this.state.loadingMore || retry)
      this.setState(
        (curr) => ({
          ...curr,
          loadingMore: true,
        }),
        () =>
          axios
            .post(
              process.env.REACT_APP_LAMBDA_API_REPORTS + "/mod-logs-more",
              {
                logIDs: this.state.modLogs.map((log) => log._id),
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  modLogs: [...curr.modLogs, ...res.data.modLogs],
                  endReached: res.data.modLogs.length < 100,
                  loadingMore: false,
                }),
                this.readAll
              );
            })
            .catch((err) => {
              console.log("mod log more err", err);
              if (!this.state.redirecting)
                setTimeout(() => this.more(true), 1000);
            })
      );
  };

  /**
   * Triggered when notified of a mod log update via socket
   * Pings the server for the update
   * Updates state
   */
  update = () =>
    axios
      .post(
        process.env.REACT_APP_LAMBDA_API_REPORTS + "/mod-logs-update",
        {
          logIDs: this.state.modLogs.map((log) => log._id),
          timestamp: this.state.modLogs.length
            ? this.state.modLogs[0].timestamp
            : new Date(new Date().setMinutes(new Date().getMinutes() - 1)),
        },
        {
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState(
          (curr) => ({
            ...curr,
            modLogs: [...curr.modLogs, ...res.data.modLogs],
          }),
          this.readAll
        );
      })
      .catch((err) => {
        console.log("update err", err);
        if (!this.state.redirecting) setTimeout(this.update, 1000);
      });

  /**
   * Triggered any time the user loads the page or receives new mod logs
   * After 1 second, marks all mod logs as read
   */
  readAll = () =>
    setTimeout(
      () =>
        axios
          .get(process.env.REACT_APP_LAMBDA_PROFILE + "/read-mod-logs", {
            headers: {
              Authorization: this.props.token,
            },
          })
          .then((res) => {
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                modLogs: this.state.modLogs.map((m) => ({
                  ...m,
                  unread: false,
                })),
              }),
              () => this.props.set_unread_mod_logs(0)
            );
          })
          .catch((err) => {
            console.log("readAll error", err);
            this.readAll();
          }),
      1000
    );

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="py-4 h-100 mod-log-container page-container"
      >
        <MDBContainer>
          {this.state.loaded ? (
            <motion.div
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
            >
              {this.state.modLogs.length ? (
                <MDBCard className="cards-full-width">
                  <MDBCardBody>
                    <h5 className="text-center display-6">Mod Logs</h5>
                    <hr></hr>
                    <MDBListGroup light>
                      {this.state.modLogs
                        .sort(
                          (a, b) =>
                            new Date(b.timestamp) - new Date(a.timestamp)
                        )
                        .map((log) => {
                          const details = {
                            mod: (
                              <Link
                                className="text-primary"
                                to={`/${log.modInfo.username}`}
                                onClick={(e) =>
                                  this.route(e, `/${log.modInfo.username}`)
                                }
                              >
                                @{log.modInfo.username}
                              </Link>
                            ),
                            time: (
                              <div className="me-2 modlog-time">
                                <p className="text-blusteel m-0">
                                  {h.makeDateHR(log.timestamp)}
                                </p>
                                <p className="text-blusteel m-0 modlog-timehr">
                                  {h.getTimeHR(log.timestamp)}
                                </p>
                              </div>
                            ),
                          };
                          if (log.type === "user") {
                            details.recipient = (
                              <Link
                                className="text-primary"
                                to={`/${log.userInfo.username}`}
                                onClick={(e) =>
                                  this.route(e, `/${log.userInfo.username}`)
                                }
                              >
                                @{log.userInfo.username}
                              </Link>
                            );
                            switch (log.action) {
                              case "ban":
                                details.icon = (
                                  <i className="fas fa-gavel text-danger"></i>
                                );
                                details.action = (
                                  <span className="text-default"> banned </span>
                                );
                                break;
                              case "dismiss":
                                details.icon = (
                                  <i className="far fa-check-circle text-primary"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    dismissed all reports against{" "}
                                  </span>
                                );
                                break;
                              case "edit":
                                details.icon = (
                                  <i className="fas fa-edit text-primary"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    edited the user information of{" "}
                                  </span>
                                );
                                break;
                              case "restore":
                                details.icon = (
                                  <i className="far fa-check-circle text-success"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    lifted the ban of{" "}
                                  </span>
                                );
                                break;
                              case "verify":
                                details.icon = (
                                  <i className="far fa-check-circle text-success"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    verified{" "}
                                  </span>
                                );
                                break;
                              case "unverify":
                                details.icon = (
                                  <i className="far fa-times-circle text-danger"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    removed verification from{" "}
                                  </span>
                                );
                                break;
                              case "approve":
                                details.icon = (
                                  <i className="far fa-check-circle text-success"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    approved{" "}
                                  </span>
                                );
                                break;
                              case "reject":
                                details.icon = (
                                  <i className="far fa-times-circle text-danger"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    rejected{" "}
                                  </span>
                                );
                                break;
                              default:
                                console.log("oob log action", log);
                                details.icon = (
                                  <i className="far fa-check-circle text-primary"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    took action against{" "}
                                  </span>
                                );
                                break;
                            }
                          } else {
                            details.recipient = (
                              <>
                                <span className="text-capitalize">
                                  {env.EMISSION_NAME}
                                </span>{" "}
                                <Link
                                  to={`/e/${log.emissionID}`}
                                  onClick={(e) =>
                                    this.route(e, `/e/${log.emissionID}`)
                                  }
                                  className="text-primary"
                                >
                                  #{log.emissionID}
                                </Link>
                              </>
                            );
                            switch (log.action) {
                              case "remove":
                                details.icon = (
                                  <i className="fas fa-gavel text-danger"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    removed{" "}
                                  </span>
                                );
                                break;
                              case "dismiss":
                              case "dismiss":
                                details.icon = (
                                  <i className="far fa-check-circle text-primary"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    dismissed all reports against{" "}
                                  </span>
                                );
                                break;
                              case "restore":
                                details.icon = (
                                  <i className="far fa-check-circle text-success"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    restored{" "}
                                  </span>
                                );
                                break;
                              default:
                                console.log("oob log action", log);
                                details.icon = (
                                  <i className="far fa-check-circle text-primary"></i>
                                );
                                details.action = (
                                  <span className="text-default">
                                    {" "}
                                    took action against{" "}
                                  </span>
                                );
                                break;
                            }
                          }
                          return (
                            <MDBListGroupItem
                              className={`${
                                log.unread ? "bg-litepink" : ""
                              } transition-1 px-2`}
                              key={log._id}
                            >
                              <motion.div
                                className="d-flex align-items-center notification-icon-avatar"
                                transition={t.transition}
                                exit={
                                  this.state.rendered ? t.fade_out : t.normalize
                                }
                                animate={t.normalize}
                                initial={
                                  this.state.rendered ? t.fade_out : t.normalize
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <h5
                                    style={{ width: "30px" }}
                                    className="me-2"
                                  >
                                    {details.icon}
                                  </h5>
                                  <div className="align-self-start">
                                    {details.time}
                                  </div>
                                </div>

                                <div className="align-self-start">
                                  <h5 className="mod-log-text">
                                    {details.mod}
                                    {details.action}
                                    {details.recipient}
                                  </h5>
                                </div>
                              </motion.div>
                            </MDBListGroupItem>
                          );
                        })}
                    </MDBListGroup>
                    {this.state.endReached ? (
                      <></>
                    ) : (
                      <div className="pt-4 pb-2">
                        {this.state.loadingMore ? (
                          <MDBBtn
                            size="lg"
                            disabled
                            className="d-block mx-auto w-50 border-transparent"
                            rippleColor="primary"
                            outline
                            color="primary"
                            rounded
                          >
                            <Spinner
                              color="primary"
                              size="sm"
                              className="me-2"
                            />
                            Please Wait
                          </MDBBtn>
                        ) : (
                          <MDBBtn
                            size="lg"
                            onClick={this.more}
                            className="d-block mx-auto w-50"
                            rippleColor="primary"
                            outline
                            color="primary"
                            rounded
                          >
                            See More
                          </MDBBtn>
                        )}
                      </div>
                    )}
                  </MDBCardBody>
                </MDBCard>
              ) : (
                <h5 className="text-center display-4 mb-4 mt-5">
                  There are no mod logs
                </h5>
              )}
            </motion.div>
          ) : (
            <>
              <h5 className="text-center display-6 mb-4 mt-5">Mod Logs</h5>
              <LogoLoader />
            </>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  set_unread_mod_logs,
  set_token,
  set_redirect_url,
  set_cache,
})(ModLogs);
