import React from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { connect } from "react-redux";
import { route, set_token } from "../redux/actions";
import { forgot_password_schema } from "../utilities/validations";
import t from "../utilities/transitions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import Spinner from "../components/Spinner";

const fields = [
  {
    text: "Username",
    id: "username",
  },
  {
    text: "Email Address",
    id: "email",
  },
];

class ForgotPassword extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * working: Boolean - whether the request is pending on the server
       * exit: Object - framer-motion exit transition
       * inputs: Array - The input data (values, errors, etc)
       */
      working: false,
      exit: this.getExit(props),
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
    };
  }

  /**
   * Set exit transition to default values upon mount
   * Run empty change handler
   */
  componentDidMount() {
    this.setState(
      (curr) => ({
        ...curr,
        exit: t.fade_out,
      }),
      () =>
        this.changeHandler({
          target: {
            name: "",
          },
        })
    );
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * If the user is coming from the login page, fade in from the right
   * Otherwise, fade in
   */
  getExit = (props) => {
    const h = props.historyStack;
    if (h[h.length - 1] === "/login") return t.fade_out_right;
    else return t.fade_out;
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Route to the "Check your email" page
   */
  submit = () => {
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value.trim()])
          );
          try {
            forgot_password_schema.validateSync(data, {
              abortEarly: false,
            });
            const captchaKey = await this.getRecaptcha();
            const fd = {};
            for (const key in data) {
              fd[key] = data[key];
            }
            fd["captchaKey"] = captchaKey;
            axios
              .post(
                process.env.REACT_APP_LAMBDA_AUTH + "/forgot-password",
                fd,
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                if (res.data.success) this.props.route("/check-email");
                else
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                    }),
                    () => alert(res.data.error)
                  );
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    if (
                      err.response &&
                      err.response.status &&
                      err.response.status === 404
                    )
                      alert("Username/Email combination not found");
                    else alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   * Fired when the user clicks the Back button
   * Set the component to fade out to the right, then navigate to the intended endpoint
   */
  back = () =>
    this.setState(
      (curr) => ({
        ...curr,
        exit: t.fade_out_right,
      }),
      () => this.props.route("/login")
    );

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          forgot_password_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState((curr) => ({
            ...curr,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          }));
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            (curr) => ({
              ...curr,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            }),
            () =>
              this.state.inputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              )
          );
        }
      }
    );

  /**
   *
   * @param {KeyPress Event} e
   *
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField =
          this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  render() {
    return (
      <motion.div
        className="py-4 page-container"
        transition={t.transition}
        exit={this.state.exit}
        animate={t.normalize}
        initial={this.state.exit}
      >
        <MDBContainer>
          <h1 className="display-6 text-center">Forgot Password</h1>
          <hr></hr>
          <div className="mx-auto mt-2 form-containers">
            <MDBValidation id="form" onSubmit={this.submit}>
              {fields.map((i) => (
                <MDBValidationItem
                  key={i.id}
                  className="pb-4 z-index-50-child"
                  feedback={
                    this.state.inputs.find((input) => input.id === i.id).error
                  }
                  invalid={true}
                >
                  <MDBInput
                    name={i.id}
                    onChange={this.changeHandler}
                    id={i.id}
                    label={i.text}
                    size="lg"
                    className={`${
                      !this.state.inputs.find((input) => input.id === i.id)
                        .invalid
                        ? "mb-0"
                        : 0
                    }`}
                    onKeyPress={this.pressEnter}
                    onKeyDown={this.pressTab}
                  />
                </MDBValidationItem>
              ))}
            </MDBValidation>
            {this.state.working ? (
              <MDBBtn
                color="primary"
                size="lg"
                className="w-100 z-index-50"
                block
                disabled
              >
                <Spinner size="sm" className="me-2" />
                Working
              </MDBBtn>
            ) : (
              <MDBBtn
                color="primary"
                onClick={this.submit}
                size="lg"
                block
                className="w-100 z-index-50"
              >
                <i className="fas fa-paper-plane me-2"></i>Submit
              </MDBBtn>
            )}
            <MDBBtn
              onClick={this.back}
              color="dark"
              size="lg"
              className="w-100 mt-4 z-index-50"
              block
            >
              <i className="fas fa-chevron-left me-2"></i>Back
            </MDBBtn>
            <small className="mt-4 d-block mx-auto text-center text-danger">
              <i className="fas fa-exclamation-triangle me-2" />
              Resetting your password will leave all of your private messages
              encrypted. You will not be able to see them again unless you
              remember your old password.
            </small>
            <hr></hr>
            <small className="d-block mx-auto text-center">
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">
                {" "}
                Privacy Policy
              </a>{" "}
              and
              <a href="https://policies.google.com/terms">
                {" "}
                Terms of Service
              </a>{" "}
              apply.
            </small>
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_token })(ForgotPassword);
