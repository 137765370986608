import env from "../../env";
import React from "react";
import { motion } from "framer-motion";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import { connect } from "react-redux";
import {
  MDBCard,
  MDBCardBody,
  MDBTooltip,
  MDBBtn,
  MDBSpinner,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { route } from "../../redux/actions";
import { v4 as uuid } from "uuid";
import RemoveMessageModal from "./RemoveMessageModal";
import TextInput from "../../components/textInput/TextInput";
import Url from "url-parse";

const maxChars = 5000;
let loadingMore = false;

class ChatBox extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a private chat is in the process of being sent
       * scrollToBottom: Boolean - Whether an incoming chat message will cause the chatbox to scroll to the bottom
       * cooldown: Number - Seconds left in cooldown before the user can send another message
       * cooldownInterval: false | Interval that decrements cooldown by 1 every second
       */
      working: false,
      scrollToBottom: true,
      cooldown: 0,
      cooldownInterval: false,
      reset: false,
      messageHovering: false,
      messageRemoving: false,
      removeMessageModalShown: false,
      contentKey: false,
      loadingMore: false,
    };
    this.id = uuid();
  }

  componentDidMount() {
    const encrypted =
      !this.props.loadingMore &&
      this.props.conversation.messages.find((m) => !m.decrypted);
    if (!encrypted) this.scrollToBottom();
  }

  // Execute this.scrollToBottom upon receiving a new message
  componentDidUpdate(prevProps, prevState) {
    const prevEncrypted =
      !prevProps.loadingMore &&
      prevProps.conversation.messages.find((m) => !m.decrypted);
    const encrypted =
      !this.props.loadingMore &&
      this.props.conversation.messages.find((m) => !m.decrypted);
    if (prevEncrypted && !encrypted) this.scrollToBottom();
    if (
      (this.props.conversation.messages &&
        prevProps.conversation.messages &&
        prevProps.conversation.messages.length !==
          this.props.conversation.messages.length) ||
      (prevProps.askee !== this.props.askee &&
        this.props.askee === this.props.virgilChad)
    )
      this.scrollToBottom();
    if (!prevProps.loadingMore && this.props.loadingMore)
      this.setState((curr) => ({
        ...curr,
        scrollToID: this.props.conversation.messages.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        )[0].id,
      }));
  }

  setRemoveMessageModalShown = (option) =>
    this.setState((curr) => ({
      ...curr,
      removeMessageModalShown: option,
    }));

  toggleRemoveMessageModalShown = () =>
    this.setState((curr) => ({
      ...curr,
      removeMessageModalShown: !curr.removeMessageModalShown,
    }));

  remove = (messageID) => {
    this.setState(
      (curr) => ({
        ...curr,
        messageRemoving: messageID,
      }),
      () => {
        this.toggleRemoveMessageModalShown();
      }
    );
  };

  /**
   * Triggered when the user submits a new message
   *
   * Submit only if a message is not already in the process of being submitted
   * Validate message
   * Emit message via socket
   * Add message to application state
   * Place user on 3 second cooldown
   */
  submit = (enterPressed) =>
    this.setState(
      (curr) => ({
        ...curr,
        working: true,
      }),
      () =>
        setTimeout(() => {
          try {
            if (!this.state.cooldown) {
              const users = this.props.conversation.parties
                ? this.props.conversation.parties
                    .filter((p) => p !== this.props.userInfo._id)
                    .map((p) =>
                      this.props.conversation.users.find((u) => u._id === p)
                    )
                : [];
              let emissionData = document.getElementById("input-message");
              let length = String(emissionData.textContent)
                .split("")
                .filter((c) => {
                  const checkWhiteSpace = c.match(/[\s]/);
                  if (!checkWhiteSpace) return true;
                  else {
                    return [" ", "\n"].indexOf(c) > -1;
                  }
                }).length;
              if (length > maxChars)
                throw `Character limit exceeded (Max: ${maxChars} characters)`;
              if (
                !length ||
                emissionData.innerHTML === "<div><p><br /></p></div>"
              )
                throw "Please enter a message";
              this.forceParse();
              // Check again
              emissionData = document.getElementById("input-message");
              length = String(emissionData.textContent)
                .split("")
                .filter((c) => {
                  const checkWhiteSpace = c.match(/[\s]/);
                  if (!checkWhiteSpace) return true;
                  else {
                    return [" ", "\n"].indexOf(c) > -1;
                  }
                }).length;
              if (length > maxChars)
                throw `Character limit exceeded (Max: ${maxChars} characters)`;
              if (
                !length ||
                emissionData.innerHTML === "<div><p><br /></p></div>"
              )
                throw "Please enter a message";
              const messageID = uuid();

              if (!this.props.virgilChad) {
                const newKeys = {};
                const broadcast = users.map((user) => {
                  const { message, keys } = h.processBroadcastMessage(
                    user,
                    this.props.conversation,
                    this.props.userInfo,
                    emissionData.innerHTML
                  );
                  Object.keys(keys).forEach(
                    (key) => (newKeys[key] = keys[key])
                  );
                  return message;
                });
                this.props.addMessage({
                  timestamp: new Date(),
                  from: this.props.userInfo._id,
                  to: broadcast[0]?.to,
                  message: h.sanitizeHTML(emissionData.innerHTML),
                  id: messageID,
                  local: true,
                  decrypted: true,
                  conversationID: this.props.conversation._id,
                  newKeys,
                });
                this.props.socket.emit("message", {
                  broadcast,
                  id: messageID,
                  conversationID: this.props.conversation._id,
                  message: !broadcast.length
                    ? this.props.conversation.solo.encrypt(
                        h.sanitizeHTML(emissionData.innerHTML)
                      )
                    : null,
                });
                this.setState(
                  (curr) => ({
                    ...curr,
                    text: "",
                    cooldown: 3,
                    working: false,
                    reset: !this.state.reset,
                  }),
                  () => {
                    setTimeout(this.decrementCooldown, 1000);
                    if (enterPressed)
                      this.setState((curr) => ({
                        ...curr,
                        contentKey: !this.state.contentKey,
                      }));
                  }
                );
              }
            }
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => alert(err)
            );
          }
        }, 200)
    );

  /**
   * If state.scrollToBottom, scroll to bottom
   * scrollBehavior is normal for first scroll, set to smooth for subsequent scrolls
   * Execute after 0.1s
   */
  scrollToBottom = (force) =>
    this.setState(
      (curr) => ({
        ...curr,
        scrollToBottom: force ? true : this.state.scrollToBottom,
      }),
      () => {
        if (this.state.scrollToBottom) {
          const container = document.getElementById(
            "message-container" + this.id
          );
          container.scrollTop = container.scrollHeight;
          container.style.scrollBehavior = "smooth";
        } else if (this.state.scrollToID) {
          const container = document.getElementById(
            "message-container" + this.id
          );
          if (container) {
            container.style.scrollBehavior = "auto";
            document
              .getElementById("message-" + this.state.scrollToID)
              .scrollIntoView();
            container.scrollTop -= 30;
            container.style.scrollBehavior = "smooth";
          }
          this.setState((curr) => ({
            ...curr,
            scrollToID: false,
          }));
        }
      }
    );

  /**
   * Triggered when the user scrolls through the chatbox
   * If the scrollbar is not at the bottom, set state.scrollToBottom to false so that it won't scroll to the bottom when new messages come in
   */
  scrollHandler = (e) => {
    if (this.props.conversation?.messages?.length) {
      if (
        e.currentTarget.scrollHeight ===
        e.currentTarget.scrollTop + e.currentTarget.clientHeight
      ) {
        if (!this.state.scrollToBottom)
          this.setState((curr) => ({
            ...curr,
            scrollToBottom: true,
          }));
      } else if (this.state.scrollToBottom)
        this.setState((curr) => ({
          ...curr,
          scrollToBottom: false,
        }));
      else if (
        !loadingMore &&
        e.currentTarget.scrollTop < 75 &&
        Number(
          document.getElementById("message-list" + this.id)?.offsetHeight || 0
        ) >
          Number(
            document.getElementById("message-container" + this.id)
              ?.offsetHeight || 0
          ) &&
        this.props.conversation.messages.length !==
          this.props.conversation.totalMessages &&
        !this.props.loadingMore
      ) {
        loadingMore = true;
        this.props.loadMore(this.props.virgilChad);
        setTimeout(() => (loadingMore = false), 500);
      }
    }
  };

  /**
   * Triggered by the cooldown interval
   * Decrements the cooldown by 1
   * If cooldown reaches zero, clear the interval
   *
   */
  decrementCooldown = () =>
    this.setState(
      (curr) => ({
        ...curr,
        cooldown: this.state.cooldown > 0 ? this.state.cooldown - 1 : 0,
      }),
      () => {
        if (this.state.cooldown > 0) setTimeout(this.decrementCooldown, 1000);
      }
    );

  /**
   * Triggered when the user clicks the chat partner's profile
   * Override native navigation and use redux route method
   */
  clickUser = (e, user) => {
    e.preventDefault();
    this.props.route(`/${user}`);
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickMessage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (!href) {
        console.log("no link found", element, e.target);
        return;
      }

      const url = new Url(href);
      if (url?.hostname === window.location.hostname)
        this.props.route(url.pathname);
      else {
        window.location = href;
      }
    }
  };

  render() {
    // console.log("props", this.props.conversation);
    const recipients = this.props.conversation.parties
      ? this.props.conversation.parties
          .filter((p) => p !== this.props.userInfo._id)
          .map((p) => this.props.conversation.users.find((u) => u._id === p))
      : [];
    const starter =
      this.props.virgilChad ||
      recipients.find(
        (user) =>
          user._id === this.props.conversation.starter &&
          user._id !== this.props.userInfo._id
      );
    const nonStarters = this.props.virgilChad
      ? [this.props.virgilChad]
      : recipients.filter(
          (user) => user._id !== this.props.conversation.starter
        );
    const encrypted =
      !this.props.loadingMore &&
      this.props.conversation.messages.find((m) => !m.decrypted);
    return (
      <div
        className={`h-100 ${
          this.props.screenDimensions.width < 576 ? "pt-4" : ""
        }`}
      >
        <RemoveMessageModal
          modalShown={this.state.removeMessageModalShown}
          toggleShowModal={this.toggleRemoveMessageModalShown}
          setShowModal={this.setRemoveMessageModalShown}
          messageRemoving={this.state.messageRemoving}
          removeMessage={this.props.removeMessage}
          virgilChad={this.props.virgilChad}
        />
        <MDBCard
          className={`h-100 ${
            this.props.screenDimensions.width < 576 ? "rounded-0" : ""
          }`}
        >
          <MDBCardBody
            className={`h-100 d-flex flex-column ps-0 pe-0 pb-0 pt-2`}
          >
            {!this.props.virgilChad ? (
              <h5 className="text-center">
                {starter && (
                  <Link
                    onClick={(e) => this.clickUser(e, starter.username)}
                    to={`/${starter.username}`}
                    className="links-generic text-gold"
                  >
                    @{starter.username}
                    {recipients.find(
                      (user) => user._id !== this.props.conversation.starter
                    )
                      ? ", "
                      : ""}
                  </Link>
                )}
                {nonStarters
                  .filter(
                    (user) => user._id !== this.props.conversation.starter
                  )
                  .map((user, u) => (
                    <Link
                      onClick={(e) => this.clickUser(e, user.username)}
                      to={`/${user.username}`}
                      className="links-generic"
                      key={user._id}
                    >
                      @{user.username}
                      {u !== nonStarters.length - 1 ? ", " : ""}
                    </Link>
                  ))}
              </h5>
            ) : (
              <>
                {this.props.screenDimensions.width >= 993 ? (
                  <MDBTooltip
                    wrapperProps={{
                      className:
                        "w-100 d-flex justify-content-center border-bottom",
                    }}
                    tag="div"
                    title={this.props.virgilChad === "chad" ? "Chad" : "Virgil"}
                  >
                    <img
                      style={{ height: "4rem" }}
                      src={"/assets/images/" + this.props.virgilChad + ".png"}
                      alt={this.props.virgilChad + " icon"}
                    />
                  </MDBTooltip>
                ) : (
                  <MDBContainer fluid>
                    <MDBRow className="border-bottom">
                      {this.props.screenDimensions.width >= 500 && (
                        <MDBCol></MDBCol>
                      )}
                      <MDBCol className="d-flex justify-content-center align-items-end">
                        <MDBTooltip
                          tag="div"
                          title={
                            this.props.virgilChad === "chad" ? "Chad" : "Virgil"
                          }
                        >
                          <img
                            style={{ height: "4rem" }}
                            src={
                              "/assets/images/" + this.props.virgilChad + ".png"
                            }
                            alt={this.props.virgilChad + " icon"}
                          />
                        </MDBTooltip>
                      </MDBCol>
                      <MDBCol className="d-flex justify-content-end align-items-center">
                        <MDBBtn
                          color="link"
                          onClick={this.props.toggleVirgilChad}
                          rippleColor="primary"
                          className="d-flex align-items-center"
                        >
                          <img
                            style={{ height: "16px" }}
                            src={
                              "/assets/images/" +
                              (this.props.virgilChad === "virgil"
                                ? "chad"
                                : "virgil") +
                              ".png"
                            }
                            alt={
                              (this.props.virgilChad === "virgil"
                                ? "chad"
                                : "virgil") + " icon"
                            }
                            className="d-block me-2"
                          />
                          {this.props.screenDimensions.width >= 500 && (
                            <span>
                              {this.props.virgilChad === "virgil"
                                ? "Chad"
                                : "Virgil"}
                            </span>
                          )}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                )}
              </>
            )}
            <div
              className={`fg-1 d-flex flex-column justify-content-end position-relative`}
            >
              <div
                onTouchMove={this.scrollHandler}
                onWheel={this.scrollHandler}
                style={{ scrollBehavior: "auto" }}
                id={"message-container" + this.id}
                className="h-100 overflow-y-auto"
              >
                {encrypted || !this.props.splashed ? (
                  <div className="d-flex justify-content-center align-items-center h-100 w-100">
                    <MDBSpinner />
                  </div>
                ) : (
                  <>
                    {this.props.conversation.messages &&
                    this.props.conversation.messages.length ? (
                      <>
                        {Number(
                          document.getElementById("message-list" + this.id)
                            ?.offsetHeight || 0
                        ) >
                          Number(
                            document.getElementById(
                              "message-container" + this.id
                            )?.offsetHeight || 0
                          ) &&
                          this.props.conversation.messages.length !==
                            this.props.conversation.totalMessages && (
                            <div className="py-2">
                              <MDBBtn
                                onClick={() =>
                                  this.props.loadMore(this.props.virgilChad)
                                }
                                disabled={this.props.loadingMore}
                                color="link"
                                rippleColor="primary"
                                className={`d-block mx-auto ${
                                  this.props.loadingMore
                                    ? "border-transparent"
                                    : ""
                                }`}
                              >
                                {this.props.loadingMore ? (
                                  <motion.div
                                    transition={t.transition}
                                    initial={t.fade_out}
                                    animate={t.normalize}
                                    exit={t.fade_out_scale_1}
                                  >
                                    <MDBSpinner size="sm" color="primary" />
                                  </motion.div>
                                ) : (
                                  <motion.section
                                    transition={t.transition}
                                    initial={t.fade_out}
                                    animate={t.normalize}
                                    exit={t.fade_out_scale_1}
                                  >
                                    <i className="fas fa-undo-alt"></i>
                                  </motion.section>
                                )}
                              </MDBBtn>
                            </div>
                          )}
                        <ul
                          id={"message-list" + this.id}
                          className="list-group list-group-flush"
                        >
                          {this.props.conversation.messages
                            .sort(
                              (a, b) =>
                                new Date(a.timestamp) - new Date(b.timestamp)
                            )
                            .map((message) => {
                              const user = this.props.conversation.users?.find(
                                (p) => p._id === message.from
                              );
                              if (message.from === this.props.userInfo._id)
                                return (
                                  <motion.li
                                    transition={t.transition}
                                    exit={t.fade_out_minimize}
                                    animate={t.normalize}
                                    initial={t.fade_out_minimize}
                                    key={message.id}
                                    className="list-group-item"
                                    id={"message-" + message.id}
                                    onMouseEnter={() =>
                                      this.setState((curr) => ({
                                        ...curr,
                                        messageHovering: message.id,
                                      }))
                                    }
                                    onMouseLeave={() =>
                                      this.setState((curr) => ({
                                        ...curr,
                                        messageHovering: false,
                                      }))
                                    }
                                  >
                                    <div className="d-flex justify-content-end max-w-100 text-break position-relative">
                                      <div>
                                        <p className="m-0 text-blusteel text-end">
                                          {h.makeDateHR(
                                            new Date(message.timestamp)
                                          )}{" "}
                                          @{" "}
                                          {h.getTimeHR(
                                            new Date(message.timestamp)
                                          )}
                                        </p>
                                        <div className="d-flex justify-content-end text-wrap ms-auto">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: message.message,
                                            }}
                                            className="text-end"
                                            onClick={this.clickMessage}
                                          ></div>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-center align-items-center square-3 ms-2">
                                        <div
                                          className="fit-images fit-round"
                                          style={{
                                            backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${this.props.userInfo.avatar.thumbnail}")`,
                                            borderRadius: "50%",
                                          }}
                                        ></div>
                                      </div>
                                      {String(env.READ_ONLY) === "true" ? (
                                        <></>
                                      ) : (
                                        <>
                                          {this.state.messageHovering ===
                                            message.id && (
                                            <motion.div
                                              initial={t.fade_out}
                                              animate={t.normalize}
                                              exit={t.fade_out_scale_1}
                                              style={{ right: "-21px" }}
                                              className="position-absolute d-flex h-100 align-items-center"
                                            >
                                              {this.props.screenDimensions
                                                .width < 576 ? (
                                                <MDBBtn
                                                  color="link"
                                                  rippleColor="danger"
                                                  onClick={() => {
                                                    this.remove(message.id);
                                                  }}
                                                  size="sm"
                                                  className="text-danger p-1 bg-transparent"
                                                >
                                                  <i className="far fa-trash-alt" />
                                                </MDBBtn>
                                              ) : (
                                                <MDBTooltip
                                                  wrapperProps={{
                                                    color: "link",
                                                    rippleColor: "danger",
                                                    onClick: () => {
                                                      h.hideToolTips();
                                                      this.remove(message.id);
                                                    },
                                                    size: "sm",
                                                  }}
                                                  wrapperClass="text-danger p-1 bg-transparent"
                                                  title="Remove"
                                                >
                                                  <i className="far fa-trash-alt" />
                                                </MDBTooltip>
                                              )}
                                            </motion.div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </motion.li>
                                );
                              else {
                                return (
                                  <motion.li
                                    transition={t.transition}
                                    exit={t.fade_out_minimize}
                                    animate={t.normalize}
                                    initial={t.fade_out_minimize}
                                    key={String(message.id)}
                                    className="list-group-item"
                                    id={"message-" + message.id}
                                    onMouseEnter={() =>
                                      this.setState((curr) => ({
                                        ...curr,
                                        messageHovering: message.id,
                                      }))
                                    }
                                    onMouseLeave={() =>
                                      this.setState((curr) => ({
                                        ...curr,
                                        messageHovering: false,
                                      }))
                                    }
                                  >
                                    <div className="d-flex position-relative">
                                      <div className="chat-avatars me-2">
                                        <MDBTooltip
                                          title={
                                            this.props.virgilChad
                                              ? this.props.virgilChad ===
                                                "virgil"
                                                ? "Virgil"
                                                : "Chad"
                                              : `@${user.username}`
                                          }
                                          tag="div"
                                          className="cursor-default"
                                        >
                                          <div
                                            className="fit-images chat-avatars fit-round"
                                            style={{
                                              backgroundImage: `url("${
                                                this.props.virgilChad
                                                  ? `/assets/images/${
                                                      this.props.virgilChad
                                                    }-head${
                                                      this.props.virgilChad ===
                                                      "chad"
                                                        ? "-reverse"
                                                        : ""
                                                    }.png`
                                                  : `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${user.avatar.thumbnail}`
                                              }")`,
                                              borderRadius: "50%",
                                            }}
                                          ></div>
                                        </MDBTooltip>
                                      </div>

                                      <div className="w-0 flex-grow-1 text-break">
                                        <p className="m-0 text-blusteel">
                                          {h.makeDateHR(
                                            new Date(message.timestamp)
                                          )}{" "}
                                          @{" "}
                                          {h.getTimeHR(
                                            new Date(message.timestamp)
                                          )}
                                        </p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: message.message,
                                          }}
                                          onClick={this.clickMessage}
                                        ></div>
                                      </div>
                                    </div>
                                  </motion.li>
                                );
                              }
                            })}
                        </ul>
                      </>
                    ) : (
                      <div className="px-2">
                        {this.props.virgilChad ? (
                          <>
                            <i className="far fa-comments d-block mx-auto mt-5 fa-4x text-center" />
                            <h5 className="mt-4 display-6 text-center">
                              Send a message to begin
                            </h5>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-lock d-block mx-auto mt-5 fa-4x text-center" />
                            <h5 className="mt-4 display-6 text-center">
                              Messages are end-to-end encrypted
                            </h5>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
              {!this.state.scrollToBottom && !encrypted && (
                <motion.div
                  transition={t.transition}
                  initial={t.fade_out}
                  animate={t.normalize}
                  exit={t.fade_out_scale_1}
                  className="position-absolute"
                  style={{
                    bottom: "10px",
                    left: "10px",
                  }}
                >
                  <MDBBtn
                    onClick={() => this.scrollToBottom(true)}
                    color="link"
                    rippleColor="primary"
                  >
                    <i className="fas fa-chevron-down" />
                  </MDBBtn>
                </motion.div>
              )}
            </div>
            {String(env.READ_ONLY) === "true" &&
            !h.checkChadmin(this.props.userInfo) ? (
              <></>
            ) : (
              <>
                {!this.props.virgilChad && (
                  <TextInput
                    submit={this.submit}
                    setForceParse={(e) => (this.forceParse = e)}
                    cooldown={this.state.cooldown}
                    clearMessage={this.props.clearMessage}
                    flavor="message"
                    maxChars={5000}
                    key={this.state.reset}
                    label="Message"
                    working={this.state.working}
                    contentKey={this.state.contentKey}
                  />
                )}
              </>
            )}
          </MDBCardBody>
        </MDBCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route })(ChatBox);
