import React from "react";
import { withRouter } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { set_user, route, set_token } from "../redux/actions";
import { connect } from "react-redux";
import { change_password_uuid_schema } from "../utilities/validations";
import t from "../utilities/transitions";
import h from "../utilities/helpers";
import {
  MDBContainer,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import Spinner from "../components/Spinner";
import LogoLoader from "../components/LogoLoader";
import Encrypter from "../utilities/Encrypter";

/**
 * This is the page that the user hits when they click the password reset link that was emailed to them
 */

const fields = [
  {
    id: "password1",
    text: "New Password",
  },
  {
    id: "password2",
    text: "Re-enter password",
  },
];

class Resets extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean indicating whether the form is in the process of being submitted
       * inputs: Array - The input data (values, errors, etc)
       * loaded: Boolean - Whether the password reset data has been loaded
       * expired: Boolean - Whether the password reset has expired
       * notFound: Boolean - Whether the password reset request was not found
       */
      working: false,
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
      loaded: false,
      expired: false,
      notFound: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   * Load password reset data
   * Run blank change handler
   */
  load = () =>
    axios
      .get(
        process.env.REACT_APP_LAMBDA_AUTH +
          "/reset-request/" +
          this.props.match.params.id,
        {
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState(
          (curr) => ({
            ...curr,
            loaded: true,
            ...res.data,
          }),
          () => {
            if (!this.state.expired && !this.state.notFound)
              this.changeHandler({
                target: {
                  name: "",
                },
              });
          }
        );
      })
      .catch((err) => {
        console.log("load error", err);
        setTimeout(this.load, 1000);
      });

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          change_password_uuid_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState((curr) => ({
            ...curr,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          }));
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            (curr) => ({
              ...curr,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            }),
            () =>
              this.state.inputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              )
          );
        }
      }
    );

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user in application state
   * Route to user's profile page
   */
  submit = () => {
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            change_password_uuid_schema.validateSync(data, {
              abortEarly: false,
            });
            const fd = {};
            for (const key in data) {
              fd[key] = data[key];
            }
            fd["uuid"] = this.props.match.params.id;

            axios
              .post(
                process.env.REACT_APP_LAMBDA_AUTH + "/change-password",
                fd,
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                if (res.data.error)
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                    }),
                    () => alert(res.data.error)
                  );
                else {
                  localStorage.setItem("userID", res.data.userInfo._id);
                  localStorage.setItem(
                    "chatKey",
                    new Encrypter(
                      this.state.inputs.find((i) => i.id === "password1").value
                    ).decrypt(res.data.userInfo.chatKey)
                  );
                  this.props.set_user(res.data.userInfo);
                  this.props.route("/" + res.data.userInfo.username);
                }
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err.response);
                    alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  pressEnter = (e) => {
    /**
     * Submit the form if the user presses the enter key while in one of the inputs
     */
    if (e.key === "Enter") document.getElementById("form").requestSubmit();
  };

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="page-container"
      >
        <MDBContainer className="mt-5">
          {!this.state.loaded ? (
            <LogoLoader />
          ) : (
            <>
              {this.state.expired ? (
                <motion.h5
                  className="display-6 text-center"
                  transition={t.transition}
                  exit={t.fade_out_scale_1}
                  animate={t.normalize}
                  initial={t.fade_out}
                >
                  Reset link has expired
                </motion.h5>
              ) : (
                <>
                  {this.state.notFound ? (
                    <motion.h5
                      className="display-6 text-center"
                      transition={t.transition}
                      exit={t.fade_out_scale_1}
                      animate={t.normalize}
                      initial={t.fade_out}
                    >
                      Reset link not found
                    </motion.h5>
                  ) : (
                    <motion.div
                      transition={t.transition}
                      exit={t.fade_out_scale_1}
                      animate={t.normalize}
                      initial={t.fade_out}
                    >
                      <h1 className="display-6 text-center">Set Password</h1>
                      <hr></hr>
                      <div className="mx-auto mt-2 form-containers">
                        <MDBValidation
                          method="dialog"
                          id="form"
                          onSubmit={this.submit}
                        >
                          {fields.map((i) => (
                            <MDBValidationItem
                              key={i.id}
                              className="pb-4"
                              feedback={
                                this.state.inputs.find(
                                  (input) => input.id === i.id
                                ).error
                              }
                              invalid={true}
                            >
                              <MDBInput
                                name={i.id}
                                onChange={this.changeHandler}
                                id={i.id}
                                label={i.text}
                                size="lg"
                                className={
                                  !this.state.inputs.find(
                                    (input) => input.id === i.id
                                  ).invalid
                                    ? "mb-0"
                                    : 0
                                }
                                type="password"
                                onKeyPress={this.pressEnter}
                              />
                            </MDBValidationItem>
                          ))}
                        </MDBValidation>
                        <div className="d-grid gap-2 mb-4">
                          {this.state.working ? (
                            <MDBBtn disabled color="primary" size="lg">
                              <Spinner size="sm" className="me-2" />
                              Saving
                            </MDBBtn>
                          ) : (
                            <MDBBtn
                              onClick={this.submit}
                              color="primary"
                              size="lg"
                            >
                              <i className="fas fa-save me-2"></i>Save Changes
                            </MDBBtn>
                          )}
                        </div>
                        <small className="mt-4 d-block mx-auto text-center text-danger">
                          <i className="fas fa-exclamation-triangle me-2" />
                          Resetting your password will leave all of your private
                          messages encrypted. You will not be able to see them
                          again unless you remember your old password.
                        </small>
                      </div>
                    </motion.div>
                  )}
                </>
              )}
            </>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default withRouter(
  connect(mapStateToProps, { set_user, route, set_token })(Resets)
);
