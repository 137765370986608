import env from "../env";
import {
  SET_HISTORY,
  SET_TOKEN,
  SET_USER,
  PURGE_USER,
  ROUTE,
  SELECT_DASHBOARD_ITEM,
  DASHBOARD_NAV,
  SET_NSFW_MODAL,
  SET_PROFILE,
  CLEAR_PROFILE,
  NEW_EMISSION,
  NEW_LIKE,
  VOTE,
  LIKE_SELF,
  LIKE_OTHER,
  SIGNAL_BOOST,
  UPDATE_EMISSION,
  SET_PROFILE_CONVERSATION,
  ADD_EMISSIONS,
  ADD_LIKES,
  CHANGE_USER_DETAILS,
  RECONNECT_SOCKET,
  SET_TEMP_ACTION,
  CLEAR_TEMP_ACTION,
  SET_POLL_VOTED,
  SET_EMISSION_REPORTED,
  REMOVE_EMISSION,
  UPDATE_USER,
  BAN_OTHER,
  FOLLOW,
  TOGGLE_PRIVATE,
  PRIVATE_PROFILE,
  DISABLE_MESSAGES,
  TOGGLE_DISABLE_MESSAGES,
  ADD_MESSAGE_PROFILE,
  TOGGLE_GO_LIVE_MODAL,
  SET_GO_LIVE_MODAL,
  SET_STREAM,
  NEW_STREAM_CHAT,
  CLEAR_STREAM_CHAT,
  SET_LIVE,
  STREAM_START,
  NEW_CONTACT_MESSAGE,
  STREAM_CLEAR,
  STREAM_END,
  BLOCK,
  UNBLOCK,
  TOGGLE_DARK_MODE,
  SET_CLICK_PASS,
  NEW_VIEWER,
  LEAVE_VIEWER,
  REPLY,
  USER_EDIT,
  RESTORE_USER,
  UNPRIVATE,
  BLOCK_OTHER,
  UNBLOCK_OTHER,
  VIEW,
  VIEWS,
  SET_UNREAD_MESSAGES,
  SET_NOTIFICATION_COUNT,
  SET_PROFILE_REPORTED,
  SET_REPORT_COUNT,
  SET_OPEN_REPORTS,
  SET_UNREAD_MOD_LOGS,
  SEARCH_CHANGE,
  SET_SEARCH_TERM,
  SET_CAPTCHA_READY,
  SET_PASTED_FILES,
  SET_VERIFICATION_DETAILS,
  VIEWERS,
  SET_POLL_MODAL,
  UPDATE_SOCKET_STATE,
  ACCOUNT_DELETED,
  REMOVE_MESSAGE_PROFILE,
  REMOVE_CONVERSATION_PROFILE,
  SET_REDIRECT_URL,
  SET_CACHE,
  STREAM_EVENT_GENERIC,
  NOTIFY,
  REMOVE_NOTIFICATION,
  TEST,
  SET_SCREEN_DIMENSIONS,
  SET_VIRGIL_CHAD,
  REMOVE_VIRGIL_CHAD,
  VIRGIL_CHAD_LOAD_MORE,
  SET_STREAM_ELIGIBILITY,
  SET_CONTACT_MESSAGES,
  SET_CONTACT_MESSAGE_READ,
} from "./actions";
import { io } from "socket.io-client";
import h from "../utilities/helpers";
import { json2html } from "html2json";
import {
  blankUser,
  deletedUser,
  accountDeletedEmission,
} from "../utilities/constants";

let initialUser = JSON.parse(JSON.stringify(blankUser));
let verificationDetails = false;
let token = "";
let virgil = {
  messages: [],
  totalMessages: 0,
};
let chad = {
  messages: [],
  totalMessages: 0,
};
let streamEligibility = "";

/**
 * If there is metadata present in the document, set the initial user to the object contained in the metadata
 */
if (typeof document !== "undefined") {
  const p = document.getElementById("jizzer-metadata");
  if (p) {
    const metadata = JSON.parse(p.textContent);
    // console.log("metadata", metadata);
    if (metadata.userInfo) {
      initialUser = {
        ...metadata.userInfo,
        bio: json2html(metadata.userInfo.bio),
      };
    }
    if (metadata.verificationDetails)
      verificationDetails = metadata.verificationDetails;
    token = metadata.token;
    if (metadata.streamEligibility)
      streamEligibility = metadata.streamEligibility;
    p.remove();
  }
}

const initialJoin = h.getCurrentRoom();

const initialSocketState = {
  join: initialJoin,
};

const initialState = {
  /**
   * userInfo: Object - Users document minus sensitive info
   * historyStack: Array - List of routes that the user has been to
   * socket: false | Socket.io object
   * socketReconnect: Boolean - When flipped, components that depend on the socket will reset their socket event functions
   * profileInfo: Object - Data of the profile page that the user is currently on
   * tempAction: false | Object - Temp action data
   * goLiveModalShown: Boolean - Whether the user's livestream modal is shown
   * stream: false | MediaStream - The user's live stream
   * streamChat: Array - List of chat messages in the user's livestream modal
   * viewers: Number - Viewers watching the user's live stream
   * openReports: Number - Open reports
   * unreadModLogs: Number - Unread mod logs
   * searchText: String - The text currently in the search input
   * searchTerm: String - The currently submitted search term
   * sideNavSearchKey: Boolean - When flipped, the sidenav search bar will update
   * searchPageSearchKey: Boolean - When flipped, the main search page input will update
   * captchaReady: Boolean - Whether the Google reCaptcha system has been initialized
   * verificationDetails: false | Object - Details of the most recent email verification email
   */
  userInfo: initialUser,
  historyStack: ["/null"],
  socketState: initialSocketState,
  socket: false,
  socketReconnect: false,
  profileInfo: {
    emissions: {
      items: [],
      totalNoReply: 0,
      totalReplies: 0,
      totalMedia: 0,
    },
    likes: {
      items: [],
      total: 0,
    },
  },
  tempAction: false,
  goLiveModalShown: false,
  stream: false,
  streamChat: [],
  viewers: 0,
  openReports: 0,
  unreadModLogs: 0,
  searchText: "",
  searchTerm: "",
  sideNavSearchKey: false,
  searchPageSearchKey: false,
  captchaReady: false,
  verificationDetails: verificationDetails,
  pollModalOptions: {
    shown: false,
    voters: [],
    option: "",
    emissionID: 0,
  },
  clickPass: false,
  token: token,
  thing: false,
  redirectUrl: false,
  cache: [],
  streamRefresh: false,
  ping: 0,
  notifications: [],
  testVal: 0,
  socketEvents: [],
  screenDimensions: {
    width: window?.innerWidth || 0,
    height: window?.innerHeight || 0,
    modalBreak: Number(process.env.REACT_APP_MODAL_BREAK || 800),
  },
  virgil,
  chad,
  splashed: false,
  streamEligibility,
  contactMessages: [],
  contactMessagesLoaded: false,
  pastedFiles: [],
};

const reducer = (state = initialState, action) => {
  // console.log("redux", action, state.socketEvents);
  if (
    action.data?.eventID &&
    state.socketEvents.find((e) => e === action.data?.eventID)
  ) {
    console.log("skipping duplicate", action);
    return state;
  }

  if (
    [SET_HISTORY, UPDATE_USER, SET_USER, CHANGE_USER_DETAILS].includes(
      action.type
    ) &&
    action.data.userInfo
  ) {
    if (!action.data.userInfo.email)
      action.data.userInfo.email = "poop@cock.com";
    if (!action.data.userInfo.blocked?.length)
      action.data.userInfo.blocked = [];
    if (!action.data.userInfo.comments) action.data.userInfo.comments = "";
    if (!action.data.userInfo.nsfwAccepted)
      action.data.userInfo.nsfwAccepted = false;
    if (!Array.isArray(action.data.userInfo.boosts)) {
      console.log("boosts error", action.data);
      action.data.userInfo.boosts = [];
    }
  }

  let likes;
  switch (action.type) {
    case SET_CACHE:
      return {
        ...state,
        cache: [
          ...state.cache.filter((c) => c.page !== action.data.page),
          action.data,
        ],
      };
    case SET_PASTED_FILES:
      return {
        ...state,
        pastedFiles: Array.from(action.data),
      };
    case SET_CONTACT_MESSAGES:
      return {
        ...state,
        contactMessages: action.data,
      };
    case NEW_CONTACT_MESSAGE:
      return {
        ...state,
        contactMessages: [...state.contactMessages, action.data],
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.data,
      };
    case SET_STREAM_ELIGIBILITY:
      return {
        ...state,
        streamEligibility: action.data,
      };
    case REMOVE_VIRGIL_CHAD:
      let virgilHit = false;
      let chadHit = false;
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        virgil: {
          messages: state.virgil.messages.filter((message) => {
            if (message.id === action.data.messageID) {
              virgilHit = true;
              return false;
            }
            return true;
          }),
          totalMessages: state.virgil.totalMessages - (virgilHit ? 1 : 0),
        },
        chad: {
          messages: state.chad.messages.filter((message) => {
            if (message.id === action.data.messageID) {
              chadHit = true;
              return false;
            }
            return true;
          }),
          totalMessages: state.chad.totalMessages - (chadHit ? 1 : 0),
        },
      };
    case SET_VIRGIL_CHAD:
      if (
        state[action.data.askee].messages.find(
          (m) => m.id === action.data.message.id
        )
      )
        return state;
      return {
        ...state,
        [action.data.askee]: {
          messages: [
            ...state[action.data.askee].messages,
            { ...action.data.message, decrypted: true }, // No reason to encrypt AI for now
          ],
          totalMessages: state[action.data.askee].totalMessages + 1,
          loaded: action.data.loaded || state[action.data.askee].loaded,
        },
      };
    case VIRGIL_CHAD_LOAD_MORE:
      return {
        ...state,
        [action.data.askee]: {
          ...state[action.data.askee],
          messages: [
            ...action.data.messages.sort(
              (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
            ),
            ...state[action.data.askee].messages,
          ],
        },
      };
    case SET_CONTACT_MESSAGE_READ:
      return {
        ...state,
        contactMessages: state.contactMessages.map((message) => {
          if (message._id === action.data)
            message.readBy.push(state.userInfo._id);
          return message;
        }),
      };
    case SET_SCREEN_DIMENSIONS:
      return {
        ...state,
        screenDimensions: {
          ...state.screenDimensions,
          ...action.data,
        },
      };
    case TEST:
      return {
        ...state,
        testVal: state.testVal + 1,
      };
    case NOTIFY:
      const { icon, text, id } = action.data;
      return {
        ...state,
        notifications: [
          {
            icon,
            text,
            id,
          },
          ...state.notifications,
        ],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.data
        ),
      };
    case SET_CLICK_PASS:
      return {
        ...state,
        clickPass: action.data,
      };
    case STREAM_EVENT_GENERIC:
      return {
        ...state,
        streamRefresh: !state.streamRefresh,
      };
    case SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.data,
      };
    case REMOVE_CONVERSATION_PROFILE:
      const { conversationID, unread } = action.data;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          conversation:
            state.profileInfo.conversation._id === conversationID
              ? false
              : state.profileInfo.conversation,
        },
        userInfo: {
          ...state.userInfo,
          unreadMessages: state.userInfo.unreadMessages - unread,
        },
      };
    case SET_PROFILE_CONVERSATION:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          conversation: action.data,
        },
      };
    case SET_HISTORY:
      return {
        ...state,
        history: action.data.history,
        location: action.data.location,
        userInfo: action.data.userInfo || state.userInfo,
        socket: io(process.env.REACT_APP_SOCKET_SERVER, {
          query: initialSocketState,
          transports: ["websocket"], // or [ "websocket", "polling" ] (the order matters)
        }),
        verificationDetails: action.data.verificationDetails,
        ping: action.data.ping || 0,
        virgil: action.data.virgil || state.virgil,
        chad: action.data.chad || state.chad,
        splashed: true,
      };
    case UPDATE_SOCKET_STATE:
      return {
        ...state,
        socketState: {
          ...state.socketState,
          ...action.data,
        },
      };
    case SET_CAPTCHA_READY:
      // console.log("captcha ready", action.data);
      return {
        ...state,
        captchaReady: true,
      };
    case SET_VERIFICATION_DETAILS:
      return {
        ...state,
        verificationDetails: action.data,
      };
    case SET_POLL_MODAL:
      return {
        ...state,
        pollModalOptions: {
          ...state.pollModalOptions,
          ...action.data,
        },
      };
    case SET_SEARCH_TERM:
      state.history.push({
        pathname: "/search",
        search: "?q=" + state.searchText,
        state: {
          prevRoute: state.history.location.pathname,
          currRoute: "/search",
          search: state.searchText,
        },
      });
      return {
        ...state,
        historyStack: [...state.historyStack, state.history.location.pathname],
        searchTerm: state.searchText,
      };
    case SEARCH_CHANGE:
      return {
        ...state,
        searchText: action.data.text,
        sideNavSearchKey:
          action.data.source === "sideNav"
            ? state.sideNavSearchKey
            : !state.sideNavSearchKey,
        searchPageSearchKey:
          action.data.source === "searchPage"
            ? state.searchPageSearchKey
            : !state.searchPageSearchKey,
      };
    case SET_UNREAD_MOD_LOGS:
      return {
        ...state,
        unreadModLogs: action.data,
      };
    case SET_OPEN_REPORTS:
      return {
        ...state,
        openReports: action.data,
      };
    case SET_NOTIFICATION_COUNT:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          notifications: action.data >= 0 ? action.data : 0,
        },
      };
    case SET_REPORT_COUNT:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          unreadReports: action.data >= 0 ? action.data : 0,
        },
      };
    case SET_PROFILE_REPORTED:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          reported: true,
        },
      };
    case ACCOUNT_DELETED:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          isBlocked:
            state.profileInfo?.user?._id === action.data
              ? false
              : state.profileInfo.isBlocked,
          blocksMe:
            state.profileInfo?.user?._id === action.data
              ? false
              : state.profileInfo.blocksMe,
          conversation:
            state.profileInfo?.user?._id === action.data
              ? []
              : state.profileInfo.conversation,
          emissions:
            state.profileInfo?.user?._id === action.data
              ? {
                  items: [],
                  totalNoReply: 0,
                  totalReplies: 0,
                  totalMedia: 0,
                }
              : {
                  ...state.profileInfo.emissions,
                  items: state.profileInfo.emissions.items.map((e) => {
                    if (e.userID === action.data)
                      e = {
                        ...e,
                        ...accountDeletedEmission(e.username, e.userID),
                      };
                    if (e.signalBoost && e.signalBoost.userID === action.data)
                      e.signalBoost = {
                        ...e.signalBoost,
                        ...accountDeletedEmission(e.username, e.userID),
                      };
                    if (e.replyEmission) {
                      if (e.replyEmission.userID === action.data)
                        e.replyEmission = {
                          ...e.replyEmission,
                          ...accountDeletedEmission(e.username, e.userID),
                        };
                      if (
                        e.replyEmission.signalBoost &&
                        e.replyEmission.signalBoost.userID === action.data
                      )
                        e.replyEmission.signalBoost = {
                          ...e.replyEmission.signalBoost,
                          ...accountDeletedEmission(e.username, e.userID),
                        };
                      if (e.replyEmission.replyEmission) {
                        if (
                          e.replyEmission.replyEmission.userID === action.data
                        )
                          e.replyEmission.replyEmission = {
                            ...e.replyEmission.replyEmission,
                            ...accountDeletedEmission(e.username, e.userID),
                          };
                        if (
                          e.replyEmission.replyEmission.signalBoost &&
                          e.replyEmission.replyEmission.signalBoost ===
                            action.data
                        )
                          e.replyEmission.replyEmission.signalBoost = {
                            ...e,
                            ...accountDeletedEmission(e.username, e.userID),
                          };
                      }
                    }
                    return e;
                  }),
                },
          likes:
            state.profileInfo?.user?._id === action.data
              ? {
                  items: [],
                  total: 0,
                }
              : {
                  ...state.profileInfo.likes,
                  items: state.profileInfo.likes.items.map((e) => {
                    if (e.userID === action.data)
                      e = {
                        ...e,
                        ...accountDeletedEmission(e.username, e.userID),
                      };
                    if (e.signalBoost && e.signalBoost.userID === action.data)
                      e.signalBoost = {
                        ...e.signalBoost,
                        ...accountDeletedEmission(e.username, e.userID),
                      };
                    if (e.replyEmission) {
                      if (e.replyEmission.userID === action.data)
                        e.replyEmission = {
                          ...e.replyEmission,
                          ...accountDeletedEmission(e.username, e.userID),
                        };
                      if (
                        e.replyEmission.signalBoost &&
                        e.replyEmission.signalBoost.userID === action.data
                      )
                        e.replyEmission.signalBoost = {
                          ...e.replyEmission.signalBoost,
                          ...accountDeletedEmission(e.username, e.userID),
                        };
                      if (e.replyEmission.replyEmission) {
                        if (
                          e.replyEmission.replyEmission.userID === action.data
                        )
                          e.replyEmission.replyEmission = {
                            ...e.replyEmission.replyEmission,
                            ...accountDeletedEmission(e.username, e.userID),
                          };
                        if (
                          e.replyEmission.replyEmission.signalBoost &&
                          e.replyEmission.replyEmission.signalBoost ===
                            action.data
                        )
                          e.replyEmission.replyEmission.signalBoost = {
                            ...e,
                            ...accountDeletedEmission(e.username, e.userID),
                          };
                      }
                    }
                    return e;
                  }),
                },
          user:
            state.profileInfo?.user?._id === action.data
              ? {
                  ...state.profileInfo?.user,
                  ...deletedUser,
                }
              : state.profileInfo?.user,
        },
      };
    case RESTORE_USER:
    case UNPRIVATE:
    case UNBLOCK_OTHER:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          user:
            state.profileInfo?.user &&
            action.data.userInfo._id === state.profileInfo?.user?._id
              ? action.data.userInfo
              : state.profileInfo?.user,
          emissions: {
            ...state.profileInfo.emissions,
            items: h.replaceUserEmissions(
              state.profileInfo.emissions.items,
              action.data.emissions
            ),
          },
          likes: {
            ...state.profileInfo.likes,
            items: h.replaceUserEmissions(
              state.profileInfo.likes.items,
              action.data.emissions
            ),
          },
          blocksMe:
            action.type === UNBLOCK_OTHER ? false : state.profileInfo.blocksMe,
        },
      };
    case USER_EDIT:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          user:
            state.profileInfo?.user?._id === action.data._id
              ? {
                  ...state.profileInfo?.user,
                  ...action.data,
                }
              : state.profileInfo?.user,
          emissions: {
            ...state.profileInfo.emissions,
            items: state.profileInfo.emissions.items.map((emission) => {
              if (emission.userID === action.data._id)
                emission = {
                  ...emission,
                  avatar: action.data.avatar,
                  displayName: action.data.displayName,
                  verified: action.data.verified,
                  role: action.data.role,
                };
              if (
                emission.signalBoost &&
                emission.signalBoost.userID === action.data._id
              )
                emission.signalBoost = {
                  ...emission.signalBoost,
                  avatar: action.data.avatar,
                  displayName: action.data.displayName,
                  verified: action.data.verified,
                  role: action.data.role,
                };

              if (emission.replyEmission) {
                if (emission.replyEmission.userID === action.data._id)
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    avatar: action.data.avatar,
                    displayName: action.data.displayName,
                    verified: action.data.verified,
                    role: action.data.role,
                  };
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.userID === action.data._id
                )
                  emission.replyEmission.signalBoost = {
                    ...emission.replyEmission.signalBoost,
                    avatar: action.data.avatar,
                    displayName: action.data.displayName,
                    verified: action.data.verified,
                    role: action.data.role,
                  };

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.userID ===
                    action.data._id
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      avatar: action.data.avatar,
                      displayName: action.data.displayName,
                      verified: action.data.verified,
                      role: action.data.role,
                    };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost.userID ===
                      action.data._id
                  )
                    emission.replyEmission.replyEmission.signalBoost = {
                      ...emission.replyEmission.replyEmission.signalBoost,
                      avatar: action.data.avatar,
                      displayName: action.data.displayName,
                      verified: action.data.verified,
                      role: action.data.role,
                    };
                }
              }
              return emission;
            }),
          },
          likes: {
            ...state.profileInfo.likes,
            items: state.profileInfo.likes.items.map((emission) => {
              if (emission.userID === action.data._id)
                emission = {
                  ...emission,
                  avatar: action.data.avatar,
                  displayName: action.data.displayName,
                  verified: action.data.verified,
                  role: action.data.role,
                };
              if (
                emission.signalBoost &&
                emission.signalBoost.userID === action.data._id
              )
                emission.signalBoost = {
                  ...emission.signalBoost,
                  avatar: action.data.avatar,
                  displayName: action.data.displayName,
                  verified: action.data.verified,
                  role: action.data.role,
                };

              if (emission.replyEmission) {
                if (emission.replyEmission.userID === action.data._id)
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    avatar: action.data.avatar,
                    displayName: action.data.displayName,
                    verified: action.data.verified,
                    role: action.data.role,
                  };
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.userID === action.data._id
                )
                  emission.replyEmission.signalBoost = {
                    ...emission.replyEmission.signalBoost,
                    avatar: action.data.avatar,
                    displayName: action.data.displayName,
                    verified: action.data.verified,
                    role: action.data.role,
                  };

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.userID ===
                    action.data._id
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      avatar: action.data.avatar,
                      displayName: action.data.displayName,
                      verified: action.data.verified,
                      role: action.data.role,
                    };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost.userID ===
                      action.data._id
                  )
                    emission.replyEmission.replyEmission.signalBoost = {
                      ...emission.replyEmission.replyEmission.signalBoost,
                      avatar: action.data.avatar,
                      displayName: action.data.displayName,
                      verified: action.data.verified,
                      role: action.data.role,
                    };
                }
              }
              return emission;
            }),
          },
        },
      };
    case NEW_VIEWER:
      return {
        ...state,
        viewers: state.viewers + 1,
      };
    case LEAVE_VIEWER:
      return {
        ...state,
        viewers: state.viewers - 1,
      };
    case VIEWERS:
      return {
        ...state,
        viewers: action.data,
      };
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          userSettings: {
            ...state.userInfo.userSettings,
            theme:
              state.userInfo.userSettings.theme === "default"
                ? "dark"
                : "default",
          },
        },
      };
    case STREAM_START:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          user: {
            ...state.profileInfo?.user,
            live: state.profileInfo?.user?.live
              ? {
                  ...state.profileInfo?.user?.live,
                  ...action.data,
                }
              : action.data,
          },
        },
      };
    case BLOCK:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          isBlocked: true,
        },
      };
    case UNBLOCK:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          isBlocked: false,
        },
      };
    case STREAM_CLEAR:
      return {
        ...state,
        streamChat: [],
        viewers: 0,
      };
    case STREAM_END:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          user: {
            ...state.profileInfo?.user,
            live: false,
          },
        },
        streamChat: [],
        viewers: 0,
      };
    case SET_LIVE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          live: true,
        },
        streamChat: !state.userInfo.live ? [] : state.streamChat,
      };
    case NEW_STREAM_CHAT:
      if (
        action.data.user_id &&
        state.userInfo.blocked?.length &&
        state.userInfo.blocked.indexOf(action.data.user_id) > -1
      )
        return state;
      return {
        ...state,
        streamChat: [...state.streamChat, action.data],
      };
    case CLEAR_STREAM_CHAT:
      return {
        ...state,
        streamChat: [],
        userInfo: {
          ...state.userInfo,
          live: false,
        },
      };
    case SET_STREAM:
      return {
        ...state,
        stream: action.data,
      };
    case SET_GO_LIVE_MODAL:
      Array.from(document.getElementsByClassName("tooltip")).forEach((e) =>
        e.classList.remove("show")
      );
      return {
        ...state,
        goLiveModalShown: action.data,
      };
    case TOGGLE_GO_LIVE_MODAL:
      Array.from(document.getElementsByClassName("tooltip")).forEach((e) =>
        e.classList.remove("show")
      );
      return {
        ...state,
        goLiveModalShown: !state.goLiveModalShown,
      };
    case ADD_MESSAGE_PROFILE:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          conversation: {
            ...state.profileInfo.conversation,
            messages: [...state.profileInfo.conversation.messages, action.data],
            totalMessages: state.profileInfo.conversation.totalMessages + 1,
          },
        },
      };
    case REMOVE_MESSAGE_PROFILE:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          conversation: {
            ...state.profileInfo.conversation,
            messages: state.profileInfo.conversation.messages.filter(
              (m) => m.id !== action.data.messageID
            ),
            totalMessages: state.profileInfo.conversation.totalMessages - 1,
          },
        },
        userInfo: {
          ...state.userInfo,
          unreadMessages: action.data.unread
            ? state.userInfo.unreadMessages - 1
            : state.userInfo.unreadMessages,
        },
      };
    case DISABLE_MESSAGES:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          user: {
            ...state.profileInfo?.user,
            messagesDisabled: action.data,
          },
        },
      };
    case TOGGLE_DISABLE_MESSAGES:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          messagesDisabled: !state.userInfo.messagesDisabled,
        },
      };
    case TOGGLE_PRIVATE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          private: !state.userInfo.private,
        },
      };
    case BLOCK_OTHER:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo:
          state.profileInfo?.user &&
          state.profileInfo?.user?._id === action.data._id
            ? h.checkJanny(state.userInfo)
              ? {
                  ...state.profileInfo,
                  blocksMe: true,
                }
              : {
                  ...state.profileInfo,
                  emissions: {
                    items: [],
                    totalNoReply: 0,
                    totalReplies: 0,
                    totalMedia: 0,
                  },
                  likes: {
                    items: [],
                    total: 0,
                  },
                  blocksMe: true,
                }
            : {
                ...state.profileInfo,
                emissions: {
                  ...state.profileInfo.emissions,
                  items: state.profileInfo.emissions.items.map((emission) => {
                    if (emission.userID === action.data._id)
                      emission = h.checkJanny(state.userInfo)
                        ? {
                            ...emission,
                            blocksMe: true,
                          }
                        : {
                            ...emission,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                            blocksMe: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };
                    if (
                      emission.signalBoost &&
                      emission.signalBoost.userID === action.data._id
                    )
                      emission.signalBoost = h.checkJanny(state.userInfo)
                        ? {
                            ...emission.signalBoost,
                            blocksMe: true,
                          }
                        : {
                            ...emission.signalBoost,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                            blocksMe: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };

                    if (emission.replyEmission) {
                      if (emission.replyEmission.userID === action.data._id)
                        emission.replyEmission = h.checkJanny(state.userInfo)
                          ? {
                              ...emission.replyEmission,
                              blocksMe: true,
                            }
                          : {
                              ...emission.replyEmission,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                              blocksMe: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };
                      if (
                        emission.replyEmission.signalBoost &&
                        emission.replyEmission.signalBoost.userID ===
                          action.data._id
                      )
                        emission.replyEmission.signalBoost = h.checkJanny(
                          state.userInfo
                        )
                          ? {
                              ...emission.replyEmission.signalBoost,
                              blocksMe: true,
                            }
                          : {
                              ...emission.replyEmission.signalBoost,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                              blocksMe: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };

                      if (emission.replyEmission.replyEmission) {
                        if (
                          emission.replyEmission.replyEmission.userID ===
                          action.data._id
                        )
                          emission.replyEmission.replyEmission = h.checkJanny(
                            state.userInfo
                          )
                            ? {
                                ...emission.replyEmission.replyEmission,
                                blocksMe: true,
                              }
                            : {
                                ...emission.replyEmission.replyEmission,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                                blocksMe: true,
                                files: false,
                                pollData: false,
                                signalBoost: false,
                              };
                        if (
                          emission.replyEmission.replyEmission.signalBoost &&
                          emission.replyEmission.replyEmission.signalBoost
                            .userID === action.data._id
                        )
                          emission.replyEmission.replyEmission.signalBoost =
                            h.checkJanny(state.userInfo)
                              ? {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  blocksMe: true,
                                }
                              : {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                                  blocksMe: true,
                                  files: false,
                                  pollData: false,
                                  signalBoost: false,
                                };
                      }
                    }
                    return emission;
                  }),
                },
                likes: {
                  ...state.profileInfo.likes,
                  items: state.profileInfo.likes.items.map((emission) => {
                    if (emission.userID === action.data._id)
                      emission = state.userInfo
                        ? {
                            ...emission,
                            blocksMe: true,
                          }
                        : {
                            ...emission,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                            blocksMe: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };
                    if (
                      emission.signalBoost &&
                      emission.signalBoost.userID === action.data._id
                    )
                      emission.signalBoost = h.checkJanny(state.userInfo)
                        ? {
                            ...emission.signalBoost,
                            blocksMe: true,
                          }
                        : {
                            ...emission.signalBoost,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                            blocksMe: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };

                    if (emission.replyEmission) {
                      if (emission.replyEmission.userID === action.data._id)
                        emission.replyEmission = h.checkJanny(state.userInfo)
                          ? {
                              ...emission.replyEmission,
                              blocksMe: true,
                            }
                          : {
                              ...emission.replyEmission,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                              blocksMe: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };
                      if (
                        emission.replyEmission.signalBoost &&
                        emission.replyEmission.signalBoost.userID ===
                          action.data._id
                      )
                        emission.replyEmission.signalBoost = h.checkJanny(
                          state.userInfo
                        )
                          ? {
                              ...emission.replyEmission.signalBoost,
                              blocksMe: true,
                            }
                          : {
                              ...emission.replyEmission.signalBoost,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                              blocksMe: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };

                      if (emission.replyEmission.replyEmission) {
                        if (
                          emission.replyEmission.replyEmission.userID ===
                          action.data._id
                        )
                          emission.replyEmission.replyEmission = h.checkJanny(
                            state.userInfo
                          )
                            ? {
                                ...emission.replyEmission.replyEmission,
                                blocksMe: true,
                              }
                            : {
                                ...emission.replyEmission.replyEmission,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                                blocksMe: true,
                                files: false,
                                pollData: false,
                                signalBoost: false,
                              };
                        if (
                          emission.replyEmission.replyEmission.signalBoost &&
                          emission.replyEmission.replyEmission.signalBoost
                            .userID === action.data._id
                        )
                          emission.replyEmission.replyEmission.signalBoost =
                            state.userInfo
                              ? {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  blocksMe: true,
                                }
                              : {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} has blocked you</h5>`,
                                  blocksMe: true,
                                  files: false,
                                  pollData: false,
                                  signalBoost: false,
                                };
                      }
                    }
                    return emission;
                  }),
                },
              },
      };
    case PRIVATE_PROFILE:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo:
          (state.profileInfo?.user &&
            state.profileInfo?.user?._id === action.data._id) ||
          h.checkJanny(state.userInfo)
            ? {
                ...state.profileInfo,
                user: {
                  ...state.profileInfo?.user,
                  ...action.data,
                },
              }
            : {
                ...state.profileInfo,
                emissions: {
                  ...state.profileInfo.emissions,
                  items: state.profileInfo.emissions.items.map((emission) => {
                    if (emission.userID === action.data._id)
                      emission = h.checkJanny(state.userInfo)
                        ? {
                            ...emission,
                            private: true,
                          }
                        : {
                            ...emission,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                            private: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };
                    if (
                      emission.signalBoost &&
                      emission.signalBoost.userID === action.data._id
                    )
                      emission.signalBoost = h.checkJanny(state.userInfo)
                        ? {
                            ...emission.signalBoost,
                            private: true,
                          }
                        : {
                            ...emission.signalBoost,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                            private: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };

                    if (emission.replyEmission) {
                      if (emission.replyEmission.userID === action.data._id)
                        emission.replyEmission = h.checkJanny(state.userInfo)
                          ? {
                              ...emission.replyEmission,
                              private: true,
                            }
                          : {
                              ...emission.replyEmission,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                              private: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };
                      if (
                        emission.replyEmission.signalBoost &&
                        emission.replyEmission.signalBoost.userID ===
                          action.data._id
                      )
                        emission.replyEmission.signalBoost = h.checkJanny(
                          state.userInfo
                        )
                          ? {
                              ...emission.replyEmission.signalBoost,
                              private: true,
                            }
                          : {
                              ...emission.replyEmission.signalBoost,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                              private: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };

                      if (emission.replyEmission.replyEmission) {
                        if (
                          emission.replyEmission.replyEmission.userID ===
                          action.data._id
                        )
                          emission.replyEmission.replyEmission = h.checkJanny(
                            state.userInfo
                          )
                            ? {
                                ...emission.replyEmission.replyEmission,
                                private: true,
                              }
                            : {
                                ...emission.replyEmission.replyEmission,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                                private: true,
                                files: false,
                                pollData: false,
                                signalBoost: false,
                              };
                        if (
                          emission.replyEmission.replyEmission.signalBoost &&
                          emission.replyEmission.replyEmission.signalBoost
                            .userID === action.data._id
                        )
                          emission.replyEmission.replyEmission.signalBoost =
                            h.checkJanny(state.userInfo)
                              ? {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  private: true,
                                }
                              : {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                                  private: true,
                                  files: false,
                                  pollData: false,
                                  signalBoost: false,
                                };
                      }
                    }
                    return emission;
                  }),
                },
                likes: {
                  ...state.profileInfo.likes,
                  items: state.profileInfo.likes.items.map((emission) => {
                    if (emission.userID === action.data._id)
                      emission = h.checkJanny(state.userInfo)
                        ? {
                            ...emission,
                            private: true,
                          }
                        : {
                            ...emission,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                            private: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };
                    if (
                      emission.signalBoost &&
                      emission.signalBoost.userID === action.data._id
                    )
                      emission.signalBoost = h.checkJanny(state.userInfo)
                        ? {
                            ...emission.signalBoost,
                            private: true,
                          }
                        : {
                            ...emission.signalBoost,
                            html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                            private: true,
                            files: false,
                            pollData: false,
                            signalBoost: false,
                          };

                    if (emission.replyEmission) {
                      if (emission.replyEmission.userID === action.data._id)
                        emission.replyEmission = h.checkJanny(state.userInfo)
                          ? {
                              ...emission.replyEmission,
                              private: true,
                            }
                          : {
                              ...emission.replyEmission,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                              private: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };
                      if (
                        emission.replyEmission.signalBoost &&
                        emission.replyEmission.signalBoost.userID ===
                          action.data._id
                      )
                        emission.replyEmission.signalBoost = h.checkJanny(
                          state.userInfo
                        )
                          ? {
                              ...emission.replyEmission.signalBoost,
                              private: true,
                            }
                          : {
                              ...emission.replyEmission.signalBoost,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                              private: true,
                              files: false,
                              pollData: false,
                              signalBoost: false,
                            };

                      if (emission.replyEmission.replyEmission) {
                        if (
                          emission.replyEmission.replyEmission.userID ===
                          action.data._id
                        )
                          emission.replyEmission.replyEmission = h.checkJanny(
                            state.userInfo
                          )
                            ? {
                                ...emission.replyEmission.replyEmission,
                                private: true,
                              }
                            : {
                                ...emission.replyEmission.replyEmission,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                                private: true,
                                files: false,
                                pollData: false,
                                signalBoost: false,
                              };
                        if (
                          emission.replyEmission.replyEmission.signalBoost &&
                          emission.replyEmission.replyEmission.signalBoost
                            .userID === action.data._id
                        )
                          emission.replyEmission.replyEmission.signalBoost =
                            h.checkJanny(state.userInfo)
                              ? {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  private: true,
                                }
                              : {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} has made their profile private</h5>`,
                                  private: true,
                                  files: false,
                                  pollData: false,
                                  signalBoost: false,
                                };
                      }
                    }
                    return emission;
                  }),
                },
              },
      };
    case FOLLOW:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          following:
            action.data.actionee === state.profileInfo.user._id &&
            action.data.actioner === state.userInfo._id
              ? action.data.follow
              : state.profileInfo.following,
          followsYou:
            action.data.actionee === state.userInfo._id &&
            action.data.actioner === state.profileInfo.user._id
              ? action.data.follow
              : state.profileInfo.followsYou,
          user: state.profileInfo.user
            ? {
                ...state.profileInfo.user,
                followers:
                  action.data.actionee === state.profileInfo.user._id
                    ? action.data.follow
                      ? state.profileInfo.user.followers + 1
                      : state.profileInfo.user.followers - 1
                    : state.profileInfo.user.followers,
                following:
                  action.data.actioner === state.profileInfo.user._id
                    ? action.data.follow
                      ? state.profileInfo.user.following + 1
                      : state.profileInfo.user.following - 1
                    : state.profileInfo.user.following,
              }
            : state.profileInfo.user,
        },
        userInfo: {
          ...state.userInfo,
          followers:
            action.data.actionee === state.userInfo._id
              ? action.data.follow
                ? state.userInfo.followers + 1
                : state.userInfo.followers - 1
              : state.userInfo.followers,
          following:
            action.data.actioner === state.userInfo._id
              ? action.data.follow
                ? state.userInfo.following.concat([action.data.user_id])
                : state.userInfo.following.filter(
                    (follower) => follower !== action.data.user_id
                  )
              : state.userInfo.following,
        },
      };
    case BAN_OTHER:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo:
          (state.profileInfo?.user &&
            state.profileInfo?.user?._id === action.data._id) ||
          action.data._id === state.userInfo._id
            ? h.checkJanny(state.userInfo) ||
              state.userInfo._id === action.data._id
              ? {
                  ...state.profileInfo,
                  user: action.data,
                  emissions: {
                    ...state.profileInfo.emissions,
                    items: state.profileInfo.emissions.items.map((emission) => {
                      if (emission.userID === action.data._id)
                        emission = {
                          ...emission,
                          remove: {
                            removed: true,
                            reason: "ban",
                            user: {
                              username: "",
                              userID: "",
                            },
                            details: false,
                          },
                        };
                      if (
                        emission.signalBoost &&
                        emission.signalBoost.userID === action.data._id
                      )
                        emission.signalBoost = {
                          ...emission.signalBoost,
                          remove: {
                            removed: true,
                            reason: "ban",
                            user: {
                              username: "",
                              userID: "",
                            },
                            details: false,
                          },
                        };

                      if (emission.replyEmission) {
                        if (emission.replyEmission.userID === action.data._id)
                          emission.replyEmission = {
                            ...emission.replyEmission,
                            remove: {
                              removed: true,
                              reason: "ban",
                              user: {
                                username: "",
                                userID: "",
                              },
                              details: false,
                            },
                          };
                        if (
                          emission.replyEmission.signalBoost &&
                          emission.replyEmission.signalBoost.userID ===
                            action.data._id
                        )
                          emission.replyEmission.signalBoost = {
                            ...emission.replyEmission.signalBoost,
                            remove: {
                              removed: true,
                              reason: "ban",
                              user: {
                                username: "",
                                userID: "",
                              },
                              details: false,
                            },
                          };

                        if (emission.replyEmission.replyEmission) {
                          if (
                            emission.replyEmission.replyEmission.userID ===
                            action.data._id
                          )
                            emission.replyEmission.replyEmission = {
                              ...emission.replyEmission.replyEmission,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                            };
                          if (
                            emission.replyEmission.replyEmission.signalBoost &&
                            emission.replyEmission.replyEmission.signalBoost
                              .userID === action.data._id
                          )
                            emission.replyEmission.replyEmission.signalBoost = {
                              ...emission.replyEmission.replyEmission
                                .signalBoost,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                            };
                        }
                      }
                      return emission;
                    }),
                  },
                  likes: {
                    ...state.profileInfo.likes,
                    items: state.profileInfo.likes.items.map((emission) => {
                      if (emission.userID === action.data._id)
                        emission = {
                          ...emission,
                          remove: {
                            removed: true,
                            reason: "ban",
                            user: {
                              username: "",
                              userID: "",
                            },
                            details: false,
                          },
                        };
                      if (
                        emission.signalBoost &&
                        emission.signalBoost.userID === action.data._id
                      )
                        emission.signalBoost = {
                          ...emission.signalBoost,
                          remove: {
                            removed: true,
                            reason: "ban",
                            user: {
                              username: "",
                              userID: "",
                            },
                            details: false,
                          },
                        };

                      if (emission.replyEmission) {
                        if (emission.replyEmission.userID === action.data._id)
                          emission.replyEmission = {
                            ...emission.replyEmission,
                            remove: {
                              removed: true,
                              reason: "ban",
                              user: {
                                username: "",
                                userID: "",
                              },
                              details: false,
                            },
                          };
                        if (
                          emission.replyEmission.signalBoost &&
                          emission.replyEmission.signalBoost.userID ===
                            action.data._id
                        )
                          emission.replyEmission.signalBoost = {
                            ...emission.replyEmission.signalBoost,
                            remove: {
                              removed: true,
                              reason: "ban",
                              user: {
                                username: "",
                                userID: "",
                              },
                              details: false,
                            },
                          };

                        if (emission.replyEmission.replyEmission) {
                          if (
                            emission.replyEmission.replyEmission.userID ===
                            action.data._id
                          )
                            emission.replyEmission.replyEmission = {
                              ...emission.replyEmission.replyEmission,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                              },
                            };
                          if (
                            emission.replyEmission.replyEmission.signalBoost &&
                            emission.replyEmission.replyEmission.signalBoost
                              .userID === action.data._id
                          )
                            emission.replyEmission.replyEmission.signalBoost = {
                              ...emission.replyEmission.replyEmission
                                .signalBoost,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                            };
                        }
                      }
                      return emission;
                    }),
                  },
                }
              : {
                  ...state.profileInfo,
                  emissions: {
                    items: [],
                    totalNoReply: 0,
                    totalReplies: 0,
                    totalMedia: 0,
                  },
                  likes: {
                    items: [],
                    total: 0,
                  },
                  user: action.data,
                }
            : {
                ...state.profileInfo,
                emissions: {
                  ...state.profileInfo.emissions,
                  items: state.profileInfo.emissions.items.map((emission) => {
                    if (emission.userID === action.data._id)
                      emission =
                        h.checkJanny(state.userInfo) ||
                        state.userInfo._id === action.data._id
                          ? {
                              ...emission,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                            }
                          : {
                              ...emission,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                              files: false,
                              pollData: false,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                              signalBoost: false,
                            };
                    if (
                      emission.signalBoost &&
                      emission.signalBoost.userID === action.data._id
                    )
                      emission.signalBoost =
                        h.checkJanny(state.userInfo) ||
                        state.userInfo._id === action.data._id
                          ? {
                              ...emission.signalBoost,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                            }
                          : {
                              ...emission.signalBoost,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                              files: false,
                              pollData: false,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                              signalBoost: false,
                            };

                    if (emission.replyEmission) {
                      if (emission.replyEmission.userID === action.data._id)
                        emission.replyEmission =
                          h.checkJanny(state.userInfo) ||
                          state.userInfo._id === action.data._id
                            ? {
                                ...emission.replyEmission,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                              }
                            : {
                                ...emission.replyEmission,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                files: false,
                                pollData: false,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                                signalBoost: false,
                              };
                      if (
                        emission.replyEmission.signalBoost &&
                        emission.replyEmission.signalBoost.userID ===
                          action.data._id
                      )
                        emission.replyEmission.signalBoost =
                          h.checkJanny(state.userInfo) ||
                          state.userInfo._id === action.data._id
                            ? {
                                ...emission.replyEmission.signalBoost,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                              }
                            : {
                                ...emission.replyEmission.signalBoost,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                files: false,
                                pollData: false,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                                signalBoost: false,
                              };

                      if (emission.replyEmission.replyEmission) {
                        if (
                          emission.replyEmission.replyEmission.userID ===
                          action.data._id
                        )
                          emission.replyEmission.replyEmission =
                            h.checkJanny(state.userInfo) ||
                            state.userInfo._id === action.data._id
                              ? {
                                  ...emission.replyEmission.replyEmission,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                    details: false,
                                  },
                                }
                              : {
                                  ...emission.replyEmission.replyEmission,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                  files: false,
                                  pollData: false,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                    details: false,
                                  },
                                  signalBoost: false,
                                };
                        if (
                          emission.replyEmission.replyEmission.signalBoost &&
                          emission.replyEmission.replyEmission.signalBoost
                            .userID === action.data._id
                        )
                          emission.replyEmission.replyEmission.signalBoost =
                            h.checkJanny(state.userInfo) ||
                            state.userInfo._id === action.data._id
                              ? {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                    details: false,
                                  },
                                }
                              : {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                  files: false,
                                  pollData: false,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                    details: false,
                                  },
                                  signalBoost: false,
                                };
                      }
                    }
                    return emission;
                  }),
                },
                likes: {
                  ...state.profileInfo.likes,
                  items: state.profileInfo.likes.items.map((emission) => {
                    if (emission.userID === action.data._id)
                      emission =
                        h.checkJanny(state.userInfo) ||
                        state.userInfo._id === action.data._id
                          ? {
                              ...emission,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                            }
                          : {
                              ...emission,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                              files: false,
                              pollData: false,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                              signalBoost: false,
                            };
                    if (
                      emission.signalBoost &&
                      emission.signalBoost.userID === action.data._id
                    )
                      emission.signalBoost =
                        h.checkJanny(state.userInfo) ||
                        state.userInfo._id === action.data._id
                          ? {
                              ...emission.signalBoost,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                            }
                          : {
                              ...emission.signalBoost,
                              html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                              files: false,
                              pollData: false,
                              remove: {
                                removed: true,
                                reason: "ban",
                                user: {
                                  username: "",
                                  userID: "",
                                },
                                details: false,
                              },
                              signalBoost: false,
                            };

                    if (emission.replyEmission) {
                      if (emission.replyEmission.userID === action.data._id)
                        emission.replyEmission =
                          h.checkJanny(state.userInfo) ||
                          state.userInfo._id === action.data._id
                            ? {
                                ...emission.replyEmission,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                              }
                            : {
                                ...emission.replyEmission,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                files: false,
                                pollData: false,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                                signalBoost: false,
                              };
                      if (
                        emission.replyEmission.signalBoost &&
                        emission.replyEmission.signalBoost.userID ===
                          action.data._id
                      )
                        emission.replyEmission.signalBoost =
                          h.checkJanny(state.userInfo) ||
                          state.userInfo._id === action.data._id
                            ? {
                                ...emission.replyEmission.signalBoost,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                              }
                            : {
                                ...emission.replyEmission.signalBoost,
                                html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                files: false,
                                pollData: false,
                                remove: {
                                  removed: true,
                                  reason: "ban",
                                  user: {
                                    username: "",
                                    userID: "",
                                  },
                                  details: false,
                                },
                                signalBoost: false,
                              };

                      if (emission.replyEmission.replyEmission) {
                        if (
                          emission.replyEmission.replyEmission.userID ===
                          action.data._id
                        )
                          emission.replyEmission.replyEmission =
                            h.checkJanny(state.userInfo) ||
                            state.userInfo._id === action.data._id
                              ? {
                                  ...emission.replyEmission.replyEmission,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                  },
                                }
                              : {
                                  ...emission.replyEmission.replyEmission,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                  files: false,
                                  pollData: false,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                    details: false,
                                  },
                                  signalBoost: false,
                                };
                        if (
                          emission.replyEmission.replyEmission.signalBoost &&
                          emission.replyEmission.replyEmission.signalBoost
                            .userID === action.data._id
                        )
                          emission.replyEmission.replyEmission.signalBoost =
                            h.checkJanny(state.userInfo) ||
                            state.userInfo._id === action.data._id
                              ? {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                    details: false,
                                  },
                                }
                              : {
                                  ...emission.replyEmission.replyEmission
                                    .signalBoost,
                                  html: `<h5 class="text-center display-6 my-4">@${action.data.username} is banned</h5>`,
                                  files: false,
                                  pollData: false,
                                  remove: {
                                    removed: true,
                                    reason: "ban",
                                    user: {
                                      username: "",
                                      userID: "",
                                    },
                                    details: false,
                                  },
                                  signalBoost: false,
                                };
                      }
                    }
                    return emission;
                  }),
                },
              },
      };
    case REMOVE_EMISSION:
      const removeData = {
        html: `<h5 class="text-center display-6 my-4">${env.EMISSION_NAME} Removed</h5>`,
        files: false,
        pollData: false,
        remove: {
          ...action.data.remove,
          details: false,
        },
        signalBoost: false,
      };
      const checkEmission = state.profileInfo.emissions.items.find(
        (e) => e.emissionID === action.data.emissionID
      );
      const checkLike = state.profileInfo.likes.items.find(
        (e) => e.emissionID === action.data.emissionID
      );
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: h.replaceEmissions(
              state.profileInfo.emissions.items,
              action.data.emissionID,
              removeData
            ),
            totalNoReply:
              checkEmission && !checkEmission.replyID
                ? state.profileInfo.emissions.totalNoReply - 1
                : state.profileInfo.emissions.totalNoReply,
            totalReplies:
              checkEmission && checkEmission.replyID
                ? state.profileInfo.emissions.totalReplies - 1
                : state.profileInfo.emissions.totalReplies,
            totalMedia:
              checkEmission && checkEmission.files
                ? state.profileInfo.emissions.totalMedia - 1
                : state.profileInfo.emissions.totalMedia,
          },
          likes: {
            ...state.profileInfo.likes,
            items: h.replaceEmissions(
              state.profileInfo.likes.items,
              action.data,
              removeData
            ),
            total: checkLike
              ? state.profileInfo.likes.total - 1
              : state.profileInfo.likes.total,
          },
        },
      };
    case SET_EMISSION_REPORTED:
      const reportData = {
        reported: true,
      };
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: h.replaceEmissions(
              state.profileInfo.emissions.items,
              action.data,
              reportData
            ),
          },
          likes: {
            ...state.profileInfo.likes,
            items: h.replaceEmissions(
              state.profileInfo.likes.items,
              action.data.emissionID,
              reportData
            ),
          },
        },
      };
    case SET_UNREAD_MESSAGES:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          unreadMessages: action.data,
        },
      };
    case SET_POLL_VOTED:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: h.setVoted(state.profileInfo.emissions.items, action.data),
          },
          likes: {
            ...state.profileInfo.likes,
            items: h.setVoted(state.profileInfo.likes.items, action.data),
          },
        },
      };
    case CLEAR_TEMP_ACTION:
      return {
        ...state,
        tempAction: false,
      };
    case SET_TEMP_ACTION:
      return {
        ...state,
        tempAction: action.data,
      };
    case ADD_EMISSIONS:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: [
              ...state.profileInfo.emissions.items.filter(
                (emission) =>
                  action.data.emissions
                    .map((e) => e.emissionID)
                    .indexOf(emission.emissionID) === -1
              ),
              ...action.data.emissions,
            ].sort((a, b) => b.emissionID - a.emissionID),
            totalNoReply: action.data.totalNoReply,
            totalReplies: action.data.totalReplies,
            totalMedia: action.data.totalMedia,
          },
          likes: {
            ...state.profileInfo.likes,
            items: [
              ...state.profileInfo.likes.items.filter(
                (emission) =>
                  action.data.emissions
                    .map((e) => e.emissionID)
                    .indexOf(emission.emissionID) === -1
              ),
              ...action.data.emissions.filter((emission) => emission.liked),
            ],
            total: action.data.totalLikes,
          },
        },
      };
    case RECONNECT_SOCKET:
      return {
        ...state,
        socketReconnect: !state.socketReconnect,
      };
    case ADD_LIKES:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            totalNoReply: action.data.totalNoReply,
            totalReplies: action.data.totalReplies,
            totalMedia: action.data.totalMedia,
          },
          likes: {
            ...state.profileInfo.likes,
            items: [
              ...state.profileInfo.likes.items.filter(
                (emission) =>
                  action.data.emissions
                    .map((e) => e.emissionID)
                    .indexOf(emission.emissionID) === -1
              ),
              ...action.data.emissions,
            ],
            total: action.data.totalLikes,
          },
        },
      };
    case UPDATE_USER:
      return {
        ...state,
        userInfo: action.data.userInfo,
        verificationDetails: action.data.clearValidation
          ? false
          : state.verificationDetails,
      };
    case SET_USER:
      const profileInfo = action.data.profileInfo;
      return {
        ...state,
        splashed: false,
        thing: action.data.thing ? action.data.thing : state.thing,
        userInfo: action.data.userInfo,
        profileInfo: profileInfo
          ? {
              ...state.profileInfo,
              blocksMe: profileInfo.blocksMe,
              emissions: {
                ...state.profileInfo.emissions,
                totalMedia: profileInfo.emissions.totalMedia,
                totalNoReply: profileInfo.emissions.totalNoReply,
                totalReplies: profileInfo.emissions.totalReplies,
                items: [
                  ...state.profileInfo.emissions.items.filter((e) => {
                    if (profileInfo.isBlocked || profileInfo.blocksMe)
                      return false;

                    return !profileInfo.emissions.items.find(
                      (emission) => e.emissionID === emission.emissionID
                    );
                  }),
                  ...profileInfo.emissions.items,
                ].sort((a, b) => b.emissionID - a.emissionID),
              },
              following: profileInfo.following,
              followsYou: profileInfo.followsYou,
              isBlocked: profileInfo.isBlocked,
              likes: {
                ...state.profileInfo.likes,
                items: [
                  ...state.profileInfo.likes.items.filter((e) => {
                    if (profileInfo.isBlocked || profileInfo.blocksMe)
                      return false;

                    return !profileInfo.likes.items.find(
                      (emission) => e.emissionID === emission.emissionID
                    );
                  }),
                  ...profileInfo.likes.items,
                ],
                total: profileInfo.likes.total,
              },
              loaded: true,
              conversation: profileInfo.conversation,
              stats: profileInfo.stats,
              user: profileInfo?.user,
            }
          : state.profileInfo,
      };
    case CHANGE_USER_DETAILS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.data.userInfo,
        },
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: state.profileInfo.emissions.items.map((emission) => {
              if (emission.userID === state.userInfo._id) {
                emission.displayName = action.data.userInfo.displayName;
                emission.avatar = action.data.userInfo.avatar;
              }

              if (
                emission.signalBoost &&
                emission.signalBoost.userID === state.userInfo._id
              ) {
                emission.signalBoost.displayName =
                  action.data.userInfo.displayName;
                emission.signalBoost.avatar = action.data.userInfo.avatar;
              }

              if (emission.replyEmission) {
                if (emission.replyEmission.userID === state.userInfo._id) {
                  emission.replyEmission.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.avatar = action.data.userInfo.avatar;
                }

                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.userID ===
                    state.userInfo._id
                ) {
                  emission.replyEmission.signalBoost.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.signalBoost.avatar =
                    action.data.userInfo.avatar;
                }
              }

              if (
                emission.replyEmission &&
                emission.replyEmission.replyEmission
              ) {
                if (
                  emission.replyEmission.replyEmission.userID ===
                  state.userInfo._id
                ) {
                  emission.replyEmission.replyEmission.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.replyEmission.avatar =
                    action.data.userInfo.avatar;
                }

                if (
                  emission.replyEmission.replyEmission.signalBoost &&
                  emission.replyEmission.replyEmission.signalBoost.userID ===
                    state.userInfo._id
                ) {
                  emission.replyEmission.replyEmission.signalBoost.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.replyEmission.signalBoost.avatar =
                    action.data.userInfo.avatar;
                }
              }

              return emission;
            }),
          },
          likes: {
            ...state.profileInfo.likes,
            items: state.profileInfo.emissions.items.map((emission) => {
              if (emission.userID === state.userInfo._id) {
                emission.displayName = action.data.userInfo.displayName;
                emission.avatar = action.data.userInfo.avatar;
              }

              if (
                emission.signalBoost &&
                emission.signalBoost.userID === state.userInfo._id
              ) {
                emission.signalBoost.displayName =
                  action.data.userInfo.displayName;
                emission.signalBoost.avatar = action.data.userInfo.avatar;
              }

              if (emission.replyEmission) {
                if (emission.replyEmission.userID === state.userInfo._id) {
                  emission.replyEmission.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.avatar = action.data.userInfo.avatar;
                }

                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.userID ===
                    state.userInfo._id
                ) {
                  emission.replyEmission.signalBoost.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.signalBoost.avatar =
                    action.data.userInfo.avatar;
                }
              }

              if (
                emission.replyEmission &&
                emission.replyEmission.replyEmission
              ) {
                if (
                  emission.replyEmission.replyEmission.userID ===
                  state.userInfo._id
                ) {
                  emission.replyEmission.replyEmission.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.replyEmission.avatar =
                    action.data.userInfo.avatar;
                }

                if (
                  emission.replyEmission.replyEmission.signalBoost &&
                  emission.replyEmission.replyEmission.signalBoost.userID ===
                    state.userInfo._id
                ) {
                  emission.replyEmission.replyEmission.signalBoost.displayName =
                    action.data.userInfo.displayName;
                  emission.replyEmission.replyEmission.signalBoost.avatar =
                    action.data.userInfo.avatar;
                }
              }

              return emission;
            }),
          },
        },
      };
    case UPDATE_EMISSION:
      const emissionCheck = state.profileInfo.emissions.items.find(
        (e) => e.emissionID === action.data.emissionID
      );
      const likeCheck = state.profileInfo.likes.items.find(
        (e) => e.emissionID === action.data.emissionID
      );
      let totalNoReply_update = state.profileInfo.emissions.totalNoReply;
      let totalReplies_update = state.profileInfo.emissions.totalReplies;
      let totalMedia_update = state.profileInfo.emissions.totalMedia;
      let totalLikes = state.profileInfo.likes.total;
      if (emissionCheck?.remove?.removed !== action.data?.remove?.removed) {
        if (action.data.remove.removed) {
          if (action.data.files.length) totalMedia_update--;
          if (action.data.replyEmission) totalReplies_update--;
          else totalNoReply_update--;
        } else {
          if (action.data.files.length) totalMedia_update++;
          if (action.data.replyEmission) totalReplies_update++;
          else totalNoReply_update++;
        }
      }
      if (
        likeCheck &&
        likeCheck.remove.removed !== action.data.remove.removed
      ) {
        if (action.data.remove.removed) {
          totalLikes--;
        } else {
          totalLikes++;
        }
      }
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: h.updateEmission(
              state.profileInfo.emissions.items,
              action.data,
              state.userInfo
            ),
            totalNoReply: totalNoReply_update,
            totalReplies: totalReplies_update,
            totalMedia: totalMedia_update,
          },
          likes: {
            ...state.profileInfo.likes,
            items: h.updateEmission(
              state.profileInfo.likes.items,
              action.data,
              state.userInfo
            ),
            total: totalLikes,
          },
        },
      };
    case LIKE_SELF:
      likes = JSON.parse(JSON.stringify(state.profileInfo.likes.items));
      let emissionCheckSelf = false;
      let likeCheckSelf = false;
      let likeSelfTotal = state.profileInfo.likes.total || 0;
      let likeOtherTotal = state.profileInfo.stats?.likes || 0;

      if (state.profileInfo.stats) {
        state.profileInfo.emissions.items.forEach((emission) => {
          if (emission.emissionID === action.data.emissionID)
            emissionCheckSelf = emission;
          if (
            emission.signalBoost &&
            emission.signalBoost.emissionID === action.data.emissionID
          )
            emissionCheckSelf = emission.signalBoost;

          if (emission.replyEmission) {
            if (emission.replyEmission.emissionID === action.data.emissionID)
              emissionCheckSelf = emission.replyEmission;
            if (
              emission.replyEmission.signalBoost &&
              emission.replyEmission.signalBoost.emissionID ===
                action.data.emissionID
            )
              emissionCheckSelf = emission.replyEmission.signalBoost;

            if (emission.replyEmission.replyEmission) {
              if (
                emission.replyEmission.replyEmission.emissionID ===
                action.data.emissionID
              )
                emissionCheckSelf = emission.replyEmission.replyEmission;
              if (
                emission.replyEmission.replyEmission.signalBoost &&
                emission.replyEmission.replyEmission.signalBoost.emissionID ===
                  action.data.emissionID
              )
                emissionCheckSelf =
                  emission.replyEmission.replyEmission.signalBoost;
            }
          }
        });

        state.profileInfo.likes.items.forEach((emission) => {
          if (emission.emissionID === action.data.emissionID)
            likeCheckSelf = emission;
          if (
            emission.signalBoost &&
            emission.signalBoost.emissionID === action.data.emissionID
          )
            likeCheckSelf = emission.signalBoost;

          if (emission.replyEmission) {
            if (emission.replyEmission.emissionID === action.data.emissionID)
              likeCheckSelf = emission.replyEmission;
            if (
              emission.replyEmission.signalBoost &&
              emission.replyEmission.signalBoost.emissionID ===
                action.data.emissionID
            )
              likeCheckSelf = emission.replyEmission.signalBoost;

            if (emission.replyEmission.replyEmission) {
              if (
                emission.replyEmission.replyEmission.emissionID ===
                action.data.emissionID
              )
                likeCheckSelf = emission.replyEmission.replyEmission;
              if (
                emission.replyEmission.replyEmission.signalBoost &&
                emission.replyEmission.replyEmission.signalBoost.emissionID ===
                  action.data.emissionID
              )
                likeCheckSelf =
                  emission.replyEmission.replyEmission.signalBoost;
            }
          }
        });

        if (emissionCheckSelf && !likeCheckSelf && !emissionCheckSelf.liked) {
          likes = [...likes, emissionCheckSelf];
          if (emissionCheckSelf.userID === state.profileInfo.user._id)
            likeOtherTotal++;
          likeSelfTotal++;
        }

        if (likeCheckSelf && emissionCheckSelf.userID === state.userInfo._id) {
          likes = likes.filter(
            (e) => e.emissionID !== likeCheckSelf.emissionID
          );
          if (emissionCheckSelf.userID === state.profileInfo.user._id)
            likeOtherTotal--;
          likeSelfTotal--;
        }
      }

      const alreadyLiked = state.userInfo.likes.find(
        (like) => like === action.data.emissionID
      );
      return {
        ...state,
        socketEvents: action.data.eventID
          ? [...state.socketEvents, action.data.eventID]
          : state.socketEvents,
        userInfo: {
          ...state.userInfo,
          likes: alreadyLiked
            ? state.userInfo.likes.filter(
                (like) => like !== action.data.emissionID
              )
            : [...state.userInfo.likes, action.data.emissionID],
        },
        profileInfo: !state.profileInfo.stats
          ? state.profileInfo
          : {
              ...state.profileInfo,
              stats: {
                ...state.profileInfo.stats,
                likes: likeOtherTotal,
              },
              emissions: {
                ...state.profileInfo.emissions,
                items: state.profileInfo.emissions.items
                  .map((emission) => {
                    if (emission.emissionID === action.data.emissionID)
                      emission = {
                        ...emission,
                        liked: !emission.liked,
                        likes: emission.liked
                          ? emission.likes - 1
                          : emission.likes + 1,
                      };

                    if (
                      emission.signalBoost &&
                      emission.signalBoost.emissionID === action.data.emissionID
                    )
                      emission.signalBoost = {
                        ...emission.signalBoost,
                        liked: !emission.signalBoost.liked,
                        likes: emission.signalBoost.liked
                          ? emission.signalBoost.likes - 1
                          : emission.signalBoost.likes + 1,
                      };

                    if (emission.replyEmission) {
                      if (
                        emission.replyEmission.emissionID ===
                        action.data.emissionID
                      )
                        emission.replyEmission = {
                          ...emission.replyEmission,
                          liked: !emission.replyEmission.liked,
                          likes: emission.replyEmission.liked
                            ? emission.replyEmission.likes - 1
                            : emission.replyEmission.likes + 1,
                        };
                      if (
                        emission.replyEmission.signalBoost &&
                        emission.replyEmission.signalBoost.emissionID ===
                          action.data.emissionID
                      )
                        emission.replyEmission.signalBoost = {
                          ...emission.replyEmission.signalBoost,
                          liked: !emission.replyEmission.signalBoost.liked,
                          likes: emission.replyEmission.signalBoost.liked
                            ? emission.replyEmission.signalBoost.likes - 1
                            : emission.replyEmission.signalBoost.likes + 1,
                        };
                    }

                    if (
                      emission.replyEmission &&
                      emission.replyEmission.replyEmission
                    ) {
                      if (
                        emission.replyEmission.replyEmission.emissionID ===
                        action.data.emissionID
                      )
                        emission.replyEmission.replyEmission = {
                          ...emission.replyEmission.replyEmission,
                          liked: !emission.replyEmission.replyEmission.liked,
                          likes: emission.replyEmission.replyEmission.liked
                            ? emission.replyEmission.replyEmission.likes - 1
                            : emission.replyEmission.replyEmission.likes + 1,
                        };
                      if (
                        emission.replyEmission.replyEmission.signalBoost &&
                        emission.replyEmission.replyEmission.signalBoost
                          .emissionID === action.data.emissionID
                      )
                        emission.replyEmission.replyEmission.signalBoost = {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          liked:
                            !emission.replyEmission.replyEmission.signalBoost
                              .liked,
                          likes: emission.replyEmission.replyEmission
                            .signalBoost.liked
                            ? emission.replyEmission.replyEmission.signalBoost
                                .likes - 1
                            : emission.replyEmission.replyEmission.signalBoost
                                .likes + 1,
                        };
                    }

                    return emission;
                  })
                  .sort((a, b) => b.emissionID - a.emissionID),
              },
              likes: {
                ...state.profileInfo.likes,
                items: likes.map((emission) => {
                  if (emission.emissionID === action.data.emissionID)
                    emission = {
                      ...emission,
                      liked: !emission.liked,
                      likes: emission.liked
                        ? emission.likes - 1
                        : emission.likes + 1,
                    };

                  if (
                    emission.signalBoost &&
                    emission.signalBoost.emissionID === action.data.emissionID
                  )
                    emission.signalBoost = {
                      ...emission.signalBoost,
                      liked: !emission.signalBoost.liked,
                      likes: emission.signalBoost.liked
                        ? emission.signalBoost.likes - 1
                        : emission.signalBoost.likes + 1,
                    };

                  if (emission.replyEmission) {
                    if (
                      emission.replyEmission.emissionID ===
                      action.data.emissionID
                    )
                      emission.replyEmission = {
                        ...emission.replyEmission,
                        liked: !emission.replyEmission.liked,
                        likes: emission.replyEmission.liked
                          ? emission.replyEmission.likes - 1
                          : emission.replyEmission.likes + 1,
                      };
                    if (
                      emission.replyEmission.signalBoost &&
                      emission.replyEmission.signalBoost.emissionID ===
                        action.data.emissionID
                    )
                      emission.replyEmission.signalBoost = {
                        ...emission.replyEmission.signalBoost,
                        liked: !emission.replyEmission.signalBoost.liked,
                        likes: emission.replyEmission.signalBoost.liked
                          ? emission.replyEmission.signalBoost.likes - 1
                          : emission.replyEmission.signalBoost.likes + 1,
                      };
                  }

                  if (
                    emission.replyEmission &&
                    emission.replyEmission.replyEmission
                  ) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                      action.data.emissionID
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        liked: !emission.replyEmission.replyEmission.liked,
                        likes: emission.replyEmission.replyEmission.liked
                          ? emission.replyEmission.replyEmission.likes - 1
                          : emission.replyEmission.replyEmission.likes + 1,
                      };
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === action.data.emissionID
                    )
                      emission.replyEmission.replyEmission.signalBoost = {
                        ...emission.replyEmission.replyEmission.signalBoost,
                        liked:
                          !emission.replyEmission.replyEmission.signalBoost
                            .liked,
                        likes: emission.replyEmission.replyEmission.signalBoost
                          .liked
                          ? emission.replyEmission.replyEmission.signalBoost
                              .likes - 1
                          : emission.replyEmission.replyEmission.signalBoost
                              .likes + 1,
                      };
                  }

                  return emission;
                }),
                total: likeSelfTotal,
              },
            },
      };
    case LIKE_OTHER:
      const self =
        state.userInfo._id && action.data.userID === state.userInfo._id;
      const profile = state.profileInfo.user._id === action.data.userID;
      const liked = action.data.value > 0;
      let updatedLikes = state.profileInfo.likes.items.map((emission) => {
        if (emission.emissionID === action.data.emissionID)
          emission = {
            ...emission,
            likes: emission.likes + action.data.value,
            liked: self ? liked : emission.liked,
          };

        if (
          emission.signalBoost &&
          emission.signalBoost.emissionID === action.data.emissionID
        )
          emission.signalBoost = {
            ...emission.signalBoost,
            likes: emission.signalBoost.likes + action.data.value,
            liked: self ? liked : emission.signalBoost.liked,
          };

        if (emission.replyEmission) {
          if (emission.replyEmission.emissionID === action.data.emissionID)
            emission.replyEmission = {
              ...emission.replyEmission,
              likes: emission.replyEmission.likes + action.data.value,
              liked: self ? liked : emission.replyEmission.liked,
            };
          if (
            emission.replyEmission.signalBoost &&
            emission.replyEmission.signalBoost.emissionID ===
              action.data.emissionID
          )
            emission.replyEmission.signalBoost = {
              ...emission.replyEmission.signalBoost,
              likes:
                emission.replyEmission.signalBoost.likes + action.data.value,
              liked: self ? liked : emission.replyEmission.signalBoost.liked,
            };
        }

        if (emission.replyEmission && emission.replyEmission.replyEmission) {
          if (
            emission.replyEmission.replyEmission.emissionID ===
            action.data.emissionID
          )
            emission.replyEmission.replyEmission = {
              ...emission.replyEmission.replyEmission,
              likes:
                emission.replyEmission.replyEmission.likes + action.data.value,
              liked: self ? liked : emission.replyEmission.replyEmission.liked,
            };
          if (
            emission.replyEmission.replyEmission.signalBoost &&
            emission.replyEmission.replyEmission.signalBoost.emissionID ===
              action.data.emissionID
          )
            emission.replyEmission.replyEmission.signalBoost = {
              ...emission.replyEmission.replyEmission.signalBoost,
              likes:
                emission.replyEmission.replyEmission.signalBoost.likes +
                action.data.value,
              liked: self
                ? liked
                : emission.replyEmission.replyEmission.signalBoost.liked,
            };
        }

        return emission;
      });
      if (profile) {
        if (action.data.value > 0) updatedLikes.unshift(action.data.emission);
        else
          updatedLikes = updatedLikes.filter(
            (e) => e.emissionID !== action.data.emissionID
          );
      }
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          stats: {
            ...state.profileInfo.stats,
            likes:
              action.data.emission.userID === state.profileInfo.user._id
                ? state.profileInfo.stats.likes + action.data.value
                : state.profileInfo.stats.likes,
          },
          emissions: {
            ...state.profileInfo.emissions,
            items: state.profileInfo.emissions.items
              .map((emission) => {
                if (emission.emissionID === action.data.emissionID)
                  emission = {
                    ...emission,
                    likes: emission.likes + action.data.value,
                    liked: self ? liked : emission.liked,
                  };

                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID === action.data.emissionID
                )
                  emission.signalBoost = {
                    ...emission.signalBoost,
                    likes: emission.signalBoost.likes + action.data.value,
                    liked: self ? liked : emission.signalBoost.liked,
                  };

                if (emission.replyEmission) {
                  if (
                    emission.replyEmission.emissionID === action.data.emissionID
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      likes: emission.replyEmission.likes + action.data.value,
                      liked: self ? liked : emission.replyEmission.liked,
                    };
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      action.data.emissionID
                  )
                    emission.replyEmission.signalBoost = {
                      ...emission.replyEmission.signalBoost,
                      likes:
                        emission.replyEmission.signalBoost.likes +
                        action.data.value,
                      liked: self
                        ? liked
                        : emission.replyEmission.signalBoost.liked,
                    };
                }

                if (
                  emission.replyEmission &&
                  emission.replyEmission.replyEmission
                ) {
                  if (
                    emission.replyEmission.replyEmission.emissionID ===
                    action.data.emissionID
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      likes:
                        emission.replyEmission.replyEmission.likes +
                        action.data.value,
                      liked: self
                        ? liked
                        : emission.replyEmission.replyEmission.liked,
                    };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID === action.data.emissionID
                  )
                    emission.replyEmission.replyEmission.signalBoost = {
                      ...emission.replyEmission.replyEmission.signalBoost,
                      likes:
                        emission.replyEmission.replyEmission.signalBoost.likes +
                        action.data.value,
                      liked: self
                        ? liked
                        : emission.replyEmission.replyEmission.signalBoost
                            .liked,
                    };
                }

                return emission;
              })
              .sort((a, b) => b.emissionID - a.emissionID),
          },
          likes: {
            ...state.profileInfo.likes,
            items: updatedLikes,
            total: profile
              ? state.profileInfo.likes.total + action.data.value
              : state.profileInfo.likes.total,
          },
        },
      };
    case SIGNAL_BOOST:
      /**
       * profileInfo.stats.signalBoosts
       * profileInfo.stats.signalBoosted
       *
       * YOU:
       * On own page, boosted own - signalBoosts++, signalBoosted++
       * On own page, boosted other - signalBoosts++
       * On other page, boosted profile - signalBoosted++
       *
       * OTHER:
       * On other page, boosted other - signalBoosts++
       * On other page, boosted profile - signalBoosted++ signalBoosts++
       */

      if (action.data.fromEmissionPage) {
        if (action.data.userID !== state.userInfo._id) return state;
        else
          return {
            ...state,
            userInfo: {
              ...state.userInfo,
              boosts: state.userInfo.boosts.concat([
                {
                  boostID: action.data.emissionID,
                  emissionID: action.data.boostID,
                },
              ]), // Need to fix backwards boostID/emissionID
            },
          };
      } else
        return {
          ...state,
          userInfo:
            action.data.userID === state.userInfo._id
              ? {
                  ...state.userInfo,
                  boosts: state.userInfo.boosts.concat([action.data]),
                }
              : state.userInfo,
          socketEvents: action.data?.eventID
            ? [...state.socketEvents, action.data?.eventID]
            : state.socketEvents,
          profileInfo: {
            ...state.profileInfo,
            /**
             * profileInfo.stats.signalBoosts
             * profileInfo.stats.signalBoosted
             *
             * YOU:
             * On own page, boosted own - signalBoosts++, signalBoosted++
             * On own page, boosted other - signalBoosts++
             * On other page, boosted profile - signalBoosted++
             *
             * OTHER:
             * On other page, boosted other - signalBoosts++
             * On other page, boosted profile - signalBoosted++ signalBoosts++
             */
            stats: {
              ...state.profileInfo.stats,
              signalBoosts:
                action.data.userID === state.profileInfo.user._id
                  ? state.profileInfo.stats.signalBoosts + 1
                  : state.profileInfo.stats.signalBoosts,
              signalBoosted:
                action.data.otherID === state.profileInfo.user._id
                  ? state.profileInfo.stats.signalBoosted + 1
                  : state.profileInfo.stats.signalBoosted,
            },
            emissions: {
              ...state.profileInfo.emissions,
              items: state.profileInfo.emissions.items
                .map((emission) => {
                  if (emission.emissionID === action.data.emissionID)
                    emission = {
                      ...emission,
                      signalBoosts: emission.signalBoosts + 1,
                    };
                  if (
                    emission.signalBoost &&
                    emission.signalBoost.emissionID ===
                      action.data.emissionID &&
                    !(
                      emission.emissionID === action.data.boostID &&
                      emission.signalBoost.emissionID === action.data.emissionID
                    )
                  )
                    emission = {
                      ...emission,
                      signalBoost: {
                        ...emission.signalBoost,
                        signalBoosts: emission.signalBoost.signalBoosts + 1,
                      },
                    };
                  if (emission.replyEmission) {
                    if (
                      emission.replyEmission.emissionID ===
                      action.data.emissionID
                    )
                      emission.replyEmission = {
                        ...emission.replyEmission,
                        signalBoosts: emission.replyEmission.signalBoosts + 1,
                      };
                    if (
                      emission.replyEmission.signalBoost &&
                      emission.replyEmission.signalBoost.emissionID ===
                        action.data.emissionID
                    )
                      emission.replyEmission = {
                        ...emission.replyEmission,
                        signalBoost: {
                          ...emission.replyEmission.signalBoost,
                          signalBoosts:
                            emission.replyEmission.signalBoost.signalBoosts + 1,
                        },
                      };

                    if (emission.replyEmission.replyEmission) {
                      if (
                        emission.replyEmission.replyEmission.emissionID ===
                        action.data.emissionID
                      )
                        emission.replyEmission.replyEmission = {
                          ...emission.replyEmission.replyEmission,
                          signalBoosts:
                            emission.replyEmission.replyEmission.signalBoosts +
                            1,
                        };
                      if (
                        emission.replyEmission.replyEmission.signalBoost &&
                        emission.replyEmission.replyEmission.signalBoost
                          .emissionID === action.data.emissionID
                      )
                        emission.replyEmission.replyEmission = {
                          ...emission.replyEmission.replyEmission,
                          signalBoost: {
                            ...emission.replyEmission.replyEmission.signalBoost,
                            signalBoosts:
                              emission.replyEmission.replyEmission.signalBoost
                                .signalBoosts + 1,
                          },
                        };
                    }
                  }

                  return emission;
                })
                .sort((a, b) => b.emissionID - a.emissionID),
            },
            likes: {
              ...state.profileInfo.likes,
              items: state.profileInfo.likes.items.map((emission) => {
                if (emission.emissionID === action.data.emissionID)
                  emission = {
                    ...emission,
                    signalBoosts: emission.signalBoosts + 1,
                  };
                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID === action.data.emissionID
                )
                  emission = {
                    ...emission,
                    signalBoost: {
                      ...emission.signalBoost,
                      signalBoosts: emission.signalBoost.signalBoosts + 1,
                    },
                  };
                if (emission.replyEmission) {
                  if (
                    emission.replyEmission.emissionID === action.data.emissionID
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      signalBoosts: emission.replyEmission.signalBoosts + 1,
                    };
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      action.data.emissionID
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.signalBoost,
                        signalBoosts:
                          emission.replyEmission.signalBoost.signalBoosts + 1,
                      },
                    };

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                      action.data.emissionID
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        signalBoosts:
                          emission.replyEmission.replyEmission.signalBoosts + 1,
                      };
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === action.data.emissionID
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        signalBoost: {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          signalBoosts:
                            emission.replyEmission.replyEmission.signalBoost
                              .signalBoosts + 1,
                        },
                      };
                  }
                }

                return emission;
              }),
            },
          },
        };
    case REPLY:
      if (
        action.data?.eventID &&
        state.socketEvents.find((e) => e === action.data?.eventID)
      )
        return state;
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: state.profileInfo.emissions.items
              .map((emission) => {
                if (emission.emissionID === action.data.replyID)
                  emission = {
                    ...emission,
                    replies: emission.replies + 1,
                  };
                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID === action.data.replyID
                )
                  emission = {
                    ...emission,
                    signalBoost: {
                      ...emission.signalBoost,
                      replies: emission.signalBoost.replies + 1,
                    },
                  };
                if (emission.replyEmission) {
                  if (emission.replyEmission.emissionID === action.data.replyID)
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      replies: emission.replyEmission.replies + 1,
                    };
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      action.data.replyID
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.signalBoost,
                        replies: emission.replyEmission.signalBoost.replies + 1,
                      },
                    };

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                      action.data.replyID
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        replies:
                          emission.replyEmission.replyEmission.replies + 1,
                      };
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === action.data.replyID
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        signalBoost: {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          replies:
                            emission.replyEmission.replyEmission.signalBoost
                              .replies + 1,
                        },
                      };
                  }
                }

                return emission;
              })
              .sort((a, b) => b.emissionID - a.emissionID),
          },
          likes: {
            ...state.profileInfo.likes,
            items: state.profileInfo.likes.items.map((emission) => {
              if (emission.emissionID === action.data.replyID)
                emission = {
                  ...emission,
                  replies: emission.replies + 1,
                };
              if (
                emission.signalBoost &&
                emission.signalBoost.emissionID === action.data.replyID
              )
                emission = {
                  ...emission,
                  signalBoost: {
                    ...emission.signalBoost,
                    replies: emission.signalBoost.replies + 1,
                  },
                };
              if (emission.replyEmission) {
                if (emission.replyEmission.emissionID === action.data.replyID)
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    replies: emission.replyEmission.replies + 1,
                  };
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.emissionID ===
                    action.data.replyID
                )
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    signalBoost: {
                      ...emission.replyEmission.signalBoost,
                      replies: emission.replyEmission.signalBoost.replies + 1,
                    },
                  };

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.emissionID ===
                    action.data.replyID
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      replies: emission.replyEmission.replyEmission.replies + 1,
                    };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID === action.data.replyID
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.replyEmission.signalBoost,
                        replies:
                          emission.replyEmission.replyEmission.signalBoost
                            .replies + 1,
                      },
                    };
                }
              }

              return emission;
            }),
          },
        },
      };
    case VIEW:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: state.profileInfo.emissions.items
              .map((emission) => {
                if (emission.emissionID === action.data)
                  emission = {
                    ...emission,
                    views: emission.views + 1,
                  };
                if (
                  emission.signalBoost &&
                  emission.signalBoost.emissionID === action.data
                )
                  emission = {
                    ...emission,
                    signalBoost: {
                      ...emission.signalBoost,
                      views: emission.signalBoost.views + 1,
                    },
                  };
                if (emission.replyEmission) {
                  if (emission.replyEmission.emissionID === action.data)
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      views: emission.replyEmission.views + 1,
                    };
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost.emissionID ===
                      action.data
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.signalBoost,
                        views: emission.replyEmission.signalBoost.views + 1,
                      },
                    };

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission.emissionID ===
                      action.data
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        views: emission.replyEmission.replyEmission.views + 1,
                      };
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === action.data
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        signalBoost: {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          views:
                            emission.replyEmission.replyEmission.signalBoost
                              .views + 1,
                        },
                      };
                  }
                }

                return emission;
              })
              .sort((a, b) => b.emissionID - a.emissionID),
          },
          likes: {
            ...state.profileInfo.likes,
            items: state.profileInfo.likes.items.map((emission) => {
              if (emission.emissionID === action.data)
                emission = {
                  ...emission,
                  views: emission.views + 1,
                };
              if (
                emission.signalBoost &&
                emission.signalBoost.emissionID === action.data
              )
                emission = {
                  ...emission,
                  signalBoost: {
                    ...emission.signalBoost,
                    views: emission.signalBoost.views + 1,
                  },
                };
              if (emission.replyEmission) {
                if (emission.replyEmission.emissionID === action.data)
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    views: emission.replyEmission.views + 1,
                  };
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.emissionID === action.data
                )
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    signalBoost: {
                      ...emission.replyEmission.signalBoost,
                      views: emission.replyEmission.signalBoost.views + 1,
                    },
                  };

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.emissionID ===
                    action.data
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      views: emission.replyEmission.replyEmission.views + 1,
                    };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID === action.data
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.replyEmission.signalBoost,
                        views:
                          emission.replyEmission.replyEmission.signalBoost
                            .views + 1,
                      },
                    };
                }
              }

              return emission;
            }),
          },
        },
      };
    case VIEWS:
      const { emissionIDs, eventID } = action.data;
      return {
        ...state,
        socketEvents: eventID
          ? [...state.socketEvents, eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: state.profileInfo.emissions.items
              .map((emission) => {
                if (emissionIDs.indexOf(emission.emissionID) > -1)
                  emission = {
                    ...emission,
                    views: emission.views + 1,
                  };
                if (
                  emission.signalBoost &&
                  emissionIDs.indexOf(emission.signalBoost.emissionID) > -1
                )
                  emission = {
                    ...emission,
                    signalBoost: {
                      ...emission.signalBoost,
                      views: emission.signalBoost.views + 1,
                    },
                  };
                if (emission.replyEmission) {
                  if (
                    emissionIDs.indexOf(emission.replyEmission.emissionID) > -1
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      views: emission.replyEmission.views + 1,
                    };
                  if (
                    emission.replyEmission.signalBoost &&
                    emissionIDs.indexOf(
                      emission.replyEmission.signalBoost.emissionID
                    ) > -1
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.signalBoost,
                        views: emission.replyEmission.signalBoost.views + 1,
                      },
                    };

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emissionIDs.indexOf(
                        emission.replyEmission.replyEmission.emissionID
                      ) > -1
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        views: emission.replyEmission.replyEmission.views + 1,
                      };
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emissionIDs.indexOf(
                        emission.replyEmission.replyEmission.signalBoost
                          .emissionID
                      ) > -1
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        signalBoost: {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          views:
                            emission.replyEmission.replyEmission.signalBoost
                              .views + 1,
                        },
                      };
                  }
                }

                return emission;
              })
              .sort((a, b) => b.emissionID - a.emissionID),
          },
          likes: {
            ...state.profileInfo.likes,
            items: state.profileInfo.likes.items.map((emission) => {
              if (emissionIDs.indexOf(emission.emissionID) > -1)
                emission = {
                  ...emission,
                  views: emission.views + 1,
                };
              if (
                emission.signalBoost &&
                emissionIDs.indexOf(emission.signalBoost.emissionID) > -1
              )
                emission = {
                  ...emission,
                  signalBoost: {
                    ...emission.signalBoost,
                    views: emission.signalBoost.views + 1,
                  },
                };
              if (emission.replyEmission) {
                if (emissionIDs.indexOf(emission.replyEmission.emissionID) > -1)
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    views: emission.replyEmission.views + 1,
                  };
                if (
                  emission.replyEmission.signalBoost &&
                  emissionIDs.indexOf(
                    emission.replyEmission.signalBoost.emissionID
                  ) > -1
                )
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    signalBoost: {
                      ...emission.replyEmission.signalBoost,
                      views: emission.replyEmission.signalBoost.views + 1,
                    },
                  };

                if (emission.replyEmission.replyEmission) {
                  if (
                    emissionIDs.indexOf(
                      emission.replyEmission.replyEmission.emissionID
                    ) > -1
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      views: emission.replyEmission.replyEmission.views + 1,
                    };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emissionIDs.indexOf(
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID
                    ) > -1
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.replyEmission.signalBoost,
                        views:
                          emission.replyEmission.replyEmission.signalBoost
                            .views + 1,
                      },
                    };
                }
              }

              return emission;
            }),
          },
        },
      };
    case NEW_EMISSION:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            items: [action.data, ...state.profileInfo.emissions.items],
            totalNoReply: action.data.replyID
              ? state.profileInfo.emissions.totalNoReply
              : state.profileInfo.emissions.totalNoReply + 1,
            totalReplies: action.data.replyID
              ? state.profileInfo.emissions.totalReplies + 1
              : state.profileInfo.emissions.totalReplies,
            totalMedia: action.data.files
              ? state.profileInfo.emissions.totalMedia + 1
              : state.profileInfo.emissions.totalMedia,
          },
        },
      };
    case NEW_LIKE:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          likes: {
            items: [action.data, ...state.profileInfo.likes.items],
          },
        },
      };
    case VOTE:
      return {
        ...state,
        socketEvents: action.data?.eventID
          ? [...state.socketEvents, action.data?.eventID]
          : state.socketEvents,
        profileInfo: {
          ...state.profileInfo,
          emissions: {
            ...state.profileInfo.emissions,
            items: state.profileInfo.emissions.items
              .map((emission) => {
                if (emission._id === action.data.emissionID)
                  emission = {
                    ...emission,
                    pollData: {
                      ...emission.pollData,
                      options: action.data.options,
                      voters: [
                        ...emission.pollData.voters,
                        action.data.voterDetails,
                      ],
                    },
                  };
                if (
                  emission.signalBoost &&
                  emission.signalBoost._id === action.data.emissionID
                )
                  emission = {
                    ...emission,
                    signalBoost: {
                      ...emission.signalBoost,
                      pollData: {
                        ...emission.signalBoost.pollData,
                        options: action.data.options,
                        voters: [
                          ...emission.signalBoost.pollData.voters,
                          action.data.voterDetails,
                        ],
                      },
                    },
                  };
                if (emission.replyEmission) {
                  if (emission.replyEmission._id === action.data.emissionID)
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      pollData: {
                        ...emission.replyEmission.pollData,
                        options: action.data.options,
                        voters: [
                          ...emission.replyEmission.pollData.voters,
                          action.data.voterDetails,
                        ],
                      },
                    };
                  if (
                    emission.replyEmission.signalBoost &&
                    emission.replyEmission.signalBoost._id ===
                      action.data.emissionID
                  )
                    emission.replyEmission = {
                      ...emission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.signalBoost,
                        pollData: {
                          ...emission.replyEmission.signalBoost.pollData,
                          options: action.data.options,
                          voters: [
                            ...emission.replyEmission.signalBoost.pollData
                              .voters,
                            action.data.voterDetails,
                          ],
                        },
                      },
                    };

                  if (emission.replyEmission.replyEmission) {
                    if (
                      emission.replyEmission.replyEmission._id ===
                      action.data.emissionID
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        pollData: {
                          ...emission.replyEmission.replyEmission.pollData,
                          options: action.data.options,
                          voters: [
                            ...emission.replyEmission.replyEmission.pollData
                              .voters,
                            action.data.voterDetails,
                          ],
                        },
                      };
                    if (
                      emission.replyEmission.replyEmission.signalBoost &&
                      emission.replyEmission.replyEmission.signalBoost
                        .emissionID === action.data.emissionID
                    )
                      emission.replyEmission.replyEmission = {
                        ...emission.replyEmission.replyEmission,
                        signalBoost: {
                          ...emission.replyEmission.replyEmission.signalBoost,
                          pollData: {
                            ...emission.replyEmission.replyEmission.signalBoost
                              .pollData.pollData,
                            options: action.data.options,
                            voters: [
                              ...emission.replyEmission.replyEmission
                                .signalBoost.pollData.voters,
                              action.data.voterDetails,
                            ],
                          },
                        },
                      };
                  }
                }
                return emission;
              })
              .sort((a, b) => b.emissionID - a.emissionID),
          },
          likes: {
            ...state.profileInfo.likes,
            items: state.profileInfo.likes.items.map((emission) => {
              if (emission._id === action.data.emissionID)
                emission = {
                  ...emission,
                  pollData: {
                    ...emission.pollData,
                    options: action.data.options,
                    voters: [
                      ...emission.pollData.voters,
                      action.data.voterDetails,
                    ],
                  },
                };
              if (
                emission.signalBoost &&
                emission.signalBoost._id === action.data.emissionID
              )
                emission = {
                  ...emission,
                  signalBoost: {
                    ...emission.signalBoost,
                    pollData: {
                      ...emission.signalBoost.pollData,
                      options: action.data.options,
                      voters: [
                        ...emission.signalBoost.pollData.voters,
                        action.data.voterDetails,
                      ],
                    },
                  },
                };
              if (emission.replyEmission) {
                if (emission.replyEmission._id === action.data.emissionID)
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    pollData: {
                      ...emission.replyEmission.pollData,
                      options: action.data.options,
                      voters: [
                        ...emission.replyEmission.pollData.voters,
                        action.data.voterDetails,
                      ],
                    },
                  };
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost._id ===
                    action.data.emissionID
                )
                  emission.replyEmission = {
                    ...emission.replyEmission,
                    signalBoost: {
                      ...emission.replyEmission.signalBoost,
                      pollData: {
                        ...emission.replyEmission.signalBoost.pollData,
                        options: action.data.options,
                        voters: [
                          ...emission.replyEmission.signalBoost.pollData.voters,
                          action.data.voterDetails,
                        ],
                      },
                    },
                  };

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission._id ===
                    action.data.emissionID
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      pollData: {
                        ...emission.replyEmission.replyEmission.pollData,
                        options: action.data.options,
                        voters: [
                          ...emission.replyEmission.replyEmission.pollData
                            .voters,
                          action.data.voterDetails,
                        ],
                      },
                    };
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID === action.data.emissionID
                  )
                    emission.replyEmission.replyEmission = {
                      ...emission.replyEmission.replyEmission,
                      signalBoost: {
                        ...emission.replyEmission.replyEmission.signalBoost,
                        pollData: {
                          ...emission.replyEmission.replyEmission.signalBoost
                            .pollData,
                          options: action.data.options,
                          voters: [
                            ...emission.replyEmission.replyEmission.signalBoost
                              .pollData.voters,
                            action.data.voterDetails,
                          ],
                        },
                      },
                    };
                }
              }
              return emission;
            }),
          },
        },
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profileInfo: {
          emissions: {
            items: [],
            totalNoReply: 0,
            totalReplies: 0,
            totalMedia: 0,
          },
          likes: {
            items: [],
            total: 0,
          },
        },
      };
    case SET_PROFILE:
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          ...action.data,
          loaded: true,
        },
        viewers: !state.userInfo.live
          ? action.data.user && action.data.user.live
            ? action.data.user.live.viewers
            : state.viewers
          : state.viewers,
      };
    case DASHBOARD_NAV:
      return {
        ...state,
        dashboardNav: !state.dashboardNav,
        dashboardItemSelected: action.data,
      };
    case SET_NSFW_MODAL:
      return {
        ...state,
        showNsfwModal: action.data,
      };
    case PURGE_USER:
      localStorage.removeItem("userID");
      localStorage.removeItem("chatKey");
      return {
        ...state,
        userInfo: {
          ...blankUser,
          userSettings: {
            ...blankUser.userSettings,
            theme: state.userInfo.userSettings.theme,
          },
        },
        profileInfo: h.purgeProfileInfo(state.profileInfo),
        dashboardItemSelected: "settings",
        verificationDetails: false,
        thing: false,
        cache: [],
      };
    case ROUTE:
      if (action.data.noHistory)
        return {
          ...state,
        };

      state.history.push({
        pathname: action.data.destination,
        state: {
          prevRoute: action.data.prevRoute,
          currRoute: action.data.destination,
        },
      });
      return {
        ...state,
        historyStack: [...state.historyStack, action.data.prevRoute],
        showNsfwModal: false,
      };
    case SELECT_DASHBOARD_ITEM:
      return {
        ...state,
        dashboardItemSelected: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
