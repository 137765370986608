import React from "react";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import { MDBBtn } from "mdb-react-ui-kit";

class Error extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  /**
   * Stop stream tracks, if any
   */
  componentDidMount() {
    if (this.props.stream.getTracks)
      this.props.stream.getTracks().forEach((track) => track.stop());
  }

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <i className="fas fa-exclamation-triangle fa-6x d-block mx-auto text-center mt-5 text-danger"></i>
        <h5 className="display-6 text-center mt-4">{this.props.error}</h5>
        <div className="d-flex justify-content-center mt-4">
          <MDBBtn color="primary" size="lg" onClick={this.props.reset}>
            <i className="fas fa-redo-alt me-2"></i>Try Again
          </MDBBtn>
        </div>
      </motion.div>
    );
  }
}

export default Error;
