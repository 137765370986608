import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import SampleEmission from "../emission/SampleEmission";
import axios from "axios";
import { connect } from "react-redux";

class RestoreModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the emission if in the processed of being restored
       */
      working: false,
    };
  }

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Update emission
   * Hide modal
   */
  submit = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () =>
          axios
            .get(
              `${process.env.REACT_APP_LAMBDA_SUPPORT_EMISSIONS}/restore/${this.props.emission.emissionID}`,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => {
                  this.props.updateEmission(res.data.emission, true);
                }
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              {this.props.emission ? (
                <MDBModalContent key={this.props.emission.emissionID}>
                  <MDBModalHeader>
                    <MDBModalTitle>Are you sure?</MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <h5 className="my-4 display-6 text-center">
                      You are about to restore{" "}
                      <span className="text-lowercase">
                        {env.EMISSION_NAME}
                      </span>{" "}
                      <span className="text-gb">
                        #{this.props.emission.emissionID}
                      </span>
                    </h5>
                    {this.props.emission.remove.reason ? (
                      <div className="bg-emphasis mb-4 p-2 text-break">
                        <h5>
                          Removal reason: {this.props.emission.remove.reason}
                        </h5>
                        <p>{this.props.emission.remove.details}</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <SampleEmission
                      emission={this.props.emission}
                      toggleModal={this.props.toggleShowModal}
                    />
                  </MDBModalBody>
                  <MDBModalFooter>
                    {this.state.working ? (
                      <MDBBtn disabled color="primary">
                        <Spinner className="me-2" size="sm" />
                        Restoring
                      </MDBBtn>
                    ) : (
                      <MDBBtn onClick={this.submit} color="primary">
                        <i className="far fa-check-circle me-2"></i>Confirm
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      color="dark"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, {})(RestoreModal);
