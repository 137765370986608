import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner";
import axios from "axios";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import { set_token } from "../../redux/actions";

class DeleteAccountModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a new avatar is in the process of being uploaded to and processed on the server
       */
      working: false,
      emailSent: false,
      password: "",
    };
  }

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Make request to server
   * Update user details with new avatar
   * Hide modal
   */
  submit = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () => {
          axios
            .post(
              process.env.REACT_APP_LAMBDA_AUTH + "/delete-account",
              {
                password: this.state.password,
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState((curr) => ({
                ...curr,
                emailSent: res.data.email,
                working: false,
              }));
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            );
        }
      );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            id="change-avatar-modal"
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Are you sure?</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  {this.state.emailSent ? (
                    <motion.div
                      transition={t.transition}
                      initial={t.fade_out}
                      animate={t.normalize}
                      exit={t.fade_out_scale_1}
                    >
                      <h5 className="text-center display-6 my-4">
                        Check your email
                      </h5>
                      <p className="text-center">
                        An email has been sent to {this.state.emailSent} with a
                        link to confirm the deletion of your account.
                      </p>
                    </motion.div>
                  ) : (
                    <MDBValidation onSubmit={this.submit} method="dialog">
                      <h5 className="text-center">
                        A link will be sent to your email in order to confirm
                        your decision. Once this link is clicked, your account
                        will be deleted.
                      </h5>
                      <MDBValidationItem
                        className="pb-4 mx-auto mt-4"
                        style={{ width: "400px", maxWidth: "100%" }}
                        invalid={true}
                        feedback={
                          this.state.password
                            ? ""
                            : "Please enter your password"
                        }
                      >
                        <MDBInput
                          className={this.state.password ? "" : "mb-0"}
                          disabled={this.state.working}
                          value={this.state.password}
                          type="password"
                          onChange={(e) =>
                            this.setState((curr) => ({
                              ...curr,
                              password: e.target.value,
                            }))
                          }
                          label="Enter Your Password"
                        />
                      </MDBValidationItem>
                    </MDBValidation>
                  )}
                </MDBModalBody>
                <MDBModalFooter>
                  {!this.state.emailSent && (
                    <MDBBtn
                      color="danger"
                      disabled={this.state.working || !this.state.password}
                      onClick={this.submit}
                    >
                      {this.state.working ? (
                        <>
                          <Spinner size="sm" className="me-2" />
                          Sending Email
                        </>
                      ) : (
                        <>
                          <i className="fas fa-check-circle me-2" />
                          Send Email
                        </>
                      )}
                    </MDBBtn>
                  )}

                  <MDBBtn
                    className="bg-gray"
                    color="dark"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_token })(DeleteAccountModal);
