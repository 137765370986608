import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
  MDBCheckbox,
  MDBSelect,
} from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import { v4 as uuid } from "uuid";
import { poll_schema, option_schema } from "../../utilities/validations";
import { motion } from "framer-motion";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import { connect } from "react-redux";

class PollModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * inputs: Array of text inputs: Question, all the options, and the number of votes allowed
       */
      inputs: [
        {
          id: "question",
          htmlID: "question",
          error: "",
          invalid: true,
          value: "",
        },
        {
          id: "option",
          htmlID: "option-" + uuid(),
          error: "",
          invalid: true,
          value: "",
        },
        {
          id: "option",
          htmlID: "option-" + uuid(),
          error: "",
          invalid: true,
          value: "",
        },
        {
          id: "votesAllowed",
          htmlID: "votesAllowed",
          error: "",
          invalid: false,
          value: "1",
        },
      ],
      noExpiry: true,
      expiryUnits: "day",
      expiryLength: {
        value: "2",
        error: "",
      },
      reset: false,
    };
  }

  // Run blank changeHandler
  componentDidMount() {
    this.changeHandler({
      target: {
        value: "",
      },
    });
  }

  /**
   *
   * If parent resets, reset to default and run blank changeHandler
   * Run blank changeHandler if poll form is shown
   */
  componentDidUpdate(prevProps) {
    if (prevProps.pollReset !== this.props.pollReset)
      this.setState(
        (curr) => ({
          ...curr,
          inputs: [
            {
              id: "question",
              htmlID: "question",
              error: "",
              invalid: true,
              value: "",
            },
            {
              id: "option",
              htmlID: "option-" + uuid(),
              error: "",
              invalid: true,
              value: "",
            },
            {
              id: "option",
              htmlID: "option-" + uuid(),
              error: "",
              invalid: true,
              value: "",
            },
            {
              id: "votesAllowed",
              htmlID: "votesAllowed",
              error: "",
              invalid: false,
              value: "1",
            },
          ],
          noExpiry: true,
          expiryUnits: "day",
          expiryLength: {
            value: "2",
            error: "",
          },
        }),
        () =>
          this.changeHandler({
            target: {
              value: "",
            },
          })
      );
  }

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.map((input) => {
          if (input.htmlID === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        data.expiryLength = Number(this.state.expiryLength.value);
        try {
          let error = false;
          try {
            poll_schema.validateSync(data, {
              abortEarly: false,
            });
          } catch (err) {
            error = err;
          }
          this.state.inputs
            .filter((i) => i.id === "option")
            .forEach((input) => {
              try {
                option_schema.validateSync(
                  {
                    option: input.value,
                  },
                  {
                    abortEarly: false,
                  }
                );
              } catch (err) {
                err.inner = err.inner.map((i) => ({
                  ...i,
                  path: input.htmlID,
                }));
                if (error) error.inner = [...error.inner, ...err.inner];
                else error = err;
              }
            });
          if (error) throw error;
          this.setState(
            (curr) => ({
              ...curr,
              inputs: this.state.inputs.map((input) => {
                document.getElementById(input.htmlID).setCustomValidity("");
                return {
                  ...input,
                  invalid: false,
                  error: "",
                };
              }),
            }),
            () => {
              document.getElementById("expiryLength").setCustomValidity("");
            }
          );
        } catch (err) {
          let errorsAdded = [];
          const expiryError = err.inner.find(
            (error) => error.path === "expiryLength"
          );
          this.setState(
            (curr) => ({
              ...curr,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.htmlID) &&
                  errorsAdded.indexOf(input.htmlID) === -1
                ) {
                  errorsAdded.push(input.htmlID);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find(
                      (error) => error.path === input.htmlID
                    ).message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
              expiryLength: {
                ...curr.expiryLength,
                error: expiryError ? expiryError.message : "",
              },
            }),
            () => {
              this.state.inputs.forEach((input) => {
                document
                  .getElementById(input.htmlID)
                  .setCustomValidity(input.error);
              });
              document
                .getElementById("expiryLength")
                .setCustomValidity(this.state.expiryLength.error);
            }
          );
        }
      }
    );

  /**
   * Submit only if there isn't already a submission being sent
   * Validate inputs
   * Set poll in parent
   */
  submit = () => {
    document.getElementById("poll_form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) => {
      document.getElementById(input.htmlID).setCustomValidity(input.error);
    });
    if (!invalidInputs.length) {
      const options = this.state.inputs
        .filter((i) => i.id === "option")
        .map((i) => i.value);
      const data = {
        question: this.state.inputs.find((i) => i.id === "question").value,
        votesAllowed: Number(
          this.state.inputs.find((i) => i.id === "votesAllowed").value
        ),
        options: options,
      };
      if (!this.state.noExpiry) {
        data.expirationInfo = {
          expiryLength: Number(this.state.expiryLength.value),
          expiryUnits: this.state.expiryUnits,
        };
      }
      try {
        if (
          !options.length ||
          options.length < 2 ||
          options.length > 20 ||
          options.length < 2
        )
          throw "Invalid number of options";
        this.props.setPoll(data);
        document.getElementById("poll_form").classList.remove("was-validated");
      } catch (err) {
        console.log(err);
        if (!options.length || options.length < 2)
          alert("Please enter at least two options");
        else if (options.length > 20) alert("Too many vote options");
        else if (options.length < 2)
          alert("Please enter at least 2 unique vote options");
        else alert("An error occurred. Please try again later");
      }
    }
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks Add Option
   * Max of 20 poll options
   *
   * Adds a new option input into state
   *
   */
  addOption = (e) => {
    e.preventDefault();
    if (this.state.inputs.filter((i) => i.id === "option").length < 20)
      this.setState(
        (curr) => ({
          ...curr,
          inputs: [
            this.state.inputs.find((input) => input.id === "question"),
            ...curr.inputs.filter((input) => input.id === "option"),
            {
              id: "option",
              htmlID: "option-" + uuid(),
              error: "",
              invalid: true,
              value: "",
            },
            this.state.inputs.find((input) => input.id === "votesAllowed"),
          ],
        }),
        () =>
          this.changeHandler({
            target: {
              value: "",
            },
          })
      );
  };

  /**
   *
   * @param {String} id - HTML id of the vote option
   * @param {Click Event} e
   *
   * Triggered when the user clicks the trash can next to any of the poll options
   * Removes the poll option
   */
  removeOption = (id, e) => {
    e.preventDefault();
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.filter((i) => i.htmlID !== id),
      }),
      () => {
        if (
          Number(this.state.inputs.find((i) => i.id === "votesAllowed").value) >
          this.state.inputs.filter((i) => i.id === "option").length
        )
          this.changeHandler({
            target: {
              name: "votesAllowed",
              value: this.state.inputs.filter((i) => i.id === "option").length,
            },
          });
      }
    );
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.htmlID === e.target.id);
      if (input) {
        let nextField = this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.htmlID);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  /**
   *
   * @param {Key Event} e
   *
   * Handles change in the Votes Allowed field
   *
   * If input is
   */
  votesAllowedHandler = (e) => {
    if (!h.isNumeric(e.target.value))
      this.changeHandler({
        target: {
          name: "votesAllowed",
          value: "1",
        },
      });
    else if (
      Number(e.target.value) >
      this.state.inputs.filter((i) => i.id === "option").length
    )
      this.changeHandler({
        target: {
          name: "votesAllowed",
          value: this.state.inputs.filter((i) => i.id === "option").length,
        },
      });
    else
      this.changeHandler({
        target: {
          name: "votesAllowed",
          value: e.target.value,
        },
      });
  };

  expiryLengthChange = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        expiryLength: {
          ...curr.expiryLength,
          value:
            h.isNumeric(e.target.value) && Number(e.target.value) > 0
              ? String(e.target.value)
              : "1",
        },
      }),
      () =>
        this.changeHandler({
          target: { value: "" },
        })
    );

  toggleExpiry = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        noExpiry: e.target.checked,
      }),
      () => {
        if (this.state.noExpiry) {
          if (
            !h.isNumeric(
              this.state.expiryLength.value &&
                Number(this.state.expiryLength.value) > 0
            )
          )
            this.expiryLengthChange({
              target: {
                value: "1",
              },
            });
        } else
          setTimeout(
            () =>
              this.setState((curr) => ({
                ...curr,
                reset: !this.state.reset,
              })),
            200
          );
      }
    );

  expiryUnitsChange = (e) =>
    this.setState((curr) => ({
      ...curr,
      expiryUnits: e.value,
    }));

  render() {
    if (typeof window === "undefined" || !window.navigator) return <></>;
    return (
      <MDBModal
        open={this.props.modalShown}
        staticBackdrop
        onClosePrevented={this.props.toggleShowModal}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            this.props.screenDimensions.width >
            this.props.screenDimensions.modalBreak
              ? "xl"
              : "fullscreen"
          }
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Create Poll</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={this.props.toggleShowModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody key={this.props.pollReset + "-poll-form"}>
              <MDBValidation
                name="poll_form"
                method="dialog"
                id="poll_form"
                onSubmit={this.submit}
              >
                <MDBValidationItem
                  className="pb-4"
                  feedback={
                    this.state.inputs.find((input) => input.id === "question")
                      .error
                  }
                  invalid
                >
                  <MDBInput
                    name="question"
                    onChange={this.changeHandler}
                    id="question"
                    label="Question"
                    size="lg"
                    className={
                      !this.state.inputs.find(
                        (input) => input.id === "question"
                      ).invalid
                        ? "mb-0"
                        : 0
                    }
                    onKeyPress={this.pressEnter}
                    value={
                      this.state.inputs.find((i) => i.id === "question").value
                    }
                    onKeyDown={this.pressTab}
                  />
                </MDBValidationItem>
                {this.state.inputs
                  .filter((i) => i.id === "option")
                  .map((i, index) => (
                    <motion.div
                      className="d-flex justify-content-between align-items-start"
                      transition={t.transition}
                      initial={t.fade_out_minimize}
                      animate={t.normalize}
                      exit={t.fade_out_minimize}
                      key={i.htmlID}
                    >
                      <MDBValidationItem
                        className={`pb-4 w-${
                          this.props.screenDimensions.width >= 992
                            ? "75"
                            : "66 pe-1"
                        }`}
                        feedback={
                          this.state.inputs.find(
                            (input) => input.htmlID === i.htmlID
                          ).error
                        }
                        invalid
                      >
                        <MDBInput
                          name={i.htmlID}
                          onChange={this.changeHandler}
                          id={i.htmlID}
                          label="Vote Option"
                          size="lg"
                          className={
                            !this.state.inputs.find(
                              (input) => input.htmlID === i.htmlID
                            ).invalid
                              ? "mb-0"
                              : 0
                          }
                          onKeyPress={this.pressEnter}
                          value={
                            this.state.inputs.find(
                              (input) => input.htmlID === i.htmlID
                            ).value
                          }
                          onKeyDown={this.pressTab}
                        />
                      </MDBValidationItem>
                      {!index ? (
                        <MDBBtn
                          disabled={
                            this.state.inputs.filter((i) => i.id === "option")
                              .length >= 20
                          }
                          onClick={this.addOption}
                          color="primary"
                          type="button"
                          size={
                            this.props.screenDimensions.width <= 356
                              ? "sm"
                              : undefined
                          }
                          className={
                            this.props.screenDimensions.width >= 992 &&
                            this.props.screenDimensions.width <= 500
                              ? "d-block mx-auto"
                              : ""
                          }
                        >
                          {this.state.inputs.filter((i) => i.id === "option")
                            .length >= 20 ? (
                            "Options Maxed (20)"
                          ) : (
                            <>
                              <i className="fas fa-plus me-2"></i>
                              <span className="d-none-lg-lower">Add </span>
                              Option
                            </>
                          )}
                        </MDBBtn>
                      ) : (
                        <></>
                      )}
                      {index > 1 ? (
                        <MDBBtn
                          type="button"
                          onClick={(e) => this.removeOption(i.htmlID, e)}
                          className="text-danger"
                          color="link"
                        >
                          <i className="far fa-trash-alt fa-lg"></i>
                        </MDBBtn>
                      ) : (
                        <></>
                      )}
                    </motion.div>
                  ))}
                <div className="py-4 d-flex align-items-start">
                  <MDBValidationItem
                    style={{ width: "10rem" }}
                    feedback={
                      this.state.inputs.find(
                        (input) => input.id === "votesAllowed"
                      ).error
                    }
                    invalid
                  >
                    <MDBInput
                      name="votesAllowed"
                      onChange={this.changeHandler}
                      id="votesAllowed"
                      label="Votes Allowed"
                      size="lg"
                      type="number"
                      min="1"
                      max={String(
                        Math.round(
                          this.state.inputs.filter((i) => i.id === "option")
                            .length
                        )
                      )}
                      className={
                        !this.state.inputs.find(
                          (input) => input.id === "votesAllowed"
                        ).invalid
                          ? "mb-0"
                          : 0
                      }
                      onKeyPress={this.pressEnter}
                      value={
                        this.state.inputs.find((i) => i.id === "votesAllowed")
                          .value
                      }
                      onKeyDown={this.pressTab}
                      onKeyUp={this.votesAllowedHandler}
                    />
                  </MDBValidationItem>
                  <div className="ms-4">
                    <MDBValidationItem invalid>
                      <MDBCheckbox
                        name="noExpiry"
                        checked={this.state.noExpiry}
                        id="noExpiry"
                        label="No Expiration"
                        onChange={this.toggleExpiry}
                      />
                    </MDBValidationItem>
                    <Collapse in={!this.state.noExpiry}>
                      <div className="d-flex align-items-start h-max-content pt-4">
                        <MDBValidationItem
                          invalid
                          error={this.state.expiryLength.error}
                        >
                          <MDBInput
                            name="expiryLength"
                            onChange={this.expiryLengthChange}
                            id="expiryLength"
                            label="Expires In"
                            type="number"
                            min="1"
                            className={
                              !this.state.expiryLength.error ? "mb-0" : 0
                            }
                            onKeyPress={this.pressEnter}
                            value={this.state.expiryLength.value}
                          />
                        </MDBValidationItem>
                        <MDBValidationItem invalid error="">
                          <MDBSelect
                            onValueChange={this.expiryUnitsChange}
                            className="ms-2"
                            data={[
                              {
                                text: "Hours",
                                value: "hour",
                                defaultSelected:
                                  this.state.expiryUnits === "hour",
                              },
                              {
                                text: "Days",
                                value: "day",
                                defaultSelected:
                                  this.state.expiryUnits === "day",
                              },
                              {
                                text: "Weeks",
                                value: "week",
                                defaultSelected:
                                  this.state.expiryUnits === "week",
                              },
                              {
                                text: "Months",
                                value: "month",
                                defaultSelected:
                                  this.state.expiryUnits === "month",
                              },
                              {
                                text: "Years",
                                value: "year",
                                defaultSelected:
                                  this.state.expiryUnits === "year",
                              },
                            ]}
                          />
                        </MDBValidationItem>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </MDBValidation>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="success" onClick={this.submit}>
                <i className="fas fa-reply me-2"></i>
                Insert
              </MDBBtn>
              <MDBBtn
                className="bg-gray"
                color="dark"
                onClick={this.props.toggleShowModal}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, {})(PollModal);
