import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EmissionPage from "../components/emission/EmissionPage";
import { MDBContainer } from "mdb-react-ui-kit";
import { route, set_token, set_cache } from "../redux/actions";

class Emission extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    const currentHistory = Number(
      String(
        String(props.history?.location?.state?.currRoute).split("/e/")[1]
      ).split("#")[0]
    );
    this.emissionID = Number(props.match.params.emissionID.split("#")[0]);
    if (currentHistory === this.emissionID) {
      cachedData = props.cache.find(
        (c) => c.page === "emission" && c.emissionID === this.emissionID
      );
      this.initialScrollTop = cachedData?.scrollTop;
    }
    this.state = cachedData
      ? {
          ...cachedData.state,
          initialEmissionPageState: cachedData.emissionPageState,
        }
      : {
          /**
           * loaded: Boolean - Whether the initial emission data has been loaded
           * loadingMore: Array - List of emissions with replies that are currently loading more replies
           */
          loaded: false,
          loadingMore: [],
        };
  }

  componentDidMount() {
    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
  }

  componentWillUnmount() {
    this.props.set_cache({
      page: "emission",
      emissionID: this.emissionID,
      state: this.state,
      emissionPageState: this.getEmissionPageState(),
      scrollTop: document.getElementById("root")?.scrollTop,
    });
  }

  /**
   * Loads the initial data
   * Sets the emissions into state
   * If there are fewer than 20 results, the end has been reached
   */
  load = () => {
    return new Promise((resolve) =>
      axios
        .get(
          process.env.REACT_APP_LAMBDA_API_EMISSIONS +
            "/by-id/" +
            this.emissionID,
          {
            headers: {
              Authorization: this.props.token,
            },
          }
        )
        .then((res) => {
          this.props.set_token(res.data.token);
          setTimeout(() => {
            const emission = res.data.emissions.find(
              (e) => e.emissionID === this.emissionID
            );
            const element = document.getElementById(
              `emission-${this.emissionID}`
            );
            if (element && emission.replyEmission) element.scrollIntoView();
          }, 333);
          resolve({
            emissions: res.data.emissions,
          });
        })
        .catch((err) => {
          console.log("load emission err", err);
          if (err.response?.status === 404) {
            alert("Not found");
            this.props.route("/");
          }
          setTimeout(async () => {
            const data = await this.load();
            resolve(data);
          }, 1000);
        })
    );
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks the View More button in a reply thread
   * Loads more replies from the thread
   */
  seeMore = (emissionID, callback) => {
    if (this.state.loadingMore.indexOf(emissionID) === -1)
      this.setState(
        (curr) => ({
          ...curr,
          loadingMore: [...curr.loadingMore, emissionID],
        }),
        () =>
          axios
            .get(
              process.env.REACT_APP_LAMBDA_API_EMISSIONS +
                "/more-replies/" +
                emissionID,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  loadingMore: this.state.loadingMore.filter(
                    (m) => m !== emissionID
                  ),
                }),
                () =>
                  callback({
                    emissions: res.data.emissions,
                  })
              );
            })
            .catch((err) => {
              console.log("see more error", err);
              setTimeout(this.seeMore, 1000);
            })
      );
  };

  render() {
    return (
      <div className="page-container">
        <MDBContainer>
          <EmissionPage
            load={this.load}
            seeMore={this.seeMore}
            flavor="emission"
            loaded={this.state.loaded}
            loadingMore={this.state.loadingMore}
            emissionID={this.emissionID}
            setGetEmissionPageState={(f) => (this.getEmissionPageState = f)}
            initialEmissionPageState={this.state.initialEmissionPageState}
          />
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route, set_token, set_cache })(
  withRouter(Emission)
);
