import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Feed from "./pages/Feed";
import React, { useEffect, useState } from "react";
import {
  set_history,
  toggle_go_live_modal,
  set_go_live_modal,
  set_captcha_ready,
  set_token,
  set_user,
  set_screen_dimensions,
  remove_notification,
  set_poll_modal,
  set_pasted_files,
} from "./redux/actions";
import { connect } from "react-redux";
import Delete from "./pages/Delete";
import Number from "./pages/Number";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Resets from "./pages/Resets";
import Cancel from "./pages/Cancel";
import Message from "./pages/Message";
import CreateAccount from "./pages/CreateAccount";
import SideNav from "./components/SideNav";
import Notifications from "./pages/Notifications";
import Messages from "./pages/Messages";
import UserBar from "./components/UserBar";
import GoLiveModal from "./components/goLiveModal/GoLiveModal";
import Emission from "./pages/Emission";
import Reports from "./pages/Reports";
import ModLogs from "./pages/ModLogs";
import Tag from "./pages/Tag";
import Search from "./pages/Search";
import Validate from "./pages/Validate";
import Info from "./pages/Info";
import Contact from "./pages/Contact";
import ValidateEmail from "./pages/ValidateEmail";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import GlobalEmissionInput from "./components/globalEmissionInput/GlobalEmissionInput";
import PollVotersModal from "./components/PollVotersModal";
import Profile from "./pages/Profile";
import axios from "axios";
import DeleteCancel from "./pages/DeleteCancel";
import VirgilAndChad from "./pages/VirgilAndChad";
import ContactMessages from "./pages/ContactMessages";
import env from "./env";
import { MDBStack, MDBToast } from "mdb-react-ui-kit";
import { motion } from "framer-motion";
import t from "./utilities/transitions";
import Test from "./pages/Test";

const App = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  let location = useLocation();
  let history = useHistory();
  let theme =
    props.userInfo.userSettings.theme === "default"
      ? createTheme({
          palette: {
            primary: {
              main: env.COLOR_PRIMARY,
            },
            secondary: {
              main: env.COLOR_SECONDARY,
            },
            info: {
              main: env.COLOR_INFO,
            },
            warning: {
              main: env.COLOR_WARNING,
            },
            danger: {
              main: env.COLOR_DANGER,
            },
            success: {
              main: env.COLOR_SUCCESS,
            },
            light: {
              main: env.COLOR_LIGHT,
            },
            dark: {
              main: env.COLOR_DARK,
            },
          },
        })
      : createTheme({
          palette: {
            primary: {
              main: env.COLOR_PRIMARY_DARK,
            },
            secondary: {
              main: env.COLOR_SECONDARY_DARK,
            },
            info: {
              main: env.COLOR_INFO_DARK,
            },
            warning: {
              main: env.COLOR_WARNING_DARK,
            },
            danger: {
              main: env.COLOR_DANGER_DARK,
            },
            success: {
              main: env.COLOR_SUCCESS_DARK,
            },
            light: {
              main: env.COLOR_LIGHT_DARK,
            },
            dark: {
              main: env.COLOR_DARK_DARK,
            },
          },
        });

  const pasteFile = (e) => props.set_pasted_files(e.clipboardData.files);

  useEffect(() => {
    document
      .getElementById("root")
      .addEventListener("scroll", (e) => setScrollPosition(e.target.scrollTop));
    document.addEventListener("onautocomplete", (e) => {
      if (e.target.classList.contains("form-control"))
        e.target.classList.add("active");
    });
    window.addEventListener("resize", () => {
      props.set_screen_dimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
    document.onpaste = pasteFile;
  }, []);

  useEffect(() => {
    if (
      process.env.REACT_APP_DEV !== "true" &&
      window.grecaptcha?.enterprise?.ready
    )
      window.grecaptcha.enterprise.ready(props.set_captcha_ready);
  }, [String(window.grecaptcha?.enterprise?.ready)]);

  useEffect(() => {
    const start = new Date().getTime();
    const splash = () =>
      axios
        .get(process.env.REACT_APP_LAMBDA_AUTH + "/splash", {
          headers: {
            Authorization: props.token,
          },
        })
        .then((res) => {
          props.set_token(res.data.token);
          props.set_history(
            location,
            history,
            res.data.userInfo,
            res.data.verificationDetails,
            new Date().getTime() - start,
            res.data.virgil,
            res.data.chad
          ); // Set history into redux store as soon as app is rendered on the client
        })
        .catch((err) => {
          console.log("splash error", err);
          setTimeout(splash, 1000);
        });

    const getToken = () =>
      axios
        .get("/no-token")
        .then((res) => {
          props.set_token(res.data.metadata.token);
          props.set_history(
            location,
            history,
            res.data.userInfo,
            res.data.verificationDetails,
            new Date().getTime() - start
          );
        })
        .catch((err) => {
          console.log("Get token error", err);
          setTimeout(getToken, 1000);
        });

    if (!props.token) getToken();
    else if (!window.location.pathname.includes("/delete/")) splash();
    else
      props.set_history(
        location,
        history,
        null,
        null,
        new Date().getTime() - start
      );
  }, [props.userInfo._id]);

  useEffect(() => {
    theme =
      props.userInfo.userSettings.theme === "default"
        ? createTheme({
            palette: {
              primary: {
                main: env.COLOR_PRIMARY,
              },
              secondary: {
                main: env.COLOR_SECONDARY,
              },
              info: {
                main: env.COLOR_INFO,
              },
              warning: {
                main: env.COLOR_WARNING,
              },
              danger: {
                main: env.COLOR_DANGER,
              },
              success: {
                main: env.COLOR_SUCCESS,
              },
              light: {
                main: env.COLOR_LIGHT,
              },
              dark: {
                main: env.COLOR_DARK,
              },
            },
          })
        : createTheme({
            palette: {
              primary: {
                main: env.COLOR_PRIMARY_DARK,
              },
              secondary: {
                main: env.COLOR_SECONDARY_DARK,
              },
              info: {
                main: env.COLOR_INFO_DARK,
              },
              warning: {
                main: env.COLOR_WARNING_DARK,
              },
              danger: {
                main: env.COLOR_DANGER_DARK,
              },
              success: {
                main: env.COLOR_SUCCESS_DARK,
              },
              light: {
                main: env.COLOR_LIGHT_DARK,
              },
              dark: {
                main: env.COLOR_DARK_DARK,
              },
            },
          });
  }, [props.userInfo.userSettings.theme]);

  /**
   * Currently using React Router 5
   * React Router 6 recently released; stupidly not made backwards-compatible and removes numerous features with no replacement
   */

  if (!props.token) return <></>;

  return (
    /**
     * AnimatePresence tells the child components to use framer motion page transitions
     * exitBeforeEnter flag makes sure the exit transition completes before the entrance on the next page initiates
     * The "key" in the Switch is necessary for animations to work properly. Needs to be unique on every page change, hence setting it to location.pathname
     */
    <ThemeProvider theme={theme}>
      {props.token && (
        <div className="h-100 position-relative">
          <div className="h-100 d-flex flex-column scroll">
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/">
                  <Feed />
                </Route>
                {/* <Route exact path="/test">
                  <Test location={location} />
                </Route> */}
                <Route exact path="/n/:number">
                  <Number history={history} location={location} />
                </Route>
                <Route exact path="/number/:number">
                  <Number history={history} location={location} />
                </Route>
                <Route exact path="/search">
                  <Search location={location} />
                </Route>
                <Route exact path="/info">
                  <Info />
                </Route>
                <Route exact path="/logs">
                  <ModLogs history={history} location={location} />
                </Route>
                <Route exact path="/login">
                  <Login history={history} location={location} />
                </Route>
                <Route exact path="/set-password/:id">
                  <Resets />
                </Route>
                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/tag/:tag">
                  <Tag />
                </Route>
                <Route exact path="/delete/:id">
                  <Delete />
                </Route>
                <Route exact path="/delete-cancel/:id">
                  <DeleteCancel />
                </Route>
                <Route exact path="/cancel/:id">
                  <Cancel />
                </Route>
                <Route exact path="/check-email">
                  <Message message="An email has been sent with a link to reset your password." />
                </Route>
                <Route exact path="/awaiting-approval-email">
                  <Message
                    message={`Your account is awaiting approval. An email has been sent from ${process.env.REACT_APP_VERIFICATION_EMAIL} to verify your email address. Please click the link in this email to verify your account.`}
                  />
                </Route>
                <Route exact path="/awaiting-approval">
                  <Message
                    message={`Your account is awaiting approval. Please check back later.`}
                  />
                </Route>
                <Route exact path="/received">
                  <Message
                    message={`Your message has been recieved. We will get back to you shortly.`}
                  />
                </Route>
                <Route exact path="/validate-email">
                  <ValidateEmail history={history} location={location} />
                </Route>
                <Route exact path="/create-account">
                  <CreateAccount history={history} location={location} />
                </Route>
                <Route exact path="/messages">
                  <Messages history={history} location={location} />
                </Route>
                <Route exact path="/contact">
                  <Contact />
                </Route>
                <Route exact path="/contact-messages">
                  <ContactMessages />
                </Route>
                <Route exact path="/reports">
                  <Reports history={history} location={location} />
                </Route>
                <Route exact path="/notifications">
                  <Notifications history={history} location={location} />
                </Route>
                <Route exact path="/virgil-chad">
                  <VirgilAndChad history={history} location={location} />
                </Route>
                <Route exact path="/not-found">
                  <Message message="Not Found" />
                </Route>
                <Route exact path="/e/:emissionID">
                  <Emission />
                </Route>
                <Route exact path="/verify/:id">
                  <Validate />
                </Route>
                <Route exact path="/null">
                  <Redirect to="/" />
                </Route>
                <Route exact path="/:profile">
                  <Profile />
                </Route>
                <Route path="*">
                  <Redirect to="/not-found" />
                </Route>
              </Switch>
            </AnimatePresence>
          </div>
          {typeof window !== "undefined" &&
            ![
              "/login",
              "/create-account",
              "/forgot-password",
              "/validate-email",
              "/received",
              "/awaiting-approval",
              "/awaiting-approval-email",
              "/messages",
              "/virgil-chad",
            ].includes(window.location.pathname) && (
              <GlobalEmissionInput scrollPosition={scrollPosition} />
            )}

          <SideNav loggedIn={props.loggedIn} />
          <UserBar key={props.userInfo.username} />
          {typeof window !== "undefined" && (
            <GoLiveModal
              modalShown={props.goLiveModalShown}
              setShowModal={props.set_go_live_modal}
              toggleShowModal={props.toggle_go_live_modal}
            />
          )}
          <PollVotersModal
            toggleShowModal={() =>
              props.set_poll_modal({
                shown: false,
              })
            }
          />
          <div className="position-relative">
            <MDBStack
              position="bottom-left"
              className="position-fixed page-container"
            >
              {props.notifications.map((notification) => {
                return (
                  <motion.div
                    transition={t.transition}
                    initial={t.fade_out}
                    animate={t.normalize}
                    exit={t.fade_out_scale_1}
                    key={notification.id}
                  >
                    <MDBToast
                      color={
                        props.userInfo.userSettings.theme === "default"
                          ? "light"
                          : "dark"
                      }
                      id={`notification-${notification.id}`}
                      defaultOpen={true}
                      autohide={true}
                      initialAnimation={false}
                      delay={2000}
                      appendToBody={false}
                      headerContent={
                        <div className="me-auto">{notification.icon}</div>
                      }
                      bodyContent={<h5>{notification.text}</h5>}
                      onClosed={() => {
                        console.log("closed");
                        props.remove_notification(notification.id);
                      }}
                      className={`shadow-5 ${
                        props.userInfo.userSettings.theme === "default"
                          ? "bg-blusteel-light"
                          : "bg-dark"
                      }`}
                    />
                  </motion.div>
                );
              })}
            </MDBStack>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_history,
  set_token,
  toggle_go_live_modal,
  set_go_live_modal,
  set_captcha_ready,
  set_user,
  set_screen_dimensions,
  remove_notification,
  set_poll_modal,
  set_pasted_files,
})(App);
