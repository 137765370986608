import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import axios from "axios";
import { contact_form_schema } from "../utilities/validations";
import { route, set_token } from "../redux/actions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBTextArea,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import Spinner from "../components/Spinner";

/**
 * This is the Contact page
 * Users can submit site messages here
 */

const fields = [
  {
    id: "name",
    text: "Name",
    type: "input",
    col: "4",
  },
  {
    id: "email",
    text: "Email",
    type: "input",
    col: "4",
  },
  {
    id: "subject",
    text: "Subject",
    type: "input",
    col: "4",
  },
  {
    id: "message",
    text: "Message",
    type: "textarea",
    col: "12",
  },
];

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean  whether the form is in the process of being submitted
       * inputs: Array - The input data (values, errors, etc)
       */
      working: false,
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: true,
        value: "",
      })),
    };
  }

  /**
   * Run blank change handler to get initial validations
   */
  componentDidMount() {
    this.changeHandler({
      target: {
        name: "",
      },
    });
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          contact_form_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState((curr) => ({
            ...curr,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          }));
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            (curr) => ({
              ...curr,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            }),
            () =>
              this.state.inputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              )
          );
        }
      }
    );

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * If successful, route user to "Message Received page"
   * If unsuccessful, alert user that an error has occurred
   */
  submit = () => {
    document.getElementById("form").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value])
          );
          try {
            contact_form_schema.validateSync(data, {
              abortEarly: false,
            });
            const captchaKey = await this.getRecaptcha();
            const fd = {};
            for (const key in data) {
              fd[key] = data[key];
            }
            fd["captchaKey"] = captchaKey;
            axios
              .post(
                process.env.REACT_APP_LAMBDA_CONTACT + "/contact-form",
                fd,
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                if (res.data.error)
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                    }),
                    () => alert(res.data.error)
                  );
                else this.props.route("/received");
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField =
          this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  render() {
    return (
      <motion.div
        className="py-4 page-container"
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBContainer>
          <div className="mx-auto max-w-90 mt-5">
            <h1 className="display-4 text-center">Contact</h1>
            <hr></hr>
            <MDBValidation
              id="form"
              className="row mt-4"
              method="dialog"
              name="form"
            >
              {fields.map((i) => (
                <MDBValidationItem
                  key={i.id}
                  className={`pb-4 z-index-50-child col-12 col-md-${i.col}`}
                  feedback={
                    this.state.inputs.find((input) => input.id === i.id).error
                  }
                  invalid={true}
                >
                  {i.type === "input" ? (
                    <MDBInput
                      name={i.id}
                      onChange={this.changeHandler}
                      id={i.id}
                      label={i.text}
                      size="lg"
                      className={`${
                        !this.state.inputs.find((input) => input.id === i.id)
                          .invalid
                          ? "mb-0"
                          : 0
                      }`}
                      onKeyPress={this.pressEnter}
                      onKeyDown={this.pressTab}
                    />
                  ) : (
                    <MDBTextArea
                      name={i.id}
                      onChange={this.changeHandler}
                      id={i.id}
                      label={i.text}
                      size="lg"
                      className={`${
                        !this.state.inputs.find((input) => input.id === i.id)
                          .invalid
                          ? "mb-0"
                          : 0
                      } z-index-50`}
                      style={{ minHeight: "10rem" }}
                      onKeyDown={this.pressTab}
                    />
                  )}
                </MDBValidationItem>
              ))}
            </MDBValidation>
            <div className="row">
              <div className="col-12">
                {this.state.working ? (
                  <MDBBtn
                    className="contact-form-buttons z-index-50"
                    color="primary"
                    disabled
                  >
                    <Spinner size="sm" className="me-2" />
                    Sending
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    onClick={this.submit}
                    color="primary"
                    type="submit"
                    className="contact-form-buttons z-index-50"
                  >
                    <i className="fas fa-paper-plane me-2"></i>Send
                  </MDBBtn>
                )}
              </div>
            </div>
          </div>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_token })(Contact);
