import env from "../env";
import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import axios from "axios";
import { login_schema } from "../utilities/validations";
import {
  set_user,
  route,
  clear_temp_action,
  set_verification_details,
  set_token,
  set_redirect_url,
} from "../redux/actions";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBContainer,
  MDBBtn,
} from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import Spinner from "../components/Spinner";
import Encrypter from "../utilities/Encrypter";

class Login extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * working: Boolean indicating whether the user is in the process of logging in
       * exit: framer-motion exit transition
       * inputs: Array - The input data (values, errors, etc)
       */
      working: false,
      exit: this.getExit(props),
      inputs: [
        {
          id: "username",
          error: "",
          invalid: true,
          value: "",
        },
        {
          id: "password",
          error: "",
          invalid: true,
          value: "",
        },
      ],
    };
  }

  /**
   * If user is already logged in, route to the user's profile page
   * Set exit transition to default value
   * Clear temporary action, if any
   * Run blank change handler
   */
  componentDidMount() {
    if (this.props.userInfo.username) {
      this.props.history.push(`/${this.props.userInfo.username}`);
    } else if (this.props.verificationDetails)
      this.props.history.push("/validate-email");
    this.setState(
      (curr) => ({
        ...curr,
        exit: t.fade_out_scale_1,
      }),
      () => {
        this.props.clear_temp_action();
        this.changeHandler({
          target: {
            name: "",
          },
        });
      }
    );
  }

  /**
   * When logged in, route to user profile
   * After login attempt and sent verification details, navigate to page asking to validate email
   */
  componentDidUpdate(prevProps) {
    h.floatLabels();
    if (
      this.props.userInfo.username &&
      prevProps.userInfo.username !== this.props.userInfo.username
    ) {
      const redirectUrl = this.props.redirectUrl;
      if (redirectUrl) {
        this.props.set_redirect_url(false);
        this.props.route(redirectUrl);
      } else this.props.route(`/${this.props.userInfo.username}`);
    }

    if (!prevProps.verificationDetails && this.props.verificationDetails)
      this.props.route("/validate-email");
  }

  /**
   * If user is coming from the Forgot Password page, fade in from the left
   * Otherwise, fade in
   */
  getExit = (props) => {
    const h = props.historyStack;
    if (h[h.length - 1] === "/forgot-password") return t.fade_out_left;
    else return t.fade_out;
  };

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () =>
    new Promise(async (resolve, reject) => {
      if (String(process.env.REACT_APP_DEV) === "true")
        return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
      if (this.props.captchaReady)
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
          .then(resolve)
          .catch((err) => {
            console.log(err);
            alert("Human verification failed. Refresh the page and try again.");
            reject();
          });
      else
        setTimeout(async () => {
          const captchaKey = await this.getRecaptcha();
          resolve(captchaKey);
        }, 500);
    });

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user in application state
   */
  submit = () => {
    document.getElementById("login").classList.add("was-validated");
    let invalidInputs = this.state.inputs.filter((input) => input.invalid);
    invalidInputs.forEach((input) =>
      document.getElementById(input.id).setCustomValidity(input.error)
    );
    if (!this.state.working && !invalidInputs.length)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          const data = Object.fromEntries(
            this.state.inputs.map((input) => [input.id, input.value.trim()])
          );
          try {
            login_schema.validateSync(data, {
              abortEarly: false,
            });
            const captchaKey = await this.getRecaptcha();
            data.captchaKey = captchaKey;
            axios
              .post(process.env.REACT_APP_LAMBDA_AUTH + "/login", data, {
                headers: {
                  Authorization: this.props.token,
                },
              })
              .then((res) => {
                this.props.set_token(res.data.token);
                localStorage.setItem("userID", res.data.userInfo._id);
                localStorage.setItem(
                  "chatKey",
                  new Encrypter(
                    this.state.inputs.find((i) => i.id === "password").value
                  ).decrypt(res.data.userInfo.chatKey)
                );
                this.props.set_user(res.data.userInfo);
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err.response);
                    if (err.response) {
                      switch (err.response.status) {
                        case 401:
                          alert("Invalid username or password");
                          break;
                        case 403:
                          alert(err.response.data.message);
                          break;
                        case 423:
                          this.props.set_verification_details(
                            err.response.data
                          );
                          break;
                        default:
                          alert("An error occurred. Please try again later");
                      }
                    } else alert("An error occurred. Please try again later");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  /**
   * Fired when the user clicks the Forgot Password button
   * Set the component to fade out to the left, then navigate to the Forgot Password page
   */
  forgotPassword = () =>
    this.setState(
      (curr) => ({
        ...curr,
        exit: t.fade_out_left,
      }),
      () => this.props.route("/forgot-password")
    );

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value.trim()])
        );
        try {
          login_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState((curr) => ({
            ...curr,
            inputs: this.state.inputs.map((input) => {
              document.getElementById(input.id).setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          }));
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            (curr) => ({
              ...curr,
              inputs: this.state.inputs.map((input) => {
                if (
                  err.inner.find((error) => error.path === input.id) &&
                  errorsAdded.indexOf(input.id) === -1
                ) {
                  errorsAdded.push(input.id);
                  return {
                    ...input,
                    invalid: true,
                    error: err.inner.find((error) => error.path === input.id)
                      .message,
                  };
                } else
                  return {
                    ...input,
                    invalid: false,
                    error: "",
                  };
              }),
            }),
            () =>
              this.state.inputs.forEach((input) =>
                document.getElementById(input.id).setCustomValidity(input.error)
              )
          );
        }
      }
    );

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.state.inputs.find((f) => f.id === e.target.id);
      if (input) {
        const nextField =
          this.state.inputs[this.state.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(nextField.id);
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  render() {
    return (
      <motion.div
        className="min-h-100 py-4 page-container"
        transition={t.transition}
        exit={this.state.exit}
        animate={t.normalize}
        initial={this.state.exit}
      >
        <MDBContainer>
          <h1 className="display-4 text-center">Login</h1>
          <hr></hr>
          <div className="form-containers">
            <MDBValidation
              name="login"
              method="dialog"
              id="login"
              onSubmit={this.submit}
            >
              <MDBValidationItem
                className="pb-4 z-index-50-child"
                feedback={
                  this.state.inputs.find((input) => input.id === "username")
                    .error
                }
                invalid={true}
              >
                <MDBInput
                  name="username"
                  onChange={this.changeHandler}
                  id="username"
                  label="Username or Email"
                  size="lg"
                  className={`${
                    !this.state.inputs.find((input) => input.id === "username")
                      .invalid
                      ? "mb-0"
                      : 0
                  }`}
                  onKeyPress={this.pressEnter}
                  onKeyDown={this.pressTab}
                />
              </MDBValidationItem>
              <MDBValidationItem
                className="pb-4 z-index-50-child"
                feedback={
                  this.state.inputs.find((input) => input.id === "password")
                    .error
                }
                invalid={true}
              >
                <MDBInput
                  name="password"
                  onChange={this.changeHandler}
                  id="password"
                  label="Password"
                  size="lg"
                  type="password"
                  className={`${
                    !this.state.inputs.find((input) => input.id === "password")
                      .invalid
                      ? "mb-0"
                      : 0
                  } z-index-50`}
                  onKeyPress={this.pressEnter}
                  onKeyDown={this.pressTab}
                />
              </MDBValidationItem>
            </MDBValidation>
            {this.state.working ? (
              <MDBBtn
                color="success"
                size="lg"
                className="w-100 z-index-50"
                block
                disabled
              >
                <Spinner size="sm" className="me-2" />
                Working
              </MDBBtn>
            ) : (
              <MDBBtn
                color="success"
                onClick={this.submit}
                size="lg"
                block
                className="w-100 z-index-50"
              >
                <i className="fas fa-paper-plane me-2"></i>Submit
              </MDBBtn>
            )}
            <MDBBtn
              onClick={this.forgotPassword}
              size="lg"
              className="w-100 my-4 bg-gray z-index-50"
              block
            >
              Forgot Password<i className="fas fa-chevron-right ms-2"></i>
            </MDBBtn>
            {String(env.READ_ONLY) !== "true" && (
              <MDBBtn
                color="link"
                rippleColor="primary"
                className="text-unset mb-4 fs-6 d-block mx-auto z-index-50"
                onClick={() => this.props.route("/create-account")}
              >
                <i className="fas fa-user-plus me-2"></i>Create Account
              </MDBBtn>
            )}
          </div>
          <small className="mt-2 d-block mx-auto text-center">
            This site is protected by reCAPTCHA, and the Google
            <a href="https://policies.google.com/privacy">
              {" "}
              Privacy Policy
            </a>{" "}
            and
            <a href="https://policies.google.com/terms">
              {" "}
              Terms of Service
            </a>{" "}
            apply.
          </small>
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_user,
  route,
  clear_temp_action,
  set_verification_details,
  set_token,
  set_redirect_url,
})(Login);
