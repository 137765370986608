import env from "../env";
import React from "react";
import sanitize from "sanitize-html";
import { parse } from "node-html-parser";
import { MDBBadge, MDBTooltip } from "mdb-react-ui-kit";
import videoParser from "js-video-url-parser";
import VideoSnapshot from "video-snapshot";
import Encrypter from "./Encrypter";
import crypto from "crypto-browserify";
import { Buffer } from "safe-buffer";
import axios from "axios";
import pngVideo from "./pngVideo";

const urlRegex =
  /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const h = {};

h.getSignedRequests = (files, token, set_token) =>
  new Promise((resolve, reject) =>
    axios
      .post(
        process.env.REACT_APP_LAMBDA_SUPPORT + "/signed-request-data",
        {
          items: files.map((file) => {
            let item = {};
            const type = file.type.split("/")[0].toLowerCase();
            switch (type) {
              case "audio":
                item.folder = "audio";
                break;
              case "video":
                item.folder = "videos";
                break;
              case "image":
                item.folder = "images";
                break;
              default:
                item.folder = "other";
            }
            item.file = file.name;
            return item;
          }),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        set_token(res.data.token);
        resolve(res.data.signedRequests);
      })
      .catch(reject)
  );

h.processImage = (file, progressHandler, token, set_token) =>
  new Promise((resolve, reject) => {
    const fd = new FormData();
    fd.append("files", file.file, file.name);
    axios
      .post(process.env.REACT_APP_LAMBDA_API + "/image", fd, {
        headers: {
          Authorization: token,
        },
        onUploadProgress: progressHandler,
      })
      .then((res) => {
        set_token(res.data.token);
        resolve(res.data.processedFile);
      })
      .catch(reject);
  });

h.processFile = (file, progressHandler, thumbnail) => {
  const requestData = thumbnail ? file.thumbnailRequest : file.requestData;
  return new Promise(async (resolve, reject) => {
    try {
      const fd = new FormData();
      Object.keys(requestData.fields).forEach((key) =>
        fd.append(key, requestData.fields[key])
      );
      if (thumbnail) {
        // const thumbnailFile = await h.getVideoThumbnail(file.file);
        if (!file.thumbnailFile) {
          console.log("Thumbnail was not generated", file);
          file.thumbnailFile = await h.getVideoThumbnail(file.file);
        }
        fd.append("file", file.thumbnailFile, "thumbnail.png");
      } else fd.append("file", file.file, file.name);

      // axios
      //   .post(requestData.url, fd, {
      //     onUploadProgress: progressHandler,
      //   })
      //   .finally(resolve);
      axios.post(requestData.url, fd).finally(resolve);
    } catch (err) {
      reject(err);
    }
  });
};

/**
 *
 * @param {String} dataURL - DataURL
 * @param {String} filename - File name
 *
 * Converts dataURL to Uint8Array, then feeds Uint8Array, filename, and mimetype into File constructor
 *
 * @returns JavaScript file object
 */
h.dataURLtoFile = (dataURL, filename) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

h.svgToDataURL = (svg) => {
  const escapedSvg = svg
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/#/g, "%23");
  const urlEncodedSvg = encodeURIComponent(escapedSvg);
  const dataUri = `data:image/svg+xml;charset=UTF-8,${urlEncodedSvg}`;
  return dataUri;
};

/**
 *
 * @param {File} file - Video file
 *
 * Creates <video>
 * Sets src of video to file
 * Once duration is determined, get VideoSnapshot
 *
 * @returns Thumbnail of video taken halfway through the video
 */
h.getVideoThumbnail = (file) =>
  new Promise((resolve) => {
    if (["video/mp4", "video/webm", "video/quicktime"].includes(file.type)) {
      const fileReader = new FileReader();
      fileReader.onload = function () {
        try {
          const blob = new Blob([fileReader.result], { type: file.type });
          const url = URL.createObjectURL(blob);
          const video = document.getElementById("video-thumbnail-temp");
          video.addEventListener("loadeddata", function () {
            try {
              video.currentTime = this.duration / 2;
            } catch (err) {
              console.log("loadeddata error", err);
              resolve(h.dataURLtoFile(pngVideo, "file.png"));
            }
          });
          video.addEventListener("seeked", function () {
            snapImage();
          });
          const snapImage = function () {
            try {
              setTimeout(() => {
                const canvas = document.getElementById("canvas-thumbnail-temp");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas
                  .getContext("2d")
                  .drawImage(video, 0, 0, canvas.width, canvas.height);
                canvas.getContext("2d").canvas.toBlob(
                  (blob) => {
                    console.log("thumb", blob, new File([blob], "file.png"));
                    resolve(new File([blob], "file.png"));
                  },
                  "image/png",
                  1
                );
              }, 200);
            } catch (err) {
              console.log("snapImage error", err);
              resolve(h.dataURLtoFile(pngVideo, "file.png"));
            }
          };
          video.preload = "metadata";
          video.src = url;
          video.muted = true;
          video.playsInline = true;

          video.play();
        } catch (err) {
          console.log("onload error", err);
          resolve(h.dataURLtoFile(pngVideo, "file.png"));
        }
      };
      fileReader.readAsArrayBuffer(file);
    } else {
      resolve(h.dataURLtoFile(pngVideo, "file.png"));
    }
  });

h.copyToClipboard = (string) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", true);
    textarea.setAttribute("contenteditable", true);
    textarea.style.position = "fixed";
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand("copy");
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    const copyHotkey = isMac ? "⌘C" : "CTRL+C";
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
};

h.getAudioFromVideo = (file) =>
  new Promise((resolve) => {
    try {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);
      video.onloadeddata = () =>
        resolve(video.captureStream().getAudioTracks().length);
    } catch (err) {
      console.log("getAudioFromVideo error", err);
      resolve(0);
    }
  });

h.processThumbnail = (file, token, set_token) =>
  new Promise((resolve, reject) =>
    axios
      .post(process.env.REACT_APP_LAMBDA_API + "/image", file, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        set_token(res.data.token);
        resolve(res.data.thumbnail);
      })
      .catch(reject)
  );

h.getFileType = (file) => {
  switch (file.name.split(".")[file.name.split(".").length - 1].toLowerCase()) {
    case "txt":
    case "log":
    case "text":
      return "text/plain";
    case "html":
      return "text/html";
    case "css":
      return "text/css";
    case "js":
      return "text/javascript";
    case "xml":
      return "text/xml";
    case "csv":
      return "text/csv";
    case "md":
      return "text/markdown";
    case "bin":
      return "application/octet-stream";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "tiff":
      return "image/tiff";
    case "ico":
      return "image/x-icon";
    case "svg":
      return "image/svg+xml";
    case "midi":
      return "audio/midi";
    case "m4a":
      return "audio/m4a";
    case "aac":
      return "audio/aac";
    case "mp3":
      return "audio/mpeg";
    case "wav":
    case "wave":
      return "audio/wav";
    case "ogg":
      return "audio/ogg";
    case "avi":
      return "video/avi";
    case "mkv":
      return "video/x-matroska";
    case "mp4":
      return "video/mp4";
    case "mov":
    case "qt":
      return "video/quicktime";
    case "sfw":
      return "application/x-shockwave-flash";
    case "webm":
      return "video/webm";
    case "xl":
    case "xls":
    case "xlsx":
      return "application/excel";
    case "zip":
      return "application/zip";
    case "pdf":
      return "application/pdf";
    case "doc":
    case "docx":
      return "application/msword";
    case "tar":
      return "application/x-tar";
    case "gz":
      return "application/x-gzip";
    case "ttf":
      return "font/ttf";
    case "ppt":
    case "pttx":
      return "application/powerpoint";
    case "otf":
      return "font/otf";
    default:
      return "application/octet-stream";
  }
};

// Hides all tooltips on the page
h.hideToolTips = () => {
  Array.from(document.getElementsByClassName("tooltip")).forEach((e) => {
    // e.classList.remove("show")
    e.style.display = "none";
  });
};

/**
 *
 * @param {String} html
 * @returns JSX - Fragment or iframe of last supported 3rd party video embed in it
 */
h.getMediaFromHtml = (html) => {
  try {
    if (!html) return <></>;
    const urls = html.match(urlRegex);
    if (!urls) return <></>;
    else {
      let video = false;
      urls.forEach((url) => {
        const parsed = videoParser.parse(url);
        if (parsed) video = parsed;
      });
      if (!video) return <></>;
      else
        switch (video.provider) {
          case "youtube":
            return (
              <iframe
                className="iframe-videos no-route"
                src={`https://www.youtube.com/embed/${video.id}`}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            );
          case "vimeo":
            return (
              <iframe
                src={`https://player.vimeo.com/video/${video.id}`}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="Vimeo video player"
                className="iframe-videos no-route"
              />
            );
          case "twitch":
            return (
              <iframe
                src={`https://player.twitch.tv/?channel=${video.channel}&parent=${window.location.host}`}
                frameborder="0"
                allowfullscreen="true"
                scrolling="no"
                className="iframe-videos no-route"
              />
            );
          case "dailymotion":
            return (
              <iframe
                frameborder="0"
                type="text/html"
                src={`https://www.dailymotion.com/embed/video/${video.id}`}
                allowfullscreen
                title="Dailymotion Video Player"
                allow="autoplay"
                className="iframe-videos no-route"
              />
            );
          default:
            return <></>;
        }
    }
  } catch (err) {
    console.log("get media from html error", err);
    return <></>;
  }
};

/**
 *
 * @param {Object} userInfo - Users document
 * @param {String} classes - CSS classes
 * @returns Tooltip with badge that depends on the user's role with provided CSS classes
 */
h.getBadge = (userInfo, classes) => {
  if (!userInfo) return <></>;
  if (userInfo.role === "Chadmin")
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{
          className: "name-chadmin cursor-default no-route d-inline-block",
        }}
        title={env.ADMIN_NAME}
        onMouseLeave={h.hideToolTips}
      >
        <MDBBadge className={`badge-chadmin ${classes} no-route`}>
          <div className="d-flex justify-content-center align-items-center no-route">
            <div
              className="fit-images no-route"
              style={{
                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/assets/images/meltrans.png")`,
              }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else if (userInfo.role === "Janny")
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{
          className: "name-janny cursor-default no-route d-inline-block",
        }}
        title={env.MOD_NAME}
      >
        <MDBBadge className={`badge-janny no-route ${classes}`}>
          <div className="d-flex justify-content-center align-items-center no-route">
            <div
              className="fit-images no-route"
              style={{
                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/assets/images/thomastrans.png")`,
              }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else if (userInfo.verified)
    return (
      <MDBTooltip
        tag="span"
        wrapperProps={{
          className: "name-verified cursor-default no-route d-inline-block",
        }}
        title="Verified"
      >
        <MDBBadge className={`badge-verified ${classes} no-route`}>
          <div className="d-flex justify-content-center align-items-center no-route">
            <div
              className="fit-images no-route"
              style={{
                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/assets/images/verifiedlogotrans.png")`,
              }}
            ></div>
          </div>
        </MDBBadge>
      </MDBTooltip>
    );
  else return <></>;
};

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable date in the format "MM/DD/YYYY"
 */
h.makeDateHR = (date) => {
  date = new Date(date);
  let months = date.getMonth() + 1;
  let days = date.getDate();
  let years = date.getFullYear();
  return months + "/" + days + "/" + years;
};

/**
 *
 * @param {JavaScript date} timestamp
 * @returns The time that the message was sent if sent less than a day ago, otherwise the date that the message was sent
 */
h.getMessageTime = (timestamp) => {
  const now = new Date();
  const messageTime = new Date(timestamp);
  const timeDifference = now.getTime() - messageTime.getTime();
  const day = 1000 * 60 * 60 * 24;
  if (timeDifference > day) return h.getNiceDate(timestamp);
  else return h.getTimeHR(timestamp);
};

/**
 *
 * @param {Number} size - Size of the file to be measured
 * @returns Size of file in Bytes/KB/MB/etc
 */
h.getFileSize = (size) => {
  size = Number(size);
  const units = ["Bytes", "KB", "MB", "GB"];
  let scale = 0;
  while (size > 900 && scale < 3) {
    size /= 1024;
    scale++;
  }
  return Math.round(size * 100) / 100 + " " + units[scale];
};

/**
 *
 * @param {String} html - HTML string
 * @returns The length of the regular text in the string sans whitespace that is not a space or new line
 */
h.checkHTMLLength = (html, skipSpaces) =>
  String(parse(html).textContent)
    .split("")
    .filter((c) => {
      const checkWhiteSpace = c.match(/[\s]/);
      if (!checkWhiteSpace) return true;
      else if (skipSpaces) return false;
      else {
        return [" ", "\n"].indexOf(c) > -1;
      }
    }).length;

/**
 *
 * @param {Object} userInfo - Users document
 * @param {Array} rawData - List of emissions
 * @returns emissionIDs of emissions that were authored by the user
 */
h.getThreadEmissions = (userInfo, rawData) => {
  let emissions = [];
  rawData.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  emissions = [...new Set(emissions)];
  return emissions;
};

h.processBroadcastMessage = (user, conversation, userInfo, message) => {
  /**
   * If most recent resetPassword, use that
   * Else, use kay
   */
  let encrypter;
  let senderKey;
  let receiverKey;
  const newKeys = {};
  if (!conversation.resetPassword) {
    let key = conversation.keys.find((k) => k[userInfo._id] && k[user._id]);

    if (key?.sharedKey) encrypter = key[userInfo._id];
    else {
      const sharedKey = crypto
        .randomBytes(Number(process.env.REACT_APP_BYTE_LENGTH))
        .toString("hex");
      encrypter = new Encrypter(sharedKey);
      newKeys[user._id] = sharedKey;
      newKeys[userInfo._id] = sharedKey;
      senderKey = crypto
        .publicEncrypt(userInfo.publicKey, Buffer.from(sharedKey, "binary"))
        .toString("binary");
      receiverKey = crypto
        .publicEncrypt(user.publicKey, Buffer.from(sharedKey, "binary"))
        .toString("binary");
    }
  } else {
    encrypter = conversation.resetPassword
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]
      .newKeys.find((k) => k[userInfo._id] && k[user._id])[userInfo._id];
  }

  if (!encrypter)
    encrypter = conversation.keys.find((k) => k[userInfo._id] && k[user._id])[
      userInfo._id
    ];
  message = encrypter.encrypt(h.sanitizeHTML(message));

  return {
    message: {
      to: user._id,
      message,
      senderKey,
      receiverKey,
    },
    keys: newKeys,
  };
};

h.decryptMessage = (userID, conversation, message) => {
  let decryptedMessage = "";
  try {
    let decrypter;
    if (message.to) {
      if (message.translator && message.to === userID) {
        decrypter = conversation.keys.find(
          (k) => k[message.to] && k[message.translator]
        )[userID];
      } else {
        if (!conversation.resetPassword) {
          decrypter = conversation.keys.find(
            (k) => k[message.to] && k[message.from]
          )[userID];
        } else {
          const rpLesser = conversation.resetPassword.filter(
            (rp) => new Date(rp.timestamp) < new Date(message.timestamp)
          );
          if (rpLesser.length) {
            decrypter = rpLesser
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]
              .newKeys.find((k) => k[message.to] && k[message.from])[userID];
          } else {
            decrypter = conversation.keys.find(
              (k) => k[message.to] && k[message.from]
            )[userID];
          }
        }
      }
    } else {
      decrypter = conversation.solo;
    }

    if (!decrypter)
      decrypter = decrypter = conversation.keys.find(
        (k) => k[message.to] && k[message.from]
      )[userID];
    if (!decrypter) throw "no valid decrypter found";
    decryptedMessage = decrypter.decrypt(message.message);
    decryptedMessage = h.sanitizeHTML(decryptedMessage);
  } catch (err) {
    // console.log("Decrypt error", err);
    // console.log(userID);
    // console.log(conversation);
    // console.log(message);
    decryptedMessage = `<p class="text-italic">This message could not be decrypted, possibly due to a reset password.</p>`;
  }
  return decryptedMessage;
};

h.decryptConversations = (userID, chatKey, conversations, sample) =>
  conversations
    .map((conversation) => {
      try {
        if (sample) {
          const lastMessage = conversation.messages.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          )[0];
          if (lastMessage) {
            conversation.keys = conversation.keys.map((key) => {
              if (
                !key.sharedKey &&
                (key[lastMessage.from] || key[lastMessage.translator]) &&
                key[lastMessage.to]
              ) {
                try {
                  const sharedKey = crypto
                    .privateDecrypt(chatKey, Buffer.from(key[userID], "binary"))
                    .toString("binary");
                  const encrypter = new Encrypter(sharedKey);
                  key.sharedKey = sharedKey;
                  key[userID] = encrypter;
                  key.encrypt = encrypter.encrypt;
                  key.decrypt = encrypter.decrypt;
                } catch (err) {
                  // console.log("key error", err);
                }
              }

              return key;
            });
          }
        } else {
          if (userID === conversation.starter && !conversation.solo) {
            if (
              conversation.resetPassword &&
              conversation.resetPassword.find((rp) => rp.userID === userID)
            ) {
              const encrypter = new Encrypter(
                crypto
                  .privateDecrypt(
                    chatKey,
                    Buffer.from(
                      conversation.resetPassword
                        .filter((rp) => rp.userID === userID)
                        .sort(
                          (a, b) =>
                            new Date(b.timestamp) - new Date(a.timestamp)
                        )[0].soloKey,
                      "binary"
                    )
                  )
                  .toString("binary")
              );
              conversation.solo = encrypter;
              conversation.soloEncrypt = encrypter.encrypt;
              conversation.soloDecrypt = encrypter.decrypt;
            } else {
              const encrypter = new Encrypter(
                crypto
                  .privateDecrypt(
                    chatKey,
                    Buffer.from(conversation.soloKey, "binary")
                  )
                  .toString("binary")
              );
              conversation.solo = encrypter;
              conversation.soloEncrypt = encrypter.encrypt;
              conversation.soloDecrypt = encrypter.decrypt;
            }
          }
          conversation.keys = conversation.keys.map((key) => {
            try {
              if (!key.sharedKey) {
                const sharedKey = crypto
                  .privateDecrypt(chatKey, Buffer.from(key[userID], "binary"))
                  .toString("binary");
                const encrypter = new Encrypter(sharedKey);
                key.sharedKey = sharedKey;
                key[userID] = encrypter;
                key.encrypt = encrypter.encrypt;
                key.decrypt = encrypter.decrypt;
              }
            } catch (err) {}

            return key;
          });
        }
        if (conversation.resetPassword) {
          conversation.resetPassword = conversation.resetPassword
            .filter((rp) => rp.newKeys.find((key) => key[userID]))
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map((rp, index) => {
              // if (rp.userID === userID && !index) {
              //   if (!rp.solo) {
              //     const encrypter = new Encrypter(
              //       crypto
              //         .privateDecrypt(
              //           chatKey,
              //           Buffer.from(rp.soloKey, "binary")
              //         )
              //         .toString("binary")
              //     );
              //     rp.solo = encrypter;
              //     rp.soloEncrypt = encrypter.encrypt;
              //     rp.soloDecrypt = encrypter.decrypt;
              //   }
              // }

              rp.newKeys = rp.newKeys.map((key) => {
                if (!key.sharedKey) {
                  key.sharedKey = crypto
                    .privateDecrypt(chatKey, Buffer.from(key[userID], "binary"))
                    .toString("binary");
                  const encrypter = new Encrypter(key.sharedKey);
                  key[userID] = encrypter;
                  key.encrypt = encrypter.encrypt;
                  key.decrypt = encrypter.decrypt;
                }

                return key;
              });

              return rp;
            });
        }
        if (sample)
          conversation.messages = conversation.messages
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map((message, i) => {
              if (!i) {
                message.decrypted = true;
                message.message = h.decryptMessage(
                  userID,
                  conversation,
                  message
                );
              }
              return message;
            });
        else
          conversation.messages = conversation.messages.map((message) => {
            if (!message.decrypted) {
              message.decrypted = true;
              message.message = h.decryptMessage(userID, conversation, message);
            }
            return message;
          });

        return conversation;
      } catch (err) {
        console.log("error", err);
        return false;
      }
    })
    .filter((c) => c);

/**
 *
 * @param {Object} userInfo - Users document
 * @param {Object} profileInfo - Profile Info
 * @returns emissionIDs of emissions in profile emissions and profile likes that were authored by the user
 */
h.getUserProfileEmissions = (userInfo, profileInfo) => {
  let emissions = [];
  profileInfo.emissions.items.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  profileInfo.likes.items.forEach((emission) => {
    if (emission.userID === userInfo._id) emissions.push(emission.emissionID);
    if (emission.signalBoost && emission.signalBoost.userID === userInfo._id)
      emissions.push(emission.signalBoost.emissionID);
    if (emission.replyEmission) {
      if (emission.replyEmission.userID === userInfo._id)
        emissions.push(emission.replyEmission.emissionID);
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.userID === userInfo._id
      )
        emissions.push(emission.replyEmission.signalBoost.emissionID);

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.userID === userInfo._id)
          emissions.push(emission.replyEmission.replyEmission.emissionID);
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.userID ===
            userInfo._id
        )
          emissions.push(
            emission.replyEmission.replyEmission.signalBoost.emissionID
          );
      }
    }
  });
  emissions = [...new Set(emissions)];
  return emissions;
};

h.updateArrayItems = (oldArrayItems, newArrayItems) => [
  ...oldArrayItems.filter((o) => !newArrayItems.find((n) => n._id === o._id)),
  ...newArrayItems,
];

/**
 *
 * @param {Array} oldEmissions - Old list of emissions
 * @param {Array} newEmissions - New list of emissions
 *
 * Loops through old emissions
 * If any match any in the newEmissions array, replace with new data
 *
 * @returns Updated list of emissions
 */
h.replaceUserEmissions = (oldEmissions, newEmissions) =>
  oldEmissions.map((e) => {
    let replacedEmission;
    replacedEmission = newEmissions.find(
      (emission) => emission.emissionID === e.emissionID
    );
    if (replacedEmission)
      e = {
        ...e,
        ...replacedEmission,
      };
    else {
      if (e.signalBoost) {
        replacedEmission = newEmissions.find(
          (emission) => emission.emissionID === e.signalBoost.emissionID
        );
        if (replacedEmission)
          e.signalBoost = {
            ...e.signalBoost,
            ...replacedEmission,
          };
      }
      if (e.replyEmission) {
        replacedEmission = newEmissions.find(
          (emission) => emission.emissionID === e.replyEmission.emissionID
        );
        if (replacedEmission)
          e.replyEmission = {
            ...e.replyEmission,
            ...replacedEmission,
          };
        else {
          if (e.replyEmission.signalBoost) {
            replacedEmission = newEmissions.find(
              (emission) =>
                emission.emissionID === e.replyEmission.signalBoost.emissionID
            );
            if (replacedEmission)
              e.replyEmission.signalBoost = {
                ...e.replyEmission.signalBoost,
                ...replacedEmission,
              };
          }
          if (e.replyEmission.replyEmission) {
            replacedEmission = newEmissions.find(
              (emission) =>
                emission.emissionID === e.replyEmission.replyEmission.emissionID
            );
            if (replacedEmission)
              e.replyEmission.replyEmission = {
                ...e.replyEmission.replyEmission,
                ...replacedEmission,
              };
            else {
              if (e.replyEmission.replyEmission.signalBoost) {
                replacedEmission = newEmissions.find(
                  (emission) =>
                    emission.emissionID ===
                    e.replyEmission.replyEmission.signalBoost.emissionID
                );
                if (replacedEmission)
                  e.replyEmission.replyEmission.signalBoost = {
                    ...e.replyEmission.replyEmission.signalBoost,
                    ...replacedEmission,
                  };
              }
            }
          }
        }
      }
    }

    return e;
  });

/**
 *
 * @param {JavaScript date} date
 * @returns a human readable time in the format "0:00AM"
 */
h.getTimeHR = (date) => {
  date = new Date(date);
  let meridian = "AM";
  let hours = date.getHours();
  let minutes = date.getMinutes();
  if (hours >= 12) meridian = "PM";
  if (!hours) hours = 12;
  if (hours > 12) {
    hours -= 12;
  }
  if (String(minutes).length === 1) minutes = `0${minutes}`;
  return hours + ":" + minutes + meridian;
};

h.promiseWithTimeout = (mainTask, timeout, failResult) =>
  new Promise((resolve) => {
    try {
      const timer = () =>
        new Promise((resolve) =>
          setTimeout(() => resolve(failResult), timeout)
        );

      Promise.race([mainTask, timer].map((fn) => fn()))
        .then(resolve)
        .catch((err) => {
          console.log("race error", err);
          resolve(failResult);
        });
    } catch (err) {
      console.log("promiseWithTimeout error", err);
      resolve(failResult);
    }
  });

/**
 *
 * @param {MediaStream} stream
 * @returns Dimensions of the stream in pixels
 */
h.getStreamDimensions = async (stream, ping) => {
  const empty = {
    width: 0,
    height: 0,
  };

  try {
    if (!ping || typeof ping !== "number") ping = 0;
    const mainTask = () =>
      new Promise((resolve) => {
        try {
          let video = document.createElement("video");
          video.muted = true;
          video.srcObject = stream;
          video.onloadedmetadata = () => {
            const dimensions = {
              width: video.videoWidth,
              height: video.videoHeight,
            };

            video = null;

            resolve(dimensions);
          };
        } catch (err) {
          console.log("getStreamDimensions error", err);
          return resolve(empty);
        }
      });
    const dimensions = await h.promiseWithTimeout(mainTask, ping + 500, empty);
    return dimensions;
  } catch (err) {
    console.log("getStreamDimensions error", err);
    return empty;
  }
};

/**
 * @param {String | Number} num - A number (i.e. 1000000)
 * @returns String - Number with commas appended (i.e. 1,000,000)
 */
h.numberWithCommas = (num) => {
  if (!num && typeof num !== "number") {
    console.log("numberWithCommas", num);
    return "0";
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 *
 * @param {String} string
 * @returns The first up to 24 characters of that string
 */
h.veryShortString = (string) => {
  string = String(string);
  if (string.length > 25) return string.substring(0, 24) + "...";
  else return string;
};

/**
 *
 * @param {String} string
 * @returns The first up to 99 characters of that string
 */
h.shortString = (string) => {
  string = String(string);
  if (string.length > 100) return string.substring(0, 99) + "...";
  else return string;
};

/**
 *
 * @param {String} string
 * @returns Boolean - Whether the string is a number.
 */
h.isNumeric = (string) => {
  if (typeof string != "string") return false;
  return !isNaN(string) && !isNaN(parseFloat(string));
};

/**
 * Fixes MDB bug in which labels on inputs with text input are not properly floated
 * Floats the labels
 */
h.floatLabels = () =>
  setTimeout(
    () =>
      [].slice
        .call(document.getElementsByClassName("form-control"))
        .forEach((e) => {
          if (e.value) {
            if (!e.classList.contains("active")) {
              e.classList.add("active");
              const oldValue = e.value;
              e.value += "4";
              e.value = oldValue;
            }
          }
        }),
    250
  );

/**
 *
 * @param {JavaScript date} date
 * @returns Date in the format of "Jan 1, 1970"
 */
h.getNiceDate = (date) => {
  date = new Date(date);
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

/**
 *
 * @param {File} file
 * @returns md5 hash of the file
 */
h.getMD5 = (file) => {
  try {
    return crypto
      .createHash("md5")
      .update(
        JSON.stringify({
          lastModified: file.lastModified,
          name: file.name,
          type: file.type,
          size: file.size,
        })
      )
      .digest("hex");
  } catch (err) {
    console.log("getMD5 error", err, file);
  }
};

/**
 *
 * @param {Number} time - Milliseconds to sleep
 *
 * Freezes for the amount of milliseconds specified
 */
h.sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

/**
 * Executes a captcha challenge and generates a key a key
 * Will hang until connected to captcha servers
 */
h.getRecaptcha = (reCaptchaProps) =>
  new Promise(async (resolve, reject) => {
    if (reCaptchaProps.executeRecaptcha)
      reCaptchaProps
        .executeRecaptcha()
        .then((res) => resolve(res))
        .catch((err) => {
          console.log("captcha error", err);
          alert("Recaptcha error. Refresh the page and try again.");
          return reject(false);
        });
    else {
      return reject(true);
    }
  });

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Janny privileges
 */
h.checkJanny = (userInfo) =>
  userInfo && ["Janny", "Chadmin"].indexOf(userInfo.role) !== -1;

/**
 *
 * @param {Object} userInfo - Users document
 * @returns Boolean - Whether the user has Chadmin privileges
 */
h.checkChadmin = (userInfo) => userInfo && userInfo.role === "Chadmin";

/**
 *
 * @param {String} code - Removal code
 * @returns Removal label
 */
h.getRemovedReason = (code) => {
  switch (code) {
    case "fed":
      return "Terrorism/Fedposting";
    case "porn":
      return "Porn";
    case "spam":
      return "Spam";
    default:
      console.log("Oob removed reason", code);
      return "Other";
  }
};

/**
 *
 * @param {String} string
 * @returns The first up to 100 characters of that string
 */
h.abbreviatedText = (text) => {
  text = String(text);
  if (text.length > 100) return text.substring(0, 100) + "...";
  else return text;
};

/**
 *
 * @param {String} string
 * @returns The first up to 1000 characters of that string
 */
h.longString = (text) => {
  text = String(text);
  if (text.length > 1000) return text.substring(0, 1000) + "...";
  else return text;
};

/**
 *
 * @param {Object} emission - Emissions document
 * @param {Object} userInfo - Users document
 * @returns Emission with metadata applied (likes/votes on main/replies/signalboosted/etc)
 */
h.setMetadata = (emission, userInfo) => {
  if (emission.userLikes || !userInfo)
    emission.liked = userInfo
      ? emission.userLikes.indexOf(userInfo._id) > -1
      : false;
  if (emission.pollData && (emission.pollData.voters || !userInfo))
    emission.pollData.voted = userInfo
      ? emission.pollData.voters.find(
          (voter) => voter.userID === userInfo._id
        ) !== undefined
      : false;

  if (emission.signalBoost) {
    if (emission.signalBoost.userLikes || !userInfo)
      emission.signalBoost.liked = userInfo
        ? emission.signalBoost.userLikes.indexOf(userInfo._id) > -1
        : false;
    if (
      emission.signalBoost.pollData &&
      (emission.signalBoost.pollData.voters || !userInfo)
    )
      emission.signalBoost.pollData.voted = userInfo
        ? emission.signalBoost.pollData.voters.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;
  }

  if (emission.replyEmission) {
    if (emission.replyEmission.userLikes || !userInfo)
      emission.replyEmission.liked = userInfo
        ? emission.replyEmission.userLikes.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;
    if (
      emission.replyEmission.pollData &&
      (emission.replyEmission.pollData.voters || !userInfo)
    )
      emission.replyEmission.pollData.voted = userInfo
        ? emission.replyEmission.pollData.voters.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;

    if (emission.replyEmission.signalBoost) {
      if (emission.replyEmission.signalBoost.userLikes || !userInfo)
        emission.replyEmission.signalBoost.liked = userInfo
          ? emission.replyEmission.signalBoost.userLikes.find(
              (voter) => voter.userID === userInfo._id
            ) !== undefined
          : false;
      if (
        emission.replyEmission.signalBoost.pollData &&
        (emission.replyEmission.signalBoost.pollData.voters || !userInfo)
      )
        emission.replyEmission.signalBoost.pollData.voted = userInfo
          ? emission.replyEmission.signalBoost.pollData.voters.find(
              (voter) => voter.userID === userInfo._id
            ) !== undefined
          : false;
    }
  }

  if (emission.replyEmission && emission.replyEmission.replyEmission) {
    if (emission.replyEmission.replyEmission.userLikes || !userInfo)
      emission.replyEmission.replyEmission.liked = userInfo
        ? emission.replyEmission.replyEmission.userLikes.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;
    if (
      emission.replyEmission.replyEmission.pollData &&
      (emission.replyEmission.replyEmission.pollData.voters || !userInfo)
    )
      emission.replyEmission.replyEmission.pollData.voted = userInfo
        ? emission.replyEmission.replyEmission.pollData.voters.find(
            (voter) => voter.userID === userInfo._id
          ) !== undefined
        : false;

    if (emission.replyEmission.replyEmission.signalBoost) {
      if (
        emission.replyEmission.replyEmission.signalBoost.userLikes ||
        !userInfo
      )
        emission.replyEmission.replyEmission.signalBoost.liked = userInfo
          ? emission.replyEmission.replyEmission.signalBoost.userLikes.find(
              (voter) => voter.userID === userInfo._id
            ) !== undefined
          : false;
      if (
        emission.replyEmission.replyEmission.signalBoost.pollData &&
        (emission.replyEmission.replyEmission.signalBoost.pollData.voters ||
          !userInfo)
      )
        emission.replyEmission.replyEmission.signalBoost.pollData.voted =
          userInfo
            ? emission.replyEmission.replyEmission.signalBoost.pollData.voters.find(
                (voter) => voter.userID === userInfo._id
              ) !== undefined
            : false;
    }
  }

  return emission;
};

/**
 *
 * @param {HTML Element} e
 * @returns Inner dimensions of the element
 */
h.innerDimensions = (e) => {
  const computedStyle = getComputedStyle(e);

  let width = e.clientWidth;
  let height = e.clientHeight;

  height -=
    parseFloat(computedStyle.paddingTop) +
    parseFloat(computedStyle.paddingBottom);
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight);

  return {
    height: height,
    width: width,
  };
};

/**
 *
 * @param {Object} profileInfo
 * @returns Blank profile
 */
h.purgeProfileInfo = (profileInfo) => ({
  ...profileInfo,
  emissions: {
    ...profileInfo.emissions,
    items: profileInfo.emissions.items.map((e) => h.setMetadata(e, false)),
  },
  likes: {
    ...profileInfo.likes,
    items: profileInfo.likes.items.map((e) => h.setMetadata(e, false)),
  },
  following: false,
  followsYou: false,
  live: false,
});

/**
 *
 * @param {Array} emissions - List of emissions
 * @param {Number} emissionID - ref Emissions.emissionID
 * @param {Object} newData - Keys/values of emission with emissionID to update
 * @returns Emissions array with the emission with emissionID updated
 */
h.replaceEmissions = (emissions, emissionID, newData) =>
  emissions.map((emission) => {
    if (emission.emissionID === emissionID)
      emission = {
        ...emission,
        ...newData,
      };
    if (emission.signalBoost && emission.signalBoost.emissionID === emissionID)
      emission.signalBoost = {
        ...emission.signalBoost,
        ...newData,
      };

    if (emission.replyEmission) {
      if (emission.replyEmission.emissionID === emissionID)
        emission.replyEmission = {
          ...emission.replyEmission,
          ...newData,
        };
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.emissionID === emissionID
      )
        emission.replyEmission.signalBoost = {
          ...emission.replyEmission.signalBoost,
          ...newData,
        };

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.emissionID === emissionID)
          emission.replyEmission.replyEmission = {
            ...emission.replyEmission.replyEmission,
            ...newData,
          };
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.emissionID ===
            emissionID
        )
          emission.replyEmission.replyEmission.signalBoost = {
            ...emission.replyEmission.replyEmission.signalBoost,
            ...newData,
          };
      }
    }
    return emission;
  });

h.getCurrentRoom = () => {
  if (typeof window === "undefined") return "";
  let path = window.location.pathname;
  if (path === "/") {
    return "f";
  } else if (window.location.pathname.includes("/tag/")) {
    return (
      window.location.pathname
        .split("/tag/")[1]
        .split("/")[0]
        .replace(/^[\W_]+/g, "")
        .toLowerCase() + "⚓⚓"
    );
  } else if (
    !(
      path.includes("/number") ||
      path.includes("/n/") ||
      path.includes("/set-password/") ||
      path.includes("/delete/") ||
      path.includes("/delete-cancel/") ||
      path.includes("/e/") ||
      path.includes("/verify/") ||
      path.includes("/cancel/")
    )
  ) {
    path = path.split("/")[1].split("/")[0];
    if (
      [
        "search",
        "info",
        "logs",
        "login",
        "forgot-password",
        "check-email",
        "awaiting-approval-email",
        "awaiting-approval",
        "received",
        "validate-email",
        "create-account",
        "messages",
        "contact",
        "reports",
        "notifications",
        "not-found",
        "null",
      ].indexOf(path) === -1
    ) {
      return path.replace(/^[\W_]+/g, "").toLowerCase() + "⚓";
    } else return "";
  } else return "";
};

/**
 *
 * @param {String} html - HTML string
 *
 * If an html string that is not properly parsed (class names wrong, etc), this function will fix it
 *
 * @returns HTML string that is properly parsed
 */
h.parseStrayTags = (html) => {
  if (html.includes("⚓")) {
    alert("Invalid characters detected");
    return "<p><br></p>";
  }
  html = html.replace(/[\u200B-\u200F\uFEFF]/g, "");
  let parsedHTML = parse(html);

  parsedHTML = Array.from(parsedHTML.getElementsByTagName("p"))
    .map((node) => {
      // Regular links
      Array.from(node.getElementsByTagName("a"))
        .filter((link) => ["#", "@"].indexOf(link.textContent[0]) === -1)
        .forEach(
          (link) =>
            (link.textContent = `⚓⚓⚓⚓${link.getAttribute("href")}⚓ ⚓⚓${
              link.textContent
            }⚓⚓ ⚓`)
        );
      let updatedHTML = node.textContent;
      updatedHTML = updatedHTML.split("⚓⚓⚓⚓");

      for (let u = 0; u < updatedHTML.length; u++) {
        const slice = updatedHTML[u];
        if (slice.includes("⚓ ⚓⚓")) {
          const href = slice.split("⚓ ⚓⚓")[0];
          const text = slice.split("⚓ ⚓⚓")[1].split("⚓⚓ ⚓")[0];
          updatedHTML[
            u
          ] = `<a class="text-blue text-decoration-none" href="${href}">${text}</a>${
            slice.split("⚓⚓ ⚓")[1]
          }`;
        }
      }
      updatedHTML = updatedHTML.join("");

      updatedHTML = updatedHTML.split("");
      // Hashtags/mentions
      updatedHTML.forEach((char, c) => {
        if (
          char === "#" &&
          updatedHTML[c + 1] &&
          ["@", "#", "\n", " "].indexOf(updatedHTML[c + 1]) === -1
        ) {
          updatedHTML[c] = "⚓⚓HASH⚓";
          let index = c + 1;
          let endFound = false;
          while (!endFound) {
            if (
              !updatedHTML[index + 1] ||
              ["@", "#", "\n", " "].indexOf(updatedHTML[index + 1]) > -1
            ) {
              updatedHTML[index] = updatedHTML[index] + "⚓HASH⚓⚓";
              endFound = true;
            }
            index++;
          }
        }
        if (
          char === "@" &&
          updatedHTML[c + 1] &&
          ["@", "#", "\n", " "].indexOf(updatedHTML[c + 1]) === -1
        ) {
          updatedHTML[c] = "⚓⚓MENTION⚓";
          let index = c + 1;
          let endFound = false;
          while (!endFound) {
            if (
              !updatedHTML[index + 1] ||
              ["@", "#", "\n", " "].indexOf(updatedHTML[index + 1]) > -1
            ) {
              updatedHTML[index] = updatedHTML[index] + "⚓MENTION⚓⚓";
              endFound = true;
            }
            index++;
          }
        }
      });

      updatedHTML = updatedHTML.join("");

      updatedHTML = updatedHTML.split("⚓⚓HASH⚓");
      for (let u = 0; u < updatedHTML.length; u++) {
        const slice = updatedHTML[u];
        if (slice.includes("⚓HASH⚓⚓")) {
          const text = slice.split("⚓HASH⚓⚓")[0];
          updatedHTML[
            u
          ] = `<a class="text-secondary" href="/tag/${text}">#${text}</a>${
            slice.split("⚓HASH⚓⚓")[1]
          }`;
        }
      }
      updatedHTML = updatedHTML.join("");

      updatedHTML = updatedHTML.split("⚓⚓MENTION⚓");
      for (let u = 0; u < updatedHTML.length; u++) {
        const slice = updatedHTML[u];
        if (slice.includes("⚓MENTION⚓⚓")) {
          const text = slice.split("⚓MENTION⚓⚓")[0];
          updatedHTML[
            u
          ] = `<a class="text-success" href="/${text}">@${text}</a>${
            slice.split("⚓MENTION⚓⚓")[1]
          }`;
        }
      }
      updatedHTML = updatedHTML.join("");
      return "<p>" + updatedHTML + "</p>";
    })
    .join("");

  return parsedHTML;
};

/**
 *
 * @param {String} html - HTML string
 * @returns HTML with only approved tags, classes, and attributes
 */
h.sanitizeHTML = (html) => {
  while (html.split("<p><br></p><p><br></p>").length > 1)
    html = html.split("<p><br></p><p><br></p>").join("<p><br></p>");
  html = h.parseStrayTags(html);
  while (html.split("  ").length > 1) html = html.split("  ").join(" ");
  while (html.split("\n\n").length > 1) html = html.split("\n\n").join("\n");
  while (html.split(" \n \n").length > 1)
    html = html.split(" \n \n").join("\n");
  const clean = sanitize(html, {
    allowedTags: ["a", "br", "p", "div", "span"],
    allowedAttributes: {
      a: ["href", "class"],
      br: [],
      p: [],
      div: [],
      span: [],
    },
    allowedClasses: {
      a: [
        "text-success",
        "text-secondary",
        "text-blue",
        "text-decoration-none",
        "ql-mention",
        "ql-hashtag",
      ],
      br: [],
      p: [],
      div: [],
      span: [],
    },
  });
  // console.log("sanitized", clean);
  return clean;
};

h.canSeePrivate = (userToSee, privateUser) => {
  /**
   * Elevated permissions
   * blocked
   * following
   */
  // console.log("canSeePrivate", userToSee, privateUser);
  if (h.checkChadmin(userToSee) || h.checkJanny(userToSee)) return true;
  if (userToSee.user_id === privateUser.user_id) return true;
  if (
    userToSee.blocked.includes(privateUser.user_id) ||
    privateUser.blocksMe ||
    (Array.isArray(privateUser.blocked) &&
      privateUser.blocked.includes(userToSee.user_id))
  )
    return false;
  if (
    userToSee.following.includes(privateUser.user_id) ||
    privateUser.followsMe ||
    (Array.isArray(privateUser.following) &&
      privateUser.following.includes(userToSee.user_id))
  )
    return true;
  return false;
};

/**
 *
 * @param {Array} emissions - List of emissions
 * @param {Number} emissionID - ref Emissions.emissionID
 * @returns List of emissions with all emission with emissionID flagged as voted
 */
h.setVoted = (emissions, emissionID) =>
  emissions.map((emission) => {
    if (emission.emissionID === emissionID)
      emission = {
        ...emission,
        pollData: {
          ...emission.pollData,
          voted: true,
        },
      };
    if (emission.signalBoost && emission.signalBoost.emissionID === emissionID)
      emission.signalBoost = {
        ...emission.signalBoost,
        pollData: {
          ...emission.signalBoost.pollData,
          voted: true,
        },
      };

    if (emission.replyEmission) {
      if (emission.replyEmission.emissionID === emissionID)
        emission.replyEmission = {
          ...emission.replyEmission,
          pollData: {
            ...emission.replyEmission.pollData,
            voted: true,
          },
        };
      if (
        emission.replyEmission.signalBoost &&
        emission.replyEmission.signalBoost.emissionID === emissionID
      )
        emission.replyEmission.signalBoost = {
          ...emission.replyEmission.signalBoost,
          pollData: {
            ...emission.replyEmission.signalBoost.pollData,
            voted: true,
          },
        };

      if (emission.replyEmission.replyEmission) {
        if (emission.replyEmission.replyEmission.emissionID === emissionID)
          emission.replyEmission.replyEmission = {
            ...emission.replyEmission.replyEmission,
            pollData: {
              ...emission.replyEmission.replyEmission.pollData,
              voted: true,
            },
          };
        if (
          emission.replyEmission.replyEmission.signalBoost &&
          emission.replyEmission.replyEmission.signalBoost.emissionID ===
            emissionID
        )
          emission.replyEmission.replyEmission.signalBoost = {
            ...emission.replyEmission.replyEmission.signalBoost,
            pollData: {
              ...emission.replyEmission.replyEmission.signalBoost.pollData,
              voted: true,
            },
          };
      }
    }
    return emission;
  });

/**
 *
 * @param {Array} emissions - List of emissions
 * @param {Object} newEmission - Emissions document
 * @param {Object} userInfo - Users document
 * @returns Emissions array with the emission with newEmission.emissionID replaced
 */
h.updateEmission = (emissions, newEmission, userInfo) => {
  return emissions
    .map((emission) => {
      if (emission.emissionID === newEmission.emissionID)
        emission = {
          ...emission,
          ...h.setMetadata(newEmission, userInfo),
        };
      if (
        emission.signalBoost &&
        emission.signalBoost.emissionID === newEmission.emissionID
      )
        emission.signalBoost = {
          ...emission.signalBoost,
          ...h.setMetadata(newEmission, userInfo),
        };

      if (emission.replyEmission) {
        if (emission.replyEmission.emissionID === newEmission.emissionID)
          emission.replyEmission = {
            ...emission.replyEmission,
            ...h.setMetadata(newEmission, userInfo),
          };
        if (
          emission.replyEmission.signalBoost &&
          emission.replyEmission.signalBoost.emissionID ===
            newEmission.emissionID
        )
          emission.replyEmission.signalBoost = {
            ...emission.replyEmission.signalBoost,
            ...h.setMetadata(newEmission, userInfo),
          };

        if (emission.replyEmission.replyEmission) {
          if (
            emission.replyEmission.replyEmission.emissionID ===
            newEmission.emissionID
          )
            emission.replyEmission.replyEmission = {
              ...emission.replyEmission.replyEmission,
              ...h.setMetadata(newEmission, userInfo),
            };
          if (
            emission.replyEmission.replyEmission.signalBoost &&
            emission.replyEmission.replyEmission.signalBoost.emissionID ===
              newEmission.emissionID
          )
            emission.replyEmission.replyEmission.signalBoost = {
              ...emission.replyEmission.replyEmission.signalBoost,
              ...h.setMetadata(newEmission, userInfo),
            };
        }
      }

      return emission;
    })
    .sort((a, b) => b.emissionID - a.emissionID);
};

/**
 *
 * @param {Number} value - Any number
 *
 * Shortens numbers by compiling them
 * i.e. 1000 bytes -> 1KB
 * 10000 bytes -> 10KB
 *
 *
 * @returns The compiled number
 */
h.compiledNumber = (value) => {
  let compiledNumber = value;
  if (!h.isNumeric(String(compiledNumber))) return compiledNumber;
  compiledNumber = String(compiledNumber);
  if (compiledNumber >= 1000000000 || compiledNumber <= -1000000000)
    return (
      compiledNumber.split("")[0] + "." + compiledNumber.split("")[1] + "B"
    );
  else if (compiledNumber >= 1000000 || compiledNumber <= -1000000)
    return (
      compiledNumber.split("")[0] + "." + compiledNumber.split("")[1] + "M"
    );
  else if (compiledNumber >= 1000 || compiledNumber <= -1000)
    return (
      compiledNumber.split("")[0] + "." + compiledNumber.split("")[1] + "K"
    );
  return String(value);
};

export default h;
