import React from "react";
import Reports from "./Reports";
import Ban from "./Ban";
import Remove from "./Remove";
import EditUser from "./EditUser";

class View extends React.Component {
  constructor(props) {
    super();
    this.view = props.view;
  }

  render() {
    switch (this.view) {
      case "reports":
        return (
          <Reports
            toggleShowModal={this.props.toggleShowModal}
            notify={this.props.notify}
            report={this.props.report}
            setView={this.props.setView}
            purgeReports={this.props.purgeReports}
            token={this.props.token}
            set_token={this.props.set_token}
          />
        );
      case "ban":
        return (
          <Ban
            toggleShowModal={this.props.toggleShowModal}
            notify={this.props.notify}
            report={this.props.report}
            setView={this.props.setView}
            purgeReports={this.props.purgeReports}
            token={this.props.token}
            set_token={this.props.set_token}
          />
        );
      case "remove":
        return (
          <Remove
            toggleShowModal={this.props.toggleShowModal}
            notify={this.props.notify}
            report={this.props.report}
            setView={this.props.setView}
            purgeReports={this.props.purgeReports}
            token={this.props.token}
            set_token={this.props.set_token}
          />
        );
      case "edit":
        return (
          <EditUser
            toggleShowModal={this.props.toggleShowModal}
            notify={this.props.notify}
            report={this.props.report}
            setView={this.props.setView}
            purgeReports={this.props.purgeReports}
            token={this.props.token}
            set_token={this.props.set_token}
          />
        );
      default:
        console.log("oob detail view", this.view);
        return <></>;
    }
  }
}

export default View;
