import env from "../env";
import React from "react";
import t from "../utilities/transitions";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import {
  route,
  set_search_term,
  search_change,
  set_token,
  set_cache,
} from "../redux/actions";
import LogoLoader from "../components/LogoLoader";
import {
  MDBContainer,
  MDBInputGroup,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import axios from "axios";
import Spinner from "../components/Spinner";
import FeedNav from "./search/FeedNav";
import { StaticRouter, Switch, Route } from "react-router-dom";
import SearchTab from "./search/SearchTab";
import { deletedUser } from "../utilities/constants";

class Search extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    const searchTerm =
      props.searchTerm || new URLSearchParams(props.location?.search).get("q");
    if (
      props.history?.location?.state?.currRoute.split("#")[0] === "/search" &&
      searchTerm &&
      props.history?.location?.state?.search === searchTerm
    ) {
      cachedData = props.cache.find(
        (c) => c.page === "search" && searchTerm === c.searchTerm
      );
      this.initialScrollTop = cachedData?.scrollTop;
    }
    this.state = cachedData
      ? cachedData.state
      : {
          /**
           * visited: Boolean - Whether the page has been visited, and a search has completed or there is no search term
           * searching: String - Search term
           * emissions: Array - List of emissions in the Emissions tab in the search results
           * users: Array - List of users in the Users tab in the search results
           * searched: Boolean - Whether a search has completed
           * userEndReached: Boolean - Whether the end of the results in the Users tab has been reached
           * emissionEndReached: Boolean - Whether the end of the results in the Emissions tab has been reached
           * totalEmissions: Number - Total number of emissions in the search results
           * totalUsers: Number - Total number of users in the search results
           * searchID: String - _id of SearchResults
           * tab: String - Tab that the user is on - "emissions" | "users"
           * loadingMore: Array - List of emissions with comment threads that are in the process of loading more replies in their respective threads
           * notificationIcon: JSX - Notification icon
           * notificationText: String - Notification text
           * polls: Array - List of polls that the user is voting in
           * pollsSubmitting: Array - List of polls that are in the process of having their votes submitted
           * loginModalShown: Boolean - Whether the login modal is shown
           * fileModalShown: Boolean - Whether the file modal is shown
           * signalBoostModalShown: Boolean - Whether the signal boost modal is shown
           * replyModalShown: Boolean - Whether the reply modal is shown
           * removeModalShown: Boolean - Whether the remove modal is shown
           * reportModalShown: Boolean - Whether the report modal is shown
           * restoreModalShown: Boolean - Whether the restore modal is shown
           * fileSelected: false | Object - The file currently selected
           * fileList: Array - List of files on the emission that the fileSelected belongs to
           * emissionSelected: false | Object - The emission currently selected
           * emissionSignalBoosting: false | Object - The emission currently signal boosting
           * emissionReplying: false | Object - The emission currently replying
           * emissionCopied: false | Number - EmissionID of Emission copied
           * emissionRemoving: false | Object - The emission currently removing
           * emissionReporting: false | Object - The emission currently reporting
           * emissionRestoring: false | Object - The emission currently restoring
           * socketConnected: Boolean - Whether the socket is currently connected
           * tempAction: false | Object - Temporary action data that must be executed upon logging in
           */
          visited: false,
          searching:
            props.searchTerm ||
            (typeof window !== "undefined" &&
              new URLSearchParams(props.location.search).get("q")),
          users: [],
          searched: false,
          userEndReached: true,
          emissionEndReached: true,
          totalEmissions: 0,
          totalUsers: 0,
          searchID: "",
          tab: "emissions",
          loadingMore: [],
          notificationIcon: <></>,
          notificationText: "",
          polls: [],
          pollsSubmitting: [],
          loginModalShown: false,
          fileModalShown: false,
          signalBoostModalShown: false,
          replyModalShown: false,
          removeModalShown: false,
          reportModalShown: false,
          restoreModalShown: false,
          fileSelected: false,
          fileList: [],
          emissionSelected: false,
          emissionSignalBoosting: false,
          emissionReplying: false,
          emissionCopied: false,
          emissionRemoving: false,
          emissionReporting: false,
          emissionRestoring: false,
          socketConnected: false,
          tempAction: false,
          socketStore: [],
          socketEvents: [],
          resultReset: false,
          paramAccepted: false,
        };
  }

  /**
   * Connect socket if main socket is connected
   * Execute the search if the user visits the page with a search term
   *
   */
  componentDidMount() {
    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
    const searchQuery = new URLSearchParams(this.props.location.search).get(
      "q"
    );
    if (this.state.searching && (!searchQuery || this.props.searchTerm))
      this.search();
    else if (searchQuery) {
      this.props.search_change(
        {
          target: {
            value: searchQuery,
          },
        },
        "searchPage"
      );
    } else
      this.setState((curr) => ({
        ...curr,
        visited: true,
      }));
  }

  /**
   * Execute search if user submits a new search term
   * Connect socket if main socket connects under any circumstances
   * If user logs in, reset poll data and emission metadata
   * If user logs in from login modal, execute temp action
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.searchText &&
      !this.state.paramAccepted &&
      this.props.history &&
      !this.props.searchTerm
    ) {
      this.props.set_search_term();
    }

    if (
      prevProps.searchTerm !== this.props.searchTerm &&
      (this.state.visited || !this.state.paramAccepted) &&
      this.props.history
    ) {
      this.search();
    }
  }

  /**
   * Clear search field if the user leaves the page
   */
  componentWillUnmount() {
    this.props.set_cache({
      page: "search",
      state: this.state,
      searchTerm: this.props.searchTerm,
      scrollTop: document.getElementById("root")?.scrollTop,
    });
  }

  pushSocketEvent = (event) =>
    this.setState((curr) => ({
      ...curr,
      socketStore: [...curr.socketStore, event],
    }));

  /**
   *
   * @param {String} tab - Tab - "emissions" | "users"
   *
   * Changes the tab to the one the user selects
   */
  selectTab = (e, tab) =>
    this.setState(
      (curr) => ({
        ...curr,
        tab: tab,
      }),
      () => {
        if (tab === "users")
          this.setState((curr) => ({
            ...curr,
            socketStore: [],
          }));
      }
    );

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () => "";
  // new Promise(async (resolve, reject) => {
  //   if (String(process.env.REACT_APP_DEV) === "true")
  //     return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
  //   if (this.props.captchaReady)
  //     window.grecaptcha.enterprise
  //       .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
  //       .then(resolve)
  //       .catch((err) => {
  //         console.log(err);
  //         alert("Human verification failed. Refresh the page and try again.");
  //         reject();
  //       });
  //   else
  //     setTimeout(async () => {
  //       const captchaKey = await this.getRecaptcha();
  //       resolve(captchaKey);
  //     }, 500);
  // });

  /**
   * Triggered when the user clicks the Search button
   * Executes the search
   * Sets new data into state
   */
  search = () =>
    this.setState(
      (curr) => ({
        ...curr,
        searching: true,
        paramAccepted: true,
      }),
      async () => {
        const captchaKey = await this.getRecaptcha();
        axios
          .post(
            process.env.REACT_APP_LAMBDA_SEARCH,
            {
              term: this.props.searchTerm,
              captchaKey: captchaKey,
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            let tab = this.state.tab;
            if (
              this.state.tab === "emissions" &&
              !res.data.emissions.length &&
              res.data.users.length
            )
              tab = "users";
            if (
              this.state.tab === "users" &&
              !res.data.users.length &&
              res.data.emissions.length
            )
              tab = "emissions";
            this.setState((curr) => ({
              ...curr,
              searching: false,
              searched: true,
              emissions: res.data.emissions,
              users: res.data.users,
              emissionEndReached: res.data.emissionEndReached,
              userEndReached: res.data.userEndReached,
              totalEmissions: res.data.totalEmissions,
              totalUsers: res.data.totalUsers,
              searchID: res.data.searchID,
              visited: true,
              tab: tab,
              resultReset: !this.state.resultReset,
            }));
          })
          .catch((err) => {
            console.log("search err", err);
            setTimeout(this.search, 1000);
          });
      }
    );

  /**
   *
   * @param {String} type - The tab that the user wishes to load more data
   *
   * Triggered when the user clicks the View More button at the end of the list of emissions or users on any tab
   */
  loadMore = (type, emissions, callback) =>
    this.setState(
      (curr) => ({
        ...curr,
        loadingMore: [...curr.loadingMore, type],
      }),
      () =>
        axios
          .post(
            process.env.REACT_APP_LAMBDA_SEARCH + "/more/" + type,
            {
              searchID: this.state.searchID,
              currentLength:
                type === "users" ? this.state.users.length : emissions.length,
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                [type]: [...curr[type], ...res.data[type]],
                [type
                  .split("")
                  .filter((char, c) => c !== type.split("").length - 1)
                  .join("") + "EndReached"]:
                  res.data[
                    type
                      .split("")
                      .filter((char, c) => c !== type.split("").length - 1)
                      .join("") + "EndReached"
                  ],
                loadingMore: this.state.loadingMore.filter((t) => t !== type),
              }),
              () => {
                if (type === "emissions")
                  callback({
                    emissions: res.data.emissions,
                  });
              }
            );
          })
          .catch((err) => {
            console.log("load more error", err);
            setTimeout(() => this.loadMore(type, emissions, callback), 1000);
          })
    );

  updateEmissions = (emissions) =>
    this.setState((curr) => ({
      ...curr,
      emissions: emissions,
    }));

  userEdit = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...user,
              ...userInfo,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  accountDeleted = (userID, socketEvent) =>
    this.setState((curr) => ({
      ...curr,
      users: this.state.users.map((user) => {
        if (user._id === userID)
          user = {
            ...user,
            ...deletedUser,
          };
        return user;
      }),
      socketStore: socketEvent
        ? [...curr.socketStore, socketEvent]
        : this.state.socketStore,
    }));

  ban = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  restoreUser = (userInfo, socketEvent) => {
    this.setState((curr) => ({
      ...curr,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...userInfo,
            score: user.score,
          };

        return user;
      }),
      socketStore: socketEvent
        ? [...curr.socketStore, socketEvent]
        : this.state.socketStore,
    }));
  };

  unblockOther = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  unprivate = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  blockOther = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id) user.blocked = true;

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  privateProfile = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.props.set_search_term();
    }
  };

  render() {
    return (
      <motion.div
        id="search-top"
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="py-4 h-100 page-container"
      >
        <MDBContainer>
          {this.state.searching && !this.state.visited ? (
            <motion.div
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
            >
              <h5 className="text-center display-6 mb-4 mt-5">Searching</h5>
              <LogoLoader />
            </motion.div>
          ) : (
            <motion.div
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
            >
              <MDBInputGroup
                textAfter={
                  <MDBBtn
                    onClick={
                      this.state.searching
                        ? () => {}
                        : this.props.set_search_term
                    }
                    disabled={this.state.searching}
                    color="success"
                    className={`${
                      (this.props.searchText &&
                        this.props.searchText !== this.props.searchTerm) ||
                      this.state.searching
                        ? ""
                        : "invis"
                    } h-100`}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      boxShadow: "none",
                    }}
                  >
                    {this.state.searching ? (
                      <Spinner size="sm" />
                    ) : (
                      <i className="fas fa-paper-plane"></i>
                    )}
                  </MDBBtn>
                }
                textBefore={
                  <i
                    id="search-icon"
                    style={{ transition: "0.2s" }}
                    className="fas fa-search"
                  ></i>
                }
                className="mt-4 w-75 mx-auto"
                id="search-group"
                size="lg"
              >
                <MDBInput
                  label="Search"
                  size="lg"
                  className="w-100"
                  key={this.props.searchPageSearchKey + "search"}
                  defaultValue={this.props.searchText}
                  onChange={(e) => this.props.search_change(e, "searchPage")}
                  onKeyPress={this.pressEnter}
                />
              </MDBInputGroup>
              <small className="mt-4 d-block mx-auto text-center">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">
                  {" "}
                  Privacy Policy
                </a>{" "}
                and
                <a href="https://policies.google.com/terms">
                  {" "}
                  Terms of Service
                </a>{" "}
                apply.
              </small>
              <hr></hr>
              {this.state.searched ? (
                <>
                  {this.state.totalEmissions || this.state.totalUsers ? (
                    <>
                      <motion.div
                        transition={t.transition}
                        exit={t.fade_out_scale_1}
                        animate={t.normalize}
                        initial={t.fade_out}
                        className="d-flex justify-content-between align-items-start"
                      >
                        <FeedNav
                          tab={this.state.tab}
                          selectTab={this.selectTab}
                        />
                        <div>
                          <h6 className="text-end">
                            {this.state.totalEmissions}{" "}
                            {this.state.totalEmissions === 1 ? (
                              <span className="text-capitalize text-blusteel">
                                {env.EMISSION_NAME}
                              </span>
                            ) : (
                              <span className="text-capitalize text-blusteel">
                                {env.EMISSION_PLURAL}
                              </span>
                            )}
                          </h6>
                          <h6 className="text-end">
                            {this.state.totalUsers}{" "}
                            <span className="text-blusteel">
                              User{this.state.totalUsers === 1 ? "" : "s"}
                            </span>
                          </h6>
                        </div>
                      </motion.div>
                      <StaticRouter location={this.state.tab}>
                        <AnimatePresence exitBeforeEnter>
                          <Switch key={this.state.tab}>
                            <Route exact path=":tab">
                              <SearchTab
                                tab={this.state.tab}
                                selectTab={this.selectTab}
                                loaded={this.state.loaded}
                                userInfo={this.props.userInfo}
                                route={this.props.route}
                                loadingMore={this.state.loadingMore}
                                seeMore={this.seeMore}
                                loadMore={this.loadMore}
                                userEndReached={this.state.userEndReached}
                                emissionEndReached={
                                  this.state.emissionEndReached
                                }
                                emissions={this.state.emissions}
                                users={this.state.users}
                                updateEmissions={this.updateEmissions}
                                userEdit={this.userEdit}
                                ban={this.ban}
                                accountDeleted={this.accountDeleted}
                                restoreUser={this.restoreUser}
                                privateProfile={this.privateProfile}
                                unprivate={this.unprivate}
                                blockOther={this.blockOther}
                                unblockOther={this.unblockOther}
                                pushSocketEvent={this.pushSocketEvent}
                                socketStore={this.state.socketStore}
                                key={this.state.resultReset}
                              />
                            </Route>
                          </Switch>
                        </AnimatePresence>
                      </StaticRouter>
                    </>
                  ) : (
                    <h5 className="mb-4 mt-5 text-center display-6">
                      No results found
                    </h5>
                  )}
                </>
              ) : (
                <></>
              )}
            </motion.div>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  set_search_term,
  search_change,
  set_token,
  set_cache,
})(Search);
