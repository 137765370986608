import React from "react";
import { motion } from "framer-motion";
import h from "../utilities/helpers";
import t from "../utilities/transitions";

const Count = ({ value, isTime, className }) => {
  const animatedDigit = (digit) => {
    if (h.isNumeric(digit)) {
      digit = Number(digit);
      if (Math.round(digit) % 2)
        return (
          <motion.section
            transition={t.transition}
            initial={t.fade_out}
            animate={t.normalize}
            exit={t.fade_out_scale_1}
            className="d-inline skip-ripple no-route"
          >
            {digit}
          </motion.section>
        );
    }

    return (
      <motion.div
        transition={t.transition}
        initial={t.fade_out}
        animate={t.normalize}
        exit={t.fade_out_scale_1}
        className="d-inline skip-ripple no-route"
      >
        {digit}
      </motion.div>
    );
  };

  value = Number(value);

  if (String(value) === "NaN") value = 0;
  value = Math.round(value);
  if (isTime) {
    if (value < 0) value = 0;
    const hours = Math.floor(value / 3600);
    let minutes = Math.floor((value - hours * 3600) / 60);
    let seconds = value - hours * 3600 - minutes * 60;

    minutes = String(minutes);
    seconds = String(seconds);
    if (hours && minutes.split("").length < 2) minutes = `0${minutes}`;
    if (seconds.split("").length < 2) seconds = `0${seconds}`;
    return (
      <span className={className}>
        {hours ? <>{String(hours).split("").map(animatedDigit)}:</> : <></>}
        {minutes.split("").map(animatedDigit)}:
        {seconds.split("").map(animatedDigit)}
      </span>
    );
  } else return h.compiledNumber(value).split("").map(animatedDigit);
};
export default Count;
