import env from "../../../env";
import React from "react";
import { connect } from "react-redux";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRipple,
} from "mdb-react-ui-kit";
import { set_click_pass, route, notify } from "../../../redux/actions";
import h from "../../../utilities/helpers";
import t from "../../../utilities/transitions";
import ChangeAvatarModal from "./profileInfo/ChangeAvatarModal";
import ChangeBackgroundModal from "./profileInfo/ChangeBackgroundModal";
import { motion } from "framer-motion";
import EditProfileModal from "./profileInfo/EditProfileModal";
import Url from "url-parse";
import Count from "../../../components/Count";

class ProfileInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * changeAvatarShown: Boolean - Whether the Change Avatar modal is shown
       * changeBackgroundShown: Boolean - Whether the Change Background modal is shown
       * editProfileShown: Boolean - Whether the Edit Profile modal is shown
       */
      changeAvatarShown: false,
      changeBackgroundShown: false,
      editProfileShown: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.clickPass?.action !== "edit-profile" &&
      this.props.clickPass?.action === "edit-profile"
    ) {
      this.toggleEditProfileModal();
      this.props.set_click_pass(false);
    }
  }

  toggleChangeAvatarModal = () => {
    this.setState((curr) => ({
      ...curr,
      changeAvatarShown: !this.state.changeAvatarShown,
    }));
  };

  setChangeAvatarModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      changeAvatarShown: option,
    }));
  };

  toggleChangeBackgroundModal = () => {
    this.setState((curr) => ({
      ...curr,
      changeBackgroundShown: !this.state.changeBackgroundShown,
    }));
  };

  setChangeBackgroundModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      changeBackgroundShown: option,
    }));
  };

  toggleEditProfileModal = () => {
    this.setState((curr) => ({
      ...curr,
      editProfileShown: !this.state.editProfileShown,
    }));
  };

  setEditProfileModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      editProfileShown: option,
    }));
  };

  changeAvatar = (e) => {
    e.stopPropagation();
    if (!this.props.userInfo.ban.banned) this.toggleChangeAvatarModal();
  };

  /**
   *
   * @returns The ban expiration data of a banned user
   */
  getBanExpiry = () => {
    if (this.props.userInfo.ban.duration === "permanent")
      return "Your ban is permanent";
    else {
      const expiry = new Date(this.props.userInfo.ban.timestamp);
      switch (this.props.userInfo.ban.duration) {
        case "day":
          expiry.setDate(expiry.getDate() + 1);
          break;
        case "week":
          expiry.setDate(expiry.getDate() + 7);
          break;
        case "month":
          expiry.setMonth(expiry.getMonth() + 1);
          break;
        default:
          console.log("ban is permanent");
      }
      return `Your ban will expire on ${h.makeDateHR(expiry)} at ${h.getTimeHR(
        expiry
      )}`;
    }
  };

  /**
   *
   * Special profiles have gold numbers.
   * The following User user_ids are special:
   * * User 1
   * * Any user over 10 with all the same numbers (i.e. 666)
   * * Any user over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the user is a special user
   */
  checkSpecialProfile = () => {
    if (this.props.userInfo.user_id === 1) return true;
    const split = String(this.props.userInfo.user_id).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the profile's bio
   * If the user clicked a link, route to the href
   */
  clickBio = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (href) {
        const url = new Url(href);
        if (url.hostname === window.location.hostname)
          this.props.route(url.pathname);
        else window.location = href;
      }
    }
  };

  render() {
    return (
      <MDBCard
        className={`cards-full-width mt-4 ${
          this.props.userInfo.ban.banned
            ? "bg-litepink"
            : this.props.userInfo.private
            ? "bg-liteteal"
            : ""
        }`}
      >
        <ChangeAvatarModal
          setShowModal={this.setChangeAvatarModal}
          toggleShowModal={this.toggleChangeAvatarModal}
          modalShown={this.state.changeAvatarShown}
        />
        <ChangeBackgroundModal
          setShowModal={this.setChangeBackgroundModal}
          toggleShowModal={this.toggleChangeBackgroundModal}
          modalShown={this.state.changeBackgroundShown}
        />
        <EditProfileModal
          googleReCaptchaProps={this.props.googleReCaptchaProps}
          setShowModal={this.setEditProfileModal}
          toggleShowModal={this.toggleEditProfileModal}
          modalShown={this.state.editProfileShown}
        />
        <MDBCardBody className="profile-cards">
          <div className="position-relative">
            <MDBRipple
              rippleColor="light"
              onClick={
                String(env.READ_ONLY) === "true" &&
                !h.checkChadmin(this.props.userInfo)
                  ? () =>
                      this.props.notify(
                        <i className="fas fa-times-circle me-2 text-danger"></i>,
                        "Instance is read-only"
                      )
                  : !this.props.userInfo.ban.banned
                  ? this.toggleChangeBackgroundModal
                  : () =>
                      this.props.notify(
                        <i className="fas fa-gavel me-2 text-danger"></i>,
                        "You are banned"
                      )
              }
              className="cursor-pointer w-100"
              style={{
                backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.userInfo.background.main}")`,
              }}
              id="background-image-profile"
            ></MDBRipple>
            <div id="avatar-border-profile">
              <MDBRipple
                rippleColor="primary"
                id="avatar-image-profile"
                onClick={
                  String(env.READ_ONLY) === "true" &&
                  !h.checkChadmin(this.props.userInfo)
                    ? () =>
                        this.props.notify(
                          <i className="fas fa-times-circle me-2 text-danger"></i>,
                          "Instance is read-only"
                        )
                    : !this.props.userInfo.ban.banned
                    ? this.changeAvatar
                    : () =>
                        this.props.notify(
                          <i className="fas fa-gavel me-2 text-danger"></i>,
                          "You are banned"
                        )
                }
                className="cursor-pointer fit-round"
                style={{
                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.props.userInfo.avatar.main}")`,
                }}
              ></MDBRipple>
            </div>
          </div>
          <MDBContainer fluid className="px-0 profile-info">
            <div className="row mx-0 align-items-start">
              <div className="col-12 col-xl-6 offset-0 offset-xl-3 px-0 d-flex justify-content-center">
                {this.props.userInfo.location ? (
                  <p className="text-blusteel text-break profile-fact">
                    <i className="fas fa-map-marker-alt me-2"></i>
                    {this.props.userInfo.location}
                  </p>
                ) : (
                  <></>
                )}
                {this.props.userInfo.website ? (
                  <a
                    className="ms-4 text-break profile-fact"
                    href={
                      this.props.userInfo.website.startsWith("http://") ||
                      this.props.userInfo.website.startsWith("https://")
                        ? this.props.userInfo.website
                        : `//${this.props.userInfo.website}`
                    }
                  >
                    <p>
                      <i className="fas fa-link me-2"></i>
                      {this.props.userInfo.website}
                    </p>
                  </a>
                ) : (
                  <></>
                )}
                <p className="text-blusteel ms-4 profile-fact">
                  <i className="far fa-calendar-alt me-2"></i>Joined{" "}
                  {h.getNiceDate(this.props.userInfo.creationDate)}
                </p>
              </div>
            </div>
          </MDBContainer>
          <div className="d-flex justify-content-between profile-profile-info">
            <div className="d-flex flex-column align-self-stretch min-w-20">
              <h5 className="mb-0">
                {h.getBadge(this.props.userInfo, "me-2 badge-h5")}
                {this.props.userInfo.displayName}
              </h5>
              <p className="text-blusteel mb-1">
                @{this.props.userInfo.username}
              </p>
              {String(env.READ_ONLY) === "true" &&
              !h.checkChadmin(this.props.userInfo) ? (
                <></>
              ) : (
                <>
                  {this.props.userInfo.ban.banned ? (
                    <></>
                  ) : (
                    <MDBRipple
                      onClick={this.toggleEditProfileModal}
                      className="cursor-pointer pe-1 py-1 rounded w-max-content"
                      rippleColor="primary"
                      id="edit-profile-button"
                    >
                      <h6 className="text-primary">
                        <i className="fas fa-edit me-2"></i>
                        Edit Profile Info
                      </h6>
                    </MDBRipple>
                  )}
                </>
              )}

              <div className="d-flex flex-column justify-content-end flex-grow-1">
                <div className="d-flex">
                  <p
                    title={
                      h.numberWithCommas(this.props.userInfo.followers) +
                      " " +
                      (this.props.userInfo.followers === 1
                        ? env.FOLLOW_NAME
                        : env.FOLLOW_PLURAL)
                    }
                    className="me-2 mb-0"
                  >
                    <span className="fw-bold">
                      <Count
                        value={this.props.userInfo.followers}
                        className="fw-bold"
                      />
                    </span>{" "}
                    {this.props.userInfo.followers === 1 ? (
                      <span className="text-capitalize text-blusteel">
                        {env.FOLLOW_NAME}
                      </span>
                    ) : (
                      <span className="text-capitalize text-blusteel">
                        {env.FOLLOW_PLURAL}
                      </span>
                    )}
                  </p>
                  <p
                    title={
                      h.numberWithCommas(this.props.userInfo.following.length) +
                      " " +
                      env.FOLLOW_CURRENT
                    }
                    className="mb-0"
                  >
                    <span className="fw-bold">
                      <Count
                        value={this.props.userInfo.following.length}
                        className="fw-bold"
                      />
                    </span>{" "}
                    <span className="text-blusteel">{env.FOLLOW_CURRENT}</span>
                  </p>
                </div>
              </div>
            </div>
            {this.props.userInfo.bio &&
            h.checkHTMLLength(this.props.userInfo.bio, true) ? (
              <div className="flex-grow-1 align-self-stretch bg-emphasis mt-4 mx-2 p-2 rounded-6 text-break min-w-50">
                <div
                  dangerouslySetInnerHTML={{ __html: this.props.userInfo.bio }}
                  onClick={this.clickBio}
                ></div>
              </div>
            ) : (
              <></>
            )}
            {this.props.profileInfo.stats ? (
              <div className="min-w-20">
                <h5
                  className={`mb-0 text-gb text-end mt-3 text-${
                    this.checkSpecialProfile() ? "gold" : "default"
                  }`}
                >
                  #{this.props.userInfo.user_id}
                </h5>
                <motion.div
                  className="p-2"
                  transition={t.transition}
                  exit={t.fade_out_minimize}
                  animate={t.normalize}
                  initial={t.fade_out_minimize}
                >
                  <div className="d-flex justify-content-between">
                    <i className="fas fa-chart-line fa-lg text-success d-block"></i>
                    <h5
                      title={
                        h.numberWithCommas(
                          Number(
                            this.props.profileInfo.emissions.totalNoReply
                          ) +
                            Number(
                              this.props.profileInfo.emissions.totalReplies
                            )
                        ) +
                        " " +
                        (Number(this.props.profileInfo.emissions.totalNoReply) +
                          Number(
                            this.props.profileInfo.emissions.totalReplies
                          ) ===
                        1
                          ? env.EMISSION_NAME
                          : env.EMISSION_PLURAL)
                      }
                      className="m-0"
                    >
                      <Count
                        value={
                          Number(
                            this.props.profileInfo.emissions.totalNoReply
                          ) +
                          Number(this.props.profileInfo.emissions.totalReplies)
                        }
                      />{" "}
                      {Number(this.props.profileInfo.emissions.totalNoReply) +
                        Number(
                          this.props.profileInfo.emissions.totalReplies
                        ) ===
                      1
                        ? env.EMISSION_NAME
                        : env.EMISSION_PLURAL}
                    </h5>
                  </div>
                  <hr className="my-1"></hr>
                  <div className="d-flex justify-content-between profile-stat-container">
                    <div className="me-2">
                      <p
                        title={
                          h.numberWithCommas(
                            this.props.profileInfo.likes.total
                          ) +
                          " Like" +
                          (this.props.profileInfo.likes.total === 1 ? "" : "s")
                        }
                        className="m-0 profile-likes"
                      >
                        <span className="fw-bold">
                          <Count
                            value={this.props.profileInfo.likes.total}
                            className="fw-bold"
                          />
                        </span>{" "}
                        Like
                        {this.props.profileInfo.likes.total === 1 ? "" : "s"}
                      </p>
                      <p
                        title={
                          h.numberWithCommas(
                            this.props.profileInfo.stats.likes
                          ) + " Liked by Others"
                        }
                        className="m-0 profile-likes"
                      >
                        <span className="fw-bold">
                          <Count
                            value={this.props.profileInfo.stats.likes}
                            className="fw-bold"
                          />
                        </span>{" "}
                        Liked by Others
                      </p>
                    </div>
                    <div className="ms-2">
                      <p
                        title={
                          h.numberWithCommas(
                            this.props.profileInfo.stats.signalBoosts
                          ) +
                          " " +
                          (this.props.profileInfo.stats.signalBoosts === 1
                            ? env.SIGNALBOOST_NAME
                            : env.SIGNALBOOST_PLURAL)
                        }
                        className="m-0 text-end"
                      >
                        <span className="fw-bold">
                          <Count
                            value={this.props.profileInfo.stats.signalBoosts}
                            className="fw-bold"
                          />
                        </span>{" "}
                        {this.props.profileInfo.stats.signalBoosts === 1 ? (
                          <span className="text-capitalize">
                            {env.SIGNALBOOST_NAME}
                          </span>
                        ) : (
                          <span className="text-capitalize">
                            {env.SIGNALBOOST_PLURAL}
                          </span>
                        )}
                      </p>
                      <p
                        title={
                          h.numberWithCommas(
                            this.props.profileInfo.stats.signalBoosted
                          ) +
                          " " +
                          env.SIGNALBOOST_PAST +
                          " by Others"
                        }
                        className="m-0 text-end"
                      >
                        <span className="fw-bold">
                          <Count
                            value={this.props.profileInfo.stats.signalBoosted}
                            className="fw-bold"
                          />
                        </span>{" "}
                        <span className="text-capitalize">
                          {env.SIGNALBOOST_PAST}
                        </span>{" "}
                        by Others
                      </p>
                    </div>
                  </div>
                </motion.div>
              </div>
            ) : (
              <div className="min-w-20">
                <h5 className="mb-0 text-gb text-end mt-3">
                  #{this.props.userInfo.user_id}
                </h5>
                <section className="p-2 invis">
                  <div className="d-flex justify-content-between profile-stat-container">
                    <i className="fas fa-chart-line fa-lg text-success d-block"></i>
                    <h5 className="m-0">
                      0{" "}
                      <span className="text-capitalize">
                        {env.EMISSION_PLURAL}
                      </span>
                    </h5>
                  </div>
                  <hr className="my-1"></hr>
                  <div className="d-flex justify-content-between">
                    <div className="me-2">
                      <p className="m-0 profile-likes">
                        <span className="fw-bold">0</span> Likes
                      </p>
                      <p className="m-0 profile-likes">
                        <span className="fw-bold">0</span> Liked by Others
                      </p>
                    </div>
                    <div className="ms-2">
                      <p className="m-0 text-end">
                        <span className="fw-bold">0</span>{" "}
                        <span className="text-capitalize">
                          {env.SIGNALBOOST_PLURAL}
                        </span>
                      </p>
                      <p className="m-0 text-end">
                        <span className="fw-bold">0</span>{" "}
                        <span className="text-capitalize">
                          {env.SIGNALBOOST_PAST}
                        </span>{" "}
                        by Others
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            )}
          </div>
          {this.props.userInfo.ban.banned ? (
            <>
              <hr></hr>
              <h5 className="text-center display-6">You are banned</h5>
              <div className="bg-emphasis p-2 rounded-6">
                <h5>
                  Ban reason:{" "}
                  <span className="text-transform-capitalize">
                    {this.props.userInfo.ban.reason}
                  </span>
                </h5>
                <p>{this.props.userInfo.ban.details}</p>
                <p className="text-center">{this.getBanExpiry()}</p>
              </div>
            </>
          ) : (
            <>
              {this.props.userInfo.private ? (
                <>
                  <hr></hr>
                  <h5 className="text-center display-6">
                    Your profile is private
                  </h5>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </MDBCardBody>
      </MDBCard>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { route, set_click_pass, notify })(
  ProfileInfo
);
