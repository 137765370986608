import env from "../../env";
import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBProgressBar,
  MDBBtn,
  MDBContainer,
} from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import h from "../../utilities/helpers";
import { route } from "../../redux/actions";
import { connect } from "react-redux";
import Url from "url-parse";
import PollExpiration from "../../components/PollExpiration";

class SampleEmission extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * showRemoveDetails: Boolean - Whether the MDBCollapse showing the emission removal details is open
       */
      showRemoveDetails: false,
      pollReset: false,
      pollCheck: false,
    };
  }

  componentDidMount() {
    this.setState((curr) => ({
      ...curr,
      pollCheck: setInterval(this.pollCheck, 1000),
    }));
  }

  componentWillUnmount() {
    clearInterval(this.state.pollCheck);
  }

  pollCheck = () => {
    if (
      !this.state.pollReset &&
      this.props.emission.pollData &&
      this.props.emission.pollData.expirationDate &&
      new Date().getTime() >
        new Date(this.props.emission.pollData.expirationDate)
    )
      this.setState((curr) => ({
        ...curr,
        pollReset: true,
      }));
  };

  /**
   *
   * @param {Click Event} e
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks an emission id
   *
   * If the emission is already on the page, scroll to it
   * Else, route to /e/emissionID
   */
  scrollToEmission = (e, emissionID) => {
    e.preventDefault();
    const emission = document.getElementById(`emission-${emissionID}`);
    if (emission) emission.scrollIntoView();
    else this.props.route(`/e/${emissionID}`);
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickEmissionBody = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (["SPAN", "H5"].indexOf(e.target.tagName) > -1)
      element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (!href) {
        console.log("no link found", element, e.target);
        return;
      }
      const url = new Url(href);
      if (url?.hostname === window.location.hostname)
        this.props.route(url.pathname);
      else {
        console.log("native route SampleEmission");
        window.location = href;
      }
    } else if (this.props.barebones)
      this.props.route("/e/" + this.props.emission.emissionID);
  };

  /**
   * Toggles the display of removal details
   */
  toggleRemoveDetails = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((curr) => ({
      ...curr,
      showRemoveDetails: !this.state.showRemoveDetails,
    }));
  };

  /**
   * @param {Click Event} e
   *
   * Triggered when the user clicks a profile
   *
   * If the user is already on that profile page, scroll to the top of the feed
   * Else route to that profile page
   */
  clickProfile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.toggleModal) this.props.toggleModal();
    if (
      window.location.pathname.split("/")[1].split("?")[0].toLowerCase() ===
      this.props.emission.username.toLowerCase()
    ) {
      document.getElementById("feed-top").scrollIntoView();
    } else {
      this.props.route(`/${this.props.emission.username}`);
    }
  };

  /**
   * Returns a card background color based on the state of the emission
   *
   * If highlighted, light green
   * If author is blocked or emission is removed, light pink
   * If author blocks the user, light purple
   * If author has privated their account, light teal
   *
   * @returns A CSS background class
   */
  getCardBackground = () => {
    if (this.props.emission.isBlocked) return "bg-litepurple";
    else if (this.props.emission.remove.removed) return "bg-litepink";
    else if (this.props.emission.blocksMe) return "bg-litepurple";
    else if (this.props.emission.private) return "bg-liteteal";
    else return "";
  };

  /**
   *
   * Special emissions have gold numbers.
   * The following Emission emissionIDs are special:
   * * Emission 1
   * * Any emission over 10 with all the same numbers (i.e. 666)
   * * Any emission over 10 where all numbers except for the first are zeros (i.e. 5000)
   *
   *
   * @returns Boolean - Whether the emission is a special emission
   */
  checkSpecialEmission = () => {
    if (this.props.emission.emissionID === 1) return true;
    const split = String(this.props.emission.emissionID).split("");
    if (split.length === 1) return false;
    if (split.every((c) => c === split[0])) return true;
    if (split.length < 3) return false;
    let special = true;
    split.forEach((char, s) => {
      if (s && Number(char)) special = false;
    });
    return special;
  };

  getFileThumbnail = (file) => {
    switch (file.type) {
      case "audio":
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route cursor-pointer skip-ripple">
            <i className="fas fa-volume-up fa-3x d-block no-route skip-ripple"></i>
          </div>
        );
      case "image":
      case "video":
        return (
          <div
            className="fit-images no-route cursor-pointer rounded-3 skip-ripple"
            style={{
              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${file.thumbnail}")`,
            }}
          ></div>
        );
      default:
        return (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center no-route skip-ripple cursor-pointer">
            <h6 className="file-labels-generic no-route skip-ripple">
              {file.main.split(".")[file.main.split(".").length - 1]}
            </h6>
          </div>
        );
    }
  };

  render() {
    if (!this.props.emission) return <></>;
    return (
      <MDBCard
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (this.props.barebones) this.clickEmissionBody(e);
        }}
        className={`shadow ${
          this.props.barebones ? "scale-minor-hover-shrink e-barebones" : ""
        } transition-25 ${this.getCardBackground()} `}
      >
        <MDBCardBody>
          {this.props.signalBoosted ? (
            <>
              <h5 className="text-blusteel">
                <i className="fas fa-retweet me-2 text-danger"></i>
                <span className="text-capitalize">{env.SIGNALBOOST_PAST} </span>
                <span className="text-capitalize">{env.EMISSION_NAME}</span>
              </h5>
              <hr></hr>
            </>
          ) : (
            <></>
          )}
          <div className="d-flex">
            <div
              style={{ width: 0 }}
              className="d-flex emission-headers flex-grow-1"
            >
              <Link
                onClick={this.clickProfile}
                className="h-max-content w-max-content emission-avatars"
                to={`/${this.props.emission.username}`}
                id={"avatar-" + this.rippleID}
              >
                <div
                  className={`d-flex justify-content-center align-items-center square-${
                    this.props.barebones ? "3" : "4"
                  } cursor-pointer`}
                >
                  <div
                    className="fit-images rounded-circle fit-round"
                    style={{
                      backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${this.props.emission.avatar.thumbnail}")`,
                    }}
                  ></div>
                </div>
              </Link>
              <div style={{ width: 0 }} className="flex-grow-1 px-2">
                <div className="d-flex emission-name-info align-items-center">
                  <Link
                    onClick={this.clickProfile}
                    className="max-w-max-content d-block emission-display-names"
                    to={`/${this.props.emission.username}`}
                    id={"dn-" + this.rippleID}
                  >
                    <h5 className="mb-0 text-default mx-0 text-break">
                      {h.getBadge(this.props.emission, "me-2 badge-h5")}
                      {this.props.emission.displayName}
                    </h5>
                  </Link>
                  {!this.props.barebones && this.props.emission.signalBoost && (
                    <small className="text-blusteel">
                      <Link
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.scrollToEmission(e, this.props.emission.replyID);
                        }}
                        className="text-blusteel no-route cursor-pointer force-ripple sb"
                        to={`/e/${this.props.emission.signalBoost.emissionID}`}
                        id={"sb-" + this.rippleID}
                      >
                        {this.props.emission.html &&
                        this.props.emission.html !== "<p><br></p>" ? (
                          <>
                            <i className="fas fa-quote-left me-2 ms-4"></i>
                            Quote Replying to #
                            <span className="text-gb">
                              {this.props.emission.signalBoost.emissionID}
                            </span>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-retweet me-2 ms-4"></i>
                            <span className="text-capitalize">
                              {env.REACT_APP_SIGNALBOOST_CURRENT}{" "}
                            </span>
                            #
                            <span className="text-gb">
                              {this.props.emission.signalBoost.emissionID}
                            </span>
                          </>
                        )}
                      </Link>
                    </small>
                  )}
                  {!this.props.barebones && this.props.emission.replyID && (
                    <small>
                      <Link
                        to={`/e/${this.props.emission.replyID}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.scrollToEmission(e, this.props.emission.replyID);
                        }}
                        className="text-blusteel reps"
                        id={"rep-" + this.rippleID}
                      >
                        <i className="fas fa-reply me-2 ms-4"></i>
                        Replying to #
                        <span className="text-gb">
                          {this.props.emission.replyID}
                        </span>
                      </Link>
                    </small>
                  )}
                </div>
                <Link
                  onClick={this.clickProfile}
                  className="max-w-max-content d-block ats"
                  to={`/${this.props.emission.username}`}
                  id={"at-" + this.rippleID}
                >
                  <p className="text-blusteel mx-0 text-break">
                    @{this.props.emission.username}
                  </p>
                </Link>
                {h.getMediaFromHtml(this.props.emission.html)}
                <div
                  className="emission-text-regular e-text-barebones"
                  dangerouslySetInnerHTML={{ __html: this.props.emission.html }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (!this.props.barebones) this.clickEmissionBody(e);
                  }}
                ></div>
              </div>
            </div>
            <div className="max-w-33">
              <h5 className={`text-end mb-0 d-block ms-auto px-2 text-nowrap`}>
                <Link
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.props.route(`/e/${this.props.emission.emissionID}`);
                  }}
                  to={`/e/${this.props.emission.emissionID}`}
                >
                  <span
                    className={`text-gb ${
                      this.props.barebones ? "fs-6" : ""
                    } text-${this.checkSpecialEmission() ? "gold" : "default"}`}
                  >
                    #{this.props.emission.emissionID}
                  </span>
                </Link>
              </h5>
              {this.props.emission.remove.removed && (
                <>
                  {this.props.emission.remove.user.userID ===
                  this.props.userInfo._id ? (
                    <>
                      {this.props.emission.remove.details ? (
                        <>
                          {h.checkJanny(this.props.userInfo) ? (
                            <MDBBtn
                              rippleColor="danger"
                              onClick={this.toggleRemoveDetails}
                              color="link"
                              className="text-danger p-1 text-end ms-auto text-break"
                            >
                              You removed this {env.EMISSION_NAME}
                              {this.props.emission.remove.reason
                                ? ` (${this.props.emission.remove.reason})`
                                : ""}
                            </MDBBtn>
                          ) : (
                            <p className="text-danger ms-auto mb-0 text-btn pe-2 text-end text-break">
                              You removed this {env.EMISSION_NAME}
                              {this.props.emission.remove.reason
                                ? ` (${this.props.emission.remove.reason})`
                                : ""}
                            </p>
                          )}
                        </>
                      ) : (
                        <p className="text-danger ms-auto mb-0 text-btn pe-2 text-end text-break">
                          You removed this {env.EMISSION_NAME}
                          {this.props.emission.remove.reason
                            ? ` (${this.props.emission.remove.reason})`
                            : ""}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {this.props.emission.remove.details ? (
                        <>
                          {h.checkJanny(this.props.userInfo) &&
                          !this.props.barebones ? (
                            <MDBBtn
                              rippleColor="danger"
                              onClick={this.toggleRemoveDetails}
                              color="link"
                              className="text-danger p-1 text-end"
                            >
                              {env.EMISSION_NAME} removed
                              {this.props.emission.remove.reason
                                ? `: ${this.props.emission.remove.reason}`
                                : ""}
                            </MDBBtn>
                          ) : (
                            <p className="text-danger ms-auto mb-0 text-btn pe-2 text-end text-break">
                              {env.EMISSION_NAME} removed
                              {this.props.emission.remove.reason
                                ? `: ${this.props.emission.remove.reason}`
                                : ` by @${this.props.emission.username}`}
                            </p>
                          )}
                        </>
                      ) : (
                        <p className="text-danger ms-auto mb-0 text-btn pe-2 text-end text-break">
                          {env.EMISSION_NAME} removed
                          {this.props.emission.remove.reason
                            ? `: ${this.props.emission.remove.reason}`
                            : ` by @${this.props.emission.username}`}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
              {h.checkJanny(this.props.userInfo) && (
                <Collapse in={this.state.showRemoveDetails}>
                  <div className="bg-emphasis p-2 text-break">
                    {this.props.emission.remove.details}
                  </div>
                </Collapse>
              )}
              <p className="mb-0 text-end d-block ms-auto px-2 text-nowrap">
                {h.makeDateHR(this.props.emission.timestamp)}
              </p>
              <p className="mb-0 text-blusteel text-end d-block ms-auto px-2 text-nowrap">
                {h.getTimeHR(this.props.emission.timestamp)}
              </p>
            </div>
          </div>
          <div
            className="emission-text-mobile e-text-barebones"
            dangerouslySetInnerHTML={{ __html: this.props.emission.html }}
            onClick={this.clickEmissionBody}
          ></div>
          {this.props.emission.signalBoost && (
            <SampleEmission
              {...this.props}
              signalBoosted={true}
              emission={this.props.emission.signalBoost}
              barebones={this.props.barebones}
            />
          )}
          {!this.props.barebones && this.props.emission.pollData && (
            <React.Fragment key={this.state.pollReset}>
              <hr />
              <div className="bg-emphasis p-3 rounded-6">
                <h5 className="text-blusteel">
                  <i className="fas fa-poll me-2 text-secondary"></i>
                  Poll
                </h5>
                <hr />
                <h5 className="text-center">
                  {this.props.emission.pollData.question}
                </h5>
                {this.props.emission.pollData.voted ||
                (String(env.READ_ONLY) === "true" &&
                  !h.checkChadmin(this.props.userInfo)) ||
                (this.props.emission.pollData.expirationDate &&
                  new Date().getTime() >
                    new Date(
                      this.props.emission.pollData.expirationDate
                    ).getTime()) ? (
                  <>
                    <p
                      className="m-0"
                      title={`${h.numberWithCommas(
                        this.props.emission.pollData.options.reduce(
                          (prev, curr) => prev + curr.votes,
                          0
                        )
                      )} Vote${
                        this.props.emission.pollData.options.reduce(
                          (prev, curr) => prev + curr.votes,
                          0
                        ) === 1
                          ? ""
                          : "s"
                      }`}
                    >
                      {h.compiledNumber(
                        this.props.emission.pollData.options.reduce(
                          (prev, curr) => prev + curr.votes,
                          0
                        )
                      )}{" "}
                      Vote
                      {this.props.emission.pollData.options.reduce(
                        (prev, curr) => prev + curr.votes,
                        0
                      ) === 1
                        ? ""
                        : "s"}
                    </p>
                    <PollExpiration pollData={this.props.emission.pollData} />
                    {this.props.emission.pollData.options.map((option) => {
                      const totalVotes =
                        this.props.emission.pollData.options.reduce(
                          (prev, curr) => prev + curr.votes,
                          0
                        );
                      const width = (option.votes / totalVotes) * 100;
                      const userVotes =
                        this.props.emission.pollData.voters.find(
                          (voter) => voter.userID === this.props.userInfo._id
                        );
                      const voted = userVotes
                        ? userVotes.votes?.indexOf(option.id) > -1
                        : false;
                      return (
                        <>
                          <div
                            key={option.id}
                            className="mt-4 d-flex justify-content-between"
                          >
                            <label
                              title={`${h.numberWithCommas(option.votes)} vote
                              ${option.votes === 1 ? "" : "s"}`}
                              htmlFor={`vote-option-${option.id}`}
                            >
                              {option.option} ({h.compiledNumber(option.votes)}{" "}
                              vote
                              {option.votes === 1 ? "" : "s"})
                            </label>
                            {voted && (
                              <p className="m-0 text-success">
                                You
                                <i className="fas fa-check-circle ms-2" />
                              </p>
                            )}
                          </div>
                          <MDBProgress
                            className="mt-1"
                            id={`vote-option-${option.id}`}
                          >
                            <MDBProgressBar
                              bgColor={voted ? "success" : "primary"}
                              width={width}
                              valuemin={0}
                              valuemax={totalVotes}
                            ></MDBProgressBar>
                          </MDBProgress>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <p className="m-0">
                      Votes Allowed: {this.props.emission.pollData.votesAllowed}
                    </p>
                    <PollExpiration pollData={this.props.emission.pollData} />
                    <MDBListGroup>
                      {this.props.emission.pollData.options.map((option) => (
                        <MDBListGroupItem key={option.id}>
                          {option.option}
                        </MDBListGroupItem>
                      ))}
                    </MDBListGroup>
                  </>
                )}
              </div>
            </React.Fragment>
          )}
          {this.props.emission.files.length && (
            <>
              {this.props.barebones ? (
                <>
                  <hr />
                  <p className="m-0 text-btn">
                    <i className="fas fa-photo-video me-2 text-success"></i>
                    Media ({this.props.emission.files.length})
                  </p>
                </>
              ) : (
                <>
                  <hr />
                  <div className="bg-emphasis p-3 rounded-6">
                    <h5 className="text-blusteel">
                      <i className="fas fa-photo-video me-2 text-success"></i>
                      Media
                    </h5>
                    <hr />
                    <MDBContainer fluid>
                      <div
                        key={this.props.emission.files.length}
                        className="row"
                      >
                        {this.props.emission.files.map((file, index) => (
                          <div
                            key={index}
                            className="col-12-400 col-6 col-md-4 col-lg-3 col-xxl-2 rounded-2 mt-2 px-1 d-flex justify-content-center rounded-3 bg-file"
                          >
                            <div className="square-10 p-2">
                              {this.getFileThumbnail(file)}
                            </div>
                          </div>
                        ))}
                      </div>
                    </MDBContainer>
                  </div>
                </>
              )}
            </>
          )}
        </MDBCardBody>
      </MDBCard>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route })(SampleEmission);
