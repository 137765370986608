import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class UserInteractionModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>{this.props.description}</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <MDBListGroup light>
                    {this.props.users.map((user) => (
                      <Link
                        className="w-100"
                        key={user._id}
                        to={user.link}
                        onClick={(e) => this.props.route(e, user.link)}
                      >
                        <MDBRipple rippleColor="primary" className="w-100">
                          <MDBListGroupItem
                            className="d-flex align-items-center w-100 p-2"
                            action
                          >
                            <div className="chat-avatars rounded-circle">
                              <div
                                className="fit-images fit-round"
                                style={{
                                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${user.avatar}")`,
                                  borderRadius: "50%",
                                }}
                              ></div>
                            </div>
                            <h5 className="mb-0 ms-2">@{user.name}</h5>
                          </MDBListGroupItem>
                        </MDBRipple>
                      </Link>
                    ))}
                  </MDBListGroup>
                </MDBModalBody>
                <MDBModalFooter>
                  <MDBBtn
                    className="bg-gray"
                    color="dark"
                    onClick={this.props.toggleShowModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, {})(UserInteractionModal);
