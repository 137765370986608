import React from "react";
import LogoLoader from "../components/LogoLoader";
import { MDBContainer } from "mdb-react-ui-kit";
import axios from "axios";
import { motion } from "framer-motion";
import t from "../utilities/transitions";
import { connect } from "react-redux";
import { route, set_token } from "../redux/actions";
import { withRouter } from "react-router-dom";

class Number extends React.Component {
  constructor(props) {
    super();
    this.number = props.match.params.number;
  }

  componentDidMount() {
    this.load();
  }

  /**
   * Fetches the username from the user_id
   * Routes to that user profile
   */
  load = () =>
    axios
      .get(process.env.REACT_APP_LAMBDA_USERS + "/number/" + this.number, {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => {
        this.props.set_token(res.data.token);
        this.props.history.push(`/${res.data.user.username}`);
      })
      .catch((err) => {
        if (err.response?.status === 404) this.props.route("/not-found");
        else setTimeout(this.load, 1000);
      });

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 h-100 page-container"
      >
        <MDBContainer>
          <h5 className="text-center display-6 mb-4 mt-5">
            User #{this.number}
          </h5>
          <LogoLoader />
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route, set_token })(
  withRouter(Number)
);
