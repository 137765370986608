const settings = document.getElementById("jizzer-env")?.textContent;

const imageExtensions =
  "image/png image/jpeg image/jpg image/gif image/bmp image/webp image/svg+xml ";
const audioExtensions =
  "audio/aac audio/mpeg audio/ogg audio/wav audio/wave audio/x-wav ";
const videoExtensions = "video/mp4 video/webm ";

const getExtensionString = (preferences) => {
  let string = "";
  if (preferences.IMAGES_ALLOWED) string += imageExtensions;
  if (preferences.AUDIO_ALLOWED) string += audioExtensions;
  if (preferences.VIDEO_ALLOWED) string += videoExtensions;

  return string.trim();
};

let env = {};

if (process.env.REACT_APP_DEV) {
  env = {
    HOST: "localhost:3001",
    CAPTCHA_KEY: "6Leu_JYnAAAAANDZq0yG5L_AKYWehCZC1nm70wWw",
    BUCKET_HOST: "https://f.filepimps.com",
    INSTANCE_ID: "cef73b83-19e7-4156-84bd-bd2c4e3cb4f6",
    NAME: "Filepimps",
    EMAIL_VERIFICATION_REQUIRED: "false",
    REQUIRE_APPROVAL: "false",
    ALL_FILES_ALLOWED: "true",
    IMAGES_ALLOWED: "true",
    AUDIO_ALLOWED: "true",
    VIDEO_ALLOWED: "true",
    MAX_INDIVIDUAL_FILE_SIZE: Math.round(Number(2000) * 1024 * 1024),
    MAX_EMISSION_CHARS: "300",
    MAX_FILE_COUNT: "32",
    MAX_TOTAL_FILE_SIZE: Math.round(Number(2000) * 1024 * 1024),
    STREAMING_ENABLED: "true",
    STREAMING_STORAGE_ENABLED: "true",
    STREAMING_VERIFICATION_REQUIRED: "false",
    EMISSION_NAME: "Post",
    EMISSION_PLURAL: "Posts",
    SIGNALBOOST_NAME: "Signalboost",
    SIGNALBOOST_PLURAL: "Signalboosts",
    SIGNALBOOST_PAST: "Signalboosted",
    SIGNALBOOST_VERB: "Signalboost",
    SIGNALBOOST_CURRENT: "Signalboosting",
    FOLLOW_NAME: "Follower",
    FOLLOW_PLURAL: "Followers",
    FOLLOW_VERB: "Follow",
    FOLLOW_CURRENT: "Following",
    FOLLOW_PAST: "Followed",
    UNFOLLOW_VERB: "Unfollow",
    UNFOLLOW_CURRENT: "Unfollowing",
    ADMIN_NAME: "Admin",
    ADMIN_PLURAL: "Admins",
    MOD_NAME: "Mod",
    MOD_PLURAL: "Mods",
    CHILD_NAME: "Child",
    CHILD_PLURAL: "Children",
    VIRGIL_CHAD_ALLOWED: "true",
    UPLOADS_LOCKED: "false",
    VIRGIL_CHAD_LOCKED: "false",
    READ_ONLY: "false",
    COLOR_PRIMARY: "#1266f1",
    COLOR_SECONDARY: "#b23cfd",
    COLOR_SUCCESS: "#00b74a",
    COLOR_WARNING: "#ffa900",
    COLOR_DANGER: "#f93154",
    COLOR_INFO: "#39c0ed",
    COLOR_LIGHT: "#f9f9f9",
    COLOR_DARK: "#262626",
    COLOR_PRIMARY_DARK: "#1266f1",
    COLOR_SECONDARY_DARK: "#b23cfd",
    COLOR_SUCCESS_DARK: "#00b74a",
    COLOR_WARNING_DARK: "#ffa900",
    COLOR_DANGER_DARK: "#f93154",
    COLOR_INFO_DARK: "#39c0ed",
    COLOR_LIGHT_DARK: "#f9f9f9",
    COLOR_DARK_DARK: "#262626",
  };
  env.ALLOWED_EXTENSIONS = getExtensionString(env);
} else {
  try {
    if (settings) {
      env = JSON.parse(settings);
      env.ALLOWED_EXTENSIONS = getExtensionString(env);
    } else
      Object.keys(process.env)
        .filter((key) => key.includes("REACT_APP_"))
        .forEach(
          (key) => (env[key.replace("REACT_APP_", "")] = process.env[key])
        );
  } catch (err) {
    console.log(
      "An error occurred while loading the environment",
      err,
      settings
    );
  }
}

// console.log("env", env);

export default env;
