import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import SampleEmission from "../../components/emission/SampleEmission";
import SampleProfile from "../../components/SampleProfile";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import View from "./detailModal/View";
import Approval from "./detailModal/Approval";
import { connect } from "react-redux";

class DetailModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * view: String - The current view
       */
      view: "reports",
    };
  }

  /**
   * When the user resizes the page, reset the modal body height
   */
  componentDidMount() {
    window.addEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * When modal is displayed, set body height
   * When user selects a report, navigate to the Reports view
   *
   */
  componentDidUpdate(prevProps) {
    if (prevProps.modalShown !== this.props.modalShown) {
      this.setState(
        (curr) => ({
          ...curr,
          view: "reports",
        }),
        () => setTimeout(this.setModalBodyHeight, 333)
      );
    }
    if (prevProps.report && !this.props.report)
      this.setState(
        (curr) => ({
          ...curr,
          view: "reports",
        }),
        this.props.toggleShowModal
      );
  }

  /**
   * remove event listeners
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.setModalBodyHeight);
  }

  setView = (view) =>
    this.setState(
      (curr) => ({
        ...curr,
        view: view,
      }),
      () => setTimeout(this.setModalBodyHeight, 333)
    );

  /**
   * Set the body height to that of its immediate child
   * Causes the body to smoothly grow or shrink instead of pop
   */
  setModalBodyHeight = () => {
    const body = document.getElementById("detail-modal-body");
    if (body) {
      if (
        this.props.screenDimensions.width >
        this.props.screenDimensions.modalBreak
      ) {
        body.style.height = `${
          document.getElementById("detail-modal-body-child").clientHeight
        }px`;
      } else {
        body.style.flexGrow = "1";
        body.style.height = `0`;
      }
    }
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={this.props.toggleShowModal}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              {this.props.report ? (
                <MDBModalContent>
                  <MDBModalHeader>
                    <MDBModalTitle>
                      {this.props.report.type === "emission" ? (
                        <h5 className="m-0">
                          <i className="fas fa-wifi text-success me-2" />
                          Reports for{" "}
                          <span className="text-capitalize">
                            {env.EMISSION_NAME}
                          </span>{" "}
                          <span className="text-gb">
                            #{this.props.report.emissionID}
                          </span>
                        </h5>
                      ) : (
                        <>
                          {this.props.report.type === "approval" ? (
                            <h5 className="m-0">
                              <i className="fas fa-user-plus text-secondary me-2" />
                              Approving @{this.props.report.userInfo.username}
                            </h5>
                          ) : (
                            <h5 className="m-0">
                              <i className="fas fa-user text-primary me-2" />
                              Reports for @{this.props.report.userInfo.username}
                            </h5>
                          )}
                        </>
                      )}
                    </MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <div
                    id="detail-modal-body"
                    className={`transition-25 overflow-y-${
                      this.props.screenDimensions.width >
                      this.props.screenDimensions.modalBreak
                        ? "hidden"
                        : "auto"
                    }`}
                  >
                    <MDBModalBody id="detail-modal-body-child">
                      {this.props.report.type === "emission" ? (
                        <>
                          <SampleEmission
                            emission={this.props.report.emission}
                            toggleModal={this.props.toggleShowModal}
                          />
                        </>
                      ) : (
                        <>
                          <SampleProfile
                            profile={this.props.report.userInfo}
                            preventRouting={true}
                          />
                        </>
                      )}
                      {this.props.report.type === "approval" ? (
                        <Approval
                          report={this.props.report}
                          notify={this.props.notify}
                          toggleShowModal={this.props.toggleShowModal}
                          purgeReports={this.props.purgeReports}
                          token={this.props.token}
                          set_token={this.props.set_token}
                        />
                      ) : (
                        <StaticRouter location={this.state.view}>
                          <AnimatePresence exitBeforeEnter>
                            <Switch key={this.state.view}>
                              <Route exact path=":view">
                                <View
                                  view={this.state.view}
                                  toggleShowModal={this.props.toggleShowModal}
                                  key={this.state.view}
                                  notify={this.props.notify}
                                  report={this.props.report}
                                  setView={this.setView}
                                  purgeReports={this.props.purgeReports}
                                  token={this.props.token}
                                  set_token={this.props.set_token}
                                />
                              </Route>
                            </Switch>
                          </AnimatePresence>
                        </StaticRouter>
                      )}
                    </MDBModalBody>
                  </div>
                  <MDBModalFooter>
                    <MDBBtn
                      className="bg-gray"
                      color="dark"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, {})(DetailModal);
