import env from "../../env";
import React from "react";
import { motion } from "framer-motion";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import { MDBTooltip, MDBBtn } from "mdb-react-ui-kit";
import RemoveFile from "../RemoveFile";
import Spinner from "../Spinner";
import axios from "axios";

class File extends React.Component {
  constructor(props) {
    super();
    this.file = props.file;
    this.state = {
      /**
       * playing: Boolean - Whether the file is an audio or video file and being played
       * playButtonSize: Number - The height and width of the video play button in pixels
       * videoDimensions: Object - The height and width of the video in pixels
       */
      playing: false,
      playButtonSize: 0,
      videoDimensions: {
        height: 0,
        width: 0,
      },
      loaded: false,
      linkCopied: false,
      downloading: false,
    };
  }

  /**
   * Sets the video dimensions
   * Play button will be 75x75 pixels unless the video's smallest dimension is less than 150 pixels
   * If less than 150 but larger than 10, half smallest dimension
   * If smaller than 10, 5 pixels
   */
  setVideoDimensions = () => {
    const video = document.getElementById(this.file.main.split(".")[0]);
    const smallerDimension =
      video.clientWidth > video.clientHeight
        ? video.clientHeight
        : video.clientWidth;
    this.setState((curr) => ({
      ...curr,
      playButtonSize:
        smallerDimension > 10
          ? smallerDimension < 150
            ? smallerDimension / 2
            : 75
          : 5,
      videoDimensions: {
        height: video.clientHeight,
        width: video.clientWidth,
      },
    }));
  };

  /**
   * Triggered when the user clicks the play button on a video
   * Plays the video
   */
  playMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.state.playing)
      this.setState(
        (curr) => ({
          ...curr,
          playing: true,
        }),
        () => document.getElementById(this.file.main.split(".")[0]).play()
      );
  };

  /**
   * Triggered when the user stops playing an audio or video file
   * Stops the media
   */
  stopMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState((curr) => ({
      ...curr,
      playing: false,
    }));
  };

  /**
   * Triggered when the user plays or pauses an audio file
   * Toggles the play
   *
   */
  toggleMedia = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(
      (curr) => ({
        ...curr,
        playing: !this.state.playing,
      }),
      () => {
        if (this.state.playing)
          document.getElementById(this.file.main.split(".")[0]).play();
        else document.getElementById(this.file.main.split(".")[0]).pause();
      }
    );
  };

  setLoaded = () =>
    this.setState((curr) => ({
      ...curr,
      loaded: true,
    }));

  copyDirectLink = () =>
    this.setState(
      (curr) => ({
        ...curr,
        linkCopied: true,
      }),
      () => {
        h.copyToClipboard(
          `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/${
            this.file.type
          }${["audio", "other"].includes(this.file.type) ? "" : "s"}/${
            this.file.main
          }`
        );
        window.dispatchEvent(new Event("scroll"));
      }
    );

  download = () =>
    this.setState(
      (curr) => ({
        ...curr,
        downloading: true,
      }),
      () => {
        const url = `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/${
          this.file.type
        }${["audio", "other"].includes(this.file.type) ? "" : "s"}/${
          this.file.main
        }`;
        try {
          let contentType;
          fetch(url, {
            method: "get",
            responseType: "arraybuffer",
            headers: { "Cache-Control": "no-cache" },
          })
            .then((response) => {
              contentType = response.headers.get("content-type");
              return response.arrayBuffer();
            })
            .then((buffer) => {
              const data = Buffer.from(buffer, "binary").toString("base64");
              if (data) {
                const iOS =
                  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
                  !window.MSStream;
                const isChrome =
                  navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
                  navigator.vendor.toLowerCase().indexOf("google") > -1;
                let iOSVersion = [];
                if (iOS) {
                  iOSVersion = navigator.userAgent
                    .match(/OS [\d_]+/i)[0]
                    .substr(3)
                    .split("_")
                    .map((n) => parseInt(n));
                }
                console.log(iOS, isChrome, iOSVersion);
                const attachmentData = data;
                const attachmentName = url.split("/").pop();

                const binary = atob(attachmentData.replace(/\s/g, ""));
                const len = binary.length;
                const buffer = new ArrayBuffer(len);
                let view = new Uint8Array(buffer);
                for (let i = 0; i < len; i++) {
                  view[i] = binary.charCodeAt(i);
                }
                const linkElement = document.createElement("a");
                try {
                  let hrefUrl = "";
                  let blob = "";
                  if (iOS && !isChrome && iOSVersion[0] <= 12) {
                    blob = "data:application/pdf;base64," + data;
                    hrefUrl = blob;
                  } else {
                    if (iOS && !isChrome) {
                      contentType = "application/octet-stream";
                    }
                    blob = new Blob([view], { type: contentType });
                    hrefUrl = window.URL.createObjectURL(blob);
                  }
                  linkElement.setAttribute("href", hrefUrl);
                  linkElement.setAttribute("target", "_blank");
                  if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
                    linkElement.setAttribute("download", attachmentName);
                  }
                  const clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                  });
                  linkElement.dispatchEvent(clickEvent);
                  this.setState((curr) => ({
                    ...curr,
                    downloading: false,
                  }));
                } catch (err) {
                  console.log("download error", err);
                  this.setState(
                    (curr) => ({
                      ...curr,
                      downloading: false,
                    }),
                    () => {
                      const a = document.createElement("a");
                      a.href = url;
                      a.download = url.split("/").pop();
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);
                    }
                  );
                }
              }
            })
            .catch((err) => {
              console.log("download error", err);
              this.setState(
                (curr) => ({
                  ...curr,
                  downloading: false,
                }),
                () => {
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = url.split("/").pop();
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                }
              );
            });
        } catch (err) {
          console.log("download error", err);
          this.setState(
            (curr) => ({
              ...curr,
              downloading: false,
            }),
            () => {
              const a = document.createElement("a");
              a.href = url;
              a.download = url.split("/").pop();
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          );
        }
      }
    );

  render() {
    return (
      <motion.div
        className="h-100 d-flex flex-column justify-content-center align-items-center file-modal-blank-space"
        transition={t.transition}
        initial={this.props.enter}
        animate={t.normalize}
        exit={this.props.exit}
      >
        <div className="d-flex justify-content-center align-items-start w-100 file-modal-blank-space py-1">
          <MDBBtn
            color="light"
            size="sm"
            className="me-2"
            onClick={this.download}
            disabled={this.state.downloading}
          >
            {this.state.downloading ? (
              <>
                <Spinner className="me-2" size="sm" color="dark" />
                Downloading
              </>
            ) : (
              <>
                <i className="fas fa-download me-2"></i>
                Download
              </>
            )}
          </MDBBtn>
          <MDBTooltip
            className="min-w-max-content cursor-pointer"
            onMouseLeave={h.hideToolTips}
            wrapperProps={{
              size: "sm",
              className: `cursor-pointer ${
                h.checkChadmin(this.props.userInfo) &&
                !this.file.main.includes("removed.png")
                  ? "me-1"
                  : ""
              }`,
              color: "primary",
              onClick: this.copyDirectLink,
            }}
            title={
              this.state.linkCopied
                ? "Link Copied"
                : `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/${
                    this.file.type
                  }${["audio", "other"].includes(this.file.type) ? "" : "s"}/${
                    this.file.main
                  }`
            }
          >
            <i className="fas fa-share-alt cursor-pointer me-2"></i>
            Direct Link
          </MDBTooltip>
          {h.checkChadmin(this.props.userInfo) &&
            !this.file.main.includes("removed.png") && (
              <RemoveFile
                file={`${env.INSTANCE_ID}/${this.file.type}${
                  ["audio", "other"].includes(this.file.type) ? "" : "s"
                }/${this.file.main}`}
                callback={this.props.toggleShowModal}
                className="ms-1"
                smallButtons={true}
              />
            )}
        </div>

        {(() => {
          const extension = String(
            this.file.main.split(".")[this.file.main.split(".").length - 1]
          ).toLowerCase();
          switch (this.file.type) {
            case "audio":
              if (
                ["mp3", "webm", "aac", "wav", "flac", "wma"].includes(extension)
              ) {
                return (
                  <div className="d-flex flex-column justify-content-center align-items-center h-0 flex-grow-1 w-100 file-modal-blank-space">
                    <motion.audio
                      src={`${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/audio/${this.file.main}`}
                      id={this.file.main.split(".")[0]}
                      controls={true}
                      onPause={this.stopMedia}
                      transition={t.transition}
                      exit={t.fade_out_minimize}
                      animate={t.normalize}
                      initial={t.fade_out_minimize}
                      onCanPlay={this.setLoaded}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="w-100 h-0 flex-grow-1 d-flex justify-content-center align-items-center file-modal-blank-space position-relative">
                    <MDBBtn
                      color="light"
                      size="lg"
                      onClick={this.download}
                      disabled={this.state.downloading}
                    >
                      {this.state.downloading ? (
                        <>
                          <Spinner className="me-2" size="sm" color="dark" />
                          Downloading
                        </>
                      ) : (
                        <>
                          <i className="fas fa-download me-2"></i>
                          Download
                        </>
                      )}
                    </MDBBtn>
                  </div>
                );
              }
            case "video":
              if (["mp4", "webm", "mov"].includes(extension)) {
                return (
                  <div className="d-flex justify-content-center align-items-center h-0 flex-grow-1 w-100 position-relative file-modal-blank-space">
                    <video
                      className="max-w-100 max-h-100 rounded-6"
                      src={`${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/videos/${this.file.main}`}
                      id={this.file.main.split(".")[0]}
                      controls={true}
                      onPause={this.stopMedia}
                      onCanPlay={this.setLoaded}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="w-100 h-0 flex-grow-1 d-flex justify-content-center align-items-center file-modal-blank-space position-relative">
                    <MDBBtn
                      color="light"
                      size="lg"
                      onClick={this.download}
                      disabled={this.state.downloading}
                    >
                      {this.state.downloading ? (
                        <>
                          <Spinner className="me-2" size="sm" color="dark" />
                          Downloading
                        </>
                      ) : (
                        <>
                          <i className="fas fa-download me-2"></i>
                          Download
                        </>
                      )}
                    </MDBBtn>
                  </div>
                );
              }

            case "image":
              return (
                <div className="w-100 h-0 flex-grow-1 d-flex justify-content-center align-items-center file-modal-blank-space position-relative">
                  <img
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.file.main}`
                      )
                    }
                    src={`${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/images/${this.file.main}`}
                    className="d-block max-h-100 max-w-100 cursor-pointer rounded-6"
                    onLoad={this.setLoaded}
                  />
                </div>
              );
            default:
              return (
                <embed
                  src={`${process.env.REACT_APP_BUCKET_HOST}/${
                    env.INSTANCE_ID
                  }/${this.file.type}${
                    ["audio", "other"].includes(this.file.type) ? "" : "s"
                  }/${this.file.main}`}
                  className="h-100 w-100"
                  type={this.file.mimeType}
                ></embed>
              );
          }
        })()}
      </motion.div>
    );
  }
}

export default File;
