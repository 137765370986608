import env from "../env";
import React from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import axios from "axios";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRipple,
  MDBBtn,
  MDBTooltip,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { parse } from "node-html-parser";
import ChatBox from "./messages/ChatBox";
import {
  route,
  set_unread_messages,
  set_token,
  set_redirect_url,
} from "../redux/actions";
import NewMessageModal from "./messages/NewMessageModal";
import EnterPasswordModal from "./messages/EnterPasswordModal";
import RemoveConversationModal from "./messages/RemoveConversationModal";
import ManageConversationModal from "./messages/ManageConversationModal";
import Encrypter from "../utilities/Encrypter";
import crypto from "crypto-browserify";
import { Buffer } from "safe-buffer";
import Count from "../components/Count";

let loadingMore = false;

class Messages extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * conversations: Array - List of private chats
       * loaded: Boolean - Whether the initial data has loaded
       * conversationSelected: false | Object - The selected conversation data
       * socketConnected: Boolean - Whether the message socket has connected
       */
      conversations: [],
      removed: [],
      loaded: false,
      decrypting: false,
      conversationSelected: false,
      socketConnected: false,
      newMessageModalShown: false,
      enterPasswordModalShown: false,
      removeConversationModalShown: false,
      manageConversationModalShown: false,
      conversationHovering: false,
      conversationRemoving: false,
      conversationManaging: false,
      loadingMore: [],
      loadingMoreConversations: false,
      redirecting: false,
      totalConversations: 0,
      socketEvents: [],
    };
  }

  /**
   * If user is not logged in, route to the login page
   * Else, load messages
   */
  componentDidMount() {
    if (
      !localStorage.getItem("chatKey") ||
      localStorage.getItem("userID") !== this.props.userInfo._id
    )
      setTimeout(() => this.toggleEnterPasswordModalShown(), 666);
    if (!this.props.userInfo._id) {
      if (this.props.verificationDetails)
        this.props.history.push("/validate-email");
      else {
        this.props.set_redirect_url("/messages");
        this.props.history.push("/login");
      }
    } else this.load();
  }

  /**
   * If the user logs out, route to the login page
   * If the main socket is disconnected then reconnects, initialize the component socket
   * If the main socket is initially connected, initialize the component socket
   */
  componentDidUpdate(prevProps) {
    if (prevProps.userInfo._id && !this.props.userInfo._id)
      this.props.route("/login");

    if (!prevProps.socket && this.props.socket) this.initializeSocket();
  }

  componentWillUnmount() {
    if (this.props.socket) {
      this.props.socket.off("conversation-kicked");
      this.props.socket.off("conversation-left");
      this.props.socket.off("conversation-removed");
      this.props.socket.off("conversation-add");
      this.props.socket.off("remove-message");
      this.props.socket.off("new-message");
      this.props.socket.off("update-user");
      this.props.socket.off("read");
      this.props.socket.off("read-all");
    }
  }

  removeMessage = (messageID) =>
    this.setState(
      (curr) => ({
        ...curr,
        conversations: this.state.conversations.map((conversation) => {
          let found = false;
          conversation.messages = conversation.messages.filter((message) => {
            if (message.id === messageID) {
              found = true;
              return false;
            }

            return true;
          });
          if (found) conversation.totalMessages--;
          return conversation;
        }),
      }),
      this.setUnreadMessages
    );

  toggleNewMessageModalShown = () =>
    this.setState((curr) => ({
      ...curr,
      newMessageModalShown: !this.state.newMessageModalShown,
    }));

  setNewMessageModalShown = (option) =>
    this.setState((curr) => ({
      ...curr,
      newMessageModalShown: option,
    }));

  toggleEnterPasswordModalShown = () => {
    this.setState((curr) => ({
      ...curr,
      enterPasswordModalShown: !this.state.enterPasswordModalShown,
    }));
  };

  setEnterPasswordModalShown = (option) => {
    this.setState((curr) => ({
      ...curr,
      enterPasswordModalShown: option,
    }));
  };

  toggleRemoveConversationModalShown = () =>
    this.setState((curr) => ({
      ...curr,
      removeConversationModalShown: !this.state.removeConversationModalShown,
    }));

  setRemoveConversationModalShown = (option) =>
    this.setState((curr) => ({
      ...curr,
      removeConversationModalShown: option,
    }));

  setManageConversationModalShown = (option) =>
    this.setState((curr) => ({
      ...curr,
      manageConversationModalShown: option,
    }));

  toggleManageConversationModal = () =>
    this.setState((curr) => ({
      ...curr,
      manageConversationModalShown: !this.state.manageConversationModalShown,
    }));

  removeConversation = (conversationID) =>
    this.setState((curr) => ({
      ...curr,
      removed: [...this.state.removed, conversationID],
      conversations: this.state.conversations.filter(
        (c) => c._id !== conversationID
      ),
      conversationRemoving:
        this.state.conversationRemoving === conversationID
          ? false
          : this.state.conversationRemoving,
      conversationSelected:
        this.state.conversationSelected === conversationID
          ? false
          : this.state.conversationSelected,
      removeConversationModalShown:
        this.state.conversationRemoving === conversationID
          ? false
          : this.state.removeConversationModalShown,
      totalConversations: this.state.totalConversations - 1,
    }));

  loadMore = () => {
    const conversationID = this.state.conversationSelected;
    this.setState(
      (curr) => ({
        ...curr,
        loadingMore: [...new Set([...this.state.loadingMore, conversationID])],
      }),
      () =>
        axios
          .post(
            `${process.env.REACT_APP_LAMBDA_MESSAGES}/more-messages`,
            {
              conversationID,
              messageIDs: this.state.conversations
                .find((c) => c._id === conversationID)
                .messages.map((m) => m.id),
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                conversations: this.state.conversations.map((conversation) => {
                  if (conversation._id === conversationID) {
                    conversation.totalMessages = res.data.totalMessages;
                    conversation.messages = [
                      ...conversation.messages,
                      ...res.data.messages.map((messageObj) => {
                        if (
                          messageObj.to &&
                          !conversation.keys.find(
                            (k) => k[messageObj.to] && k[messageObj.from]
                          )?.sharedKey &&
                          messageObj.from !== this.props.userInfo._id
                        ) {
                          conversation.keys = conversation.keys.map((key) => {
                            if (key[messageObj.from] && key[messageObj.to]) {
                              const sharedKey = crypto
                                .privateDecrypt(
                                  localStorage.getItem("chatKey"),
                                  Buffer.from(
                                    key[this.props.userInfo._id],
                                    "binary"
                                  )
                                )
                                .toString("binary");
                              key.sharedKey = sharedKey;
                              key[this.props.userInfo._id] = new Encrypter(
                                sharedKey
                              );
                            }

                            return key;
                          });
                        }
                        return {
                          ...messageObj,
                          decrypted: true,
                          message: h.decryptMessage(
                            this.props.userInfo._id,
                            conversation,
                            messageObj
                          ),
                        };
                      }),
                    ].sort(
                      (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                    );
                  }
                  return conversation;
                }),
                loadingMore: this.state.loadingMore.filter(
                  (c) => c !== conversationID
                ),
              }),
              this.setUnreadMessages
            );
          })
          .catch((err) => {
            console.log("error loading more", err);
            if (!this.state.redirecting) setTimeout(this.loadMore, 1000);
          })
    );
  };

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.off("new-message");
    this.props.socket.off("update-user");
    this.props.socket.off("read");
    this.props.socket.off("remove-message");
    this.props.socket.off("conversation-left");
    this.props.socket.off("conversation-removed");
    this.props.socket.off("conversation-kicked");
    this.props.socket.off("conversation-add");
    this.props.socket.off("read-all");

    this.props.socket.on("read-all", () => {
      try {
        this.markAllRead(true);
      } catch (err) {
        console.log("read-all error", err);
      }
    });
    this.props.socket.on("conversation-add", (details) => {
      try {
        const { conversationID, users, newKeys } = details;
        if (
          this.state.conversations.find(
            (conversation) => conversation._id === conversationID
          )
        )
          this.setState((curr) => ({
            ...curr,
            conversations: this.state.conversations.map((conversation) => {
              if (conversation._id === conversationID) {
                conversation.parties = [
                  ...conversation.parties,
                  ...users.map((u) => u._id),
                ];
                conversation.users = [...conversation.users, ...users];
                if (newKeys) {
                  conversation.keys = [
                    ...conversation.keys,
                    ...newKeys.map((key) => {
                      if (!key.sharedKey) {
                        const sharedKey = crypto
                          .privateDecrypt(
                            localStorage.getItem("chatKey"),
                            Buffer.from(key[this.props.userInfo._id], "binary")
                          )
                          .toString("binary");
                        key.sharedKey = sharedKey;
                        key[this.props.userInfo._id] = new Encrypter(sharedKey);
                      }

                      return key;
                    }),
                  ];
                }
              }

              return conversation;
            }),
          }));
        else {
          this.getNewConversation(conversationID);
        }
      } catch (err) {
        console.log("conversation-add error", err);
      }
    });

    this.props.socket.on(
      "conversation-kicked",
      (conversationID, userID, unread) => {
        try {
          if (userID === this.props.userInfo._id) {
            this.setState(
              (curr) => ({
                ...curr,
                conversations: this.state.conversations.filter(
                  (c) => c._id !== conversationID
                ),
                conversationSelected:
                  this.state.conversationSelected === conversationID
                    ? false
                    : this.state.conversationSelected,
                totalConversations: this.state.totalConversations - 1,
              }),
              () => {
                if (unread)
                  this.props.set_unread_messages(
                    this.props.userInfo.unreadMessages - unread
                  );
              }
            );
          } else
            this.setState((curr) => ({
              ...curr,
              conversations: this.state.conversations.map((conversation) => {
                if (conversation._id === conversationID) {
                  conversation.parties = conversation.parties.filter(
                    (p) => p !== userID
                  );
                  conversation.kickedUsers.push(userID);
                }
                return conversation;
              }),
            }));
        } catch (err) {
          console.log("conversation-kicked error", err);
        }
      }
    );

    this.props.socket.on("conversation-removed", (conversationID, unread) => {
      try {
        this.removeConversation(conversationID);
        if (unread)
          this.props.set_unread_messages(
            this.props.userInfo.unreadMessages - unread
          );
      } catch (err) {
        console.log("conversation-removed error", err);
      }
    });

    this.props.socket.on(
      "conversation-left",
      (conversationID, userID, unread) => {
        try {
          if (unread)
            this.props.set_unread_messages(
              this.props.userInfo.unreadMessages - unread
            );
          if (userID === this.props.userInfo._id)
            this.removeConversation(conversationID);
          else
            this.setState((curr) => ({
              ...curr,
              conversations: this.state.conversations.map((conversation) => {
                if (conversation._id === conversationID) {
                  const messagesAffected = conversation.messages.filter(
                    (c) => c.from === userID
                  );
                  conversation.totalMessages -= messagesAffected.length;
                  conversation.messages = conversation.messages.filter(
                    (m) =>
                      !messagesAffected.find((message) => message.id === m.id)
                  );
                  conversation.parties = conversation.parties.filter(
                    (p) => p !== userID
                  );
                }

                return conversation;
              }),
            }));
        } catch (err) {
          console.log("conversation-left error", err);
        }
      }
    );

    this.props.socket.on("remove-message", (data) => {
      try {
        if (
          data?.eventID &&
          this.state.socketEvents.find((e) => e === data?.eventID)
        )
          return;
        else
          this.setState(
            (curr) => ({
              ...curr,
              socketEvents: data?.eventID
                ? [...this.state.socketEvents, data?.eventID]
                : this.state.socketEvents,
            }),
            () => this.removeMessage(data.messageID)
          );
      } catch (err) {
        console.log("remove-message error", err);
      }
    });
    this.props.socket.on("update-user", (data) => {
      try {
        this.updateUser(data);
      } catch (err) {
        console.log("update-user error", err, data);
      }
    });
    this.props.socket.on("new-message", (data) => {
      try {
        this.addMessage(data);
      } catch (err) {
        console.log("new-message error", err, data);
      }
    });
    this.props.socket.on("read", (conversationID) => {
      try {
        this.setState((curr) => ({
          ...curr,
          conversations: this.state.conversations.map((conversation) => {
            if (conversation._id === conversationID)
              conversation.messages = conversation.messages.map((message) => ({
                ...message,
                read: true,
              }));
            return conversation;
          }),
        }));
      } catch (err) {
        console.log("read error", err, conversationID);
      }
    });
  };

  clearMessage = () =>
    this.setState((curr) => ({
      ...curr,
      conversationSelected: false,
    }));

  /**
   *
   * @param {Object} userInfo - Users document minus sensitive info
   *
   * Triggered when a user's chat partner's profile is updated
   * Updates the conversation with the updated user info
   */
  updateUser = (userInfo) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      return;

    this.setState((curr) => ({
      ...curr,
      socketEvents: userInfo?.eventID
        ? [...this.state.socketEvents, userInfo?.eventID]
        : this.state.socketEvents,
      conversations: this.state.conversations.map((conversation) => {
        if (conversation.users.find((user) => user._id === userInfo._id))
          return {
            ...conversation,
            users: [
              ...conversation.users.filter((u) => u._id !== userInfo._id),
              userInfo,
            ],
          };
        else return conversation;
      }),
    }));
  };

  /**
   * Loads a list of all of the user's private messages
   */
  load = () =>
    axios
      .get(process.env.REACT_APP_LAMBDA_MESSAGES + "/init", {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) =>
        this.setState(
          (curr) => ({
            ...curr,
            decrypting: res.data.conversations?.length ? true : false,
          }),
          () =>
            setTimeout(
              () => {
                this.props.set_token(res.data.token);
                res.data.conversations = h.decryptConversations(
                  this.props.userInfo._id,
                  localStorage.getItem("chatKey"),
                  res.data.conversations,
                  true
                );
                this.setState(
                  (curr) => ({
                    ...curr,
                    conversations: this.sortConversations(
                      res.data.conversations
                    ),
                    totalConversations: res.data.totalConversations,
                    decrypting: false,
                    loaded: res.data.conversations.map((c) => c._id),
                  }),
                  () => {
                    this.setUnreadMessages();
                    if (this.props.socket && !this.state.socketConnected)
                      this.initializeSocket();
                  }
                );
              },
              res.data.conversations?.length ? 200 : 0
            )
        )
      )
      .catch((err) => {
        console.log("message load err", err);
        if (!this.state.redirecting) setTimeout(this.load, 1000);
      });

  /**
   *
   * @param {String} conversation - _id of Conversation that the user has selected
   *
   * Triggered when the user clicks on one of their conversations
   *
   * Sets the selected conversation into state
   * Sets all of that conversations messages as read
   */
  selectConversation = (conversation) =>
    this.setState(
      (curr) => ({
        ...curr,
        conversationSelected: conversation,
        conversations: this.sortConversations(
          this.state.conversations.map((c) => {
            if (c._id === conversation) {
              if (c.messages.find((m) => !m.read))
                c.messages = c.messages.map((message) => ({
                  ...message,
                  read: true,
                }));
            }
            return c;
          })
        ),
      }),
      () => {
        this.props.socket.emit("read-messages", conversation);
        this.setUnreadMessages();
        setTimeout(() => {
          if (
            this.state.conversations
              .find((c) => c._id === conversation)
              .messages.find((m) => !m.decrypted)
          )
            this.setState((curr) => ({
              ...curr,
              conversations: this.sortConversations(
                this.state.conversations
                  .map((c) => {
                    try {
                      if (c._id === conversation)
                        c = h.decryptConversations(
                          this.props.userInfo._id,
                          localStorage.getItem("chatKey"),
                          [c]
                        )[0];

                      return c;
                    } catch (err) {
                      console.log("decrypt error", err);
                      return false;
                    }
                  })
                  .filter((c) => c)
              ),
            }));
        }, 200);
      }
    );

  /**
   *
   * @param {Object} messageObj - Conversations document
   *
   * Triggered when the user receives a new message via socket
   * Adds that message into state
   */
  addMessage = (messageObj) => {
    if (
      !this.state.conversations.find((conversation) =>
        conversation.messages.find((message) => message.id === messageObj.id)
      )
    ) {
      let found = false;
      this.setState(
        (curr) => ({
          ...curr,
          conversations: this.sortConversations(
            this.state.conversations.map((conversation) => {
              if (conversation._id === messageObj.conversationID) {
                conversation.totalMessages++;
                found = true;
                if (messageObj.newKeys) {
                  conversation.keys = [
                    ...conversation.keys,
                    ...Object.keys(messageObj.newKeys)
                      .filter((userID) => {
                        if (messageObj.from === this.props.userInfo._id)
                          return true;
                        return (
                          [this.props.userInfo._id, messageObj.from].indexOf(
                            userID
                          ) > -1
                        );
                      })
                      .map((userID) => {
                        const sharedKey = messageObj.local
                          ? messageObj.newKeys[userID]
                          : crypto
                              .privateDecrypt(
                                localStorage.getItem("chatKey"),
                                Buffer.from(
                                  messageObj.newKeys[this.props.userInfo._id],
                                  "binary"
                                )
                              )
                              .toString("binary");
                        return {
                          [userID]: messageObj.newKeys[userID],
                          sharedKey: sharedKey,
                          [this.props.userInfo._id]: new Encrypter(sharedKey),
                        };
                      }),
                  ];
                }
                if (messageObj.keyArray)
                  conversation.keys = [
                    ...conversation.keys,
                    ...messageObj.keyArray,
                  ];
                try {
                  if (
                    messageObj.to &&
                    !conversation.keys.find(
                      (k) => k[messageObj.to] && k[messageObj.from]
                    )?.sharedKey &&
                    messageObj.from !== this.props.userInfo._id
                  ) {
                    conversation.keys = conversation.keys.map((key) => {
                      if (key[messageObj.from] && key[messageObj.to]) {
                        const sharedKey = crypto
                          .privateDecrypt(
                            localStorage.getItem("chatKey"),
                            Buffer.from(key[this.props.userInfo._id], "binary")
                          )
                          .toString("binary");
                        key.sharedKey = sharedKey;
                        key[this.props.userInfo._id] = new Encrypter(sharedKey);
                      }

                      return key;
                    });
                  }
                } catch (err) {
                  console.log("changed password");
                }

                return {
                  ...conversation,
                  messages: [
                    ...conversation.messages,
                    {
                      ...messageObj,
                      decrypted: true,
                      message: messageObj.local
                        ? messageObj.message
                        : h.decryptMessage(
                            this.props.userInfo._id,
                            conversation,
                            messageObj
                          ),
                      read:
                        messageObj.party === this.props.userInfo._id ||
                        this.state.conversationSelected ===
                          messageObj.conversationID,
                    },
                  ].sort(
                    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                  ),
                };
              } else return conversation;
            })
          ),
        }),
        () => {
          if (
            !found &&
            messageObj.conversationID &&
            this.state.removed.indexOf(messageObj.conversationID) === -1
          ) {
            this.getNewConversation(messageObj.conversationID);
          } else if (
            this.state.conversationSelected === messageObj.conversationID
          )
            this.props.socket.emit("read-messages", messageObj.conversationID);
          this.setUnreadMessages();
        }
      );
    }
  };

  setUnreadMessages = () =>
    this.props.set_unread_messages(
      this.state.conversations.reduce(
        (prev, curr) =>
          prev +
          curr.messages.filter(
            (m) => !m.read && m.from !== this.props.userInfo._id
          ).length,
        0
      )
    );

  /**
   * Triggered when the user receives a new message for a brand new conversation
   * Grabs the Conversations document for that message
   */
  getNewConversation = (conversationID) => {
    axios
      .get(`${process.env.REACT_APP_LAMBDA_MESSAGES}/by-id/${conversationID}`, {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState(
          (curr) => ({
            ...curr,
            conversations: this.sortConversations([
              ...curr.conversations,
              h.decryptConversations(
                this.props.userInfo._id,
                localStorage.getItem("chatKey"),
                [res.data.conversation]
              )[0],
            ]).filter((c) => c),
            conversationSelected:
              res.data.conversation.starter === this.props.userInfo._id &&
              this.state.newMessageModalShown
                ? res.data.conversation._id
                : this.state.conversationSelected,
            newMessageModalShown:
              res.data.conversation.starter === this.props.userInfo._id
                ? false
                : this.state.newMessageModalShown,
            totalConversations: this.state.totalConversations + 1,
          }),
          () =>
            this.setState(
              (curr) => ({
                ...curr,
                loaded: [...this.state.loaded, res.data.conversation._id],
              }),
              this.setUnreadMessages
            )
        );
      })
      .catch((err) => {
        console.log("error getting new conversation", err);

        if (err.response?.status === 404) console.log("Conversation not found");
        else if (!this.state.redirecting)
          setTimeout(() => this.getNewConversation(conversationID), 1000);
      });
  };

  // Sorts the conversations by most recent last message sent or received
  sortConversations = (conversations) =>
    conversations.sort((a, b) => {
      const lastMessageA =
        a.messages.sort(
          (c, d) => new Date(d.timestamp) - new Date(c.timestamp)
        )[0]?.timestamp || a.timestamp;
      const lastMessageB =
        b.messages.sort(
          (c, d) => new Date(d.timestamp) - new Date(c.timestamp)
        )[0]?.timestamp || b.timestamp;
      return new Date(lastMessageB) - new Date(lastMessageA);
    });

  remove = (conversationID) =>
    this.setState(
      (curr) => ({
        ...curr,
        conversationRemoving: conversationID,
        conversationHovering: false,
      }),
      this.toggleRemoveConversationModalShown
    );

  manageConversation = (conversationID) =>
    this.setState(
      (curr) => ({
        ...curr,
        conversationManaging: conversationID,
      }),
      this.toggleManageConversationModal
    );

  scroll = (e) => {
    if (
      !loadingMore &&
      !this.state.loadingMoreConversations &&
      e.currentTarget.scrollHeight -
        (e.currentTarget.scrollTop + e.currentTarget.clientHeight) <
        0.1 * e.currentTarget.clientHeight &&
      this.state.conversations.length &&
      this.state.conversations.length < this.state.totalConversations
    ) {
      loadingMore = true;
      this.loadMoreConversations();
      setTimeout(() => (loadingMore = false), 500);
    }
  };

  loadMoreConversations = () =>
    this.setState(
      (curr) => ({
        ...curr,
        loadingMoreConversations: true,
      }),
      () =>
        axios
          .post(
            `${process.env.REACT_APP_LAMBDA_MESSAGES}/more-conversations`,
            {
              conversationIDs: this.state.conversations.map((c) => c._id),
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            res.data.conversations = h.decryptConversations(
              this.props.userInfo._id,
              localStorage.getItem("chatKey"),
              res.data.conversations,
              true
            );
            this.setState(
              (curr) => ({
                ...curr,
                conversations: this.sortConversations([
                  ...res.data.conversations,
                  ...this.state.conversations,
                ]),
                totalConversations: res.data.totalConversations,
                loadingMoreConversations: false,
              }),
              () =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    loaded: [
                      ...this.state.loaded,
                      ...res.data.conversations.map((c) => c._id),
                    ],
                  }),
                  () => {
                    this.setUnreadMessages();
                    if (this.props.socket && !this.state.socketConnected)
                      this.initializeSocket();
                  }
                )
            );
          })
          .catch((err) => {
            console.log("error loading more", err);
            if (!this.state.redirecting)
              setTimeout(this.loadMoreConversations, 1000);
          })
    );

  markAllRead = (fromSocket) =>
    this.setState(
      (curr) => ({
        ...curr,
        conversations: this.state.conversations.map((c) => ({
          ...c,
          messages: c.messages.map((m) => {
            if (m.from !== this.props.userInfo._id) m.read = true;
            return m;
          }),
        })),
      }),
      () => {
        this.setUnreadMessages();
        if (fromSocket !== true) this.props.socket.emit("read-all");
      }
    );

  render() {
    const hasUnread = this.state.conversations.find((c) =>
      c.messages.find((m) => !m.read && m.from !== this.props.userInfo._id)
    );
    const conversationManaging = this.state.conversations.find(
      (c) => c._id === this.state.conversationManaging
    );
    const conversationRemoving = this.state.conversations.find(
      (c) => c._id === this.state.conversationRemoving
    );
    const conversationSelected = this.state.conversations.find(
      (c) => c._id === this.state.conversationSelected
    );
    return (
      <>
        <ManageConversationModal
          modalShown={this.state.manageConversationModalShown}
          setShowModal={this.setManageConversationModalShown}
          toggleShowModal={this.toggleManageConversationModal}
          conversation={
            conversationManaging
              ? {
                  ...JSON.parse(JSON.stringify(conversationManaging)),
                  keys: conversationManaging.keys,
                  parties: conversationManaging.parties,
                }
              : conversationManaging
          }
          addMessage={this.addMessage}
        />
        <EnterPasswordModal
          modalShown={this.state.enterPasswordModalShown}
          toggleShowModal={this.toggleEnterPasswordModalShown}
          setShowModal={this.setEnterPasswordModalShown}
        />
        <RemoveConversationModal
          conversation={
            conversationRemoving
              ? {
                  ...JSON.parse(JSON.stringify(conversationRemoving)),
                  keys: conversationRemoving.keys,
                  parties: conversationRemoving.parties,
                }
              : conversationRemoving
          }
          modalShown={this.state.removeConversationModalShown}
          toggleShowModal={this.toggleRemoveConversationModalShown}
          setShowModal={this.setRemoveConversationModalShown}
        />
        {!this.state.enterPasswordModalShown && (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_scale_1}
            animate={t.normalize}
            initial={t.fade_out}
            className={`${
              this.props.screenDimensions.width > 992
                ? "pt-4 pb-3"
                : "pt-5 pb-0"
            } h-100`}
          >
            <MDBContainer className="h-100 message-container" fluid>
              {this.state.loaded && this.props.socket ? (
                <>
                  {this.props.socket && (
                    <NewMessageModal
                      modalShown={this.state.newMessageModalShown}
                      toggleShowModal={this.toggleNewMessageModalShown}
                      setShowModal={this.setNewMessageModalShown}
                      addMessage={this.addMessage}
                      conversations={this.state.conversations}
                      selectConversation={this.selectConversation}
                    />
                  )}

                  <motion.div
                    transition={t.transition}
                    exit={t.fade_out_scale_1}
                    animate={t.normalize}
                    initial={t.fade_out}
                    className="row h-100"
                  >
                    <div className="col-12 col-lg-4 h-100 message-list-lg">
                      <MDBCard className="d-flex flex-column h-100">
                        <MDBCardHeader className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <h5 id="messages-title" className="m-0 display-6">
                              Messages
                            </h5>
                            {hasUnread && (
                              <MDBTooltip
                                wrapperProps={{
                                  color: "link",
                                  rippleColor: "primary",
                                  onClick: this.markAllRead,
                                }}
                                wrapperClass="bg-transparent"
                                title="Mark All as Read"
                                placement="bottom"
                              >
                                <i className="fas fa-eye" />
                              </MDBTooltip>
                            )}
                          </div>
                          {String(env.READ_ONLY) === "true" &&
                          !h.checkChadmin(this.props.userInfo) ? (
                            <></>
                          ) : (
                            <MDBBtn
                              onClick={this.toggleNewMessageModalShown}
                              color="primary"
                              className="new-message-btn"
                            >
                              <i className="fas fa-plus me-2 mx-auto" />
                              New
                              <span id="message-additional-text"> Message</span>
                            </MDBBtn>
                          )}
                        </MDBCardHeader>
                        <MDBCardBody
                          onTouchMove={this.scroll}
                          onWheel={this.scroll}
                          className="fg-1 overflow-y-auto p-0"
                        >
                          {!this.state.conversations.length ? (
                            <p className="text-center mt-4">
                              No conversations found
                            </p>
                          ) : (
                            <ul className="list-group">
                              {this.state.conversations.map((conversation) => {
                                let users = conversation.parties
                                  .filter((p) => p !== this.props.userInfo._id)
                                  .map((p) =>
                                    conversation.users.find(
                                      (user) => user._id === p
                                    )
                                  );
                                if (!users?.length)
                                  users = [
                                    JSON.parse(
                                      JSON.stringify(this.props.userInfo)
                                    ),
                                  ];
                                const unread = conversation.messages.find(
                                  (m) =>
                                    !m.read &&
                                    m.from !== this.props.userInfo._id
                                );
                                const lastMessage = conversation.messages.sort(
                                  (a, b) =>
                                    new Date(b.timestamp) -
                                    new Date(a.timestamp)
                                )[0];
                                let lastMessageUser = conversation.messages
                                  .filter(
                                    (message) =>
                                      message.from !== this.props.userInfo._id
                                  )
                                  .sort(
                                    (a, b) =>
                                      new Date(b.timestamp) -
                                      new Date(a.timestamp)
                                  )[0];
                                if (lastMessageUser)
                                  lastMessageUser = users.find(
                                    (user) => user._id === lastMessageUser.from
                                  );
                                else
                                  lastMessageUser = users.find(
                                    (user) =>
                                      user._id !== this.props.userInfo._id
                                  );
                                if (!lastMessageUser)
                                  lastMessageUser = JSON.parse(
                                    JSON.stringify(this.props.userInfo)
                                  );
                                return (
                                  <MDBRipple>
                                    <motion.li
                                      initial={
                                        this.state.loaded?.indexOf(
                                          conversation._id
                                        ) === -1
                                          ? t.fade_out
                                          : t.normalize
                                      }
                                      animate={t.normalize}
                                      exit={t.fade_out_scale_1}
                                      transition={t.transition}
                                      className={`list-group-item list-group-item-action cursor-pointer ps-0 ${
                                        this.state.conversationSelected ===
                                        conversation._id
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.selectConversation(
                                          conversation._id
                                        )
                                      }
                                      key={conversation._id}
                                      onMouseEnter={() =>
                                        this.setState((curr) => ({
                                          ...curr,
                                          conversationHovering:
                                            conversation._id,
                                        }))
                                      }
                                      onMouseLeave={() =>
                                        this.setState((curr) => ({
                                          ...curr,
                                          conversationHovering: false,
                                        }))
                                      }
                                    >
                                      <div className="d-flex position-relative">
                                        <motion.div
                                          className="align-self-center"
                                          transition={t.transition}
                                          initial={t.fade_out}
                                          animate={t.normalize}
                                          exit={t.fade_out_scale_1}
                                        >
                                          <i
                                            className="fas fa-circle text-primary mx-2"
                                            style={{
                                              opacity: unread ? 1 : 0,
                                            }}
                                          />
                                        </motion.div>
                                        <div className="chat-avatars position-relative">
                                          <div
                                            className="fit-images chat-avatars fit-round"
                                            style={{
                                              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${lastMessageUser.avatar.thumbnail}")`,
                                              borderRadius: "50%",
                                            }}
                                          ></div>
                                          {conversation.parties.length > 2 && (
                                            <div
                                              style={{
                                                bottom: "0px",
                                                right: "0px",
                                                fontSize: "12px",
                                              }}
                                              className="position-absolute text-blusteel bg-light rounded-2 p-1"
                                            >
                                              <i
                                                style={{ marginRight: "2px" }}
                                                className="fas fa-plus fa-xs"
                                              />
                                              <Count value={users.length - 1} />
                                            </div>
                                          )}
                                        </div>

                                        <div className="flex-grow-1 ms-2">
                                          <h6 className="m-0 line-height-16">
                                            {users.map((user, u) => {
                                              return (
                                                <>
                                                  @{user.username}
                                                  {u !== users.length - 1
                                                    ? ", "
                                                    : ""}
                                                </>
                                              );
                                            })}
                                          </h6>
                                          <p
                                            className={`m-0 ${
                                              unread ? "fw-bold" : ""
                                            }`}
                                          >
                                            {lastMessage
                                              ? h.veryShortString(
                                                  parse(lastMessage.message)
                                                    .textContent
                                                )
                                              : ""}
                                          </p>
                                        </div>

                                        <motion.p
                                          transition={t.transition}
                                          initial={t.fade_out}
                                          animate={t.normalize}
                                          exit={t.fade_out_scale_1}
                                          className={`m-0 transition-25 text-end ${
                                            unread ? "fw-bold" : ""
                                          } text-${
                                            this.state.conversationSelected ===
                                            conversation._id
                                              ? ""
                                              : "blusteel"
                                          } ${
                                            this.state.conversationHovering ===
                                            conversation._id
                                              ? "invis"
                                              : ""
                                          }`}
                                        >
                                          {h.getMessageTime(
                                            lastMessage?.timestamp ||
                                              conversation.timestamp
                                          )}
                                        </motion.p>
                                        {String(env.READ_ONLY) === "true" &&
                                        !h.checkChadmin(this.props.userInfo) ? (
                                          <></>
                                        ) : (
                                          <>
                                            {this.state.conversationHovering ===
                                              conversation._id && (
                                              <motion.div
                                                transition={t.transition}
                                                initial={t.fade_out}
                                                animate={t.normalize}
                                                exit={t.fade_out_scale_1}
                                                style={{ right: 0 }}
                                                className="d-flex justify-content-end align-items-center position-absolute h-100"
                                              >
                                                {conversation.starter ===
                                                  this.props.userInfo._id && (
                                                  <MDBTooltip
                                                    wrapperProps={{
                                                      color: "link",
                                                      rippleColor: "primary",
                                                      onClick: (e) => {
                                                        e.stopPropagation();
                                                        h.hideToolTips();
                                                        this.manageConversation(
                                                          conversation._id
                                                        );
                                                      },
                                                      size: "lg",
                                                    }}
                                                    wrapperClass="bg-transparent p-0 me-3"
                                                    title="Manage"
                                                  >
                                                    <i className="fas fa-cog" />
                                                  </MDBTooltip>
                                                )}

                                                <MDBTooltip
                                                  wrapperProps={{
                                                    color: "link",
                                                    rippleColor: "danger",
                                                    onClick: (e) => {
                                                      e.stopPropagation();
                                                      h.hideToolTips();
                                                      this.remove(
                                                        conversation._id
                                                      );
                                                    },
                                                    size: "lg",
                                                  }}
                                                  wrapperClass="bg-transparent p-0 text-danger"
                                                  title={
                                                    conversation.starter ===
                                                    this.props.userInfo._id
                                                      ? "Remove"
                                                      : "Leave"
                                                  }
                                                >
                                                  <i className="far fa-trash-alt" />
                                                </MDBTooltip>
                                              </motion.div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </motion.li>
                                  </MDBRipple>
                                );
                              })}
                            </ul>
                          )}
                          {this.state.conversations.length <
                            this.state.totalConversations && (
                            <MDBBtn
                              disabled={this.state.loadingMoreConversations}
                              color="link"
                              rippleColor="primary"
                              className="d-block mx-auto my-1"
                              onClick={this.loadMoreConversations}
                            >
                              {this.state.loadingMoreConversations ? (
                                <MDBSpinner size="sm" />
                              ) : (
                                "Load More"
                              )}
                            </MDBBtn>
                          )}
                        </MDBCardBody>
                      </MDBCard>
                    </div>
                    {this.state.conversationSelected ? (
                      <motion.section
                        transition={t.transition}
                        exit={t.fade_out_scale_1}
                        animate={t.normalize}
                        initial={t.fade_out}
                        className={`col-12 col-lg-8 h-100 ${
                          this.props.screenDimensions.width < 576
                            ? "px-0 pb-0"
                            : ""
                        }`}
                      >
                        <ChatBox
                          conversation={
                            conversationSelected
                              ? {
                                  ...JSON.parse(
                                    JSON.stringify(conversationSelected)
                                  ),
                                  keys: conversationSelected.keys,
                                  parties: conversationSelected.parties,
                                  resetPassword:
                                    conversationSelected.resetPassword,
                                }
                              : conversationSelected
                          }
                          addMessage={this.addMessage}
                          clearMessage={this.clearMessage}
                          removeMessage={this.removeMessage}
                          loadingMore={
                            this.state.loadingMore.indexOf(
                              this.state.conversationSelected
                            ) > -1
                          }
                          loadMore={this.loadMore}
                        />
                      </motion.section>
                    ) : (
                      <motion.div
                        transition={t.transition}
                        exit={t.fade_out_scale_1}
                        animate={t.normalize}
                        initial={t.fade_out}
                        className="col-12 col-lg-4 h-100 message-list-mobile pt-4 px-0"
                      >
                        <MDBCard className="d-flex flex-column h-100 rounded-0">
                          <MDBCardHeader className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <h5 className="m-0 display-6 text-center">
                                Messages
                              </h5>
                              <MDBTooltip
                                wrapperProps={{
                                  color: "link",
                                  rippleColor: "primary",
                                  onClick: this.markAllRead,
                                }}
                                wrapperClass="bg-transparent"
                                title="Mark All as Read"
                                placement="bottom"
                              >
                                <i className="fas fa-eye" />
                              </MDBTooltip>
                            </div>

                            <MDBBtn
                              color="primary"
                              onClick={this.toggleNewMessageModalShown}
                              className="new-message-btn"
                            >
                              <i className="fas fa-plus me-2 mx-auto" />
                              New{" "}
                              <span id="message-additional-text-mobile">
                                {" "}
                                Message
                              </span>
                            </MDBBtn>
                          </MDBCardHeader>
                          <MDBCardBody
                            onTouchMove={this.scroll}
                            onWheel={this.scroll}
                            className="fg-1 overflow-y-auto p-0"
                          >
                            {!this.state.conversations.length ? (
                              <p className="text-center mt-4">
                                No conversations found
                              </p>
                            ) : (
                              <ul className="list-group">
                                {this.state.conversations.map(
                                  (conversation) => {
                                    let users = conversation.parties
                                      .filter(
                                        (p) => p !== this.props.userInfo._id
                                      )
                                      .map((p) =>
                                        conversation.users.find(
                                          (u) => u._id === p
                                        )
                                      );
                                    if (!users?.length)
                                      users = [
                                        JSON.parse(
                                          JSON.stringify(this.props.userInfo)
                                        ),
                                      ];
                                    const unread = conversation.messages.find(
                                      (m) =>
                                        !m.read &&
                                        m.from !== this.props.userInfo._id
                                    );
                                    const lastMessage =
                                      conversation.messages.sort(
                                        (a, b) =>
                                          new Date(b.timestamp) -
                                          new Date(a.timestamp)
                                      )[0];
                                    let lastMessageUser = conversation.messages
                                      .filter(
                                        (message) =>
                                          message.from !==
                                          this.props.userInfo._id
                                      )
                                      .sort(
                                        (a, b) =>
                                          new Date(b.timestamp) -
                                          new Date(a.timestamp)
                                      )[0];
                                    if (lastMessageUser)
                                      lastMessageUser = users.find(
                                        (user) =>
                                          user._id === lastMessageUser.from
                                      );
                                    else
                                      lastMessageUser = users.find(
                                        (user) =>
                                          user._id !== this.props.userInfo._id
                                      );
                                    if (!lastMessageUser)
                                      lastMessageUser = JSON.parse(
                                        JSON.stringify(this.props.userInfo)
                                      );
                                    return (
                                      <MDBRipple>
                                        <li
                                          className={`list-group-item list-group-item-action cursor-pointer ${
                                            this.state.conversationSelected ===
                                            conversation._id
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            this.selectConversation(
                                              conversation._id
                                            )
                                          }
                                          key={conversation._id}
                                        >
                                          <div className="d-flex">
                                            <div className="chat-avatars position-relative">
                                              <div
                                                className="fit-images chat-avatars fit-round"
                                                style={{
                                                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${lastMessageUser.avatar.thumbnail}")`,
                                                  borderRadius: "50%",
                                                }}
                                              ></div>
                                              {users.length > 2 && (
                                                <div
                                                  style={{
                                                    bottom: "0px",
                                                    right: "0px",
                                                    fontSize: "12px",
                                                  }}
                                                  className="position-absolute text-blusteel bg-light rounded-2 p-1"
                                                >
                                                  <i
                                                    style={{
                                                      marginRight: "2px",
                                                    }}
                                                    className="fas fa-plus fa-xs"
                                                  />
                                                  {users.length - 1}
                                                </div>
                                              )}
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                              <h6 className="m-0 line-height-16">
                                                {users.map((user, u) => {
                                                  return (
                                                    <>
                                                      @{user.username}
                                                      {u !== users.length - 1
                                                        ? ", "
                                                        : ""}
                                                    </>
                                                  );
                                                })}
                                              </h6>
                                              <p
                                                className={`m-0 ${
                                                  unread ? "fw-bold" : ""
                                                }`}
                                              >
                                                {lastMessage
                                                  ? h.veryShortString(
                                                      parse(lastMessage.message)
                                                        .textContent
                                                    )
                                                  : ""}
                                              </p>
                                            </div>
                                            <div className="position-relative">
                                              <p
                                                className={`m-0 ${
                                                  unread ? "fw-bold" : ""
                                                } text-${
                                                  this.state
                                                    .conversationSelected ===
                                                  conversation._id
                                                    ? ""
                                                    : "blusteel"
                                                }`}
                                              >
                                                {h.getMessageTime(
                                                  lastMessage?.timestamp ||
                                                    conversation.timestamp
                                                )}
                                              </p>
                                              <div className="d-flex justify-content-end align-items-center position-absolute end-0 bottom-0">
                                                <MDBBtn
                                                  color="link"
                                                  rippleColor="primary"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    h.hideToolTips();
                                                    this.manageConversation(
                                                      conversation._id
                                                    );
                                                  }}
                                                  size="lg"
                                                  className="bg-transparent p-0 me-3"
                                                >
                                                  <i className="fas fa-cog" />
                                                </MDBBtn>
                                                <MDBBtn
                                                  color="link"
                                                  rippleColor="danger"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    h.hideToolTips();
                                                    this.remove(
                                                      conversation._id
                                                    );
                                                  }}
                                                  size="lg"
                                                  className="bg-transparent p-0 text-danger"
                                                >
                                                  <i className="far fa-trash-alt" />
                                                </MDBBtn>
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      </MDBRipple>
                                    );
                                  }
                                )}
                              </ul>
                            )}
                            {this.state.conversations.length <
                              this.state.totalConversations && (
                              <MDBBtn
                                disabled={this.state.loadingMoreConversations}
                                color="link"
                                rippleColor="primary"
                                className="d-block mx-auto my-1"
                                onClick={this.loadMoreConversations}
                              >
                                {this.state.loadingMoreConversations ? (
                                  <MDBSpinner size="sm" />
                                ) : (
                                  "Load More"
                                )}
                              </MDBBtn>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </motion.div>
                    )}
                  </motion.div>
                </>
              ) : (
                <div className="w-75 mx-auto pt-5">
                  <h5 className="text-center display-6">Loading Messages</h5>
                  <div className="d-flex justify-content-center w-100 pt-4">
                    <MDBSpinner color="primary" />
                  </div>
                </div>
              )}
            </MDBContainer>
          </motion.div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  set_unread_messages,
  set_token,
  set_redirect_url,
})(Messages);
