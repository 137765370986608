import React from "react";
import EmissionPage from "../../components/emission/EmissionPage";
import Users from "./Users";

class SearchTab extends React.Component {
  constructor(props) {
    super();
    this.tab = props.tab;
  }

  render() {
    switch (this.tab) {
      case "emissions":
        return (
          <EmissionPage
            loading={!this.props.loaded}
            route={this.props.route}
            loadingMore={this.props.loadingMore}
            loadMore={this.props.loadMore}
            userEndReached={this.props.userEndReached}
            emissionEndReached={this.props.emissionEndReached}
            emissions={this.props.emissions}
            flavor="search"
            updateEmissions={this.props.updateEmissions}
            userEdit={this.props.userEdit}
            ban={this.props.ban}
            restoreUser={this.props.restoreUser}
            privateProfile={this.props.privateProfile}
            unprivate={this.props.unprivate}
            blockOther={this.props.blockOther}
            unblockOther={this.props.unblockOther}
            socketStore={this.props.socketStore}
            accountDeleted={this.props.accountDeleted}
          />
        );
      case "users":
        return (
          <Users
            users={this.props.users}
            loadMore={this.props.loadMore}
            userEndReached={this.props.userEndReached}
            loadingMore={this.props.loadingMore}
            pushSocketEvent={this.props.pushSocketEvent}
            userEdit={this.props.userEdit}
            ban={this.props.ban}
            restoreUser={this.props.restoreUser}
            privateProfile={this.props.privateProfile}
            unprivate={this.props.unprivate}
            blockOther={this.props.blockOther}
            unblockOther={this.props.unblockOther}
            accountDeleted={this.props.accountDeleted}
          />
        );
      default:
        console.log("oob search tab", this.tab);
    }
  }
}

export default SearchTab;
