import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import AsyncSelect from "react-select/async";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import TextInput from "../../components/textInput/TextInput";
import crypto from "crypto-browserify";
import { Buffer } from "safe-buffer";
import Encrypter from "../../utilities/Encrypter";
import h from "../../utilities/helpers";

const maxChars = 5000;

const darkTheme = {
  primary25: "#1266f1",
  primary50: "#1E88E5",
  primary75: "#42A5F5",
  primary: "#BBDEFB",
  dangerLight: "#f93154",
  danger: "#E57373",
  neutral90: "#fff",
  neutral80: "#f9f9f9",
  neutral70: "#f5f5f5",
  neutral60: "#eeeeee",
  neutral50: "#e0e0e0",
  neutral40: "#bdbdbd",
  neutral30: "#9e9e9e",
  neutral20: "#757575",
  neutral10: "#616161",
  neutral5: "#4f4f4f",
  neutral0: "#262626",
};
class NewMessageModal extends React.Component {
  constructor() {
    super();
    this.state = {
      options: [],
      searchText: "",
      searchCallback: false,
      reset: false,
      recipients: [],
      working: false,
    };
  }

  componentDidMount() {
    if (this.props.socket) this.initializeSocket();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    if (prevProps.modalShown !== this.props.modalShown && this.state.working)
      this.setState((curr) => ({
        ...curr,
        working: false,
        reset: !this.state.reset,
      }));
  }

  componentWillUnmount() {
    if (this.props.socket) this.props.socket.off("user-list");
  }

  /**
   * Turn off and then turn back on all socket events
   */
  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.off("user-list");

    this.props.socket.on("user-list", (list) => {
      try {
        if (this.state.searchCallback)
          this.state.searchCallback(
            list.map((user) => {
              return {
                label: (
                  <div className="d-flex align-items-center">
                    <div className="search-avatars ms-2">
                      <div
                        className="fit-images search-avatars fit-round"
                        style={{
                          backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${user.avatar.thumbnail}")`,
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                    <div className="fw-bold ms-2">@{user.username}</div>
                    <div className="ms-2">{user.displayName}</div>
                  </div>
                ),
                value: user._id,
                user,
              };
            })
          );
      } catch (err) {
        console.log("user-list error", err, list);
      }
    });
  };

  loadOptions = (text, callback) => {
    if (!text) return callback([]);

    this.setState(
      (curr) => ({
        ...curr,
        searchCallback: callback,
      }),
      () => {
        this.props.socket.emit("user-search", text);
      }
    );
  };

  /**
   * Submit only if a message isn't already being sent
   * Set working
   * Validate inputs
   * Emit message
   * Add message via redux
   * Put user on 3 second cooldown
   */
  submit = () =>
    this.setState(
      (curr) => ({
        ...curr,
        working: true,
      }),
      () =>
        setTimeout(() => {
          try {
            if (!this.state.cooldown) {
              this.forceParse();
              const emissionData = document.getElementById("input-new-message");
              const length = String(emissionData.textContent)
                .split("")
                .filter((c) => {
                  const checkWhiteSpace = c.match(/[\s]/);
                  if (!checkWhiteSpace) return true;
                  else {
                    return [" ", "\n"].indexOf(c) > -1;
                  }
                }).length;
              if (length > maxChars)
                throw `Character limit exceeded (Max: ${maxChars} characters)`;
              if (
                !length ||
                emissionData.innerHTML === "<div><p><br /></p></div>"
              )
                throw "Please enter a message";
              if (!this.state.recipients.length)
                throw "Please enter at least one recipient.";
              const messageID = uuid();
              const duplicate = this.props.conversations.find(
                (c) =>
                  c.parties.sort().join(",") ===
                  [
                    this.props.userInfo._id,
                    ...this.state.recipients.map((r) => r._id),
                  ]
                    .sort()
                    .join(",")
              );
              if (duplicate) {
                const broadcast = this.state.recipients.map((user) => ({
                  to: user._id,
                  message: new Encrypter(
                    duplicate.keys.find(
                      (k) => k[this.props.userInfo._id] && k[user._id]
                    ).sharedKey
                  ).encrypt(h.sanitizeHTML(emissionData.innerHTML)),
                }));
                this.props.socket.emit("message", {
                  id: messageID,
                  broadcast,
                  conversationID: duplicate._id,
                });
                this.props.selectConversation(duplicate._id);
                this.props.toggleShowModal();
              } else {
                this.props.socket.emit("message", {
                  id: messageID,
                  broadcast: this.state.recipients.map((user) => {
                    const sharedKey = crypto
                      .randomBytes(Number(process.env.REACT_APP_BYTE_LENGTH))
                      .toString("hex");
                    return {
                      to: user._id,
                      senderKey: crypto
                        .publicEncrypt(
                          this.props.userInfo.publicKey,
                          Buffer.from(sharedKey, "binary")
                        )
                        .toString("binary"),
                      receiverKey: crypto
                        .publicEncrypt(
                          user.publicKey,
                          Buffer.from(sharedKey, "binary")
                        )
                        .toString("binary"),
                      message: new Encrypter(sharedKey).encrypt(
                        h.sanitizeHTML(emissionData.innerHTML)
                      ),
                    };
                  }),
                  soloKey: crypto
                    .publicEncrypt(
                      this.props.userInfo.publicKey,
                      Buffer.from(
                        crypto
                          .randomBytes(
                            Number(process.env.REACT_APP_BYTE_LENGTH)
                          )
                          .toString("hex"),
                        "binary"
                      )
                    )
                    .toString("binary"),
                });
              }
            }
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => alert(err)
            );
          }
        }, 200)
    );

  setRecipients = (users) => {
    this.setState((curr) => ({
      ...curr,
      recipients: users.map((user) => ({
        _id: user.value,
        publicKey: user.user.publicKey,
      })),
    }));
  };

  render() {
    return (
      <MDBModal
        open={this.props.modalShown}
        staticBackdrop
        onClosePrevented={() => {
          if (!this.state.working) this.props.toggleShowModal();
        }}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            this.props.screenDimensions.width >
            this.props.screenDimensions.modalBreak
              ? "xl"
              : "fullscreen"
          }
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>New Message</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={this.props.toggleShowModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <AsyncSelect
                cacheOptions
                onChange={this.setRecipients}
                loadOptions={this.loadOptions}
                defaultOptions
                placeholder="Recipients (Start typing...)"
                isMulti
                key={this.state.reset + "select"}
                theme={(theme) => {
                  if (this.props.userInfo.userSettings.theme === "default") {
                    return theme;
                  } else
                    return {
                      ...theme,
                      colors: {
                        ...theme.colors,
                        ...darkTheme,
                      },
                    };
                }}
                className="mb-2"
              />
              <TextInput
                submit={this.submit}
                setForceParse={(e) => (this.forceParse = e)}
                cooldown={this.state.cooldown}
                flavor="new-message"
                maxChars={5000}
                key={this.state.reset + "input"}
                label="Message"
                working={this.state.working}
              />
            </MDBModalBody>
            {this.props.screenDimensions.width <=
            this.props.screenDimensions.modalBreak ? (
              <MDBModalFooter>
                <MDBBtn
                  className="bg-gray"
                  color="dark"
                  onClick={this.props.toggleShowModal}
                >
                  Close
                </MDBBtn>
              </MDBModalFooter>
            ) : (
              <></>
            )}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
  socket: state.socket,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, {})(NewMessageModal);
