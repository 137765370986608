import env from "../../env";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { set_click_pass } from "../../redux/actions";
import { connect } from "react-redux";

class ProfileNav extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.clickPass?.action !== "profile-nav" &&
      this.props.clickPass?.action === "profile-nav"
    ) {
      this.props.selectTab(this.props.clickPass.id);
      this.props.set_click_pass(false);
    }
  }

  render() {
    return (
      <Tabs
        className="profile-tabs"
        value={this.props.tabSelected}
        onChange={(e, tab) => this.props.selectTab(tab)}
      >
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i
              style={{ color: "var(--mdb-danger)" }}
              className="fas fa-wifi"
            ></i>
          }
          iconPosition="start"
          label={env.EMISSION_PLURAL}
          value="emissions"
          id="tab-emissions"
          className="profile-nav-buttons"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i style={{ color: "#FFAB00" }} className="fas fa-comments"></i>
          }
          iconPosition="start"
          label="Replies"
          value="emissionsAndReplies"
          id="tab-emissionsAndReplies"
          className="profile-nav-buttons"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i
              style={{ color: "var(--mdb-success)" }}
              className="fas fa-photo-video"
            ></i>
          }
          iconPosition="start"
          label="Media"
          value="media"
          id="tab-media"
          className="profile-nav-buttons"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i
              style={{ color: "rgb(252, 69, 223)" }}
              className="fas fa-heart"
            ></i>
          }
          iconPosition="start"
          label="Likes"
          value="likes"
          id="tab-likes"
          className="profile-nav-buttons"
        />
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({
  clickPass: state.clickPass,
});

export default connect(mapStateToProps, { set_click_pass })(ProfileNav);
