import React from "react";
import { motion } from "framer-motion";
import t from "../../utilities/transitions";
import LogoLoader from "../LogoLoader";

class Setup extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <h5 className="display-6 text-center mt-5">Retrieving Media Devices</h5>
        <div className="w-75 mx-auto mt-4">
          <LogoLoader />
        </div>
      </motion.div>
    );
  }
}

export default Setup;
