import env from "../../env";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { connect } from "react-redux";
import { set_click_pass } from "../../redux/actions";

class SideNav extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.clickPass?.action !== "feed-side-nav" &&
      this.props.clickPass?.action === "feed-side-nav"
    ) {
      this.props.selectTab(undefined, this.props.clickPass.id);
      this.props.set_click_pass(false);
    }
  }

  render() {
    const showPromo = false;
    // ["localhost"].includes(window.location.hostname);
    return (
      <Tabs
        className={`w-max-content ${showPromo ? "" : "mt-5"}`}
        orientation="vertical"
        value={this.props.tab}
        onChange={this.props.selectTab}
      >
        {this.props.userInfo._id ? (
          <Tab
            style={{ minHeight: "48px" }}
            icon={<i className="fas fa-at text-success"></i>}
            iconPosition="start"
            label={env.FOLLOW_CURRENT}
            className="side-nav-buttons"
            value="following"
            id="tab-following"
          />
        ) : (
          <span className="d-none"></span>
        )}
        <Tab
          style={{ minHeight: "48px" }}
          icon={<i className="fas fa-chart-line text-danger"></i>}
          iconPosition="start"
          label="Popular"
          value="popular"
          id="tab-popular"
          className="side-nav-buttons"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={<i style={{ color: "#FFAB00" }} className="far fa-clock"></i>}
          iconPosition="start"
          label="Recent"
          className="side-nav-buttons"
          value="recent"
          id="tab-recent"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={
            <i style={{ color: "#795548" }} className="fas fa-photo-video"></i>
          }
          iconPosition="start"
          label="Files"
          className="side-nav-buttons"
          value="files"
          id="tab-files"
        />
        {String(env.STREAMING_ENABLED) === "true" ? (
          <Tab
            style={{ minHeight: "48px" }}
            icon={
              <i
                style={{ color: "#fc45df" }}
                className="fas fa-broadcast-tower"
              ></i>
            }
            iconPosition="start"
            label="Live Now"
            className="side-nav-buttons"
            value="live"
            id="tab-live"
          />
        ) : (
          <span className="d-none"></span>
        )}
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { set_click_pass })(SideNav);
