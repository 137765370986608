import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBRipple,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardFooter,
} from "mdb-react-ui-kit";
import Spinner from "../components/Spinner";
import { set_contact_message_read } from "../redux/actions";

const ContactMessages = ({
  contactMessages,
  contactMessagesLoaded,
  screenDimensions,
  set_contact_message_read,
  socket,
  userInfo,
}) => {
  const [messageSelected, setMessageSelected] = useState("");
  const [loaded, setLoaded] = useState(false);

  const message = contactMessages.find((m) => m._id === messageSelected);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (message && messageSelected) {
      const unread = !message.readBy.includes(userInfo._id);
      if (unread) {
        set_contact_message_read(messageSelected);
        socket.emit("read-contact-message", messageSelected);
      }
    }
  }, [messageSelected]);

  useEffect(() => {
    if (screenDimensions.width < 993 && messageSelected) setMessageSelected("");
  }, [screenDimensions]);

  return (
    <motion.div
      transition={t.transition}
      exit={t.fade_out_scale_1}
      animate={t.normalize}
      initial={t.fade_out}
      className="pt-4 pb-3 h-100"
    >
      <MDBContainer className="h-100 message-container" fluid>
        <motion.div
          transition={t.transition}
          exit={t.fade_out_scale_1}
          animate={t.normalize}
          initial={t.fade_out}
          className="row h-100"
        >
          <div className="col-12 col-lg-4 h-100 message-list-lg">
            <MDBCard className="d-flex flex-column h-100">
              <MDBCardHeader className="d-flex justify-content-between align-items-center">
                <h5 id="messages-title" className="m-0 display-6 fs-3">
                  Contact Form Messages
                </h5>
              </MDBCardHeader>
              <MDBCardBody className="fg-1 overflow-y-auto p-0">
                {!contactMessages.length ? (
                  <>
                    {contactMessagesLoaded ? (
                      <p className="text-center mt-4">No messages found</p>
                    ) : (
                      <div className="d-flex justify-content-center mt-4">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </>
                ) : (
                  <MDBListGroup>
                    {contactMessages
                      .sort(
                        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                      )
                      .map((message) => {
                        const unread = !message.readBy.includes(userInfo._id);
                        return (
                          <MDBRipple>
                            <MDBListGroupItem
                              action
                              className={`cursor-pointer ps-0 z-index-50 `}
                              onClick={() => setMessageSelected(message._id)}
                              key={message._id}
                              active={messageSelected === message._id}
                            >
                              <motion.div
                                className="d-flex position-relative"
                                initial={loaded ? t.fade_out : t.normalize}
                                animate={t.normalize}
                                exit={t.fade_out_scale_1}
                                transition={t.transition}
                              >
                                <div
                                  className="align-self-center"
                                  transition={t.transition}
                                  initial={t.fade_out}
                                  animate={t.normalize}
                                  exit={t.fade_out_scale_1}
                                >
                                  <i
                                    className="fas fa-circle text-primary mx-2"
                                    style={{
                                      opacity: unread ? 1 : 0,
                                    }}
                                  />
                                </div>
                                <div className="flex-grow-1">
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <h6
                                        className={`m-0 ${
                                          unread ? "fw-bold" : ""
                                        }`}
                                      >
                                        {h.veryShortString(message.name)}
                                      </h6>
                                      <p
                                        className={`m-0 ${
                                          unread ? "fw-bold" : ""
                                        }`}
                                      >
                                        {h.veryShortString(message.subject)}
                                      </p>
                                    </div>
                                    <p
                                      className={`m-0 ${
                                        unread ? "fw-bold" : ""
                                      } ${
                                        messageSelected !== message._id
                                          ? "text-blusteel"
                                          : ""
                                      }`}
                                    >
                                      {h.getMessageTime(message.timestamp)}
                                    </p>
                                  </div>
                                  <p
                                    className={`m-0 ${unread ? "fw-bold" : ""}`}
                                  >
                                    {h.shortString(message.message)}
                                  </p>
                                </div>
                              </motion.div>
                            </MDBListGroupItem>
                          </MDBRipple>
                        );
                      })}
                  </MDBListGroup>
                )}
              </MDBCardBody>
            </MDBCard>
          </div>
          {messageSelected ? (
            <motion.section
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
              className="col-12 col-lg-8 h-100"
            >
              <MDBCard>
                <MDBCardHeader>
                  <h5 className="m-0">{message.name}</h5>
                </MDBCardHeader>
                <MDBCardBody>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      <span className="fw-bold">Email:</span> {message.email}
                    </p>
                    <p className="text-blusteel">
                      {h.getMessageTime(message.timestamp)}
                    </p>
                  </div>

                  <p>
                    <span className="fw-bold">Subject:</span> {message.subject}
                  </p>
                  <p className="fw-bold">Message</p>
                  <p>{message.message}</p>
                </MDBCardBody>
                {screenDimensions.width < 993 && (
                  <MDBCardFooter
                    className="cursor-pointer bg-blusteel text-light d-flex justify-content-center align-items-center z-index-50"
                    onKeyDown={() => setMessageSelected("")}
                    onClick={() => setMessageSelected("")}
                  >
                    <i className="fas fa-chevron-left me-2" />
                    Messages
                  </MDBCardFooter>
                )}
              </MDBCard>
            </motion.section>
          ) : (
            <motion.div
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
              className="col-12 col-lg-4 h-100 message-list-mobile pt-4"
            >
              <MDBCard className="d-flex flex-column h-100">
                <MDBCardHeader className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0 display-6 fs-3 text-center">Messages</h5>
                </MDBCardHeader>
                <MDBCardBody className="fg-1 overflow-y-auto p-0">
                  {!contactMessages.length ? (
                    <p className="text-center mt-4">No conversations found</p>
                  ) : (
                    <MDBListGroup>
                      {contactMessages
                        .sort(
                          (a, b) =>
                            new Date(b.timestamp) - new Date(a.timestamp)
                        )
                        .map((message) => {
                          const unread = !message.readBy.includes(userInfo._id);

                          return (
                            <MDBRipple>
                              <MDBListGroupItem
                                action
                                className={`cursor-pointer ps-0 z-index-50`}
                                onClick={() => setMessageSelected(message._id)}
                                key={message._id}
                                active={messageSelected === message._id}
                              >
                                <motion.div
                                  className="d-flex position-relative"
                                  initial={loaded ? t.fade_out : t.normalize}
                                  animate={t.normalize}
                                  exit={t.fade_out_scale_1}
                                  transition={t.transition}
                                >
                                  <div className="align-self-center">
                                    <i
                                      className="fas fa-circle text-primary mx-2"
                                      style={{
                                        opacity: unread ? 1 : 0,
                                      }}
                                    />
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between">
                                      <div>
                                        <h6
                                          className={`m-0 ${
                                            unread ? "fw-bold" : ""
                                          }`}
                                        >
                                          {h.veryShortString(message.name)}
                                        </h6>
                                        <p
                                          className={`m-0 ${
                                            unread ? "fw-bold" : ""
                                          }`}
                                        >
                                          {h.veryShortString(message.subject)}
                                        </p>
                                      </div>
                                      <p
                                        className={`m-0 ${
                                          unread ? "fw-bold" : ""
                                        } ${
                                          messageSelected !== message._id
                                            ? "text-blusteel"
                                            : ""
                                        }`}
                                      >
                                        {h.getMessageTime(message.timestamp)}
                                      </p>
                                    </div>
                                    <p
                                      className={`m-0 ${
                                        unread ? "fw-bold" : ""
                                      }`}
                                    >
                                      {h.shortString(message.message)}
                                    </p>
                                  </div>
                                </motion.div>
                              </MDBListGroupItem>
                            </MDBRipple>
                          );
                        })}
                    </MDBListGroup>
                  )}
                </MDBCardBody>
              </MDBCard>
            </motion.div>
          )}
        </motion.div>
      </MDBContainer>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  contactMessages: state.contactMessages,
  contactMessagesLoaded: state.contactMessagesLoaded,
  screenDimensions: state.screenDimensions,
  socket: state.socket,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, { set_contact_message_read })(
  ContactMessages
);
