import env from "../../env";
import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import {
  set_profile,
  route,
  like_self,
  add_emissions,
  new_emission,
  set_temp_action,
  clear_temp_action,
  set_poll_voted,
  update_emission,
  set_poll_modal,
  signal_boost,
  set_token,
  notify,
} from "../../redux/actions";
import FileModal from "../../components/fileModal/FileModal";
import SignalBoostModal from "../../components/actionModals/SignalBoostModal";
import axios from "axios";
import ReplyModal from "../../components/actionModals/ReplyModal";
import Url from "url-parse";
import Emissions from "./Emissions";
import EmissionsAndReplies from "./EmissionsAndReplies";
import Media from "./Media";
import Likes from "./Likes";
import { v4 as uuid } from "uuid";

let loadingMore = false;

class ProfileComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * loaded: Boolean - Whether the initial emission data has been loaded
       * emissionsReported: Array - List of Emission emissionIDs that have been reported
       * emissionCopied: false | Number - EmissionID of Emission copied
       * fileModalShown: Boolean - Whether the file modal is shown
       * signalBoostModalShown: Boolean - Whether the signal boost modal is shown
       * replyModalShown: Boolean - Whether the reply modal is shown
       * fileSelected: false | Object - The file currently selected
       * fileList: Array - List of files on the emission that the fileSelected belongs to
       * emissionSelected: false | Object - The emission currently selected
       * polls: Array - List of polls that the user is voting in
       * pollsSubmitting: Array - List of polls that are in the process of having their votes submitted
       * emissionSignalBoosting: false | Object - The emission currently signal boosting
       * emissionReplying: false | Object - The emission currently replying
       * loadingMore: Array - List of emissions with replies that are currently loading more replies
       */
      loaded: false,
      emissionsReported: [],
      actionFormOpened: false,
      emissionCopied: false,
      fileModalShown: false,
      signalBoostModalShown: false,
      replyModalShown: false,
      fileSelected: false,
      fileList: [],
      emissionSelected: false,
      polls: [],
      pollsSubmitting: [],
      emissionSignalBoosting: false,
      emissionReplying: false,
      loadingMore: false,
      scrollToEmission: 0,
    };
    this.flavor = props.flavor;
  }

  componentDidMount() {
    document.getElementById("root").addEventListener("scroll", this.scroll);
  }

  /**
   * If user logs in or out, reset polls and set metadata on emissions
   * If user logs in with temp action, trigger temp action
   */
  componentDidUpdate(prevProps) {
    if (prevProps.userInfo._id !== this.props.userInfo._id)
      this.setState(
        (curr) => ({
          ...curr,
          polls: [],
          pollsSubmitting: [],
        }),
        () => {
          if (this.props.tempAction) this.triggerTempAction();
        }
      );
    const emissionSelected = this.getEmissionSelected(this.props);
    const prevEmissionSelected = this.getEmissionSelected(prevProps);
    if (
      emissionSelected?.files &&
      prevEmissionSelected?.files &&
      JSON.stringify(emissionSelected.files) !==
        JSON.stringify(prevEmissionSelected.files)
    ) {
      this.setState((curr) => ({
        ...curr,
        fileSelected:
          emissionSelected.files[
            this.state.fileList.indexOf(this.state.fileSelected)
          ],
        fileList: emissionSelected.files,
      }));
    }
  }

  componentWillUnmount() {
    document.getElementById("root").removeEventListener("scroll", this.scroll);
  }

  scroll = (e) => {
    if (
      !loadingMore &&
      !this.state.loadingMore &&
      e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
        0.1 * e.target.clientHeight
    ) {
      const { profileInfo } = this.props;
      switch (this.flavor) {
        case "emissions":
          if (
            !loadingMore &&
            profileInfo.emissions.items.length &&
            profileInfo.emissions.items.filter((emission) => !emission.replyID)
              .length < profileInfo.emissions.totalNoReply
          ) {
            loadingMore = true;
            setTimeout(() => (loadingMore = false), 500);
            return this.seeMore();
          }

          break;
        case "emissionsAndReplies":
          const emissionsAndReplies = profileInfo.emissions.items.filter(
            (emission) => emission.replyID
          );
          if (
            !loadingMore &&
            emissionsAndReplies.length < profileInfo.emissions.totalReplies
          ) {
            loadingMore = true;
            setTimeout(() => (loadingMore = false), 500);
            return this.seeMore();
          }

          break;
        case "media":
          if (
            !loadingMore &&
            profileInfo.emissions.items.filter((emission) => emission.files)
              .length < profileInfo.emissions.totalMedia
          ) {
            loadingMore = true;
            setTimeout(() => (loadingMore = false), 500);
            return this.seeMore();
          }

          break;
        case "likes":
          if (
            !loadingMore &&
            profileInfo.likes.items.length < profileInfo.likes.total
          ) {
            loadingMore = true;
            setTimeout(() => (loadingMore = false), 500);
            return this.seeMore();
          }

          break;
        default:
          console.log("oob flavor", this.flavor);
      }
    }
  };

  /**
   * Triggered when the user logs in with a temp action
   * Executes the temp action
   */
  triggerTempAction = () => {
    if (!this.props.userInfo.ban.banned) {
      let e = false;
      switch (this.props.tempAction.label) {
        case "Reply":
          ["emissions", "likes"].forEach((parameter) =>
            this.props.profileInfo[parameter].items.forEach((emission) => {
              if (
                emission.emissionID ===
                this.props.tempAction.emission.emissionID
              )
                e = emission;
              if (
                emission.signalBoost &&
                emission.signalBoost.emissionID ===
                  this.props.tempAction.emission.emissionID
              )
                e = emission.signalBoost;

              if (emission.replyEmission) {
                if (
                  emission.replyEmission.emissionID ===
                  this.props.tempAction.emission.emissionID
                )
                  e = emission.replyEmission;
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.emissionID ===
                    this.props.tempAction.emission.emissionID
                )
                  e = emission.replyEmission.signalBoost;

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.emissionID ===
                    this.props.tempAction.emission.emissionID
                  )
                    e = emission.replyEmission.replyEmission;
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID === this.props.tempAction.emission.emissionID
                  )
                    e = emission.replyEmission.replyEmission.signalBoost;
                }
              }
            })
          );

          if (!e) e = this.props.tempAction.emission;
          this.reply(e);
          break;
        case "Signalboost":
          ["emissions", "likes"].forEach((parameter) =>
            this.props.profileInfo[parameter].items.forEach((emission) => {
              if (
                emission.emissionID ===
                this.props.tempAction.emission.emissionID
              )
                e = emission;
              if (
                emission.signalBoost &&
                emission.signalBoost.emissionID ===
                  this.props.tempAction.emission.emissionID
              )
                e = emission.signalBoost;

              if (emission.replyEmission) {
                if (
                  emission.replyEmission.emissionID ===
                  this.props.tempAction.emission.emissionID
                )
                  e = emission.replyEmission;
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.emissionID ===
                    this.props.tempAction.emission.emissionID
                )
                  e = emission.replyEmission.signalBoost;

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.emissionID ===
                    this.props.tempAction.emission.emissionID
                  )
                    e = emission.replyEmission.replyEmission;
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID === this.props.tempAction.emission.emissionID
                  )
                    e = emission.replyEmission.replyEmission.signalBoost;
                }
              }
            })
          );

          if (!e) e = this.props.tempAction.emission;
          this.signalBoost(e);
          break;
        case "Vote":
          let voted = false;

          ["emissions", "likes"].forEach((parameter) =>
            this.props.profileInfo[parameter].items.forEach((emission) => {
              if (
                emission.emissionID ===
                  this.props.tempAction.emission.emissionID &&
                emission.pollData
              )
                voted = emission.pollData.voted;
              if (
                emission.signalBoost &&
                emission.signalBoost.emissionID ===
                  this.props.tempAction.emission.emissionID &&
                emission.signalBoost.pollData
              )
                voted = emission.signalBoost.pollData.voted;

              if (emission.replyEmission) {
                if (
                  emission.replyEmission.emissionID ===
                    this.props.tempAction.emission.emissionID &&
                  emission.replyEmission.pollData
                )
                  voted = emission.replyEmission.pollData.voted;
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.emissionID ===
                    this.props.tempAction.emission.emissionID &&
                  emission.replyEmission.signalBoost.pollData
                )
                  voted = emission.replyEmission.signalBoost.pollData.voted;

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.emissionID ===
                      this.props.tempAction.emission.emissionID &&
                    emission.replyEmission.replyEmission.pollData
                  )
                    voted = emission.replyEmission.replyEmission.pollData.voted;
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID ===
                      this.props.tempAction.emission.emissionID &&
                    emission.replyEmission.replyEmission.signalBoost.pollData
                  )
                    voted =
                      emission.replyEmission.replyEmission.signalBoost.pollData
                        .voted;
                }
              }
            })
          );

          if (!voted)
            this.vote(
              this.props.tempAction.emission,
              this.props.tempAction.option
            );

          break;
        case "Submit Votes":
          this.submitVotes(
            this.props.tempAction.emission,
            this.props.tempAction.participation
          );
          break;
        case "Like":
          let liked = false;
          ["emissions", "likes"].forEach((parameter) =>
            this.props.profileInfo[parameter].items.forEach((emission) => {
              if (emission.emissionID === this.props.tempAction.emissionID)
                liked = emission.liked;
              if (
                emission.signalBoost &&
                emission.signalBoost.emissionID ===
                  this.props.tempAction.emissionID
              )
                liked = emission.signalBoost.liked;

              if (emission.replyEmission) {
                if (
                  emission.replyEmission.emissionID ===
                  this.props.tempAction.emissionID
                )
                  liked = emission.replyEmission.liked;
                if (
                  emission.replyEmission.signalBoost &&
                  emission.replyEmission.signalBoost.emissionID ===
                    this.props.tempAction.emissionID
                )
                  liked = emission.replyEmission.signalBoost.liked;

                if (emission.replyEmission.replyEmission) {
                  if (
                    emission.replyEmission.replyEmission.emissionID ===
                    this.props.tempAction.emissionID
                  )
                    liked = emission.replyEmission.replyEmission.liked;
                  if (
                    emission.replyEmission.replyEmission.signalBoost &&
                    emission.replyEmission.replyEmission.signalBoost
                      .emissionID === this.props.tempAction.emissionID
                  )
                    liked =
                      emission.replyEmission.replyEmission.signalBoost.liked;
                }
              }
            })
          );
          if (!liked) this.like(this.props.tempAction.emissionID);
          break;
        default:
          console.log("oob temp action", this.props.tempAction);
          break;
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );

    if (["follow", "message"].indexOf(this.props.tempAction.label) === -1)
      this.props.clear_temp_action();
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user reports an emission
   * Sets reported all emissions/replies/signalboosts with emissionID
   */
  setEmissionReported = (emissionID) =>
    this.setState((curr) => ({
      ...curr,
      emissionsReported: [...curr.emissionsReported, emissionID],
    }));

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks the Report or Remove button on an emission
   *
   * Opens the correspondinit report/remove form, and closes the others
   */
  openActionForm = (emissionID) => {
    if (!this.props.userInfo.ban.banned)
      this.setState((curr) => ({
        ...curr,
        actionFormOpened:
          this.state.actionFormOpened === emissionID ? false : emissionID,
      }));
    else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks inside the emission body
   * If the user clicked a link, route to the href
   */
  clickEmissionBody = (e, emissionID) => {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target;
    if (e.target.tagName === "SPAN") element = e.target.parentElement;
    if (element.tagName === "A") {
      const href = element.getAttribute("href");
      if (href) {
        const url = new Url(href);
        if (url.hostname === window.location.hostname)
          this.props.route(url.pathname);
        else {
          console.log("native route FileModal");
          window.location = href;
        }
      }
    } else if (emissionID) this.props.route("/e/" + emissionID);
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionId
   *
   * Triggered when the user clicks the Copy Link button on an emission
   * Copies the link to the clipboard
   */
  copyEmissionLink = (emissionID) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissionCopied: emissionID,
      }),
      () => {
        h.copyToClipboard(
          "https://" + window.location.host + "/emissions/" + emissionID
        );
        window.dispatchEvent(new Event("scroll"));
      }
    );

  toggleFileModal = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          fileModalShown: !this.state.fileModalShown,
        }),
        () => this.hideToolTips()
      );
  };

  setFileModal = (option) => {
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        fileModalShown: option,
      }));
  };

  toggleLoginModal = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          loginModalShown: !this.state.loginModalShown,
        }),
        () => this.hideToolTips()
      );
  };

  setLoginModal = (option) => {
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        loginModalShown: option,
      }));
  };

  toggleReplyModal = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          replyModalShown: !this.state.replyModalShown,
        }),
        () => this.hideToolTips()
      );
  };

  setReplyModal = (option) => {
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        replyModalShown: option,
      }));
  };

  /**
   *
   * @param {Object} emission - Emissions document
   *
   * Triggered when the user clicks the Reply button
   * Opens the Reply modal if logged in
   * Opens the Login modal if not logged in
   */
  reply = (emission) => {
    if (!this.props.userInfo.ban.banned) {
      this.toggleReplyModal();
      this.setState((curr) => ({
        ...curr,
        emissionReplying: emission,
        fileModalShown: false,
      }));
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  toggleSignalBoostModal = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          signalBoostModalShown: !this.state.signalBoostModalShown,
        }),
        () => this.hideToolTips()
      );
  };

  setSignalBoostModal = (option) => {
    if (!this.state.working)
      this.setState((curr) => ({
        ...curr,
        signalBoostModalShown: option,
      }));
  };

  /**
   *
   * @param {Object} emission - Emissions document
   *
   * Triggered when the user clicks the Signalboost button
   * Opens the Signalboost modal if logged in
   * Opens the Login modal if not logged in
   */
  signalBoost = (emission) => {
    if (!this.props.userInfo.ban.banned) {
      const userID = this.props.userInfo._id;
      if (userID) this.toggleSignalBoostModal();
      else {
        this.props.toggleLoginModal();
        this.props.set_temp_action({
          label: "Signalboost",
          emission: emission,
          tab: "emissions",
        });
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   *
   * @param {Object} file - Emission file object
   * @param {Array} fileList - List of files on emission that the file belongs to
   * @param {Emission} emission - Emission that the file belongs to
   *
   * Set file, fileList and emission into state
   * Toggle file modal
   */
  selectFile = (file, fileList, emission) => {
    this.toggleFileModal();
    this.setState((curr) => ({
      ...curr,
      fileSelected: file,
      fileList: fileList,
      emissionSelected: emission._id,
    }));
  };

  /**
   *
   * @param {Boolean} forward - Whether the user is navigating forward
   *
   * Triggered when the user clicks one of the arrows in the file modal
   * Changes the file selected to the previous or next depending on forward
   */
  fileNav = (forward) => {
    this.setState((curr) => ({
      ...curr,
      fileSelected:
        this.state.fileList[
          forward
            ? this.state.fileList.indexOf(this.state.fileSelected) + 1
            : this.state.fileList.indexOf(this.state.fileSelected) - 1
        ],
    }));
  };

  // Hide all tooltips on the page
  hideToolTips = () =>
    Array.from(document.getElementsByClassName("tooltip")).forEach((e) =>
      e.classList.remove("show")
    );

  /**
   *
   * @param {Object} emission - Emissions document
   * @param {String} option - id of vote option
   *
   * Find poll object in state.
   * If exists, insert vote.
   * Else, insert poll object with single vote
   */
  vote = (emission, option) => {
    console.log("profile", emission, option);
    if (!this.props.userInfo.ban.banned) {
      if (this.props.userInfo._id) {
        if (
          !this.state.pollsSubmitting.find(
            (poll) => poll.emissionID === emission.emissionID
          )
        ) {
          let voteObj = this.state.polls.find(
            (vote) => vote.emissionID === emission.emissionID
          );
          if (voteObj) {
            if (voteObj.votes.find((v) => v === option))
              this.setState((curr) => ({
                ...curr,
                polls: [
                  ...curr.polls.filter(
                    (p) => p.emissionID !== emission.emissionID
                  ),
                  {
                    ...voteObj,
                    votes: voteObj.votes.filter((v) => v !== option),
                  },
                ],
              }));
            else
              this.setState((curr) => ({
                ...curr,
                polls: [
                  ...curr.polls.filter(
                    (p) => p.emissionID !== emission.emissionID
                  ),
                  {
                    ...voteObj,
                    votes: [
                      option,
                      ...voteObj.votes.filter(
                        (vote, v) => v < emission.pollData.votesAllowed - 1
                      ),
                    ],
                  },
                ],
              }));
          } else
            this.setState((curr) => ({
              ...curr,
              polls: [
                ...curr.polls,
                {
                  emissionID: emission.emissionID,
                  votes: [option],
                },
              ],
            }));
        }
      } else {
        this.props.toggleLoginModal();
        this.props.set_temp_action({
          label: "Vote",
          emission: emission,
          option: option,
          tab: "emissions",
        });
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  fileAction = (action) =>
    this.setState(
      (curr) => ({
        ...curr,
        fileModalShown: false,
      }),
      action
    );

  /**
   *
   * @param {Object} emission - Emissions document
   * @param {Boolean} participation - Whether the user is participating in the poll
   *
   * Triggered when the user submits a poll
   *
   * Submit poll data to server
   * Update emission
   */
  submitVotes = (emission, participation) => {
    if (!this.props.userInfo.ban.banned) {
      if (this.props.userInfo._id) {
        if (
          !this.state.pollsSubmitting.find(
            (poll) => poll.emissionID === emission.emissionID
          )
        ) {
          if (participation) {
            const pollObj = this.state.polls.find(
              (poll) => poll.emissionID === emission.emissionID
            );
            if (!pollObj || !pollObj.votes.length)
              alert("You must enter at least one vote.");
            else
              this.setState(
                (curr) => ({
                  ...curr,
                  pollsSubmitting: [
                    ...curr.pollsSubmitting,
                    {
                      emissionID: emission.emissionID,
                      participating: true,
                    },
                  ],
                }),
                () =>
                  axios
                    .post(
                      process.env.REACT_APP_LAMBDA_EMISSIONS + "/vote",
                      {
                        ...pollObj,
                        participating: true,
                        emissionID: emission.emissionID,
                        pollID: emission.pollID,
                      },
                      {
                        headers: {
                          Authorization: this.props.token,
                        },
                      }
                    )
                    .then((res) => {
                      this.props.set_token(res.data.token);
                      this.setState(
                        (curr) => ({
                          ...curr,
                          pollsSubmitting: this.state.pollsSubmitting.filter(
                            (poll) => poll.emissionID !== emission.emissionID
                          ),
                        }),
                        () => this.props.set_poll_voted(emission.emissionID)
                      );
                    })
                    .catch((err) =>
                      this.setState(
                        (curr) => ({
                          ...curr,
                          pollsSubmitting: this.state.pollsSubmitting.filter(
                            (poll) => poll.emissionID !== emission.emissionID
                          ),
                        }),
                        () => {
                          console.log(err);
                          alert("An error occurred. Please try again later.");
                        }
                      )
                    )
              );
          } else
            this.setState(
              (curr) => ({
                ...curr,
                pollsSubmitting: [
                  ...curr.pollsSubmitting,
                  {
                    emissionID: emission.emissionID,
                    participating: false,
                  },
                ],
              }),
              () =>
                axios
                  .post(
                    process.env.REACT_APP_LAMBDA_EMISSIONS + "/vote",
                    {
                      emissionID: emission.emissionID,
                      pollID: emission.pollID,
                      participating: false,
                    },
                    {
                      headers: {
                        Authorization: this.props.token,
                      },
                    }
                  )
                  .then((res) => {
                    this.props.set_token(res.data.token);
                    this.setState(
                      (curr) => ({
                        ...curr,
                        pollsSubmitting: this.state.pollsSubmitting.filter(
                          (poll) => poll.emissionID !== emission.emissionID
                        ),
                      }),
                      () => this.props.set_poll_voted(emission.emissionID)
                    );
                  })
                  .catch((err) =>
                    this.setState(
                      (curr) => ({
                        ...curr,
                        pollsSubmitting: this.state.pollsSubmitting.filter(
                          (poll) => poll.emissionID !== emission.emissionID
                        ),
                      }),
                      () => {
                        console.log(err);
                        alert("An error occurred. Please try again later.");
                      }
                    )
                  )
            );
        }
      } else {
        this.props.toggleLoginModal();
        this.props.set_temp_action({
          label: "Submit Votes",
          emission: emission,
          participation: participation,
          tab: "emissions",
        });
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user likes or unlikes an emission
   * Triggers this.likeSelf
   * Make network request
   */
  like = (emissionID) => {
    if (!this.props.userInfo.ban.banned) {
      if (this.props.userInfo._id) {
        const eventID = uuid();
        this.props.like_self(emissionID, eventID);

        const likeOnNetwork = () =>
          axios
            .post(
              `${process.env.REACT_APP_LAMBDA_EMISSIONS}/like`,
              { eventID, emissionID },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => this.props.set_token(res.data.token))
            .catch((err) => {
              console.log("Error committing the like", err);
              if (err.response?.status === 401) console.log("Forbidden.");
              else setTimeout(likeOnNetwork, 1000);
            });

        likeOnNetwork();
      } else {
        this.props.toggleLoginModal();
        this.props.set_temp_action({
          label: "Like",
          emissionID: emissionID,
          tab: "emissions",
        });
        this.setState((curr) => ({
          ...curr,
          fileModalShown: false,
        }));
      }
    } else
      this.props.notify(
        <i className="fas fa-gavel me-2 text-danger"></i>,
        "You are banned"
      );
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks the View More button in a reply thread
   * Loads more replies from the thread
   */
  seeMore = (retry) => {
    if (!this.state.loadingMore || retry)
      this.setState(
        (curr) => ({
          ...curr,
          loadingMore: true,
        }),
        () => {
          loadingMore = false;
          let category;
          let lastEmission;
          switch (this.flavor) {
            case "emissions":
              category = "noReply";
              lastEmission = this.props.profileInfo.emissions.items
                .filter((emission) => !emission.replyID)
                .sort((a, b) => a.emissionID - b.emissionID)[0].emissionID;
              break;
            case "emissionsAndReplies":
              category = "replies";
              lastEmission = this.props.profileInfo.emissions.items
                .filter((emission) => emission.replyID)
                .sort((a, b) => a.emissionID - b.emissionID)[0].emissionID;
              break;
            case "likes":
              category = "likes";
              lastEmission = this.props.profileInfo.likes.items.sort(
                (a, b) => a.emissionID - b.emissionID
              )[0].emissionID;
              break;
            case "media":
              category = "media";
              lastEmission = this.props.profileInfo.emissions.items
                .filter((emission) => emission.files)
                .sort((a, b) => a.emissionID - b.emissionID)[0].emissionID;
              break;
            default:
              console.log("oob flavor see more", this.flavor);
              category = "noReply";
          }
          axios
            .post(
              process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/more",
              {
                username: this.props.profileInfo?.user?.username.toLowerCase(),
                lastEmission: lastEmission,
                category: category,
                likesCollected: this.props.profileInfo.likes.items.map(
                  (like) => like.emissionID
                ),
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  loadingMore: false,
                  scrollToEmission: res.data.emissions.sort(
                    (a, b) => b.emissionID - a.emissionID
                  )[0]?.emissionID,
                }),
                () => this.props.add_emissions(res.data)
              );
            })
            .catch((err) => {
              console.log(err);
              setTimeout(() => this.seeMore(true), 1500);
            });
        }
      );
  };

  handlePin = (emission) => {
    if (emission.pinned)
      setTimeout(() => {
        if (
          ["emissionsAndReplies", "likes"].indexOf(this.props.tabSelected) > -1
        )
          this.props.selectTab(false, "emissions");
        else if (this.props.tabSelected === "media") {
          if (!emission.files) this.props.selectTab(false, "emissions");
        }
        document.getElementById("feed-top").scrollIntoView();
      }, 333);
    if (
      this.props.profileInfo.emissions.items.find(
        (e) => e.emissionID === emission.emissionID
      )
    )
      this.props.update_emission(h.setMetadata(emission));
    else this.props.new_emission(h.setMetadata(emission));
  };

  renderFeedFlavor = () => {
    const props = {
      profileInfo: this.props.profileInfo,
      clickEmissionBody: this.clickEmissionBody,
      vote: this.vote,
      polls: this.state.polls,
      submitVotes: this.submitVotes,
      pollsSubmitting: this.state.pollsSubmitting,
      selectFile: this.selectFile,
      signalBoost: this.signalBoost,
      like: this.like,
      copyEmissionLink: this.copyEmissionLink,
      emissionCopied: this.state.emissionCopied,
      reply: this.reply,
      userInfo: this.props.userInfo,
      route: this.props.route,
      report: this.props.report,
      remove: this.props.remove,
      restore: this.props.restore,
      loadingMore: this.state.loadingMore,
      seeMore: this.seeMore,
      handlePin: this.handlePin,
      updating: this.props.updating,
      token: this.props.token,
    };
    switch (this.flavor) {
      case "emissions":
        return <Emissions {...props} />;
      case "emissionsAndReplies":
        return <EmissionsAndReplies {...props} />;
      case "media":
        return <Media {...props} />;
      case "likes":
        return <Likes {...props} />;
      default:
        console.log("oob flavor", this.flavor);
        return <Emissions {...props} />;
    }
  };

  modalSignalBoost = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        signalBoostModalShown: false,
      }),
      () => this.props.signal_boost(e)
    );

  getEmissionSelected = (props) => {
    let emissionSelected;
    ["emissions", "likes"].forEach((criteria) => {
      if (!emissionSelected)
        emissionSelected = props.profileInfo[criteria].items.find(
          (e) => e._id === this.state.emissionSelected
        );
      if (!emissionSelected)
        emissionSelected = props.profileInfo[criteria].items.find(
          (e) => e.signalBoost?._id === this.state.emissionSelected
        )?.signalBoost;
      if (!emissionSelected)
        emissionSelected = props.profileInfo[criteria].items.find(
          (e) => e.replyEmission?._id === this.state.emissionSelected
        )?.replyEmission;
      if (!emissionSelected)
        emissionSelected = props.profileInfo[criteria].items.find(
          (e) =>
            e.replyEmission?.signalBoost?._id === this.state.emissionSelected
        )?.replyEmission?.signalBoost;
      if (!emissionSelected)
        emissionSelected = props.profileInfo[criteria].items.find(
          (e) =>
            e.replyEmission?.replyEmission?._id === this.state.emissionSelected
        )?.replyEmission?.replyEmission;
      if (!emissionSelected)
        emissionSelected = props.profileInfo[criteria].items.find(
          (e) =>
            e.replyEmission?.replyEmission?.signalBoost?._id ===
            this.state.emissionSelected
        )?.replyEmission?.replyEmission?.signalBoost;
    });
    return emissionSelected;
  };

  render() {
    const emissionSelected = this.getEmissionSelected(this.props);
    let replies = [];
    if (emissionSelected) {
      ["emissions", "likes"].forEach((criteria) => {
        this.props.profileInfo[criteria].items.forEach((emission) => {
          if (
            !replies.find((r) => r._id === emission._id) &&
            emission.replyID === emissionSelected.emissionID
          )
            replies.push(emission);
          if (
            !replies.find((r) => r._id === emission.signalBoost?._id) &&
            emission.signalBoost?.replyID === emissionSelected.emissionID
          )
            replies.push(emission.signalBoost);
          if (emission.replyEmission) {
            if (
              !replies.find((r) => r._id === emission.replyEmission._id) &&
              emission.replyEmission.replyID === emissionSelected.emissionID
            )
              replies.push(emission.replyEmission);
            if (
              !replies.find(
                (r) => r._id === emission.replyEmission.signalBoost?._id
              ) &&
              emission.replyEmission.signalBoost?.replyID ===
                emissionSelected.emissionID
            )
              replies.push(emission.replyEmission.signalBoost);
            if (emission.replyEmission.replyEmission) {
              if (
                !replies.find(
                  (r) => r._id === emission.replyEmission.replyEmission._id
                ) &&
                emission.replyEmission.replyEmission.replyID ===
                  emissionSelected.emissionID
              )
                replies.push(emission.replyEmission.replyEmission);
              if (
                !replies.find(
                  (r) =>
                    r._id ===
                    emission.replyEmission.replyEmission.signalBoost?._id
                ) &&
                emission.replyEmission.replyEmission.signalBoost?.replyID ===
                  emissionSelected.emissionID
              )
                replies.push(emission.replyEmission.replyEmission.signalBoost);
            }
          }
        });
      });

      replies = replies.sort((a, b) => b.emissionID - a.emissionID);
    }
    return (
      <motion.div
        className="px-2"
        style={{ overflow: "hidden" }}
        transition={t.transition}
        exit={this.props.exit}
        animate={t.normalize}
        id="profile-component-container"
        initial={this.props.enter}
      >
        <FileModal
          modalShown={this.state.fileModalShown}
          setShowModal={this.setFileModal}
          toggleShowModal={this.toggleFileModal}
          file={this.state.fileSelected}
          fileList={this.state.fileList}
          fileNav={this.fileNav}
          emissionID={this.state.emissionSelected.emissionID}
          emissionList={[
            ...this.props.profileInfo.emissions.items,
            ...this.props.profileInfo.likes.items,
          ]}
          signalBoost={this.signalBoost}
          like={this.like}
          notify={this.props.notify}
          emission={emissionSelected}
          replies={replies}
          remove={this.props.remove}
          restore={this.props.restore}
          report={this.props.report}
          reply={this.reply}
          fileAction={this.fileAction}
          updateEmission={this.props.update_emission}
        />
        <SignalBoostModal
          modalShown={this.state.signalBoostModalShown}
          setShowModal={this.setSignalBoostModal}
          toggleShowModal={this.toggleSignalBoostModal}
          emission={this.state.emissionSignalBoosting}
          tabSelected={this.props.tabSelected}
          selectTab={this.props.selectTab}
          notify={this.props.notify}
          signalBoost={this.modalSignalBoost}
        />
        <ReplyModal
          modalShown={this.state.replyModalShown}
          setShowModal={this.setReplyModal}
          toggleShowModal={this.toggleReplyModal}
          emission={this.state.emissionReplying}
          tabSelected={this.props.tabSelected}
          selectTab={this.props.selectTab}
        />
        {this.renderFeedFlavor()}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  set_profile,
  route,
  like_self,
  add_emissions,
  new_emission,
  set_temp_action,
  clear_temp_action,
  set_poll_voted,
  update_emission,
  set_poll_modal,
  signal_boost,
  set_token,
  notify,
})(ProfileComponent);
