import env from "../../env";
import React from "react";
import { connect } from "react-redux";
import { route, clear_temp_action, set_temp_action } from "../../redux/actions";
import { MDBTooltip, MDBBtn } from "mdb-react-ui-kit";
import h from "../../utilities/helpers";
import t from "../../utilities/transitions";
import GlobalEmissionInputModal from "./GlobalEmissionInputModal";
import { motion } from "framer-motion";

class GlobalEmissionInput extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether an emission is in the process of being submitted
       * emissionModalShown: Boolean - Whether the emission input modal is shown
       * loginModalShown: Boolean - Whether the login modal is shown
       * notificationIcon: JSX - The notification icon
       * notificationText: String - String of the notification
       */
      working: false,
      emissionModalShown: false,
      loginModalShown: false,
      notificationIcon: <></>,
      notificationText: "",
      testOpen: false,
      scrolling: false,
    };
  }

  /**
   * If user logs in using the login modal:
   * * Hide login modal
   * * Notify user that they have logged in
   * * Open Emission modal
   * * Clear temp action
   */
  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.scrollPosition &&
      this.props.scrollPosition &&
      this.state.scrolling
    )
      this.setState((curr) => ({
        ...curr,
        scrolling: false,
      }));
  }

  setEmissionModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      emissionModalShown: option,
    }));

  toggleEmissionModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        emissionModalShown: !this.state.emissionModalShown,
      }),
      () => this.props.clear_temp_action()
    );
  };

  scrollToTop = () =>
    this.setState(
      (curr) => ({
        ...curr,
        scrolling: true,
      }),
      () => (document.getElementById("root").scrollTop = 0)
    );

  render() {
    return (
      <>
        <GlobalEmissionInputModal
          modalShown={this.state.emissionModalShown}
          toggleShowModal={this.toggleEmissionModal}
          setShowModal={this.setEmissionModal}
        />
        <div
          className="position-fixed d-flex flex-column align-content-center"
          style={{
            bottom: "1rem",
            right: "1rem",
          }}
        >
          {(this.props.profileInfo.user?._id ||
            window.location.pathname === "/") &&
          this.props.scrollPosition &&
          !this.state.scrolling ? (
            <MDBBtn
              onClick={this.scrollToTop}
              color="link"
              rippleColor="primary"
              className="text-primary"
            >
              <motion.i
                transition={t.transition}
                initial={t.fade_out_minimize}
                animate={t.normalize}
                exit={t.fade_out_minimize}
                className="fas fa-angle-up fa-2x"
              />
            </MDBBtn>
          ) : (
            <></>
          )}
          {String(env.READ_ONLY) === "true" &&
          !h.checkChadmin(this.props.userInfo) ? (
            <></>
          ) : (
            <MDBTooltip
              wrapperProps={{
                size: "lg",
                style: {
                  width: "4rem",
                  height: "4rem",
                },
                floating: true,
                className: "global-input-button p-2 mt-4",
                onClick: () => {
                  setTimeout(() => h.hideToolTips(), 200);
                  this.toggleEmissionModal();
                },
              }}
              title={`New ${env.EMISSION_NAME}`}
              placement="left"
            >
              <div
                style={{
                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/icons/newjizz.png")`,
                }}
                className="fit-images"
              ></div>
            </MDBTooltip>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  clear_temp_action,
  set_temp_action,
})(GlobalEmissionInput);
