import env from "../../env";
import React from "react";
import { connect } from "react-redux";
import { set_click_pass } from "../../redux/actions";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

class FeedNav extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.clickPass &&
      this.props.clickPass?.action === "search-feed-nav"
    ) {
      this.props.selectTab(false, "emissions");
      this.props.set_click_pass(false);
    }
  }

  render() {
    return (
      <Tabs
        className="mt-2"
        value={this.props.tab}
        onChange={this.props.selectTab}
        id="search-page-nav"
      >
        <Tab
          style={{ minHeight: "48px" }}
          icon={<i className="fas fa-wifi text-danger"></i>}
          iconPosition="start"
          label={env.EMISSION_PLURAL}
          value="emissions"
          id="tab-emissions"
        />
        <Tab
          style={{ minHeight: "48px" }}
          icon={<i className="fas fa-users text-success"></i>}
          iconPosition="start"
          label="Users"
          value="users"
          id="tab-users"
        />
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({
  clickPass: state.clickPass,
});

export default connect(mapStateToProps, { set_click_pass })(FeedNav);
