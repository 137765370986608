import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import h from "../../utilities/helpers";

class RemoveMessageModal extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  confirm = () => {
    this.props.removeMessage(this.props.messageRemoving);
    this.props.socket.emit(
      "remove-message",
      this.props.messageRemoving,
      this.props.virgilChad
    );
    this.props.toggleShowModal();
  };

  render() {
    return (
      <MDBModal
        open={this.props.modalShown}
        staticBackdrop
        onClosePrevented={() => {
          if (!this.state.working) this.props.toggleShowModal();
        }}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            this.props.screenDimensions.width >
            this.props.screenDimensions.modalBreak
              ? "xl"
              : "fullscreen"
          }
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Remove Message</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={this.props.toggleShowModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <h5 className="my-4">
                Would you like to permanently delete this message?
              </h5>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={this.confirm}>
                <i className="fas fa-check-circle me-2" />
                Confirm
              </MDBBtn>
              <MDBBtn
                color="dark"
                className="bg-gray"
                onClick={this.props.toggleShowModal}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
  socket: state.socket,
});

export default connect(mapStateToProps, {})(RemoveMessageModal);
