import React from "react";
import { MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { connect } from "react-redux";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import { set_user } from "../../../../redux/actions";
import { motion } from "framer-motion";
import TextInput from "../../../../components/textInput/TextInput";

class EditInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * reset: Boolean - Measures nothing, but fixes an MDB ui bug when flipped
       */
      reset: false,
    };
  }

  /**
   * Fix mdb inputs
   * Send the child's forceParse() method to the parent
   * Run empty change handler
   */
  componentDidMount() {
    this.fixMDBInputs();
    try {
      if (this.props.setForceParse) {
        this.forceParse = this.forceParse.bind(this);
        this.props.setForceParse(this.forceParse);
      }
    } catch (err) {
      console.log("EditInfo", err);
    }

    this.props.changeHandler({
      target: {
        name: "",
      },
    });
  }

  /**
   * Fix MDB inputs when the modal is shown
   */
  componentDidUpdate(prevProps) {
    if (prevProps.modalShown !== this.props.modalShown) this.fixMDBInputs();
  }

  /**
   * Fixes MDB ui bug in which labels are not properly floating or are crossed out
   */
  fixMDBInputs = () => {
    [].slice
      .call(document.getElementsByClassName("comment-inputs"))
      .forEach((e) => {
        if (!e.value && e.classList.contains("active"))
          e.classList.remove("active");
        else if (e.value && !e.classList.contains("active"))
          e.classList.add("active");
      });
    this.setState(
      (curr) => ({
        ...curr,
        reset: !this.state.reset,
      }),
      () => h.floatLabels()
    );
  };

  /**
   *
   * @param {Event} e - Keypress event
   *
   * Triggered when the user presses the Tab key
   * Moves cursor to next input (MDB is bugged)
   * Removed when MDB fixes
   */
  pressTab = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const input = this.props.inputs.find((f) => f.id === e.target.name);
      if (input) {
        const nextField =
          this.props.inputs[this.props.inputs.indexOf(input) + 1];
        if (nextField) {
          const element = document.getElementById(
            nextField.id + "-edit-profile"
          );
          if (element) {
            setTimeout(() => {
              element.focus();
              element.select();
            }, 100);
          }
        }
      }
    }
  };

  render() {
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBValidation
          method="dialog"
          id="form-edit-profile"
          name="form-edit-profile"
          className="row mt-4"
          onSubmit={this.props.submit}
        >
          {this.props.fields
            .filter((field) => ["bio", "avatar"].indexOf(field.id) === -1)
            .map((i) => (
              <MDBValidationItem
                key={i.id + "-edit-profile"}
                className="pb-4 col-12 col-lg-6"
                feedback={
                  this.props.inputs.find((input) => input.id === i.id).error
                }
                invalid={true}
              >
                <MDBInput
                  name={i.id}
                  onChange={this.props.changeHandler}
                  id={i.id + "-edit-profile"}
                  label={i.text}
                  size="lg"
                  className={
                    !this.props.inputs.find((input) => input.id === i.id)
                      .invalid
                      ? "mb-0"
                      : 0
                  }
                  type={i.type}
                  onKeyPress={this.props.pressEnter}
                  defaultValue={
                    this.props.inputs.find((input) => input.id === i.id).value
                  }
                  reset={this.state.reset}
                  onKeyDown={this.pressTab}
                />
              </MDBValidationItem>
            ))}
          <MDBValidationItem className="col-12 col-lg-6">
            <TextInput
              setForceParse={(f) => (this.forceParse = f)}
              working={this.props.working}
              flavor="bio-edit-self"
              maxChars={1000}
              label="Bio"
              prefilledHTML={this.props.userInfo.bio}
            />
          </MDBValidationItem>
        </MDBValidation>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_user })(EditInfo);
