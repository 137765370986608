import env from "../env";
import React from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import { MDBNavbar, MDBTooltip, MDBBtn, MDBRipple } from "mdb-react-ui-kit";
import axios from "axios";
import { purge_user, route, set_token } from "../redux/actions";
import { Link } from "react-router-dom";

class UserBar extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the user is in the process of logging out
       */
      working: false,
    };
  }

  /**
   *
   * @param {Boolean} retry - Whether this is retrying from a previous failed logout attempt
   *
   * Triggered when the user clicks Logout
   *
   * Logs the user out
   * Disconnects the socket
   * Purges the user
   *
   */
  logout = (retry) => {
    if (!this.state.working || retry)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () =>
          axios
            .get(process.env.REACT_APP_LAMBDA_AUTH + "/logout", {
              headers: {
                Authorization: this.props.token,
              },
            })
            .then((res) => {
              this.props.set_token(res.data.token);
              this.init();
            })
            .catch((err) => {
              console.log("logout error", err);
              setTimeout(() => this.logout(true), 1000);
            })
      );
  };

  init = () =>
    axios
      .get(
        process.env.REACT_APP_LAMBDA_AUTH +
          "/init/" +
          this.props.userInfo.userSettings.theme,
        {
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((res) => {
        this.props.set_token(res.data.token);
        this.props.purge_user();
        this.setState((curr) => ({
          ...curr,
          working: false,
        }));
      })
      .catch((err) => {
        console.log("Init error", err);
        setTimeout(this.init, 1500);
      });

  /**
   *
   * @param {Click Event} e
   *
   * Triggered when the user clicks their avatar
   * Navigates to the user's profile
   */
  goToProfile = (e) => {
    e.preventDefault();
    this.props.route(`/${this.props.userInfo.username}`);
  };

  render() {
    return (
      <motion.div
        className={`position-fixed w-max-content end-0 top-0 ${
          this.props.userInfo.ban.banned ? "bg-litepink" : ""
        }`}
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out_scale_1}
      >
        <MDBNavbar
          style={{
            borderBottomLeftRadius: "0.33rem",
          }}
          className="p-0"
          light
          bgColor={
            this.props.userInfo.userSettings.theme === "default"
              ? "light"
              : "dark"
          }
        >
          {this.props.userInfo.username ||
          this.props.verificationDetails?.verificationAvatar ? (
            <>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <MDBTooltip
                  tag="div"
                  placement="left"
                  title={
                    this.props.verificationDetails?.verificationUsername ||
                    this.props.userInfo.username
                  }
                  className="me-2"
                >
                  {this.props.verificationDetails ? (
                    <div>
                      <MDBRipple rippleColor="primary">
                        <div className="d-flex justify-content-center align-items-center square-3 p-2">
                          <div
                            className="fit-images fit-round"
                            style={{
                              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${this.props.verificationDetails.verificationAvatar.thumbnail}")`,
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                      </MDBRipple>
                    </div>
                  ) : (
                    <Link
                      to={`/${this.props.userInfo.username}`}
                      onClick={this.goToProfile}
                    >
                      <MDBRipple rippleColor="primary">
                        <div className="d-flex justify-content-center align-items-center square-3 p-2">
                          <div
                            className="fit-images fit-round"
                            style={{
                              backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${this.props.userInfo.avatar.thumbnail}")`,
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                      </MDBRipple>
                    </Link>
                  )}
                </MDBTooltip>
              </motion.div>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <h5 className="fw-bold m-0">|</h5>
              </motion.div>
            </>
          ) : (
            <></>
          )}
          {h.checkChadmin(this.props.userInfo) && (
            <>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <MDBTooltip
                  tag="div"
                  placement="bottom"
                  title="Contact Messages"
                  className="me-2"
                >
                  <Link
                    to="/contact-messages"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.route("/contact-messages");
                    }}
                  >
                    <MDBBtn
                      rippleColor="success"
                      color="link"
                      className="text-success px-2"
                    >
                      <i
                        className={`fa${
                          this.props.contactMessages.length ? "s" : "r"
                        } fa-lg fa-envelope`}
                      ></i>
                    </MDBBtn>
                  </Link>
                </MDBTooltip>
              </motion.div>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <h5 className="fw-bold m-0">|</h5>
              </motion.div>
            </>
          )}
          <MDBTooltip
            tag="div"
            placement={this.props.userInfo.username ? "bottom" : "left"}
            title="Info"
            className="me-2"
          >
            <Link
              to="/info"
              onClick={(e) => {
                e.preventDefault();
                this.props.route("/info");
              }}
            >
              <MDBBtn
                rippleColor="#1976d2"
                color="link"
                className="text-darkblu px-2"
              >
                <i className="fas fa-lg fa-info-circle"></i>
              </MDBBtn>
            </Link>
          </MDBTooltip>
          {this.props.userInfo.username || this.props.verificationDetails ? (
            <>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <h5 className="fw-bold m-0">|</h5>
              </motion.div>
              <motion.div
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <MDBBtn
                  disabled={this.state.working}
                  onClick={this.logout}
                  className={`text-danger px-2 ${
                    this.state.working ? "cursor-default opacity-flash" : ""
                  }`}
                  rippleColor="danger"
                  color="link"
                >
                  Logout
                </MDBBtn>
              </motion.div>
            </>
          ) : (
            <></>
          )}
        </MDBNavbar>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { purge_user, route, set_token })(
  UserBar
);
