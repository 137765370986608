import env from "../../../env";
import React from "react";
import { motion } from "framer-motion";
import h from "../../../utilities/helpers";
import t from "../../../utilities/transitions";
import { MDBBtn, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import Spinner from "../../../components/Spinner";
import { Link } from "react-router-dom";
import { route, set_token } from "../../../redux/actions";
import { connect } from "react-redux";
import axios from "axios";

class Reports extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a report action is occurring
       * dismission: Boolean - Whether a report is in the process of being dismissed
       */
      working: false,
      dismissing: false,
    };
  }

  ban = () => {
    if (
      !this.state.working &&
      !(
        !h.checkChadmin(this.props.userInfo) &&
        h.checkJanny(this.props.report.emission.role)
      )
    )
      this.props.setView("ban");
  };

  remove = () => {
    if (
      !this.state.working &&
      !(
        !h.checkChadmin(this.props.userInfo) &&
        h.checkJanny(this.props.report.emission.role)
      )
    )
      this.props.setView("remove");
  };

  edit = () => {
    if (
      !this.state.working &&
      !(
        !h.checkChadmin(this.props.userInfo) &&
        h.checkJanny(this.props.report.emission.role)
      )
    )
      this.props.setView("edit");
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, destination) => {
    e.preventDefault();
    this.props.route(destination);
  };

  /**
   * Triggered when the user clicks the Dismiss button on a report
   *
   * Dismiss the report
   * Notify the user that the report has been dismissed
   * Purge all reports with that user/emission id
   */
  dismiss = () => {
    if (
      !this.state.working &&
      !(
        !h.checkChadmin(this.props.userInfo) &&
        h.checkJanny(this.props.report.emission.role)
      )
    )
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
          dismissing: true,
        }),
        () => {
          let submission = {
            type: this.props.report.type,
          };
          if (this.props.report.type === "emission")
            submission.emissionID = this.props.report.emissionID;
          else submission.user_id = this.props.report.user_id;
          axios
            .post(
              process.env.REACT_APP_LAMBDA_SUPPORT + "/dismiss",
              submission,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              if (this.props.report.type === "emission")
                submission.text = (
                  <>
                    Dismissed all reports against
                    <span className="text-capitalize">
                      {" "}
                      {env.EMISSION_NAME}{" "}
                    </span>
                    <span className="text-gb">
                      #{this.props.report.emissionID}
                    </span>
                  </>
                );
              else
                submission.text = (
                  <>
                    Dismissed all reports against @
                    {this.props.report.userInfo.username}
                  </>
                );
              submission.icon = (
                <i className="far fa-check-circle me-2 text-success"></i>
              );
              this.props.purgeReports(submission);
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                  dismissing: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            );
        }
      );
  };

  render() {
    return (
      <motion.div
        className="mt-4"
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        {this.props.report.type === "user" ? (
          <div className="d-flex">
            <motion.div
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
              className="d-flex"
            >
              {this.state.dismissing ? (
                <MDBBtn
                  className="report-detail-buttons"
                  color="primary"
                  disabled
                >
                  <Spinner className="me-2" size="sm" />
                  Dismissing
                </MDBBtn>
              ) : (
                <MDBBtn
                  className="report-detail-buttons"
                  onClick={this.dismiss}
                  disabled={
                    this.state.working ||
                    (!h.checkChadmin(this.props.userInfo) &&
                      ["Chadmin", "Janny"].indexOf(
                        this.props.report.userInfo.role
                      ) > -1)
                  }
                  color="primary"
                >
                  <i className="far fa-check-circle me-2"></i>
                  Dismiss
                </MDBBtn>
              )}
              <MDBBtn
                onClick={this.edit}
                className="ms-2 report-detail-buttons"
                disabled={
                  this.state.working ||
                  (!h.checkChadmin(this.props.userInfo) &&
                    ["Chadmin", "Janny"].indexOf(
                      this.props.report.userInfo.role
                    ) > -1)
                }
                color="dark"
              >
                <i className="fas fa-edit me-2"></i>
                Edit User Info
              </MDBBtn>
              <MDBBtn
                className="ms-2 report-detail-buttons"
                onClick={this.ban}
                color="danger"
                disabled={
                  this.state.working ||
                  (!h.checkChadmin(this.props.userInfo) &&
                    ["Chadmin", "Janny"].indexOf(
                      this.props.report.userInfo.role
                    ) > -1)
                }
              >
                <i className="fas fa-user-times me-2"></i>
                Ban User
              </MDBBtn>
            </motion.div>
          </div>
        ) : (
          <motion.div
            transition={t.transition}
            exit={t.fade_out_scale_1}
            animate={t.normalize}
            initial={t.fade_out}
            className="d-flex"
          >
            {this.state.dismissing ? (
              <MDBBtn
                className="report-detail-buttons"
                color="primary"
                disabled
              >
                <Spinner className="me-2" size="sm" />
                Dismissing
              </MDBBtn>
            ) : (
              <MDBBtn
                className="report-detail-buttons"
                onClick={this.dismiss}
                disabled={
                  this.state.working ||
                  (!h.checkChadmin(this.props.userInfo) &&
                    ["Chadmin", "Janny"].indexOf(
                      this.props.report.emission.role
                    ) > -1)
                }
                color="primary"
              >
                <i className="far fa-check-circle me-2"></i>
                Dismiss
              </MDBBtn>
            )}
            <MDBBtn
              onClick={this.remove}
              className="ms-2 report-detail-buttons"
              disabled={
                this.state.working ||
                (!h.checkChadmin(this.props.userInfo) &&
                  ["Chadmin", "Janny"].indexOf(
                    this.props.report.emission.role
                  ) > -1)
              }
              color="dark"
            >
              <i className="far fa-trash-alt me-2"></i>
              Remove
            </MDBBtn>
            <MDBBtn
              className="ms-2 report-detail-buttons"
              onClick={this.ban}
              color="danger"
              disabled={
                this.state.working ||
                (!h.checkChadmin(this.props.userInfo) &&
                  ["Chadmin", "Janny"].indexOf(
                    this.props.report.emission.role
                  ) > -1)
              }
            >
              <i className="fas fa-user-times me-2"></i>
              Remove and Ban User
            </MDBBtn>
          </motion.div>
        )}
        <hr></hr>
        <h5>Reports</h5>
        <MDBListGroup>
          {this.props.report.reports.map((report) => (
            <MDBListGroupItem key={report._id}>
              <div className="d-flex justify-content-between">
                <h6>
                  {report.reportUser ? (
                    <Link
                      to={`/${report.reportUser.username}`}
                      onClick={(e) =>
                        this.route(e, `/${report.reportUser.username}`)
                      }
                    >
                      @{report.reportUser.username}
                    </Link>
                  ) : (
                    "Anonymous Reporter"
                  )}
                </h6>
                <p className="text-blusteel m-0">
                  {h.makeDateHR(report.timestamp)}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="m-0 text-transform-capitalize text-default">
                  {report.reason}
                </p>
                <p className="text-blusteel m-0">
                  {h.getTimeHR(report.timestamp)}
                </p>
              </div>
              <p className="m-0 text-break">{report.details}</p>
            </MDBListGroupItem>
          ))}
        </MDBListGroup>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { route, set_token })(Reports);
