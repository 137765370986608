import env from "../env";
import React from "react";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBRipple,
  MDBTooltip,
  MDBBadge,
  MDBBtn,
  MDBInput,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  set_contact_messages,
  route,
  toggle_dark_mode,
  reconnect_socket,
  update_user,
  purge_user,
  follow,
  new_stream_chat,
  new_viewer,
  leave_viewer,
  set_unread_messages,
  set_notification_count,
  set_open_reports,
  set_unread_mod_logs,
  set_search_term,
  search_change,
  viewers,
  clear_stream_chat,
  set_click_pass,
  update_socket_state,
  set_token,
  stream_event_generic,
  notify,
  remove_virgil_chad,
  set_virgil_chad,
  set_stream_eligibility,
  new_contact_message,
  set_contact_message_read,
} from "../redux/actions";
import { motion } from "framer-motion";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import axios from "axios";
import Count from "./Count";
import Url from "url-parse";

class SideNav extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * searchOpen: Boolean - Whether the searchbox is open
       * reset: Boolean - Measures nothing, but when toggled, fixes MDB ui bug with search box
       * navOpen: Boolean - Whether the navigation is open on mobile screens
       * screenSize: Number - Width of the screen in pixels
       */
      searchOpen: false,
      reset: false,
      navOpen: false,
      screenSize: 0,
      socketEvents: [],
    };
  }

  /**
   * If socket already initiated, emit the "login" signal
   * Add resize event listener, and run the resize function to get the screen size
   */
  componentDidMount() {
    if (this.props.socket) this.initializeSocket();
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  /**
   * If theme changes, swap the css files
   * If the user is demoted and on a page they are no longer authorized to be on, route to their profile
   * Initialize socket once set
   * Fix MDB bug that adds scrollbars to the side nav for no reason
   * Reset message sockets if the user navigates to a page that is not the Messages page
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.userInfo.userSettings.theme !==
      this.props.userInfo.userSettings.theme
    ) {
      [].slice
        .call(document.head.getElementsByTagName("link"))
        .forEach((link, l) => {
          if (
            link.href.includes(
              "/styles/" + prevProps.userInfo.userSettings.theme
            )
          ) {
            link.href =
              link.href.split("/styles/")[0] +
              `/styles/${this.props.userInfo.userSettings.theme}.css`;
          }
          if (link.href.includes("custom-")) {
            link.href =
              link.href.split("custom-")[0] +
              "custom-" +
              this.props.userInfo.userSettings.theme +
              ".css";
          }
          if (link.href.includes("css_variables_")) {
            link.href =
              link.href.split("css_variables_")[0] +
              "css_variables_" +
              this.props.userInfo.userSettings.theme +
              ".css";
          }
        });
    }

    if (
      this.props.userInfo._id &&
      prevProps.userInfo.role !== this.props.userInfo.role &&
      ((!h.checkJanny(this.props.userInfo) &&
        this.props.history.location.pathname === "/logs") ||
        (!h.checkChadmin(this.props.userInfo) &&
          this.props.history.location.pathname === "/reports"))
    )
      this.props.route(`/${this.props.userInfo.username}`);

    if (!prevProps.socket && this.props.socket) this.initializeSocket();

    this.fixMDB();

    if (prevProps.historyStack.length !== this.props.historyStack.length)
      this.setMessageSockets();

    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      const socketRoom = h.getCurrentRoom();
      if (
        prevProps.location.pathname === "/search" &&
        this.props.location.pathname !== "/search"
      )
        this.props.search_change(
          {
            target: {
              value: "",
            },
          },
          "searchPage"
        );
      if (socketRoom !== this.props.socketState.join)
        this.props.update_socket_state({
          join: socketRoom,
        });
    }
    if (
      this.props.socket &&
      JSON.stringify(prevProps.socketState) !==
        JSON.stringify(this.props.socketState)
    ) {
      this.props.socket.io.opts.query = this.props.socketState;
      if (this.props.userInfo.live) {
        this.props.socket.emit("update-state", {
          current: this.props.socketState,
          previous: prevProps.socketState,
        });
      } else {
        this.props.socket.disconnect().connect();
      }
    }

    if (prevProps.userInfo._id !== this.props.userInfo._id) {
      this.props.socket.disconnect().connect();
    }
  }

  /**
   * Remove resize event listener
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
    this.props.socket.off("disconnect");
    this.props.socket.off("mod-profile-update");
    this.props.socket.off("ban-self");
    this.props.socket.off("restore-self");
    this.props.socket.off("follow");
    this.props.socket.off("stream-chat");
    this.props.socket.off("stream-chat-self");
    this.props.socket.off("new-viewer");
    this.props.socket.off("new-viewer-self");
    this.props.socket.off("report-count");
    this.props.socket.off("unread-modlog-count");
    this.props.socket.off("new-mod-log");
    this.props.socket.off("viewers");
    this.props.socket.off("viewers-self");
    this.props.socket.off("stream-terminated");
    this.props.socket.off("initialized");
    this.props.socket.off("delete-self");
    this.props.socket.off("remove-virgil-chad");
    this.props.socket.off("stream-eligibility");
    this.props.socket.off("new-contact-message");
    this.props.socket.off("contact-messages");
    this.props.socket.off("read-contact-message");
  }

  /**
   * Update screen size in state
   *
   */
  resize = () =>
    this.setState((curr) => ({
      ...curr,
      screenSize: window.innerWidth,
    }));

  /**
   * Turn off all methods
   * Tell other components that rely on the socket to re-initialize with redux reconnect_socket function
   * Emit login event
   * Turn on all methods
   */
  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.off("disconnect");
    this.props.socket.off("mod-profile-update");
    this.props.socket.off("ban-self");
    this.props.socket.off("restore-self");
    this.props.socket.off("follow");
    this.props.socket.off("stream-chat");
    this.props.socket.off("stream-chat-self");
    this.props.socket.off("new-viewer");
    this.props.socket.off("new-viewer-self");
    this.props.socket.off("report-count");
    this.props.socket.off("unread-modlog-count");
    this.props.socket.off("new-mod-log");
    this.props.socket.off("viewers");
    this.props.socket.off("viewers-self");
    this.props.socket.off("stream-terminated");
    this.props.socket.off("initialized");
    this.props.socket.off("delete-self");
    this.props.socket.off("remove-virgil-chad");
    this.props.socket.off("stream-eligibility");
    this.props.socket.off("new-contact-message");
    this.props.socket.off("contact-messages");
    this.props.socket.off("read-contact-message");

    this.props.socket.on(
      "read-contact-message",
      this.props.set_contact_message_read
    );

    this.props.socket.on("contact-messages", this.props.set_contact_messages);

    this.props.socket.on("new-contact-message", this.props.new_contact_message);

    this.props.socket.on("test", (data) => console.log("test", data));

    this.props.socket.on(
      "stream-eligibility",
      this.props.set_stream_eligibility
    );

    this.props.socket.on("new-virgil-chad", this.props.set_virgil_chad);

    this.props.socket.on("remove-virgil-chad", this.props.remove_virgil_chad);

    this.props.socket.on("delete-self", (data) => {
      try {
        if (
          data?.eventID &&
          this.props.socketEvents.find((e) => e === data?.eventID)
        )
          return;

        this.setState(
          (curr) => ({
            ...curr,
            socketEvents: data.eventID
              ? [...this.state.socketEvents, data.eventID]
              : this.state.socketEvents,
          }),
          () => {
            this.props.notify(
              <i className="fas fa-gavel text-danger me-2"></i>,
              "Your account has been deleted"
            );
            this.props.purge_user(true);
          }
        );
      } catch (err) {
        console.log("delete-self error", err, data);
      }
    });
    this.props.socket.on("stream-terminated", (data) => {
      try {
        this.props.clear_stream_chat(data);
      } catch (err) {
        console.log("stream-terminated error", err, data);
      }
    });
    this.props.socket.on("new-mod-log", () => {
      try {
        this.props.set_unread_mod_logs(this.props.unreadModLogs + 1);
      } catch (err) {
        console.log("new-mod-log error", err);
      }
    });
    this.props.socket.on("unread-modlog-count", (data) => {
      try {
        this.props.set_unread_mod_logs(data);
      } catch (err) {
        console.log("unread mod log error", err, data);
      }
    });
    this.props.socket.on("report-count", (data) => {
      try {
        this.props.set_open_reports(data);
      } catch (err) {
        console.log("report count error", err, data);
      }
    });

    /**
     * Triggered when a mod updates the user's profile
     * Notify the user
     * Update the user
     */
    this.props.socket.on(
      "mod-profile-update",
      (data, clearValidation, selfImposed) => {
        try {
          if (
            data?.eventID &&
            this.props.socketEvents.find((e) => e === data?.eventID)
          )
            return;

          if (data.ban.banned && !this.props.userInfo.ban.banned) {
            this.props.notify(
              <i className="fas fa-gavel text-danger me-2"></i>,
              "You have been banned"
            );
          } else if (!data.ban.banned && this.props.userInfo.ban.banned) {
            this.props.notify(
              <i className="fas fa-gavel text-success me-2"></i>,
              "Your ban has been lifted"
            );
          } else if (!selfImposed)
            this.props.notify(
              <i className="fas fa-gavel text-success me-2"></i>,
              `Your profile has been updated by a ${env.MOD_NAME}`
            );
          this.props.update_user(data, clearValidation);
        } catch (err) {
          console.log("mod profile update error", err, data, clearValidation);
        }
      }
    );

    this.props.socket.on("follow", (data) => {
      try {
        this.props.follow(data);
      } catch (err) {
        console.log("follow self", err, data);
      }
    });

    this.props.socket.on("viewers", (viewers) => {
      try {
        if (!this.props.userInfo.live) this.props.viewers(viewers);
      } catch (err) {
        console.log("viewers error", err, viewers);
      }
    });
    this.props.socket.on("viewers-self", (data) => {
      try {
        this.props.viewers(data);
      } catch (err) {
        console.log("viewers-self error", err, data);
      }
    });

    this.props.socket.on("new-viewer", () => {
      try {
        if (!this.props.userInfo.live) this.props.new_viewer();
      } catch (err) {
        console.log("new-viewer error", err);
      }
    });
    this.props.socket.on("new-viewer-self", (data) => {
      try {
        this.props.new_viewer(data);
      } catch (err) {
        console.log("new-viewer-self error", err, data);
      }
    });

    this.props.socket.on("stream-chat", (message) => {
      try {
        if (!this.props.userInfo.live) this.props.new_stream_chat(message);
      } catch (err) {
        console.log("stream-chat error", err, message);
      }
    });

    this.props.socket.on("stream-chat-self", (data) => {
      try {
        this.props.new_stream_chat(data);
      } catch (err) {
        console.log("stream-chat-self error", err, data);
      }
    });
    this.setMessageSockets();
  };

  /**
   * Initializes the message and notification sockets only
   * If user is not on the Messages page, initialize the message socket events
   * If user is not on the Notifications page, initialize the notification socket events
   */
  setMessageSockets = () => {
    if (!this.props.socket) return;
    if (
      this.props.socket &&
      (["/", "/notifications", "/logs", "/reports", "/search"].indexOf(
        this.props.history.location.pathname
      ) > -1 ||
        this.props.history.location.pathname.includes("/e/") ||
        this.props.history.location.pathname.includes("/tag/") ||
        new RegExp(this.props.userInfo.username, "i").test(
          this.props.history.location.pathname
        ))
    ) {
      this.props.socket.off("message-count");
      this.props.socket.off("new-message");
      this.props.socket.off("remove-message");
      this.props.socket.off("conversation-removed");
      this.props.socket.off("conversation-left");
      this.props.socket.off("conversation-kicked");
      this.props.socket.off("read-all");

      this.props.socket.on("read-all", () => {
        try {
          this.props.set_unread_messages(0);
        } catch (err) {
          console.log("read-all error", err);
        }
      });

      this.props.socket.on(
        "conversation-kicked",
        (conversationID, userID, unread) => {
          try {
            if (userID === this.props.userInfo._id)
              this.props.set_unread_messages(
                this.props.userInfo.unreadMessages - unread
              );
          } catch (err) {
            console.log("conversation-kicked", err);
          }
        }
      );

      this.props.socket.on(
        "conversation-left",
        (conversationID, userID, unread) => {
          try {
            if (unread)
              this.props.set_unread_messages(
                this.props.userInfo.unreadMessages - unread
              );
          } catch (err) {
            console.log("conversation-left error", err);
          }
        }
      );

      this.props.socket.on("conversation-removed", (conversationID, unread) => {
        try {
          this.props.set_unread_messages(
            this.props.userInfo.unreadMessages - unread
          );
        } catch (err) {
          console.log("conversation removed error", err);
        }
      });

      this.props.socket.on("remove-message", (data) => {
        try {
          if (
            data.unread &&
            !(
              data?.eventID &&
              this.state.socketEvents.find((e) => e === data?.eventID)
            )
          )
            this.setState(
              (curr) => ({
                ...curr,
                socketEvents: data?.eventID
                  ? [...this.state.socketEvents, data?.eventID]
                  : this.state.socketEvents,
              }),
              () =>
                this.props.set_unread_messages(
                  this.props.userInfo.unreadMessages - 1
                )
            );
        } catch (err) {
          console.log("remove message error", err, data);
        }
      });
      this.props.socket.on("message-count", (data) => {
        try {
          this.props.set_unread_messages(data);
        } catch (err) {
          console.log("message-count error", err, data);
        }
      });
      this.props.socket.on("new-message", (message) => {
        try {
          if (message.from !== this.props.userInfo._id)
            this.props.set_unread_messages(
              this.props.userInfo.unreadMessages + 1
            );
        } catch (err) {
          console.log("new-message error", err, message);
        }
      });
    }
    if (this.props.history.location.pathname !== "/notifications") {
      this.props.socket.off("notification");
      this.props.socket.off("remove-notification");
      this.props.socket.off("notification-count");

      this.props.socket.on("notification-count", (data) => {
        try {
          this.props.set_notification_count(data);
        } catch (err) {
          console.log("notification-count error", err, data);
        }
      });
      this.props.socket.on("remove-notification", (data) => {
        try {
          if (
            data?.eventID &&
            this.state.socketEvents.find((e) => e === data?.eventID)
          )
            return;
          else
            this.setState(
              (curr) => ({
                ...curr,
                socketEvents: data?.eventID
                  ? [...this.state.socketEvents, data?.eventID]
                  : this.state.socketEvents,
              }),
              () =>
                this.props.set_notification_count(
                  this.props.userInfo.notifications - 1
                )
            );
        } catch (err) {
          console.log("remove-notification error", err);
        }
      });
      this.props.socket.on("notification", (data) => {
        try {
          if (
            data?.eventID &&
            this.state.socketEvents.find((e) => e === data?.eventID)
          )
            return;
          else
            this.setState(
              (curr) => ({
                ...curr,
                socketEvents: data?.eventID
                  ? [...this.state.socketEvents, data?.eventID]
                  : this.state.socketEvents,
              }),
              () =>
                this.props.set_notification_count(
                  this.props.userInfo.notifications + 1
                )
            );
        } catch (err) {
          console.log("notification error", err);
        }
      });
    }
  };

  /**
   * Removes the persistent classes that keep getting added to the side nav which create scrollbars
   *
   */
  fixMDB = () =>
    setTimeout(
      () =>
        [].slice
          .call(document.getElementById("sidenav-main").childNodes)
          .forEach((node) => {
            if (node.classList.contains("ps--active-y"))
              node.classList.remove("ps--active-y");
          }),
      500
    );

  /**
   * Toggles the search box
   *
   * If search box is opened:
   * * Focus on the text input
   * * Add click event to close the search box if clicking outside the sidenav
   * * Toggle state.reset (Fixes floating label MDB issue)
   *
   * If search box is closed, remove click event listener
   */
  toggleSearch = () =>
    this.setState(
      (curr) => ({
        ...curr,
        searchOpen: !this.state.searchOpen,
      }),
      () => {
        if (this.state.searchOpen) {
          document.getElementById("nav-search-input").focus();
          setTimeout(() => {
            document.addEventListener("click", this.clickOutsideNav);
          }, 100);
          setTimeout(
            () =>
              this.setState((curr) => ({
                ...curr,
                reset: !this.state.reset,
              })),
            100
          );
        } else document.removeEventListener("click", this.clickOutsideNav);
      }
    );

  /**
   *
   * @param {Click Event} e
   *
   * If user clicks outside the side nav while the search box is open, close the search box
   */
  clickOutsideNav = (e) => {
    if (
      !document.getElementById("sidenav-main").contains(e.target) &&
      this.state.searchOpen
    )
      this.setState(
        (curr) => ({
          ...curr,
          searchOpen: false,
        }),
        () => document.removeEventListener("click", this.clickOutsideNav)
      );
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} path - Path that the user wishes to navigate to
   *
   * Triggered when the user clicks on one of the nav items
   * Routes to the desired path, closes the nav on mobile devices, closes the search box, and removes the click event
   */
  nav = (e, path) => {
    e.preventDefault();
    this.props.route(path);
    this.setState(
      (curr) => ({
        ...curr,
        searchOpen: false,
        navOpen: false,
      }),
      () => {
        if (this.state.searchOpen)
          document.removeEventListener("click", this.clickOutsideNav);
      }
    );
  };

  /**
   * Clicked when the user clicks the Toggle Dark Mode button
   * Toggles dark mode
   */
  toggleDarkMode = () => {
    this.props.toggle_dark_mode();
    axios
      .get(process.env.REACT_APP_LAMBDA_AUTH + "/toggle-dark-mode", {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => this.props.set_token(res.data.token))
      .catch((err) => console.log("Toggle dark mode error", err));
  };

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const searchTop = document.getElementById("search-top");
      if (searchTop) searchTop.scrollIntoView();
      this.setState(
        (curr) => ({
          ...curr,
          searchOpen: false,
          navOpen: false,
        }),
        this.props.set_search_term
      );
    }
  };

  /**
   * Triggered when users on mobile screens open or close the side nav
   * Toggles the side nav
   */
  toggleNavOpen = () =>
    this.setState((curr) => ({
      ...curr,
      navOpen: !this.state.navOpen,
    }));

  clickSideNav = (e) => {
    if (this.state.navOpen) return this.toggleNavOpen();

    const links = Array.from(document.getElementsByTagName("a"));
    for (let r = 0; r < links.length; r++) {
      const link = links[r];
      const dim = link.getBoundingClientRect();
      const href = link.href;
      if (
        href &&
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        const url = new Url(href);
        if (url?.hostname === window.location.hostname)
          return this.props.route(url.pathname);
        else {
          return (window.location = href);
        }
      }
    }

    const searchPageEmissionsTab = document.querySelector(
      "#search-page-nav #tab-emissions"
    );
    if (searchPageEmissionsTab) {
      const dim = searchPageEmissionsTab.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "search-feed-nav",
        });
      }
    }

    const replyButtons = Array.from(
      document.getElementsByClassName("emission-reply-buttons")
    );
    for (let r = 0; r < replyButtons.length; r++) {
      const replyButton = replyButtons[r];
      const dim = replyButton.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "reply",
          id: replyButton.id.split("reply-")[1],
        });
      }
    }
    const avatars = Array.from(
      document.getElementsByClassName("emission-avatars")
    );
    for (let r = 0; r < avatars.length; r++) {
      const avatar = avatars[r];
      const dim = avatar.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "profile",
          id: avatar.id.split("avatar-")[1],
        });
      }
    }
    const ats = Array.from(document.getElementsByClassName("ats"));
    for (let r = 0; r < ats.length; r++) {
      const at = ats[r];
      const dim = at.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "profile",
          id: at.id.split("at-")[1],
        });
      }
    }
    const dns = Array.from(
      document.getElementsByClassName("emission-display-names")
    );
    for (let r = 0; r < dns.length; r++) {
      const dn = dns[r];
      const dim = dn.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "profile",
          id: dn.id.split("dn-")[1],
        });
      }
    }
    const sbs = Array.from(document.getElementsByClassName("sb"));
    for (let r = 0; r < sbs.length; r++) {
      const sb = sbs[r];
      const dim = sb.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "emission",
          id: sb.id.split("sb-")[1],
        });
      }
    }
    const reps = Array.from(document.getElementsByClassName("reps"));
    for (let r = 0; r < reps.length; r++) {
      const rep = reps[r];
      const dim = rep.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "emission",
          id: rep.id.split("rep-")[1],
        });
      }
    }
    const sideNavButtons = Array.from(
      document.getElementsByClassName("side-nav-buttons")
    );
    for (let s = 0; s < sideNavButtons.length; s++) {
      const snb = sideNavButtons[s];
      const dim = snb.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "feed-side-nav",
          id: snb.id.split("tab-")[1],
        });
      }
    }

    const profileNavButtons = Array.from(
      document.getElementsByClassName("profile-nav-buttons")
    );

    for (let s = 0; s < profileNavButtons.length; s++) {
      const pnb = profileNavButtons[s];
      const dim = pnb.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "profile-nav",
          id: pnb.id.split("tab-")[1],
        });
      }
    }

    const emissionFileInputButtons = Array.from(
      document.getElementsByClassName("emission-file-select")
    );
    for (let b = 0; b < emissionFileInputButtons.length; b++) {
      const efib = emissionFileInputButtons[b];
      const dim = efib.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "emission-file-input",
          id: efib.id.split("emission-file-select-")[1],
        });
      }
    }

    const goLiveButtons = Array.from(
      document.getElementsByClassName("go-live-buttons-mobile")
    );
    for (let g = 0; g < goLiveButtons.length; g++) {
      const glb = goLiveButtons[g];
      const dim = glb.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "go-live",
          id: glb.id.split("go-live-button-mobile-")[1],
        });
      }
    }

    const editProfileButton = document.getElementById("edit-profile-button");
    if (editProfileButton) {
      const dim = editProfileButton.getBoundingClientRect();
      if (
        dim.top <= e.clientY &&
        dim.bottom >= e.clientY &&
        dim.left <= e.clientX &&
        dim.right >= e.clientX
      ) {
        return this.props.set_click_pass({
          action: "edit-profile",
        });
      }
    }

    this.toggleNavOpen();
  };

  render() {
    let totalNotifications =
      this.props.userInfo.notifications +
      this.props.userInfo.unreadMessages +
      this.props.openReports +
      this.props.unreadModLogs;
    if (!h.isNumeric(String(totalNotifications))) totalNotifications = 0;
    return (
      <motion.div
        className="position-relative"
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
      >
        <MDBBtn
          color="link"
          id="nav-menu-toggle"
          className={`position-fixed text-primary d-flex flex-column pt-4 align-items-center menu-toggle text-darkblu ${
            this.state.navOpen ? "left-86" : "left-0"
          }`}
          size="sm"
          onClick={this.clickSideNav}
          rippleColor="#1976d2"
        >
          <div className="w-max-content position-relative">
            <i
              className={`fas fa-${
                this.state.navOpen ? "chevron-left" : "bars"
              } text-center d-block mx-auto transition-25`}
            ></i>
            <p className="mb-0 mt-2 text-center">Menu</p>
            {totalNotifications &&
            Number(totalNotifications) &&
            !this.state.navOpen ? (
              <MDBBadge
                className="position-absolute"
                style={{
                  top: "-7px",
                  right: "0px",
                }}
                color="danger"
                notification
                pill
              >
                <Count value={totalNotifications} />
              </MDBBadge>
            ) : (
              <></>
            )}
          </div>
        </MDBBtn>
        <MDBSideNav
          backdrop={false}
          closeOnEsc={false}
          slim={!this.state.searchOpen}
          mode="side"
          slimCollapsed
          id="sidenav-main"
          open={this.state.navOpen || this.state.screenSize > 992}
        >
          <div
            className={`d-flex flex-column h-100 ${
              this.props.userInfo.ban.banned ? "bg-litepink" : ""
            }`}
          >
            <MDBRipple
              rippleColor={
                this.props.userInfo.userSettings.theme === "default"
                  ? "dark"
                  : "light"
              }
              className={`w-100 cursor-pointer`}
            >
              <Link onClick={(e) => this.nav(e, "/")} to="/">
                <div
                  style={{ width: "86px", height: "86px" }}
                  id="sidenav-logo"
                  className="d-flex justify-content-center align-items-center mx-auto"
                >
                  <div
                    className="fit-images"
                    style={{
                      backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/icons/favicon-96x96.png")`,
                    }}
                  ></div>
                </div>
              </Link>
            </MDBRipple>

            <hr className="my-0" />
            <MDBSideNavMenu className="px-0">
              <MDBSideNavItem onClick={this.toggleSearch}>
                <MDBSideNavLink
                  active={this.state.searchOpen}
                  className="d-inline-block p-0 w-100"
                  style={{ height: "max-content" }}
                >
                  <motion.div
                    transition={t.transition}
                    exit={t.fade_out_minimize}
                    animate={t.normalize}
                    initial={t.fade_out_minimize}
                  >
                    <div
                      style={{ transition: "0.25s" }}
                      className={`d-flex ${
                        this.state.searchOpen ? "" : "flex-column"
                      } align-items-center py-${this.state.searchOpen ? 4 : 3}`}
                    >
                      <i
                        style={{ transition: "0.25s" }}
                        className={`fas fa-search ${
                          this.state.searchOpen ? "fa-lg" : "nav-icons"
                        } d-block ${
                          this.state.searchOpen ? "mx-3" : "mx-auto"
                        }`}
                      ></i>
                      {this.state.searchOpen ? (
                        <div className="me-2">
                          <MDBInput
                            defaultValue={this.props.searchText}
                            onChange={(e) =>
                              this.props.search_change(e, "sideNav")
                            }
                            id="nav-search-input"
                            onClick={(e) => e.stopPropagation()}
                            onKeyPress={this.pressEnter}
                            type="text"
                            label="Search"
                            className="form-control"
                            size="lg"
                            key={this.props.sideNavSearchKey + "search"}
                          />
                          {this.state.searchOpen && this.props.searchText ? (
                            <div className="position-relative">
                              <small className="mb-0 text-default position-absolute">
                                Press ENTER to search
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <p
                          style={{ fontSize: "0.75rem", transition: "0.25s" }}
                          className="mb-0 mt-2"
                        >
                          Search
                        </p>
                      )}
                    </div>
                  </motion.div>
                </MDBSideNavLink>
              </MDBSideNavItem>
              {String(env.VIRGIL_CHAD_ALLOWED) === "true" && (
                <MDBSideNavItem>
                  <Link
                    className="text-default"
                    onClick={(e) => this.nav(e, "/virgil-chad")}
                    to="/virgil-chad"
                  >
                    <MDBSideNavLink
                      active={
                        this.props.history.location.pathname
                          .split("/")[1]
                          .split("#")[0] === "virgil-chad"
                      }
                      className="d-inline-block p-0 w-100"
                      style={{ height: "max-content" }}
                    >
                      <motion.div
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        <div
                          style={{ transition: "0.25s" }}
                          className={`nav-item-containers d-flex ${
                            this.state.searchOpen ? "" : "flex-column"
                          } align-items-center`}
                        >
                          <i
                            style={{ transition: "0.25s" }}
                            className={`fas fa-yin-yang ${
                              this.state.searchOpen ? "fa-lg" : "nav-icons"
                            } d-block ${
                              this.state.searchOpen ? "mx-3" : "mx-auto"
                            }`}
                          ></i>
                          <p
                            style={{
                              fontSize: this.state.searchOpen
                                ? "1rem"
                                : "0.75rem",
                              transition: "0.25s",
                            }}
                            className={`mb-0 ${
                              this.state.searchOpen ? "" : "mt-2"
                            }`}
                          >
                            Virgil & Chad
                          </p>
                        </div>
                      </motion.div>
                    </MDBSideNavLink>
                  </Link>
                </MDBSideNavItem>
              )}

              {this.props.userInfo.username ||
              this.props.loggedIn ||
              this.props.verificationDetails ? (
                <>
                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) => this.nav(e, "/notifications")}
                      to={"/notifications"}
                    >
                      <MDBSideNavLink
                        active={
                          this.props.history.location.pathname
                            .split("/")[1]
                            .split("#")[0] === "notifications"
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            {this.props.userInfo.notifications &&
                            Number(this.props.userInfo.notifications) ? (
                              <div
                                className={`w-max-content ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              >
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "notifications"
                                      ? "fas"
                                      : "far"
                                  } fa-bell ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } `}
                                ></i>
                                <MDBBadge color="danger" notification pill>
                                  <Count
                                    value={this.props.userInfo.notifications}
                                  />
                                </MDBBadge>
                              </div>
                            ) : (
                              <i
                                style={{ transition: "0.25s" }}
                                className={`${
                                  this.props.history.location.pathname
                                    .split("/")[1]
                                    .split("#")[0] === "notifications"
                                    ? "fas"
                                    : "far"
                                } fa-bell ${
                                  this.state.searchOpen ? "fa-lg" : "nav-icons"
                                } d-block ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              ></i>
                            )}
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.75rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Notifications
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>

                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) => this.nav(e, "/messages")}
                      to={"/messages"}
                    >
                      <MDBSideNavLink
                        active={
                          this.props.history.location.pathname
                            .split("/")[1]
                            .split("#")[0] === "messages"
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            {this.props.userInfo.unreadMessages &&
                            Number(this.props.userInfo.unreadMessages) ? (
                              <div
                                className={`w-max-content ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              >
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "messages"
                                      ? "fas"
                                      : "far"
                                  } fa-envelope ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } `}
                                ></i>
                                <MDBBadge color="danger" notification pill>
                                  <Count
                                    value={this.props.userInfo.unreadMessages}
                                  />
                                </MDBBadge>
                              </div>
                            ) : (
                              <i
                                style={{ transition: "0.25s" }}
                                className={`${
                                  this.props.history.location.pathname
                                    .split("/")[1]
                                    .split("#")[0] === "messages"
                                    ? "fas"
                                    : "far"
                                } fa-envelope ${
                                  this.state.searchOpen ? "fa-lg" : "nav-icons"
                                } d-block ${
                                  this.state.searchOpen ? "mx-3" : "mx-auto"
                                }`}
                              ></i>
                            )}
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.75rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Messages
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>

                  {h.checkJanny(this.props.userInfo) ? (
                    <MDBSideNavItem>
                      <Link
                        className="text-default"
                        onClick={(e) => this.nav(e, "/reports")}
                        to={"/reports"}
                      >
                        <MDBSideNavLink
                          active={
                            this.props.history.location.pathname
                              .split("/")[1]
                              .split("#")[0] === "reports"
                          }
                          className="d-inline-block p-0 w-100"
                          style={{ height: "max-content" }}
                        >
                          <motion.div
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                          >
                            <div
                              style={{ transition: "0.25s" }}
                              className={`nav-item-containers d-flex ${
                                this.state.searchOpen ? "" : "flex-column"
                              } align-items-center`}
                            >
                              {this.props.openReports &&
                              Number(this.props.openReports) ? (
                                <div
                                  className={`w-max-content ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                >
                                  <i
                                    style={{ transition: "0.25s" }}
                                    className={`${
                                      this.props.history.location.pathname
                                        .split("/")[1]
                                        .split("#")[0] === "reports"
                                        ? "fas"
                                        : "far"
                                    } fa-flag ${
                                      this.state.searchOpen
                                        ? "fa-lg"
                                        : "nav-icons"
                                    } `}
                                  ></i>
                                  <MDBBadge color="danger" notification pill>
                                    <Count value={this.props.openReports} />
                                  </MDBBadge>
                                </div>
                              ) : (
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "reports"
                                      ? "fas"
                                      : "far"
                                  } fa-flag ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } d-block ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                ></i>
                              )}
                              <p
                                style={{
                                  fontSize: this.state.searchOpen
                                    ? "1rem"
                                    : "0.75rem",
                                  transition: "0.25s",
                                }}
                                className={`mb-0 ${
                                  this.state.searchOpen ? "" : "mt-2"
                                }`}
                              >
                                Reports
                              </p>
                            </div>
                          </motion.div>
                        </MDBSideNavLink>
                      </Link>
                    </MDBSideNavItem>
                  ) : (
                    <></>
                  )}

                  {h.checkChadmin(this.props.userInfo) ? (
                    <MDBSideNavItem>
                      <Link
                        className="text-default"
                        onClick={(e) => this.nav(e, "/logs")}
                        to={"/logs"}
                      >
                        <MDBSideNavLink
                          active={
                            this.props.history.location.pathname
                              .split("/")[1]
                              .split("#")[0] === "logs"
                          }
                          className="d-inline-block p-0 w-100"
                          style={{ height: "max-content" }}
                        >
                          <motion.div
                            transition={t.transition}
                            exit={t.fade_out_minimize}
                            animate={t.normalize}
                            initial={t.fade_out_minimize}
                          >
                            <div
                              style={{ transition: "0.25s" }}
                              className={`nav-item-containers d-flex ${
                                this.state.searchOpen ? "" : "flex-column"
                              } align-items-center`}
                            >
                              {this.props.unreadModLogs &&
                              Number(this.props.unreadModLogs) ? (
                                <div
                                  className={`w-max-content ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                >
                                  <i
                                    style={{ transition: "0.25s" }}
                                    className={`${
                                      this.props.history.location.pathname
                                        .split("/")[1]
                                        .split("#")[0] === "logs"
                                        ? "fas"
                                        : "far"
                                    } fa-list-alt ${
                                      this.state.searchOpen
                                        ? "fa-lg"
                                        : "nav-icons"
                                    } `}
                                  ></i>
                                  <MDBBadge color="danger" notification pill>
                                    <Count value={this.props.unreadModLogs} />
                                  </MDBBadge>
                                </div>
                              ) : (
                                <i
                                  style={{ transition: "0.25s" }}
                                  className={`${
                                    this.props.history.location.pathname
                                      .split("/")[1]
                                      .split("#")[0] === "logs"
                                      ? "fas"
                                      : "far"
                                  } fa-list-alt ${
                                    this.state.searchOpen
                                      ? "fa-lg"
                                      : "nav-icons"
                                  } d-block ${
                                    this.state.searchOpen ? "mx-3" : "mx-auto"
                                  }`}
                                ></i>
                              )}
                              <p
                                style={{
                                  fontSize: this.state.searchOpen
                                    ? "1rem"
                                    : "0.75rem",
                                  transition: "0.25s",
                                }}
                                className={`mb-0 ${
                                  this.state.searchOpen ? "" : "mt-2"
                                }`}
                              >
                                Mod Logs
                              </p>
                            </div>
                          </motion.div>
                        </MDBSideNavLink>
                      </Link>
                    </MDBSideNavItem>
                  ) : (
                    <></>
                  )}

                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) =>
                        this.nav(e, `/${this.props.userInfo.username}`)
                      }
                      to={`/${this.props.userInfo.username}`}
                    >
                      <MDBSideNavLink
                        active={
                          this.props.history.location.pathname
                            .split("/")[1]
                            .split("#")[0] === this.props.userInfo.username
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            <i
                              style={{ transition: "0.25s" }}
                              className={`${
                                this.props.history.location.pathname
                                  .split("/")[1]
                                  .split("#")[0] ===
                                this.props.userInfo.username
                                  ? "fas"
                                  : "far"
                              } fa-user ${
                                this.state.searchOpen ? "fa-lg" : "nav-icons"
                              } d-block ${
                                this.state.searchOpen ? "mx-3" : "mx-auto"
                              }`}
                            ></i>
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.75rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Profile
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>
                </>
              ) : (
                <>
                  <MDBSideNavItem>
                    <Link
                      className="text-default"
                      onClick={(e) => this.nav(e, "/login")}
                      to={"/login"}
                    >
                      <MDBSideNavLink
                        active={
                          [
                            "login",
                            "create-account",
                            "forgot-password",
                          ].indexOf(
                            this.props.history.location.pathname
                              .split("/")[1]
                              .split("#")[0]
                          ) > -1
                        }
                        className="d-inline-block p-0 w-100"
                        style={{ height: "max-content" }}
                      >
                        <motion.div
                          transition={t.transition}
                          exit={t.fade_out_minimize}
                          animate={t.normalize}
                          initial={t.fade_out_minimize}
                        >
                          <div
                            style={{ transition: "0.25s" }}
                            className={`nav-item-containers d-flex ${
                              this.state.searchOpen ? "" : "flex-column"
                            } align-items-center`}
                          >
                            <i
                              style={{ transition: "0.25s" }}
                              className={`${
                                [
                                  "login",
                                  "create-account",
                                  "forgot-password",
                                ].indexOf(
                                  this.props.history.location.pathname
                                    .split("/")[1]
                                    .split("#")[0]
                                ) > -1
                                  ? "fas"
                                  : "far"
                              } fa-user ${
                                this.state.searchOpen ? "fa-lg" : "nav-icons"
                              } d-block ${
                                this.state.searchOpen ? "mx-3" : "mx-auto"
                              }`}
                            ></i>
                            <p
                              style={{
                                fontSize: this.state.searchOpen
                                  ? "1rem"
                                  : "0.7rem",
                                transition: "0.25s",
                              }}
                              className={`mb-0 ${
                                this.state.searchOpen ? "" : "mt-2"
                              }`}
                            >
                              Login / Signup
                            </p>
                          </div>
                        </motion.div>
                      </MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>
                </>
              )}
            </MDBSideNavMenu>

            <div id="theme-toggle" className="fg-1">
              <div className="d-flex flex-column justify-content-end h-100 w-100 align-items-center">
                <hr id="divider-theme-toggle" className="my-0 w-100" />
                <MDBTooltip
                  placement="right"
                  tag="div"
                  title="Toggle Dark Mode"
                  className="ms-2"
                  wrapperProps={{
                    onClick: this.toggleDarkMode,
                    className: "w-100",
                  }}
                >
                  <MDBRipple
                    id="theme-toggle-container"
                    className="d-block p-4 cursor-pointer mx-auto rounded w-100"
                  >
                    <i className="fas fa-sun nav-icons mx-auto d-block text-center"></i>
                  </MDBRipple>
                </MDBTooltip>
              </div>
            </div>
          </div>
        </MDBSideNav>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  toggle_dark_mode,
  reconnect_socket,
  update_user,
  purge_user,
  follow,
  new_stream_chat,
  new_viewer,
  leave_viewer,
  set_unread_messages,
  set_notification_count,
  set_open_reports,
  set_unread_mod_logs,
  set_search_term,
  search_change,
  viewers,
  clear_stream_chat,
  update_socket_state,
  set_click_pass,
  set_token,
  stream_event_generic,
  notify,
  remove_virgil_chad,
  set_virgil_chad,
  set_stream_eligibility,
  new_contact_message,
  set_contact_messages,
  set_contact_message_read,
})(withRouter(SideNav));
