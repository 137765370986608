import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  route,
  set_poll_modal,
  update_socket_state,
  set_token,
  set_cache,
} from "../redux/actions";
import axios from "axios";
import EmissionPage from "../components/emission/EmissionPage";
import { MDBContainer } from "mdb-react-ui-kit";

class Tag extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    const currRoute = String(
      String(props.history?.location?.state?.currRoute).split("/tag/")[1]
    ).split("#")[0];
    /**
     * this.tag: String - The hashtag of the page
     */
    this.tag = props.match.params.tag;
    if (this.tag === currRoute) {
      cachedData = props.cache.find(
        (c) => c.page === "tag" && c.tag === this.tag
      );
      this.initialScrollTop = cachedData?.scrollTop;
    }
    this.state = cachedData
      ? {
          ...cachedData.state,
          initialEmissionPageState: cachedData.emissionPageState,
        }
      : {
          /**
           * loaded: Boolean - Whether the initial emission data has been loaded
           * loadingMore: false | Array - List of emissions with replies that are currently loading more replies
           * emissions: Array - All emissions tied to the main emission which have been loaded (replies, signalboosted, etc)
           * endReached: Boolean - Whether there are no more emissions to load
           */
          loaded: false,
          loadingMore: false,
          endReached: false,
        };
  }

  componentDidMount() {
    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
  }

  componentWillUnmount() {
    this.props.set_cache({
      page: "tag",
      tag: this.tag,
      state: this.state,
      emissionPageState: this.getEmissionPageState(),
      scrollTop: document.getElementById("root")?.scrollTop,
    });
  }

  /**
   * Loads the initial data
   * Sets the emissions into state
   * If there are fewer than 20 results, the end has been reached
   */
  load = () => {
    return new Promise((resolve) =>
      axios
        .get(process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/tag/" + this.tag, {
          headers: {
            Authorization: this.props.token,
          },
        })
        .then((res) => {
          this.props.set_token(res.data.token);
          this.setState(
            (curr) => ({
              ...curr,
              endReached: res.data.endReached,
            }),
            () =>
              resolve({
                emissions: res.data.emissions,
              })
          );
        })
        .catch((err) => {
          console.log("load tags err", err);
          setTimeout(async () => {
            const data = await this.load();
            resolve(data);
          }, 1000);
        })
    );
  };

  /**
   * Triggered when the user clicks the View More button at the bottom of a comment thread on an emission
   * Loads more replies in that comment thread
   */
  loadMore = (emissions, callback) => {
    if (!this.state.loadingMore)
      this.setState(
        (curr) => ({
          ...curr,
          loadingMore: true,
        }),
        () =>
          axios
            .post(
              process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/tag/more",
              {
                tag: this.tag,
                emissions: emissions.map((e) => e.emissionID),
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  endReached: res.data.endReached,
                  loadingMore: false,
                }),
                () =>
                  callback({
                    emissions: res.data.emissions,
                  })
              );
            })
            .catch((err) => {
              console.log("load more err", err);
              setTimeout(this.loadMore, 1000);
            })
      );
  };

  render() {
    return (
      <div className="page-container">
        <MDBContainer>
          <EmissionPage
            load={this.load}
            loadMore={this.loadMore}
            flavor="tag"
            loading={this.state.loading}
            loadingMore={this.state.loadingMore}
            endReached={this.state.endReached}
            tag={this.tag}
            setGetEmissionPageState={(f) => (this.getEmissionPageState = f)}
            initialEmissionPageState={this.state.initialEmissionPageState}
          />
        </MDBContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  set_poll_modal,
  update_socket_state,
  set_token,
  set_cache,
})(withRouter(Tag));
