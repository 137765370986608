export const blankUser = {
  userSettings: {
    theme:
      typeof document !== "undefined"
        ? [].slice
            .call(document.head.getElementsByTagName("link"))
            .find((link) => link.href.split("/styles/").length > 1)
            .href.split("/styles/")[1]
            .split(".css")[0] || "default"
        : "default",
  },
  avatar: "blank.webp",
  ban: {
    banned: false,
  },
  unreadMessages: 0,
  notifications: 0,
  boosts: [],
  following: [],
  blocked: [],
};

export const deletedUser = {
  role: "Child",
  avatar: {
    main: "blank-avatar.png",
    thumbnail: "blank-avatar.png",
  },
  background: {
    main: "default-background.webp",
    thumbnail: "default-background.webp",
  },
  deleted: true,
  disabled: true,
  comments: "",
  bio: "<div><p></p></div>",
  bioText: "",
  displayName: "Deleted User",
  website: "",
  location: "",
  badge: false,
  private: false,
  nsfwAccepted: false,
  followers: 0,
  following: 0,
  likes: 0,
  ban: {
    banned: false,
    reason: false,
    user: false,
    details: false,
  },
  approved: false,
  live: false,
  verified: false,
  boosts: 0,
};

export const accountDeletedEmission = (username, userID) => ({
  signalBoost: false,
  files: false,
  html: `<div><h5 class="text-center my-4 display-6">@${username} has deleted their account.</h5></div>`,
  text: `@${username} has deleted their account.`,
  pollID: false,
  likes: 0,
  signalBoosts: 0,
  remove: {
    removed: true,
    reason: "Other",
    details: `@${username} has deleted their account`,
    user: {
      username: username,
      userID: userID,
    },
    timestamp: new Date(),
  },
  replies: 0,
  views: 0,
  tags: [],
  pinned: false,
  deleted: true,
  blocksMe: false,
});

export default { blankUser, deletedUser, accountDeletedEmission };
