import env from "../env";
import React from "react";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import LogoLoader from "../components/LogoLoader";
import { MDBContainer, MDBCard, MDBCardBody, MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import {
  route,
  set_report_count,
  set_token,
  set_redirect_url,
  set_cache,
  notify,
} from "../redux/actions";
import DetailModal from "./reports/DetailModal";
import { Link } from "react-router-dom";

class Reports extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    if (
      props.history?.location?.state?.currRoute.split("#")[0] === "/reports"
    ) {
      cachedData = props.cache.find((c) => c.page === "reports");
      this.initialScrollTop = cachedData?.scrollTop;
    }
    this.state = cachedData
      ? cachedData.state
      : {
          /**
           * reports: Array - List of reports
           * loaded: Boolean - Whether the initial data has loaded
           * detailModalShown: Boolean - Whether the modal with report details is currently displayed
           * socketConnected: Boolean - Whether the main socket has connected
           * reportSelected: false | Object - Reports document
           */
          reports: [],
          loaded: false,
          detailModalShown: false,
          socketConnected: false,
          reportSelected: false,
          redirecting: false,
        };
  }

  /**
   * If user is not logged in, redirect to the login page
   * Else, load report data
   */
  componentDidMount() {
    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
    if (!this.props.userInfo._id || !h.checkJanny(this.props.userInfo)) {
      if (this.props.verificationDetails)
        this.props.history.push("/validate-email");
      else
        this.setState(
          (curr) => ({
            ...curr,
            redirecting: true,
          }),
          () => {
            this.props.set_redirect_url("/reports");
            this.props.history.push("/login");
          }
        );
    } else this.load();
  }

  /**
   * If user logs out, redirect to the login page
   * Once a new report is received, mark reports as read after 1 second
   * If socket connects for the first time, or disconnected socket reconnects, initialize socket
   * If a new report is received, update the reports list
   */
  componentDidUpdate(prevProps) {
    if (!this.props.userInfo._id && !this.state.redirecting)
      this.setState(
        (curr) => ({
          ...curr,
          redirecting: true,
        }),
        () => {
          this.props.route("/login");
        }
      );
    if (prevProps.userInfo.unreadReports !== this.props.userInfo.unreadReports)
      this.readAll();
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    if (prevProps.openReports < this.props.openReports) this.update();
  }

  componentWillUnmount() {
    this.props.set_cache({
      page: "reports",
      state: this.state,
      scrollTop: document.getElementById("root")?.scrollTop,
    });
  }

  /**
   * Triggered when the user receives a new report via socket
   * Pings the server for an updated reports list
   * Updates the reports list
   */
  update = () =>
    axios
      .post(
        process.env.REACT_APP_LAMBDA_API_REPORTS + "/update",
        {
          ids: this.state.reports.map((r) => r._id),
        },
        {
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState((curr) => ({
          ...curr,
          reports: [
            ...curr.reports,
            ...res.data.reports.filter(
              (r) => !curr.reports.find((report) => report._id === r._id)
            ),
          ],
        }));
      })
      .catch((err) => {
        console.log("update error", err);
        if (!this.state.redirecting) setTimeout(this.update, 1500);
      });

  /**
   *
   * @param {Object} report - Reports document
   *
   * Triggered when the user clicks on a report
   * Sets that report into state and opens the Report Details modal
   */
  selectReport = (report) =>
    this.setState(
      (curr) => ({
        ...curr,
        reportSelected: report,
      }),
      this.toggleDetailModal
    );

  /**
   * Loads the initial list of reports
   * Sets that list into state
   */
  load = () =>
    axios
      .get(process.env.REACT_APP_LAMBDA_API_REPORTS, {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => {
        this.props.set_token(res.data.token);
        this.setState(
          (curr) => ({
            ...curr,
            reports: h.updateArrayItems(this.state.reports, res.data.reports),
            loaded: true,
          }),
          () => {
            this.readAll();
            // if (this.props.socket && !this.state.socketConnected)
            //   this.connectSocket();
          }
        );
      })
      .catch((err) => {
        console.log("reports load error", err);
        if (!this.state.redirecting) setTimeout(this.load, 1000);
      });

  /**
   * Turn off and then turn back on all socket actions
   */
  initializeSocket = () => {
    // console.log("socket", this.props.socket);
    if (!this.props.socket) return;
    this.props.socket.off("report");

    this.props.socket.on("report", () => {
      try {
        this.props.set_report_count(this.props.userInfo.unreadReports + 1);
      } catch (err) {
        console.log("report error", err);
      }
    });
  };

  /**
   * Triggered any time the user loads the page or receives new notifications
   * After 1 second, marks all reports as read
   */
  readAll = () =>
    setTimeout(
      () =>
        axios
          .get(process.env.REACT_APP_LAMBDA_PROFILE + "/read-reports", {
            headers: {
              Authorization: this.props.token,
            },
          })
          .then((res) => {
            this.props.set_token(res.data.token);
            this.props.set_report_count(0);
          })
          .catch((err) => {
            console.log("Read all error", err);
            if (!this.state.redirecting) setTimeout(this.readAll, 2000);
          }),
      1000
    );

  /**
   *
   * @param {Click Event} e
   * @param {String} path - href/URL
   *
   * Triggered when the user clicks a link
   * Override default behavior and use redux props.route method
   */
  route = (e, destination) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.route(destination);
  };

  toggleDetailModal = () =>
    this.setState((curr) => ({
      ...curr,
      detailModalShown: !this.state.detailModalShown,
    }));

  setDetailModal = (option) =>
    this.setState((curr) => ({
      ...curr,
      detailModalShown: option,
    }));

  /**
   *
   * @param {Object} data - Data of report(s) to purge (Content id, notification text/icon)
   *
   * Triggered when the user takes action on one of the reports
   * Purges all of the reports received for the same content
   * Notifies the user that the action has completed
   */
  purgeReports = (data) => {
    this.props.notify(data.icon, data.text);
    if (data.type === "emission")
      this.setState(
        (curr) => ({
          ...curr,
          reports: this.state.reports.filter(
            (r) => r.emissionID !== data.emissionID
          ),
          reportSelected: false,
        }),
        () => {
          if (data.ban)
            this.setState((curr) => ({
              ...curr,
              reports: this.state.reports.filter(
                (r) =>
                  r.user_id !== data.user_id &&
                  (!r.emission || r.emission.user_id !== data.user_id)
              ),
            }));
        }
      );
    else if (data.type === "approval")
      this.setState((curr) => ({
        ...curr,
        reports: this.state.reports.filter((r) => r._id !== data.reportID),
        reportSelected: false,
      }));
    else
      this.setState(
        (curr) => ({
          ...curr,
          reports: this.state.reports.filter((r) => r.user_id !== data.user_id),
          reportSelected: false,
        }),
        () => {
          if (data.ban)
            this.setState((curr) => ({
              ...curr,
              reports: this.state.reports.filter(
                (r) => r.poster !== data.poster
              ),
            }));
        }
      );
  };

  render() {
    let reports = [
      ...this.state.reports
        .filter((r) => r.type === "approval")
        .map((r) => ({
          ...r,
          userInfo: r.user,
          lastReport: new Date(r.timestamp),
        })),
    ];
    [
      ...new Set(
        this.state.reports
          .filter((r) => r.type === "user")
          .map((r) => r.user_id)
      ),
    ].forEach((user_id) => {
      const affectedReports = this.state.reports
        .filter((r) => r.type === "user" && r.user_id === user_id)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      reports.push({
        lastReport: affectedReports[0].timestamp,
        reports: affectedReports,
        type: "user",
        user_id: user_id,
        unread: affectedReports.find((r) => r.unread),
        userInfo: affectedReports[0].user,
      });
    });
    [
      ...new Set(
        this.state.reports
          .filter((r) => r.type === "emission")
          .map((r) => r.emissionID)
      ),
    ].forEach((emissionID) => {
      const affectedReports = this.state.reports
        .filter((r) => r.type === "emission" && r.emissionID === emissionID)
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      reports.push({
        lastReport: affectedReports[0].timestamp,
        reports: affectedReports,
        type: "emission",
        emissionID: emissionID,
        unread: affectedReports.find((r) => r.unread),
        emission: affectedReports[0].emission,
      });
    });
    reports = [
      ...reports
        .filter((r) => r.unread)
        .sort((a, b) => new Date(b.lastReport) - new Date(a.lastReport)),
      ...reports
        .filter((r) => !r.unread)
        .sort((a, b) => new Date(b.lastReport) - new Date(a.lastReport)),
    ];
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 h-100 report-container page-container"
      >
        <DetailModal
          modalShown={this.state.detailModalShown}
          toggleShowModal={this.toggleDetailModal}
          setShowModal={this.setDetailModal}
          report={this.state.reportSelected}
          purgeReports={this.purgeReports}
          token={this.props.token}
          set_token={this.props.set_token}
        />
        <MDBContainer>
          {this.state.loaded ? (
            <motion.div
              transition={t.transition}
              exit={t.fade_out_scale_1}
              animate={t.normalize}
              initial={t.fade_out}
            >
              {this.state.reports.length ? (
                <MDBCard className="cards-full-width">
                  <MDBCardBody>
                    <h5 className="text-center display-6">Reports</h5>
                    <hr />
                    {reports.map((report) => {
                      return (
                        <div
                          key={
                            report.type === "user"
                              ? `user-${report.user_id}`
                              : `emission-${report.emissionID}`
                          }
                        >
                          <motion.div
                            className="px-2 d-flex justify-content-between align-items-center report-info-container"
                            transition={t.transition}
                            exit={t.fade_out_scale_1}
                            animate={t.normalize}
                            initial={t.fade_out}
                          >
                            {report.type === "user" ? (
                              <h5 className="m-0">
                                <i
                                  style={{ width: "25px" }}
                                  className="fas fa-user text-primary me-2 text-center"
                                ></i>
                                <Link
                                  className="text-default"
                                  to={`/${report.userInfo.username}`}
                                  onClick={(e) =>
                                    this.route(
                                      e,
                                      `/${report.userInfo.username}`
                                    )
                                  }
                                >
                                  @{report.userInfo.username}
                                </Link>
                              </h5>
                            ) : (
                              <>
                                {report.type === "emission" ? (
                                  <h5 className="m-0">
                                    <i
                                      style={{ width: "25px" }}
                                      className="fas fa-wifi text-success me-2 text-center"
                                    ></i>
                                    <Link
                                      className="text-default"
                                      to={`/e/${report.emissionID}`}
                                      onClick={(e) =>
                                        this.route(e, `/e/${report.emissionID}`)
                                      }
                                    >
                                      <span className="text-capitalize">
                                        {env.EMISSION_NAME}
                                      </span>{" "}
                                      <span className="text-gb">
                                        #{report.emissionID}
                                      </span>
                                    </Link>
                                  </h5>
                                ) : (
                                  <h5 className="m-0">
                                    <i
                                      style={{ width: "25px" }}
                                      className="fas fa-user-plus text-secondary me-2 text-center"
                                    ></i>
                                    <Link
                                      to={`/${report.userInfo.username}`}
                                      onClick={(e) =>
                                        this.route(
                                          e,
                                          `/${report.userInfo.username}`
                                        )
                                      }
                                    >
                                      @{report.userInfo.username}
                                    </Link>
                                    <> requires approval</>
                                  </h5>
                                )}
                              </>
                            )}
                            {String(env.READ_ONLY) === "true" &&
                            !h.checkChadmin(this.props.userInfo) ? (
                              <></>
                            ) : (
                              <MDBBtn
                                color="link"
                                rippleColor="primary"
                                size="lg"
                                onClick={() => this.selectReport(report)}
                                title={
                                  h.numberWithCommas(
                                    report.reports?.length || 1
                                  ) +
                                  " Report" +
                                  ((report.reports?.length || 1) === 1
                                    ? ""
                                    : "s")
                                }
                              >
                                {report.type === "approval" ? (
                                  "View"
                                ) : (
                                  <>
                                    {h.compiledNumber(report.reports.length)}{" "}
                                    Report
                                    {(report.reports?.length || 1) === 1
                                      ? ""
                                      : "s"}
                                  </>
                                )}
                              </MDBBtn>
                            )}
                          </motion.div>
                          <hr />
                        </div>
                      );
                    })}
                  </MDBCardBody>
                </MDBCard>
              ) : (
                <h5 className="text-center display-4 mb-4 mt-5">
                  There are no reports
                </h5>
              )}
            </motion.div>
          ) : (
            <>
              <h5 className="text-center display-6 mb-4 mt-5">Reports</h5>
              <LogoLoader />
            </>
          )}
        </MDBContainer>
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  route,
  set_report_count,
  set_token,
  set_redirect_url,
  set_cache,
  notify,
})(Reports);
