import React from "react";
import { connect } from "react-redux";
import ProfileNav from "./ProfileNav";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import h from "../../utilities/helpers";
import {
  update_emission,
  set_emission_reported,
  set_token,
} from "../../redux/actions";
import ProfileComponent from "./ProfileComponent";
import RemoveModal from "../../components/actionModals/RemoveModal";
import RestoreModal from "../../components/actionModals/RestoreModal";
import ReportModal from "../../components/actionModals/ReportModal";

class Feed extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * removeModalShown: Boolean - Whether the Remove Emission modal is displayed
       * reportModalShown: Boolean - Whether the Report Emission modal is displayed
       * restoreModalShown: Boolean - Whether the Restore Emission modal is displayed
       * emissionRemoving: Object - Emission that is displayed in the Remove Emission modal
       * emissionReporting: Object - Emission that is displayed in the Report Emission modal
       * emissionRestoring: Object - Emission that is displayed in the Restore Emission modal
       */
      removeModalShown: false,
      reportModalShown: false,
      restoreModalShown: false,
      emissionRemoving: false,
      emissionReporting: false,
      emissionRestoring: false,
      firstLoad: false,
    };
  }

  componentDidMount() {
    this.setState((curr) => ({
      ...curr,
      firstLoad: true,
    }));
  }

  toggleRemoveModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        removeModalShown: !this.state.removeModalShown,
      }),
      h.hideToolTips
    );
  };

  setRemoveModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      removeModalShown: option,
    }));
  };

  remove = (emission) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissionRemoving: emission,
      }),
      this.toggleRemoveModal
    );

  toggleRestoreModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        restoreModalShown: !this.state.restoreModalShown,
      }),
      h.hideToolTips
    );
  };

  setRestoreModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      restoreModalShown: option,
    }));
  };

  restore = (emission) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissionRestoring: emission,
      }),
      this.toggleRestoreModal
    );

  toggleReportModal = () => {
    this.setState(
      (curr) => ({
        ...curr,
        reportModalShown: !this.state.reportModalShown,
      }),
      h.hideToolTips
    );
  };

  setReportModal = (option) => {
    this.setState((curr) => ({
      ...curr,
      reportModalShown: option,
    }));
  };

  report = (emission) =>
    this.setState(
      (curr) => ({
        ...curr,
        emissionReporting: emission,
      }),
      this.toggleReportModal
    );

  setEmissionReported = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        reportModalShown: false,
      }),
      () => this.props.set_emission_reported(e)
    );

  updateEmission = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        removeModalShown: false,
        restoreModalShown: false,
      }),
      () => this.props.update_emission(e)
    );

  render() {
    return (
      <>
        <RemoveModal
          modalShown={this.state.removeModalShown}
          setShowModal={this.setRemoveModal}
          toggleShowModal={this.toggleRemoveModal}
          emission={this.state.emissionRemoving}
          updateEmission={this.updateEmission}
        />
        <RestoreModal
          modalShown={this.state.restoreModalShown}
          setShowModal={this.setRestoreModal}
          toggleShowModal={this.toggleRestoreModal}
          emission={this.state.emissionRestoring}
          updateEmission={this.updateEmission}
          token={this.props.token}
          set_token={this.props.set_token}
        />
        <ReportModal
          modalShown={this.state.reportModalShown}
          setShowModal={this.setReportModal}
          toggleShowModal={this.toggleReportModal}
          emission={this.state.emissionReporting}
          setEmissionReported={this.setEmissionReported}
        />
        <hr id="feed-top" className="mt-4"></hr>
        <ProfileNav
          tabSelected={this.props.tabSelected}
          selectTab={this.props.selectTab}
        />
        <div style={{ minHeight: "100vh" }}>
          <StaticRouter location={this.props.tabSelected}>
            <AnimatePresence exitBeforeEnter>
              <Switch key={this.props.tabSelected}>
                <Route exact path=":tabSelected">
                  <ProfileComponent
                    exit={this.props.tabExit}
                    enter={this.props.tabEnter}
                    tabSelected={this.props.tabSelected}
                    notify={this.props.notify}
                    selectTab={this.props.selectTab}
                    toggleLoginModal={this.props.toggleLoginModal}
                    report={this.report}
                    remove={this.remove}
                    restore={this.restore}
                    flavor={this.props.tabSelected}
                    updating={this.props.updating}
                  />
                </Route>
              </Switch>
            </AnimatePresence>
          </StaticRouter>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  update_emission,
  set_emission_reported,
  set_token,
})(Feed);
