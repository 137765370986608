import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import Spinner from "../../../../components/Spinner";
import {
  edit_user_schema,
  change_password_schema,
} from "../../../../utilities/validations";
import t from "../../../../utilities/transitions";
import { motion } from "framer-motion";
import axios from "axios";
import {
  change_user_details,
  toggle_private,
  toggle_disable_messages,
  set_token,
  notify,
} from "../../../../redux/actions";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import EditProfileForm from "./EditProfileForm";

const fields = [
  {
    text: "Email Address",
    id: "email",
    type: "text",
  },
  {
    text: "Display Name",
    id: "displayName",
    type: "text",
  },
  {
    text: "Location (Optional)",
    id: "location",
    type: "text",
  },
  {
    text: "Website (Optional)",
    id: "website",
    type: "text",
  },
];

const changePassword = [
  {
    id: "current_password",
    text: "Current Password",
  },
  {
    id: "password1",
    text: "New Password",
  },
  {
    id: "password2",
    text: "Re-enter password",
  },
];

class EditProfileModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      /**
       * inputs: Array - The Edit Profile form input data (values, errors, etc)
       * changePasswordInputs: arrray - The Change Password form input data (values, errors, etc)
       * working: Boolean - Whether the selected form is in the process of being submitted
       * privating: Boolean - Whether the user is in the process of privating their profile
       * disablingMessages: Boolean - Whether the user is in the process of disabling private messages
       * reset: Boolean - Measures nothing, but then flipped, fixes a MDB ui bug
       * formShown: String - The form that the user has open - "info" | "password",
       * staticBackdrop: Boolean - Whether the modal backdrop is static
       */
      inputs: fields.map((field) => ({
        id: field.id,
        error: "",
        invalid: false,
        value: props.userInfo[field.id],
      })),
      changePasswordInputs: changePassword.map((field) => ({
        id: field.id,
        error: "",
        invalid: false,
        value: "",
      })),
      working: false,
      privating: false,
      disablingMessages: false,
      reset: false,
      formShown: "info",
      staticBackdrop: false,
    };
  }

  /**
   * Run blank change handler
   * When the user resizes the page, reset the modal body height
   */
  componentDidMount() {
    window.addEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * When modal is displayed, set body height
   */
  componentDidUpdate(prevProps) {
    if (prevProps.modalShown !== this.props.modalShown) {
      this.setModalBodyHeight();
    }
  }

  /**
   * remove event listeners
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () => "";
  // new Promise(async (resolve, reject) => {
  //   if (String(process.env.REACT_APP_DEV) === "true")
  //     return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
  //   if (this.props.captchaReady)
  //     window.grecaptcha.enterprise
  //       .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
  //       .then(resolve)
  //       .catch((err) => {
  //         console.info(err);
  //         alert("Human verification failed. Refresh the page and try again.");
  //         reject();
  //       });
  //   else
  //     setTimeout(async () => {
  //       const captchaKey = await this.getRecaptcha();
  //       resolve(captchaKey);
  //     }, 500);
  // });

  /**
   *
   * Close modal only if the user clicks one of the close buttons or the actual backdrop
   * Prevents bug that closes the modal when clicking anywhere while the modal is growing or shrinking
   */
  closeModal = (e) => {
    if (
      e.target.classList.contains("modal") &&
      !this.state.working &&
      this.props.modalShown
    ) {
      this.props.toggleShowModal();
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      (curr) => ({
        ...curr,
        inputs: this.state.inputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.inputs.map((input) => [input.id, input.value])
        );
        try {
          edit_user_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState((curr) => ({
            ...curr,
            inputs: this.state.inputs.map((input) => {
              document
                .getElementById(input.id + "-edit-profile")
                .setCustomValidity("");
              return {
                ...input,
                invalid: false,
                error: "",
              };
            }),
          }));
        } catch (err) {
          console.info(err);
          let errorsAdded = [];
          if (err.inner) {
            this.setState(
              (curr) => ({
                ...curr,
                inputs: this.state.inputs.map((input) => {
                  if (
                    err.inner.find((error) => error.path === input.id) &&
                    errorsAdded.indexOf(input.id) === -1
                  ) {
                    errorsAdded.push(input.id);
                    return {
                      ...input,
                      invalid: true,
                      error: err.inner.find((error) => error.path === input.id)
                        .message,
                    };
                  } else
                    return {
                      ...input,
                      invalid: false,
                      error: "",
                    };
                }),
              }),
              () =>
                this.state.inputs.forEach((input) => {
                  document
                    .getElementById(input.id + "-edit-profile")
                    .setCustomValidity(input.error);
                })
            );
          }
        }
      }
    );
  };

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Set user to user account that the server responds with
   * If unsuccessful and error 401, alert invalid username or password
   * If unsuccessful and error 403, alert user is locked out
   * If unsuccessful and not error 401 or 403, general error alert
   */
  submit = () => {
    if (this.state.formShown === "info") {
      document
        .getElementById("form-edit-profile")
        .classList.add("was-validated");
      this.forceParse();
      let invalidInputs = this.state.inputs.filter((input) => input.invalid);
      invalidInputs.forEach((input) => {
        document
          .getElementById(input.id + "-edit-profile")
          .setCustomValidity(input.error);
      });
      if (!this.state.working && !invalidInputs.length)
        this.setState(
          (curr) => ({
            ...curr,
            working: true,
          }),
          async () => {
            const data = Object.fromEntries(
              this.state.inputs.map((input) => [input.id, input.value])
            );
            try {
              edit_user_schema.validateSync(data, {
                abortEarly: false,
              });
              const captchaKey = await this.getRecaptcha();
              const fd = {};
              for (const key in data) {
                fd[key] = data[key];
              }
              fd["captchaKey"] = captchaKey;
              const bio = document.getElementById("input-bio-edit-self");
              const length = String(bio.textContent)
                .split("")
                .filter((c) => {
                  const checkWhiteSpace = c.match(/[\s]/);
                  if (!checkWhiteSpace) return true;
                  else {
                    return [" ", "\n"].indexOf(c) > -1;
                  }
                }).length;
              if (length > 1000)
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => alert("Your bio is too long (Max: 1000 chars)")
                );
              else {
                fd["bio"] = bio.innerHTML.replace(/[\u200B-\u200D\uFEFF]/g, "");
                axios
                  .post(process.env.REACT_APP_LAMBDA_PROFILE + "/update", fd, {
                    headers: {
                      Authorization: this.props.token,
                    },
                  })
                  .then((res) => {
                    this.props.toggleShowModal();
                    this.props.set_token(res.data.token);
                    this.setState(
                      (curr) => ({
                        ...curr,
                        working: false,
                      }),
                      () => {
                        document
                          .getElementById("form-edit-profile")
                          .classList.remove("was-validated");
                        this.props.change_user_details(res.data.userInfo);

                        setTimeout(
                          () =>
                            this.props.notify(
                              <i className="fas fa-user me-2 text-success"></i>,
                              "User Info Updated"
                            ),
                          200
                        );
                      }
                    );
                  })
                  .catch((err) =>
                    this.setState(
                      (curr) => ({
                        ...curr,
                        working: false,
                      }),
                      () => {
                        if (err.response?.status === 400)
                          alert(err.response.data.message);
                        else {
                          console.info(err);
                          alert("An error occurred. Please try again later.");
                        }
                      }
                    )
                  );
              }
            } catch (err) {
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => {
                  console.info(err);
                  alert("An error occurred. Please try again later");
                }
              );
            }
          }
        );
    } else {
      document
        .getElementById("form_change_password")
        .classList.add("was-validated");
      let invalidInputs = this.state.changePasswordInputs.filter(
        (input) => input.invalid
      );
      invalidInputs.forEach((input) => {
        document.getElementById(input.id).setCustomValidity(input.error);
      });
      if (!this.state.working && !invalidInputs.length)
        this.setState(
          (curr) => ({
            ...curr,
            working: true,
          }),
          async () => {
            const data = Object.fromEntries(
              this.state.changePasswordInputs.map((input) => [
                input.id,
                input.value,
              ])
            );
            try {
              change_password_schema.validateSync(data, {
                abortEarly: false,
              });
              const captchaKey = await this.getRecaptcha();
              const fd = {};
              for (const key in data) {
                fd[key] = data[key];
              }
              fd["captchaKey"] = captchaKey;
              axios
                .post(
                  process.env.REACT_APP_LAMBDA_PROFILE + "/change-password",
                  fd,
                  {
                    headers: {
                      Authorization: this.props.token,
                    },
                  }
                )
                .then((res) => {
                  this.props.set_token(res.data.token);
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                      changePasswordInputs: changePassword.map((field) => ({
                        id: field.id,
                        error: "",
                        invalid: false,
                        value: "",
                      })),
                    }),
                    () => {
                      this.changeForm();
                      this.props.notify(
                        <i className="fas fa-lock me-2 text-success"></i>,
                        "Password Changed"
                      );
                    }
                  );
                })
                .catch((err) =>
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                    }),
                    () => {
                      if (err.response?.status === 400)
                        alert(err.response.data.message);
                      else {
                        console.info(err);
                        alert("An error occurred. Please try again later.");
                      }
                    }
                  )
                );
            } catch (err) {
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () => {
                  console.info(err);
                  alert("An error occurred. Please try again later");
                }
              );
            }
          }
        );
    }
  };

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler_password = (e) =>
    this.setState(
      (curr) => ({
        ...curr,
        changePasswordInputs: this.state.changePasswordInputs.map((input) => {
          if (input.id === e.target.name)
            return {
              ...input,
              value: e.target.value,
            };
          else return input;
        }),
      }),
      () => {
        const data = Object.fromEntries(
          this.state.changePasswordInputs.map((input) => [
            input.id,
            input.value,
          ])
        );
        try {
          change_password_schema.validateSync(data, {
            abortEarly: false,
          });
          this.setState((curr) => ({
            ...curr,
            changePasswordInputs: this.state.changePasswordInputs.map(
              (input) => {
                document.getElementById(input.id).setCustomValidity("");
                return {
                  ...input,
                  invalid: false,
                  error: "",
                };
              }
            ),
          }));
        } catch (err) {
          let errorsAdded = [];
          this.setState(
            (curr) => ({
              ...curr,
              changePasswordInputs: this.state.changePasswordInputs.map(
                (input) => {
                  if (
                    err.inner.find((error) => error.path === input.id) &&
                    errorsAdded.indexOf(input.id) === -1
                  ) {
                    errorsAdded.push(input.id);
                    return {
                      ...input,
                      invalid: true,
                      error: err.inner.find((error) => error.path === input.id)
                        .message,
                    };
                  } else
                    return {
                      ...input,
                      invalid: false,
                      error: "",
                    };
                }
              ),
            }),
            () => {
              this.state.changePasswordInputs.forEach((input) => {
                document
                  .getElementById(input.id)
                  .setCustomValidity(input.error);
              });
            }
          );
        }
      }
    );

  /**
   * Submit the form if the user presses the enter key while in one of the inputs
   */
  pressEnter = (e) => {
    if (e.key === "Enter") this.submit();
  };

  /**
   * Toggles the form
   * Sets modal body height
   * Runs a blank changeHandler on the new form
   */
  changeForm = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          formShown: this.state.formShown === "info" ? "password" : "info",
        }),
        () =>
          setTimeout(() => {
            this.setModalBodyHeight();
            if (this.state.formShown === "info")
              this.changeHandler({
                target: {
                  name: "",
                },
              });
            else
              this.changeHandler_password({
                target: {
                  name: "",
                },
              });
          }, 333)
      );
  };

  /**
   * Set the body height to that of its immediate child
   * Causes the body to smoothly grow or shrink instead of pop
   */
  setModalBodyHeight = () => {
    const body = document.getElementById("edit-profile-modal-body");
    if (body) {
      if (
        this.props.screenDimensions.width >
        this.props.screenDimensions.modalBreak
      ) {
        body.style.height = `${
          document.getElementById("edit-profile-modal-body-child").clientHeight
        }px`;
      } else {
        body.style.flexGrow = "1";
        body.style.height = `0`;
      }
    }
  };
  /**
   * Triggered when the user clicks Private Profile
   * Makes request to server
   * Notifies the user
   * Updates user info in application state
   */
  privateProfile = () => {
    if (!this.state.privating)
      this.setState(
        (curr) => ({
          ...curr,
          privating: true,
        }),
        () =>
          axios
            .get(process.env.REACT_APP_LAMBDA_PROFILE + "/private", {
              headers: {
                Authorization: this.props.token,
              },
            })
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  privating: false,
                }),
                () => {
                  this.props.notify(
                    <i className="fas fa-lock text-danger me-2"></i>,
                    "Profile is now private"
                  );
                  this.props.toggle_private();
                }
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  privating: false,
                }),
                () => {
                  console.info(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user clicks Unprivate Profile
   * Makes request to server
   * Notifies the user
   * Updates user info in application state
   */
  unprivate = () => {
    if (!this.state.privating)
      this.setState(
        (curr) => ({
          ...curr,
          privating: true,
        }),
        () =>
          axios
            .get(process.env.REACT_APP_LAMBDA_PROFILE + "/unprivate", {
              headers: {
                Authorization: this.props.token,
              },
            })
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  privating: false,
                }),
                () => {
                  this.props.notify(
                    <i className="fas fa-unlock text-success me-2"></i>,
                    "Profile is now public"
                  );
                  this.props.toggle_private();
                }
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  privating: false,
                }),
                () => {
                  console.info(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when ther user clicks Disable Private Messages or Enable Private Messages
   */
  toggleDisableMessages = () => {
    if (!this.state.disablingMessages)
      this.setState(
        (curr) => ({
          ...curr,
          disablingMessages: true,
        }),
        () =>
          axios
            .get(process.env.REACT_APP_LAMBDA_PROFILE + "/disable-messages", {
              headers: {
                Authorization: this.props.token,
              },
            })
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  disablingMessages: false,
                }),
                () => {
                  if (this.props.userInfo.messagesDisabled)
                    this.props.notify(
                      <i className="fas fa-comment text-success me-2"></i>,
                      "Private messages enabled."
                    );
                  else
                    this.props.notify(
                      <i className="fas fa-comment-slash text-danger me-2"></i>,
                      "Private messages disabled"
                    );
                  this.props.toggle_disable_messages();
                }
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  disablingMessages: false,
                }),
                () => {
                  console.info(err);
                  alert("An error occurred. Please try again later");
                }
              )
            )
      );
  };

  render() {
    const screenDimensions = this.props.screenDimensions;
    return (
      <MDBModal
        staticBackdrop
        open={this.props.modalShown}
        onClosePrevented={() => {
          if (!this.state.working) this.props.toggleShowModal();
        }}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            screenDimensions.width > screenDimensions.modalBreak
              ? "xl"
              : "fullscreen"
          }
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {this.state.formShown === "info" ? (
                  <motion.div
                    className="m-0"
                    transition={t.transition}
                    exit={t.fade_out_minimize}
                    animate={t.normalize}
                    initial={t.fade_out_minimize}
                  >
                    Edit Profile Info
                  </motion.div>
                ) : (
                  <motion.p
                    className="m-0"
                    transition={t.transition}
                    exit={t.fade_out_minimize}
                    animate={t.normalize}
                    initial={t.fade_out_minimize}
                  >
                    Change Password
                  </motion.p>
                )}
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={this.props.toggleShowModal}
              ></MDBBtn>
            </MDBModalHeader>
            <div id="edit-profile-modal-body" className="transition-25">
              <MDBModalBody
                id="edit-profile-modal-body-child"
                className={
                  this.props.screenDimensions.width >
                  this.props.screenDimensions.modalBreak
                    ? ""
                    : "h-100"
                }
              >
                <StaticRouter location={this.state.formShown}>
                  <AnimatePresence exitBeforeEnter>
                    <Switch key={this.state.formShown}>
                      <Route exact path=":form">
                        <EditProfileForm
                          form={this.state.formShown}
                          pressEnter={this.pressEnter}
                          submit={this.submit}
                          changeHandler={this.changeHandler}
                          inputs={this.state.inputs}
                          fields={fields}
                          modalShown={this.props.modalShown}
                          changePassword={changePassword}
                          changeHandler_password={this.changeHandler_password}
                          changePasswordInputs={this.state.changePasswordInputs}
                          working={this.state.working}
                          setForceParse={(f) => (this.forceParse = f)}
                        />
                      </Route>
                    </Switch>
                  </AnimatePresence>
                </StaticRouter>
              </MDBModalBody>
            </div>
            <MDBModalFooter
              style={{ zIndex: 999 }}
              className="d-flex justify-content-between align-items-start"
              id="edit-profile-footer-mobile"
            >
              <div className="d-flex w-100 m-0">
                <MDBBtn
                  onClick={this.changeForm}
                  className="w-33 modal-buttons-mobile d-block bg-purple rounded-0"
                >
                  {this.state.formShown === "info" ? (
                    <>
                      <i className="fas fa-key me-2"></i>
                      <span>Change Password</span>
                    </>
                  ) : (
                    <>
                      <i className="fas fa-user me-2"></i>
                      <span>Edit Profile</span>
                    </>
                  )}
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  className="w-34 modal-buttons-mobile d-block rounded-0"
                  disabled={this.state.privating}
                  onClick={
                    this.state.privating
                      ? () => {}
                      : this.props.userInfo.private
                      ? this.unprivate
                      : this.privateProfile
                  }
                >
                  {this.state.privating ? (
                    <>
                      <Spinner className="me-2" size="sm" />
                      <span>
                        {this.props.userInfo.private
                          ? "Unprivating"
                          : "Privating"}
                      </span>
                    </>
                  ) : (
                    <>
                      {this.props.userInfo.private ? (
                        <>
                          <i className="fas fa-unlock me-2"></i>
                          <span>Unprivate Profile</span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-lock me-2"></i>
                          <span>Private Profile</span>
                        </>
                      )}
                    </>
                  )}
                </MDBBtn>
                <MDBBtn
                  className="w-33 modal-buttons-mobile d-block rounded-0 bg-teal"
                  onClick={this.toggleDisableMessages}
                  disabled={this.state.disablingMessages}
                >
                  {this.state.disablingMessages ? (
                    <>
                      <Spinner className="me-2" size="sm" />
                      <span>
                        {this.props.userInfo.messagesDisabled
                          ? "Enabling Messages"
                          : "Disable Messages"}
                      </span>
                    </>
                  ) : (
                    <>
                      {this.props.userInfo.messagesDisabled ? (
                        <>
                          <i className="fas fa-comment me-2"></i>
                          <span>Enable Private Messages</span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-comment-slash me-2"></i>
                          <span>Disable Private Messages</span>
                        </>
                      )}
                    </>
                  )}
                </MDBBtn>
              </div>
              <div className="d-flex w-100 m-0">
                <MDBBtn
                  color="primary"
                  disabled={this.state.working}
                  onClick={this.state.working ? () => {} : this.submit}
                  className={`d-block w-50 modal-buttons-mobile rounded-0 ${
                    screenDimensions.width > screenDimensions.modalBreak
                      ? "border-bottom-left-radius-0p25rem"
                      : ""
                  }`}
                >
                  {this.state.working ? (
                    <>
                      <Spinner size="sm" className="me-2" />
                      Saving
                    </>
                  ) : (
                    <>
                      <i className="fas fa-save me-2"></i>
                      <span>Save Changes</span>
                    </>
                  )}
                </MDBBtn>
                <MDBBtn
                  className={`d-block w-50 modal-buttons-mobile bg-gray rounded-0 ${
                    screenDimensions.width > screenDimensions.modalBreak
                      ? "border-bottom-right-radius-0p25rem"
                      : ""
                  }`}
                  color="dark"
                  onClick={this.props.toggleShowModal}
                >
                  <span>Close</span>
                </MDBBtn>
              </div>
            </MDBModalFooter>
            <MDBModalFooter
              style={{ zIndex: 999 }}
              className="d-flex justify-content-between align-items-start edit-profile-footer"
              id="edit-profile-footer-desktop"
            >
              <div className="d-flex edit-profile-buttons-actions">
                <MDBBtn onClick={this.changeForm} className="bg-purple">
                  {this.state.formShown === "info" ? (
                    <>
                      <i className="fas fa-key me-2"></i>
                      Change Password
                    </>
                  ) : (
                    <>
                      <i className="fas fa-user me-2"></i>
                      Edit Profile
                    </>
                  )}
                </MDBBtn>
                <MDBBtn
                  color="danger"
                  className="mx-2"
                  disabled={this.state.privating}
                  onClick={
                    this.state.privating
                      ? () => {}
                      : this.props.userInfo.private
                      ? this.unprivate
                      : this.privateProfile
                  }
                >
                  {this.state.privating ? (
                    <>
                      <Spinner className="me-2" size="sm" />
                      {this.props.userInfo.private
                        ? "Unprivating"
                        : "Privating"}
                    </>
                  ) : (
                    <>
                      {this.props.userInfo.private ? (
                        <>
                          <i className="fas fa-unlock me-2"></i>
                          Unprivate Profile
                        </>
                      ) : (
                        <>
                          <i className="fas fa-lock me-2"></i>
                          Private Profile
                        </>
                      )}
                    </>
                  )}
                </MDBBtn>

                {this.state.disablingMessages ? (
                  <MDBBtn disabled className="bg-teal">
                    <Spinner className="me-2" size="sm" />
                    {this.props.userInfo.messagesDisabled
                      ? "Enabling Messages"
                      : "Disable Messages"}
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    onClick={this.toggleDisableMessages}
                    className="bg-teal"
                  >
                    {this.props.userInfo.messagesDisabled ? (
                      <>
                        <i className="fas fa-comment me-2"></i>
                        Enable Private Messages
                      </>
                    ) : (
                      <>
                        <i className="fas fa-comment-slash me-2"></i>
                        Disable Private Messages
                      </>
                    )}
                  </MDBBtn>
                )}
              </div>
              <hr className="edit-profile-divider w-100 mb-3 mt-1" />
              <div className="d-flex align-items-start edit-profile-buttons-decisions">
                {this.state.working ? (
                  <MDBBtn disabled className="me-2" color="primary">
                    <Spinner size="sm" className="me-2" />
                    Saving
                  </MDBBtn>
                ) : (
                  <MDBBtn
                    className="me-2"
                    onClick={this.submit}
                    color="primary"
                  >
                    <i className="fas fa-save me-2"></i>Save Changes
                  </MDBBtn>
                )}
                <MDBBtn
                  className="bg-gray"
                  color="dark"
                  onClick={this.props.toggleShowModal}
                >
                  Close
                </MDBBtn>
              </div>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  change_user_details,
  toggle_private,
  toggle_disable_messages,
  set_token,
  notify,
})(EditProfileModal);
