import React from "react";

class PollExpiration extends React.Component {
  constructor() {
    super();
    this.state = {
      reset: false,
      resetInterval: false,
    };
  }

  componentDidMount() {
    this.setState((curr) => ({
      ...curr,
      resetInterval: setInterval(this.reset, 1000),
    }));
  }

  componentWillUnmount() {
    clearInterval(this.state.resetInterval);
  }

  reset = () =>
    this.setState((curr) => ({
      ...curr,
      reset: !this.state.reset,
    }));

  monthDifference = (dateA, dateB) => {
    let months = (dateB.getFullYear() - dateA.getFullYear()) * 12;
    months -= dateA.getMonth();
    months += dateB.getMonth();
    return months <= 0 ? 0 : months;
  };

  render() {
    const pollData = this.props.pollData;
    if (!pollData.expirationDate) return <></>;
    const now = new Date();
    const expiration = new Date(pollData.expirationDate);
    const difference = Math.abs(now.getTime() - expiration.getTime());
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    let months = Math.floor(Math.abs(this.monthDifference(now, expiration)));
    let years = Math.floor(
      Math.abs(now.getFullYear() - expiration.getFullYear())
    );
    if (months - years * 12 < 0) {
      years--;
    }
    return (
      <p
        key={this.state.reset}
        className={`text-${
          !years &&
          !months &&
          !weeks &&
          !days &&
          !hours &&
          !minutes &&
          now.getTime() <= expiration.getTime()
            ? "danger"
            : "blusteel"
        } m-0`}
      >
        {now.getTime() >= expiration.getTime() ? "Expired " : "Expires in "}{" "}
        {years
          ? `${years} year${years === 1 ? "" : "s"}${
              months - years * 12
                ? ` and ${months - years * 12} month${
                    months - years * 12 === 1 ? "" : "s"
                  }`
                : ""
            }`
          : months
          ? ` ${months} month${months === 1 ? "" : "s"}${
              days ? ` and ${days} day${days === 1 ? "" : "s"}` : ""
            }`
          : weeks
          ? `${weeks} week${weeks === 1 ? "" : "s"}${
              days - weeks * 7
                ? ` and ${days - weeks * 7} day${
                    days - weeks * 7 === 1 ? "" : "s"
                  }`
                : ""
            }`
          : days
          ? `${days} day${days === 1 ? "" : "s"}`
          : hours
          ? `${hours} hour${hours === 1 ? "" : "s"}`
          : minutes
          ? `${minutes} minute${minutes === 1 ? "" : "s"}${
              seconds - minutes * 60
                ? ` and ${seconds - minutes * 60} second${
                    seconds - minutes * 60 === 1 ? "" : "s"
                  }`
                : ""
            }`
          : seconds || expiration.getTime() > now.getTime()
          ? `${seconds} second${seconds === 1 ? "" : "s"}`
          : ""}
        {now.getTime() >= expiration.getTime() &&
        (seconds || minutes || hours || days || weeks || months || years)
          ? " ago"
          : ""}
      </p>
    );
  }
}

export default PollExpiration;
