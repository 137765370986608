import React, { useEffect } from "react";
import { MDBContainer, MDBBtn } from "mdb-react-ui-kit";
import h from "../utilities/helpers";
import axios from "axios";
import { Buffer } from "safe-buffer";
import VideoSnapshot from "video-snapshot";
import pngVideo from "../utilities/pngVideo";

const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z"/></svg>`;
let png;

const Test = () => {
  useEffect(() => {
    let contentType;
    fetch(
      "https://f.filepimps.com/8a737bbd-c8b7-4da5-abfb-82a512743357/videos/a85fabcc2fad488f90720dc3b756fc29.mp4",
      {
        method: "get",
        responseType: "arraybuffer",
        headers: { "Cache-Control": "no-cache" },
      }
    )
      .then((response) => {
        contentType = response.headers.get("content-type");
        return response.arrayBuffer();
      })
      .then((buffer) => {
        const data = Buffer.from(buffer, "binary").toString("base64");
        if (data) {
          const iOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          const isChrome =
            navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
            navigator.vendor.toLowerCase().indexOf("google") > -1;
          let iOSVersion = [];
          if (iOS) {
            iOSVersion = navigator.userAgent
              .match(/OS [\d_]+/i)[0]
              .substr(3)
              .split("_")
              .map((n) => parseInt(n));
          }
          const attachmentData = data;
          const attachmentName =
            "file." + String(contentType.split("/")[1] || "bin").toLowerCase();

          const binary = atob(attachmentData.replace(/\s/g, ""));
          const len = binary.length;
          const buffer = new ArrayBuffer(len);
          let view = new Uint8Array(buffer);
          for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
          }
          const linkElement = document.createElement("a");
          try {
            let hrefUrl = "";
            let blob = "";
            if (iOS && !isChrome && iOSVersion[0] <= 12) {
              blob = "data:application/pdf;base64," + data;
              hrefUrl = blob;
            } else {
              if (iOS && !isChrome) {
                contentType = "application/octet-stream";
              }
              blob = new Blob([view], { type: contentType });
              hrefUrl = window.URL.createObjectURL(blob);
            }
            linkElement.setAttribute("href", hrefUrl);
            linkElement.setAttribute("target", "_blank");
            if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
              linkElement.setAttribute("download", attachmentName);
            }
            const clickEvent = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
            });
            linkElement.dispatchEvent(clickEvent);
          } catch (err) {
            console.log("error", err);
          }
        }
      })
      .catch((err) => console.log("error", err));
  }, []);

  const download = (data, contentType) => {
    if (data) {
      const iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isChrome =
        navigator.userAgent.toLowerCase().indexOf("CriOS") > -1 ||
        navigator.vendor.toLowerCase().indexOf("google") > -1;
      let iOSVersion = [];
      if (iOS) {
        iOSVersion = navigator.userAgent
          .match(/OS [\d_]+/i)[0]
          .substr(3)
          .split("_")
          .map((n) => parseInt(n));
      }
      const attachmentData = data;
      const attachmentName =
        "file." + String(contentType.split("/")[1] || "bin").toLowerCase();

      const binary = atob(attachmentData.replace(/\s/g, ""));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      let view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const linkElement = document.createElement("a");
      try {
        let hrefUrl = "";
        let blob = "";
        if (iOS && !isChrome && iOSVersion[0] <= 12) {
          blob = "data:application/pdf;base64," + data;
          hrefUrl = blob;
        } else {
          if (iOS && !isChrome) {
            contentType = "application/octet-stream";
          }
          blob = new Blob([view], { type: contentType });
          hrefUrl = window.URL.createObjectURL(blob);
        }
        linkElement.setAttribute("href", hrefUrl);
        linkElement.setAttribute("target", "_blank");
        if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
          linkElement.setAttribute("download", attachmentName);
        }
        const clickEvent = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        linkElement.dispatchEvent(clickEvent);
      } catch (err) {
        console.log("error", err);
      }
    }
  };

  return (
    <MDBContainer id="containertest" className="mt-5">
      {/* <h5>Input:</h5>
      <input type="file" onChange={fileChange} />
      <br />
      <h5>Canvas:</h5>
      <canvas id="canvas" />
      <br />
      <h5>Image:</h5>
      <img id="thumbnail" />
      <br />
      <h5>Video:</h5>
      <video id="video" className="opacity-0 position-fixed" /> */}
    </MDBContainer>
  );
};

export default Test;
