import env from "../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBListGroup,
  MDBListGroupItem,
  MDBTooltip,
  MDBSelect,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { route, set_token } from "../../redux/actions";
import h from "../../utilities/helpers";
import Spinner from "../../components/Spinner";
import AsyncSelect from "react-select/async";
import { Collapse } from "@mui/material";
import Encrypter from "../../utilities/Encrypter";
import { v4 as uuid } from "uuid";
import axios from "axios";

const darkTheme = {
  primary25: "#1266f1",
  primary50: "#1E88E5",
  primary75: "#42A5F5",
  primary: "#BBDEFB",
  dangerLight: "#f93154",
  danger: "#E57373",
  neutral90: "#fff",
  neutral80: "#f9f9f9",
  neutral70: "#f5f5f5",
  neutral60: "#eeeeee",
  neutral50: "#e0e0e0",
  neutral40: "#bdbdbd",
  neutral30: "#9e9e9e",
  neutral20: "#757575",
  neutral10: "#616161",
  neutral5: "#4f4f4f",
  neutral0: "#262626",
};
class ManageConversationModal extends React.Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
      removing: [],
      searchText: "",
      searchCallback: false,
      reset: false,
      recipients: [],
      working: false,
      addRecipientsCollapseOpen: false,
      includeOldMessages: false,
      translated: [],
      broadcast: [],
      newKeys: [],
      addMessage: "",
    };
  }

  componentDidMount() {
    if (this.props.socket) this.initializeSocket();
  }

  componentDidUpdate(prevProps) {
    let removedFound = false;
    if (!prevProps.socket && this.props.socket) this.initializeSocket();
    if (this.props.conversation?._id && prevProps.conversation?._id) {
      this.state.removing.forEach((userID) => {
        if (
          prevProps.conversation.parties.find((u) => u === userID) &&
          !this.props.conversation.parties.find((u) => u === userID)
        ) {
          removedFound = true;
          this.setState((curr) => ({
            ...curr,
            removing: this.state.removing.filter((r) => r !== userID),
          }));
        }
      });
      if (
        !removedFound &&
        prevProps.conversation?.parties?.length !==
          this.props.conversation?.parties?.length
      )
        this.setState((curr) => ({
          ...curr,
          options: [],
          searchText: "",
          searchCallback: false,
          reset: !this.state.reset,
          recipients: [],
          working: false,
          addRecipientsCollapseOpen: false,
          includeOldMessages: false,
          translated: [],
          newKeys: [],
          addMessage: "",
        }));
      if (
        this.props.modalShown &&
        this.props.conversation?.parties?.length === 1
      )
        this.props.toggleShowModal();
    }
  }

  componentWillUnmount() {
    if (this.props.socket) this.props.socket.off("user-list-manage");
  }

  checkChange = (e) =>
    this.setState((curr) => ({
      ...curr,
      includeOldMessages: e.target.checked,
    }));

  /**
   * Turn off and then turn back on all socket events
   */
  initializeSocket = () => {
    if (!this.props.socket) return;
    this.props.socket.off("user-list-manage");

    this.props.socket.on("user-list-manage", (list) => {
      try {
        if (this.state.searchCallback)
          this.state.searchCallback(
            list.map((user) => {
              return {
                label: (
                  <div className="d-flex align-items-center">
                    <div className="search-avatars ms-2">
                      <div
                        className="fit-images search-avatars fit-round"
                        style={{
                          backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${user.avatar.thumbnail}")`,
                          borderRadius: "50%",
                        }}
                      ></div>
                    </div>
                    <div className="fw-bold ms-2">@{user.username}</div>
                    <div className="ms-2">{user.displayName}</div>
                  </div>
                ),
                value: user._id,
                user,
              };
            })
          );
      } catch (err) {
        console.log("user-list-manage erro", err, list);
      }
    });
  };

  route = (e, destination) => {
    e.preventDefault();
    this.props.route(destination);
  };

  openDropdown = (userID) =>
    this.setState((curr) => ({
      ...curr,
      dropdownOpen: userID,
    }));

  removeSelect = (e) => {
    const userID = this.state.dropdownOpen;
    this.setState(
      (curr) => ({
        ...curr,
        dropdownOpen: false,
        messageHovering: false,
      }),
      () => {
        if (e?.value) this.remove(userID);
      }
    );
  };

  remove = (userID) =>
    this.setState(
      (curr) => ({
        ...curr,
        removing: [...this.state.removing, userID],
      }),
      () =>
        setTimeout(() => {
          const user = this.props.conversation.users.find(
            (u) => u._id === userID
          );
          const messageID = uuid();
          const removeMessage = h.sanitizeHTML(
            `<p>Removed <a class="text-decoration-none" href="/${user.username}">@${user.username}</a> from this conversation</p>`
          );
          const newKeys = {};
          this.props.socket.emit(
            "conversation-kick",
            this.props.conversation._id,
            userID,
            this.props.conversation.users
              .filter(
                (user) =>
                  [
                    userID,
                    this.props.userInfo._id,
                    ...this.props.conversation.kickedUsers,
                  ].indexOf(user._id) === -1
              )
              .map((user) => {
                const { message, keys } = h.processBroadcastMessage(
                  user,
                  this.props.conversation,
                  this.props.userInfo,
                  removeMessage
                );
                Object.keys(keys).forEach((key) => (newKeys[key] = keys[key]));
                return {
                  ...message,
                  id: messageID,
                };
              })
          );
          this.props.addMessage({
            timestamp: new Date(),
            from: this.props.userInfo._id,
            to: this.state.broadcast[0]?.to,
            message: removeMessage,
            id: messageID,
            local: true,
            decrypted: true,
            conversationID: this.props.conversation._id,
            newKeys,
          });
        }, 200)
    );

  loadOptions = (text, callback) => {
    if (!text) return callback([]);

    this.setState(
      (curr) => ({
        ...curr,
        searchCallback: callback,
      }),
      () => {
        this.props.socket.emit(
          "user-search-manage",
          text,
          this.props.conversation._id
        );
      }
    );
  };

  setRecipients = (users) => {
    this.setState((curr) => ({
      ...curr,
      recipients: users.map((user) => ({
        _id: user.value,
        publicKey: user.user.publicKey,
        username: user.user.username,
      })),
    }));
  };

  submit = () => {
    if (!this.state.recipients.length)
      return alert("Please select at least one recipient");
    const message = h.sanitizeHTML(
      `<p>Added ${this.state.recipients
        .map(
          (r) =>
            `<a class="text-decoration-none" href="/${r.username}">@${r.username}</a>`
        )
        .join(", ")} to this conversation</p>`
    );
    const messageID = uuid();
    const newKeys = [];
    this.setState(
      (curr) => ({
        ...curr,
        working: true,
        addMessage: message,
      }),
      () =>
        setTimeout(
          () => {
            this.setState(
              (curr) => ({
                ...curr,
                broadcast: [
                  ...this.props.conversation.users.filter(
                    (u) =>
                      u._id !== this.props.userInfo._id &&
                      !this.props.conversation.kickedUsers.find(
                        (k) => k === u._id
                      )
                  ),
                  ...this.state.recipients,
                ].map((user) => {
                  const broadcastMessage = h.processBroadcastMessage(
                    user,
                    {
                      ...this.props.conversation,
                      keys: [
                        ...this.props.conversation.keys,
                        ...this.state.newKeys,
                      ],
                    },
                    this.props.userInfo,
                    message
                  );
                  const keys = broadcastMessage.keys;
                  if (Object.keys(keys).length) {
                    newKeys.push({
                      sharedKey: keys[this.props.userInfo._id],
                      [this.props.userInfo._id]: new Encrypter(
                        keys[this.props.userInfo._id]
                      ),
                      [user._id]: keys[this.props.userInfo._id],
                    });
                  }
                  return {
                    ...broadcastMessage.message,
                    id: messageID,
                  };
                }),
                newKeys,
              }),
              () => {
                try {
                  if (!this.state.includeOldMessages) {
                    this.props.addMessage({
                      timestamp: new Date(),
                      from: this.props.userInfo._id,
                      to: this.state.broadcast[0]?.to,
                      message: message,
                      id: messageID,
                      local: true,
                      decrypted: true,
                      conversationID: this.props.conversation._id,
                      keyArray: newKeys,
                    });
                    this.props.socket.emit(
                      "conversation-add",
                      this.props.conversation._id,
                      this.state.recipients.map((r) => r._id),
                      this.state.broadcast
                    );
                  } else this.translate(this.props.conversation.messages);
                } catch (err) {
                  console.log(err);
                  this.setState(
                    (curr) => ({
                      ...curr,
                      working: false,
                    }),
                    () => alert("An error occurred. Please try again later.")
                  );
                }
              }
            );
          },

          200
        )
    );
  };

  translate = (messages) =>
    this.setState(
      (curr) => ({
        ...curr,
        translated: [...this.state.translated, ...messages.map((m) => m.id)],
      }),
      () => {
        const translatedMessages = [];
        this.state.recipients.forEach((user) =>
          messages.forEach((message) =>
            translatedMessages.push({
              ...message,
              ...h.processBroadcastMessage(
                user,
                {
                  ...this.state.conversation,
                  keys: [
                    ...this.props.conversation.keys,
                    ...this.state.newKeys,
                  ],
                },
                this.props.userInfo,
                message.message
              ).message,
            })
          )
        );
        axios
          .post(
            `${process.env.REACT_APP_LAMBDA_MESSAGES}/translate`,
            {
              conversationID: this.props.conversation._id,
              messages: translatedMessages,
              translated: this.state.translated,
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            if (res.data.toTranslate) this.translate(res.data.toTranslate);
            else
              this.props.socket.emit(
                "conversation-add",
                this.props.conversation._id,
                this.state.recipients.map((r) => r._id),
                this.state.broadcast
              );
            this.props.addMessage({
              timestamp: new Date(),
              from: this.props.userInfo._id,
              to: this.state.broadcast[0]?.to,
              message: this.state.addMessage,
              id: this.state.broadcast[0]?.id,
              local: true,
              decrypted: true,
              conversationID: this.props.conversation._id,
              keyArray: this.state.newKeys,
            });
          })
          .catch((err) => {
            console.log("Translate error", err);
            setTimeout(() => this.translate(messages), 2000);
          });
      }
    );

  render() {
    return (
      <MDBModal
        open={this.props.modalShown}
        staticBackdrop
        onClosePrevented={() => {
          if (!this.state.working) this.props.toggleShowModal();
        }}
        tabIndex="-1"
      >
        <MDBModalDialog
          size={
            this.props.screenDimensions.width >
            this.props.screenDimensions.modalBreak
              ? "xl"
              : "fullscreen"
          }
        >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Manage Conversation</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={this.props.toggleShowModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBBtn
                color="primary"
                className="mt-2 mb-4"
                onClick={() =>
                  this.setState((curr) => ({
                    ...curr,
                    addRecipientsCollapseOpen:
                      !this.state.addRecipientsCollapseOpen,
                  }))
                }
              >
                <i className="fas fa-plus me-2" />
                Add Recipients
              </MDBBtn>
              <Collapse
                className="mt-2"
                in={this.state.addRecipientsCollapseOpen}
              >
                <div className="d-flex align-items-center mb-2">
                  <AsyncSelect
                    cacheOptions
                    onChange={this.setRecipients}
                    loadOptions={this.loadOptions}
                    defaultOptions
                    placeholder={
                      "Recipients" +
                      (this.props.screenDimensions.width >
                      this.props.screenDimensions.modalBreak
                        ? " (Start typing...)"
                        : "")
                    }
                    isMulti
                    key={this.state.reset + "select"}
                    theme={(theme) => {
                      if (
                        this.props.userInfo.userSettings.theme === "default"
                      ) {
                        return theme;
                      } else
                        return {
                          ...theme,
                          colors: {
                            ...theme.colors,
                            ...darkTheme,
                          },
                        };
                    }}
                    className="flex-grow-1"
                  />
                  <MDBBtn
                    className="ms-2"
                    color="success"
                    disabled={this.state.working}
                    onClick={this.submit}
                  >
                    {this.state.working ? (
                      <>
                        <MDBSpinner size="sm" className="me-2" />
                        Adding
                      </>
                    ) : (
                      <>
                        <i className="fas fa-check-circle me-2" />
                        Confirm
                      </>
                    )}
                  </MDBBtn>
                </div>
                <div className="mb-2 d-flex justify-content-center">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.includeOldMessages}
                          onChange={this.checkChange}
                          defaultChecked
                        />
                      }
                      label="Include old messages (will take time to decrypt)"
                    />
                  </FormGroup>
                </div>
              </Collapse>
              <MDBListGroup>
                {!this.props.conversation?.users ? (
                  <></>
                ) : (
                  this.props.conversation.parties
                    .filter((userID) => userID !== this.props.userInfo._id)
                    .map((userID) => {
                      const user = this.props.conversation.users.find(
                        (u) => u._id === userID
                      );
                      return (
                        <MDBListGroupItem
                          key={user._id}
                          className="d-flex align-items-center justify-content-between w-100 p-2"
                        >
                          <div className="d-flex align-items-center">
                            <Link
                              to={`/${user.username}`}
                              onClick={(e) =>
                                this.route(e, `/${user.username}`)
                              }
                            >
                              <div className="chat-avatars rounded-circle">
                                <div
                                  className="fit-images fit-round"
                                  style={{
                                    backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${user.avatar.thumbnail}")`,
                                    borderRadius: "50%",
                                  }}
                                ></div>
                              </div>
                            </Link>
                            <Link
                              to={`/${user.username}`}
                              onClick={(e) =>
                                this.route(e, `/${user.username}`)
                              }
                            >
                              <h5 className="mb-0 ms-2">@{user.username}</h5>
                            </Link>
                          </div>
                          <div className="d-flex align-items-center">
                            {this.state.dropdownOpen === user._id && (
                              <MDBSelect
                                onChange={this.removeSelect}
                                inputClassName="remove-select-options"
                                className="invis"
                                preventFirstSelection
                                open={this.state.dropdownOpen === user._id}
                                onClose={() => this.openDropdown(false)}
                                data={[
                                  {
                                    text: (
                                      <div className="text-danger my-2">
                                        <i className="fas fa-trash-alt me-2" />
                                        Remove
                                      </div>
                                    ),
                                    value: true,
                                  },
                                  {
                                    text: <div className="my-2">Cancel</div>,
                                    value: false,
                                  },
                                ]}
                              />
                            )}
                            <MDBTooltip
                              wrapperProps={{
                                color: "link",
                                rippleColor: "danger",
                                onClick: () => {
                                  h.hideToolTips();
                                  this.openDropdown(user._id);
                                },
                              }}
                              wrapperClass="text-danger"
                              title="Remove"
                            >
                              {this.state.removing.indexOf(user._id) > -1 ? (
                                <Spinner size="sm" color="danger" />
                              ) : (
                                <i className="far fa-trash-alt" />
                              )}
                            </MDBTooltip>
                          </div>
                        </MDBListGroupItem>
                      );
                    })
                )}
              </MDBListGroup>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="dark"
                className="bg-gray"
                onClick={this.props.toggleShowModal}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
  socket: state.socket,
  userInfo: state.userInfo,
  token: state.token,
});

export default connect(mapStateToProps, { route, set_token })(
  ManageConversationModal
);
