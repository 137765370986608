import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBInput,
} from "mdb-react-ui-kit";
import { route } from "../../redux/actions";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner";
import { string } from "yup";
import Encrypter from "../../utilities/Encrypter";

class EnterPasswordModal extends React.Component {
  constructor() {
    super();
    this.state = {
      working: false,
      input: {
        value: "",
        error: "Please enter your password",
      },
    };
  }

  componentDidMount() {
    document
      .getElementById("enter-password-input")
      .setCustomValidity(this.state.input.error);
  }

  back = () =>
    this.props.route(
      this.props.historyStack[this.props.historyStack.length - 1]
    );

  submit = () => {
    document
      .getElementById("enter-password-modal")
      .classList.add("was-validated");
    if (!this.state.input.error && !this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () =>
          setTimeout(() => {
            try {
              {
                const chatKey = new Encrypter(this.state.input.value).decrypt(
                  this.props.userInfo.chatKey
                );
                localStorage.setItem("userID", this.props.userInfo._id);
                localStorage.setItem("chatKey", chatKey);
                this.props.toggleShowModal();
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                    input: {
                      error: "Please enter your password",
                      value: "",
                    },
                  }),
                  () => {
                    const form = document.getElementById(
                      "enter-password-modal"
                    );
                    form.classList.remove("was-validated");
                    form.reset();
                  }
                );
              }
            } catch (err) {
              console.log("err", err);
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () =>
                  alert(
                    "An error occurred. Please check your password and try again."
                  )
              );
            }
          }, 250)
      );
  };

  changeHandler = (e) => {
    let error = "";
    try {
      string()
        .required("Please enter your password")
        .validateSync(e.target.value);
    } catch (err) {
      error = err?.message || "";
    }
    this.setState(
      (curr) => ({
        ...curr,
        input: {
          error,
          value: e.target.value,
        },
      }),
      () =>
        document
          .getElementById("enter-password-input")
          .setCustomValidity(this.state.input.error)
    );
  };

  render() {
    return (
      <MDBModal open={this.props.modalShown} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="md">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Please enter your password</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBValidation
                name="enter-password-modal"
                onSubmit={this.submit}
                id="enter-password-modal"
              >
                <MDBValidationItem
                  className=" mt-4 pb-4 h-100 h-100-child"
                  feedback={this.state.input.error}
                  invalid={true}
                >
                  <MDBInput
                    name="enter-password-input"
                    onChange={this.changeHandler}
                    value={this.state.input.value}
                    id="enter-password-input"
                    label="Password"
                    size="lg"
                    className={`${!this.state.input.error ? "mb-0" : ""}`}
                    type="password"
                  />
                </MDBValidationItem>
              </MDBValidation>
            </MDBModalBody>
            <MDBModalFooter className="justify-content-between">
              <MDBBtn color="dark" onClick={this.back}>
                <i className="fas fa-chevron-left me-2" />
                Go Back
              </MDBBtn>
              <MDBBtn
                onClick={this.submit}
                color={!this.props.userInfo.chatKey ? "primary" : "success"}
                disabled={this.state.working || !this.props.userInfo.chatKey}
              >
                {this.state.working ? (
                  <span id="spinner-enter-password-modal">
                    <Spinner className="me-2" size="sm" />
                    Working
                  </span>
                ) : (
                  <>
                    {this.props.userInfo.chatKey ? (
                      <>
                        <i className="fas fa-paper-plane me-2" />
                        Submit
                      </>
                    ) : (
                      <span className="opacity-flash">Loading</span>
                    )}
                  </>
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    );
  }
}

const mapStateToProps = (state) => ({
  screenDimensions: state.screenDimensions,
  historyStack: state.historyStack,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, { route })(EnterPasswordModal);
