import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBValidation,
  MDBValidationItem,
  MDBTextArea,
} from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SampleEmission from "../emission/SampleEmission";
import h from "../../utilities/helpers";
import axios from "axios";
import { connect } from "react-redux";
import { set_token } from "../../redux/actions";

// Reasons start
const reasons = [
  {
    id: "spam",
    label: "Spam",
  },
  {
    id: "porn",
    label: "Porn",
  },
  {
    id: "fed",
    label: "Illegal/Fedposting",
  },
  {
    id: "other",
    label: "Other",
  },
];
// Reasons end

class RemoveModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the emission is in the process of being removed
       * reason: String - The reason for removal
       * input: Object - The text input data (values, errors, etc)
       */
      working: false,
      reason: reasons[0].id,
      input: {
        id: "remove-input",
        error: "",
        invalid: false,
        value: "",
      },
    };
  }

  componentDidUpdate() {
    h.floatLabels();
  }

  /**
   *
   * @param {KeyboardEvent} e - Keyboard event triggered by text change in any of the text inputs
   *
   * Sets the updated values into state
   * Validates the inputs
   * Updates the inputs with errors
   * Adds/removes custom validity as appropriate
   */
  changeHandler = (e) => {
    this.setState(
      (curr) => ({
        ...curr,
        input: {
          ...curr.input,
          value: e.target.value,
        },
      }),
      () => {
        if (this.state.input.value.length > 5000)
          this.setState(
            (curr) => ({
              ...curr,
              input: {
                ...curr.input,
                invalid: true,
                error: "Too long (Max: 5000 chars)",
              },
            }),
            () =>
              document
                .getElementById("remove-input")
                .setCustomValidity(this.state.input.error)
          );
        else
          this.setState(
            (curr) => ({
              ...curr,
              input: {
                ...curr.input,
                invalid: false,
                error: "",
              },
            }),
            () => document.getElementById("remove-input").setCustomValidity("")
          );
      }
    );
  };

  /**
   * Executes a captcha challenge and generates a key a key
   * Will hang until connected to captcha servers
   */
  getRecaptcha = () => "";
  // new Promise(async (resolve, reject) => {
  //   if (String(process.env.REACT_APP_DEV) === "true")
  //     return resolve(process.env.REACT_APP_DEV_CAPTCHA_KEY);
  //   if (this.props.captchaReady)
  //     window.grecaptcha.enterprise
  //       .execute(process.env.REACT_APP_CAPTCHA_KEY, { action: "login" })
  //       .then(resolve)
  //       .catch((err) => {
  //         console.log(err);
  //         alert("Human verification failed. Refresh the page and try again.");
  //         reject();
  //       });
  //   else
  //     setTimeout(async () => {
  //       const captchaKey = await this.getRecaptcha();
  //       resolve(captchaKey);
  //     }, 500);
  // });

  /**
   * Submit only if there isn't already a submission being sent
   * Set working
   * Validate inputs
   * Make request to server
   * Update emission
   * Hide modal
   */
  submit = () => {
    document.getElementById("remove-form").classList.add("was-validated");
    if (this.state.input.value.length > 5000)
      document
        .getElementById("remove-input")
        .setCustomValidity(this.state.input.error);
    if (!this.state.working && this.state.input.value.length <= 5000)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        async () => {
          try {
            // const captchaKey = await this.getRecaptcha(this.props.googleReCaptchaProps);
            const submission = {
              details: this.state.input.value,
              reason: this.state.reason,
              emissionID: this.props.emission.emissionID,
            };
            axios
              .post(
                process.env.REACT_APP_LAMBDA_SUPPORT_EMISSIONS + "/remove",
                submission,
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                    reason: reasons[0].id,
                    input: {
                      id: "remove-input",
                      error: "",
                      invalid: false,
                      value: "",
                    },
                  }),
                  () => {
                    this.props.updateEmission(res.data.emission, true);
                  }
                );
              })
              .catch((err) =>
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                  }),
                  () => {
                    console.log(err);
                    alert("An error occurred. Please try again later.");
                  }
                )
              );
          } catch (err) {
            this.setState(
              (curr) => ({
                ...curr,
                working: false,
              }),
              () => {
                console.log(err);
                alert("An error occurred. Please try again later");
              }
            );
          }
        }
      );
  };

  reasonChange = (e) =>
    this.setState((curr) => ({
      ...curr,
      reason: e.target.value,
    }));

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              {this.props.emission ? (
                <MDBModalContent key={this.props.emission.emissionID}>
                  <MDBModalHeader>
                    <MDBModalTitle>
                      Remove{" "}
                      <span className="text-gb">
                        #{this.props.emission.emissionID}
                      </span>
                    </MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={this.props.toggleShowModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    <div className="d-flex mb-4 report-details">
                      <div className="px-4">
                        <FormControl>
                          <h5 className="fs-5 display-6 m-0">Reason</h5>
                          <hr className="my-1" />
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={this.state.reason}
                            name="radio-buttons-group"
                            onChange={this.reasonChange}
                          >
                            {reasons.map((reason) => (
                              <FormControlLabel
                                key={reason.id}
                                value={reason.id}
                                control={<Radio />}
                                label={reason.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="flex-grow-1">
                        <MDBValidation
                          className="h-100"
                          method="dialog"
                          id="remove-form"
                          name="remove-form"
                        >
                          <MDBValidationItem
                            className="pb-4 h-100 h-100-child"
                            feedback={this.state.input.error}
                            invalid={true}
                          >
                            <MDBTextArea
                              name="remove-input"
                              onChange={this.changeHandler}
                              id="remove-input"
                              label="Details"
                              size="lg"
                              className={`h-100 ${
                                !this.state.input.invalid ? "mb-0" : 0
                              }`}
                            />
                          </MDBValidationItem>
                        </MDBValidation>
                      </div>
                    </div>
                    <SampleEmission
                      emission={this.props.emission}
                      toggleModal={this.props.toggleShowModal}
                    />
                  </MDBModalBody>
                  <MDBModalFooter>
                    {this.state.working ? (
                      <MDBBtn disabled color="danger">
                        <Spinner className="me-2" size="sm" />
                        Removing
                      </MDBBtn>
                    ) : (
                      <MDBBtn onClick={this.submit} color="danger">
                        <i className="fas fa-trash-alt me-2"></i>Remove
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      color="dark"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              ) : (
                <></>
              )}
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  captchaReady: state.captchaReady,
  token: state.token,
  screenDimensions: state.screenDimensions,
});

export default connect(mapStateToProps, { set_token })(RemoveModal);
