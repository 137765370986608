import env from "../env";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";
import h from "../utilities/helpers";
import t from "../utilities/transitions";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBListGroup,
  MDBListGroupItem,
  MDBAlert,
} from "mdb-react-ui-kit";
import {
  route,
  update_socket_state,
  set_token,
  set_cache,
} from "../redux/actions";
import SideNav from "./feed/SideNav";
import { StaticRouter, Switch, Route, Link } from "react-router-dom";
import FeedComponent from "./feed/FeedComponent";
import LogoLoader from "../components/LogoLoader";
import axios from "axios";
import { deletedUser } from "../utilities/constants";
import Count from "../components/Count";

class Feed extends React.Component {
  constructor(props) {
    super();
    let cachedData;
    this.initialScrollTop = 0;
    if (props.history?.location?.state?.currRoute === "/") {
      cachedData = props.cache.find((c) => c.page === "feed");
      this.initialScrollTop = cachedData?.scrollTop;
    }
    this.state = cachedData
      ? cachedData.state
      : {
          /**
           * tab: String - The feed tab that the user is currently on - "following" | "popular" | "recent" | "live"
           * loaded: Boolean - Whether the initial data has been loaded
           * emissions: Array - List of all of the emissions that will go into any of the tabs
           * users: Array - List of all of the users that will go into the Live tab
           * loadingMore: Array - List of tabs that are in the process of loading more (older) emissions
           * checkingNewer: Array - List of tabs that are in the process of loading more (newer) emissions
           * followEnd: Boolean - Whether the user has reached the end of the list of emissions made by accounts that that user is following
           * totalEnd: Boolean - Whether the user has reached the end of the list of recent emissions
           * popularEnd: Boolean - Whether the user has reached the end of the list of popular emissions
           * notificationIcon: JSX - The notification icon
           * notificationText: String - The notification text
           * polls: Array - List of polls that the user is voting in
           * pollsSubmitting: Array - List of polls that are in the process of having their votes submitted
           * loginModalShown: Boolean - Whether the login modal is shown
           * fileModalShown: Boolean - Whether the file modal is shown
           * signalBoostModalShown: Boolean - Whether the signal boost modal is shown
           * replyModalShown: Boolean - Whether the reply modal is shown
           * removeModalShown: Boolean - Whether the remove modal is shown
           * reportModalShown: Boolean - Whether the report modal is shown
           * restoreModalShown: Boolean - Whether the restore modal is shown
           * fileSelected: false | Object - The file currently selected
           * fileList: Array - List of files on the emission that the fileSelected belongs to
           * emissionSelected: false | Object - The emission currently selected
           * emissionSignalBoosting: false | Object - The emission currently signal boosting
           * emissionReplying: false | Object - The emission currently replying
           * emissionCopied: false | Number - EmissionID of Emission copied
           * emissionRemoving: false | Object - The emission currently removing
           * emissionReporting: false | Object - The emission currently reporting
           * emissionRestoring: false | Object - The emission currently restoring
           * socketConnected: Boolean - Whether the socket is currently connected
           * tempAction: false | Object - Temporary action data that must be executed upon logging in
           * trending: false | Object - Trending emissions data
           */
          tab: "recent",
          loaded: false,
          emissions: [],
          users: [],
          loadingMore: [],
          checkingNewer: [],
          followEnd: false,
          totalEnd: false,
          popularEnd: false,
          filesEnd: false,
          notificationIcon: <></>,
          notificationText: "",
          polls: [],
          pollsSubmitting: [],
          loginModalShown: false,
          fileModalShown: false,
          signalBoostModalShown: false,
          replyModalShown: false,
          removeModalShown: false,
          reportModalShown: false,
          restoreModalShown: false,
          fileSelected: false,
          fileList: [],
          emissionSelected: false,
          emissionSignalBoosting: false,
          emissionReplying: false,
          emissionCopied: false,
          emissionRemoving: false,
          emissionReporting: false,
          emissionRestoring: false,
          socketConnected: false,
          reconnectInterval: false,
          tempAction: false,
          trending: false,
          checkingStreams: false,
          socketEvents: [],
          socketStore: [],
          loadRound: 1,
        };
  }

  /**
   * Load emission data
   * Connect socket
   *
   */
  componentDidMount() {
    if (this.props.socket) {
      this.props.socket.off("trending");
      this.props.socket.on("trending", (trending) => {
        try {
          this.setState({
            ...this.state,
            trending: trending,
          });
        } catch (err) {
          console.log("trending error", err);
        }
      });
    }

    if (this.initialScrollTop) {
      const root = document.getElementById("root");
      root.style.scrollBehavior = "auto";
      root.scrollTop = this.initialScrollTop;
      root.style.scrollBehavior = "smooth";
    }
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.socket && this.props.socket) {
      this.props.socket.off("trending");
      this.props.socket.on("trending", (trending) => {
        try {
          this.setState({
            ...this.state,
            trending: trending,
          });
        } catch (err) {
          console.log("trending error", err, trending);
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.set_cache({
      page: "feed",
      state: this.state,
      scrollTop: document.getElementById("root")?.scrollTop,
    });
  }

  /**
   * Loads initial feed data, and applies the data into state
   * If there is a failure, try again after 1 second
   *
   */
  load = () =>
    axios
      .get(process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/feed/init", {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => {
        this.props.set_token(res.data.token);
        let tab = this.state.tab;
        if (
          tab === "following" &&
          !res.data.emissions.find((emission) => emission.following)
        )
          tab = "popular";
        if (
          tab === "popular" &&
          !res.data.emissions.find((emission) => emission.popular)
        )
          tab = "recent";
        this.setState((curr) => ({
          ...curr,
          loaded: true,
          emissions: h.updateArrayItems(
            this.state.emissions,
            res.data.emissions.map((e) => {
              if (e.popular)
                e.score =
                  e.views + 25 * e.likes + 50 * e.replies + 75 * e.signalBoosts;
              return {
                ...e,
                loadRound: this.state.loadRound,
              };
            })
          ),
          followEnd: res.data.followEnd,
          totalEnd: res.data.totalEnd,
          popularEnd: res.data.popularEnd,
          trending: res.data.trending,
          filesEnd: res.data.filesEnd,
          users: h.updateArrayItems(this.state.users, res.data.profiles),
          liveEnd: res.data.liveEnd,
          tab: tab,
        }));
      })
      .catch((err) => {
        console.log("load error", err);
        setTimeout(this.load, 1000);
      });

  selectTab = (e, tab) =>
    this.setState((curr) => ({
      ...curr,
      tab,
    }));

  /**
   *
   * @param {String} type - The tab that the user wishes to load more data
   *
   * Triggered when the user clicks the View More button at the end of the list of emissions or users on any tab
   */
  loadMore = (type, emissionList, callback) => {
    this.setState(
      (curr) => ({
        ...curr,
        loadingMore: [...curr.loadingMore, type],
        loadRound: this.state.loadRound + 1,
      }),
      () => {
        switch (type) {
          case "following":
            axios
              .post(
                process.env.REACT_APP_LAMBDA_API_EMISSIONS +
                  "/feed/more/following",
                {
                  firstEmission: emissionList.sort(
                    (a, b) => a.emissionID - b.emissionID
                  )[0].emissionID,
                },
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                res.data.emissions = res.data.emissions.map((e) => ({
                  ...e,
                  loadRound: this.state.loadRound,
                }));
                this.setState(
                  (curr) => ({
                    ...curr,
                    loadingMore: this.state.loadingMore.filter(
                      (m) => m !== type
                    ),
                    emissions: [
                      ...emissionList,
                      ...res.data.emissions.map((e) => {
                        if (e.popular)
                          e.score =
                            e.views +
                            25 * e.likes +
                            50 * e.replies +
                            75 * e.signalBoosts;
                        return e;
                      }),
                    ],
                    followEnd: res.data.emissions.length < 20,
                  }),
                  () => {
                    callback({
                      emissions: res.data.emissions,
                    });
                  }
                );
              })
              .catch((err) => {
                console.log("load more follow error", err);
                setTimeout(
                  () => this.loadMore(type, emissionList, callback),
                  1000
                );
              });
            break;
          case "files":
            axios
              .post(
                process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/feed/more/files",
                {
                  firstEmission: emissionList.sort(
                    (a, b) => a.emissionID - b.emissionID
                  )[0].emissionID,
                },
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                res.data.emissions = res.data.emissions.map((e) => ({
                  ...e,
                  loadRound: this.state.loadRound,
                }));
                this.setState(
                  (curr) => ({
                    ...curr,
                    loadingMore: this.state.loadingMore.filter(
                      (m) => m !== type
                    ),
                    emissions: [
                      ...emissionList,
                      ...res.data.emissions.map((e) => {
                        if (e.popular)
                          e.score =
                            e.views +
                            25 * e.likes +
                            50 * e.replies +
                            75 * e.signalBoosts;
                        return e;
                      }),
                    ],
                    filesEnd: res.data.emissions.length < 20,
                  }),
                  () => {
                    callback({
                      emissions: res.data.emissions,
                    });
                  }
                );
              })
              .catch((err) => {
                console.log("load more files error", err);
                setTimeout(
                  () => this.loadMore(type, emissionList, callback),
                  1000
                );
              });
            break;
          case "popular":
            axios
              .post(
                process.env.REACT_APP_LAMBDA_API_EMISSIONS +
                  "/feed/more/popular",
                {
                  emissions: emissionList
                    .filter((e) => e.popular)
                    .map((e) => e.emissionID),
                },
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                this.setState(
                  (curr) => ({
                    ...curr,
                    loadingMore: this.state.loadingMore.filter(
                      (m) => m !== type
                    ),
                    emissions: [
                      ...emissionList.filter(
                        (e) =>
                          !res.data.emissions.find(
                            (emission) => emission.emissionID === e.emissionID
                          )
                      ),
                      ...res.data.emissions.map((e) => {
                        if (e.popular)
                          e.score =
                            e.views +
                            25 * e.likes +
                            50 * e.replies +
                            75 * e.signalBoosts;
                        return e;
                      }),
                    ],
                    popularEnd: res.data.emissions.length < 20,
                  }),
                  () => {
                    callback({
                      emissions: res.data.emissions,
                    });
                  }
                );
              })
              .catch((err) => {
                console.log("load more follow error", err);
                setTimeout(
                  () => this.loadMore(type, emissionList, callback),
                  1000
                );
              });
            break;
          case "recent":
            axios
              .post(
                process.env.REACT_APP_LAMBDA_API_EMISSIONS +
                  "/feed/more/recent",
                {
                  emissions: emissionList.map((e) => e.emissionID),
                },
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                const newEmissionList = [
                  ...emissionList,
                  ...res.data.emissions,
                ];
                const totalEnd =
                  newEmissionList.find(
                    (emission) => emission.emissionID === 1
                  ) &&
                  newEmissionList.length ===
                    newEmissionList.sort(
                      (a, b) => b.emissionID - a.emissionID
                    )[0].emissionID;
                this.setState(
                  (curr) => ({
                    ...curr,
                    loadingMore: this.state.loadingMore.filter(
                      (m) => m !== type
                    ),
                    emissions: newEmissionList,
                    totalEnd: totalEnd,
                  }),
                  () => {
                    callback({
                      emissions: res.data.emissions,
                    });
                  }
                );
              })
              .catch((err) => {
                console.log("load more follow error", err);
                setTimeout(
                  () => this.loadMore(type, emissionList, callback),
                  1000
                );
              });
            break;
          case "live":
            axios
              .post(
                process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/feed/more/live",
                {
                  profiles: this.state.users.map((profile) => profile._id),
                },
                {
                  headers: {
                    Authorization: this.props.token,
                  },
                }
              )
              .then((res) => {
                this.props.set_token(res.data.token);
                this.setState((curr) => ({
                  ...curr,
                  loadingMore: this.state.loadingMore.filter((m) => m !== type),
                  users: [...curr.users, ...res.data.profiles],
                  liveEnd: res.data.profiles.length < 20,
                }));
              })
              .catch((err) => {
                console.log("load more profiles error", err);
                setTimeout(
                  () => this.loadMore(type, emissionList, callback),
                  1000
                );
              });
          default:
            console.log("oob load more", type);
        }
      }
    );
  };

  /**
   *
   * @param {Number} emissionID - ref Emissions.emissionID
   *
   * Triggered when the user clicks the Check for Newer button in any of the tavs
   * Loads newer users/emissions from that tab
   */
  checkNewer = (type, emissions, callback) => {
    this.setState(
      (curr) => ({
        ...curr,
        checkingNewer: [...curr.checkingNewer, type],
      }),
      () =>
        axios
          .post(
            process.env.REACT_APP_LAMBDA_API_EMISSIONS + "/feed/newer/" + type,
            {
              lastEmission: emissions.sort(
                (a, b) => b.emissionID - a.emissionID
              )[0].emissionID,
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                checkingNewer: this.state.checkingNewer.filter(
                  (c) => c !== type
                ),
                emissions: [
                  ...res.data.emissions.map((e) => {
                    if (e.popular)
                      e.score =
                        e.views +
                        25 * e.likes +
                        50 * e.replies +
                        75 * e.signalBoosts;
                    return e;
                  }),
                  ...emissions,
                ],
              }),
              () => {
                callback({
                  emissions: res.data.emissions,
                });
              }
            );
          })
          .catch((err) => {
            console.log("load more error", err);
            setTimeout(() => this.checkNewer(type, emissions, callback), 1000);
          })
    );
  };

  /**
   *
   * @param {Click Event} e
   * @param {String} tag - A hashtag
   *
   * Triggered when the user clicks on a hashtag
   * Routes to that tag page
   */
  clickTag = (e, tag) => {
    e.preventDefault();
    this.props.route(`/tag/${tag}`);
  };

  checkStreams = () => {
    if (!this.state.checkingStreams)
      this.setState(
        (curr) => ({
          ...curr,
          checkingStreams: true,
        }),
        () =>
          axios
            .get(process.env.REACT_APP_LAMBDA_USERS + "/live", {
              headers: {
                Authorization: this.props.token,
              },
            })
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState((curr) => ({
                ...curr,
                users: res.data.profiles,
                liveEnd: res.data.liveEnd,
                checkingStreams: false,
              }));
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  checkingStreams: false,
                }),
                () => {
                  console.log("Check streams error", err);
                  alert(
                    "An error occurred. Please check your connection and try again."
                  );
                }
              )
            )
      );
  };

  userEdit = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...user,
              ...userInfo,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  accountDeleted = (userID, socketEvent) => {
    this.setState((curr) => ({
      ...curr,
      users: this.state.users.map((user) => {
        if (user._id === userID)
          user = {
            ...user,
            ...deletedUser,
          };

        return user;
      }),
      socketStore: socketEvent
        ? [...curr.socketStore, socketEvent]
        : this.state.socketStore,
    }));
  };

  ban = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  restoreUser = (userInfo, socketEvent) =>
    this.setState((curr) => ({
      ...curr,
      users: this.state.users.map((user) => {
        if (user._id === userInfo._id)
          user = {
            ...userInfo,
            score: user.score,
          };

        return user;
      }),
      socketStore: socketEvent
        ? [...curr.socketStore, socketEvent]
        : this.state.socketStore,
    }));

  unblockOther = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  unprivate = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  blockOther = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id) user.blocked = true;

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  privateProfile = (userInfo, socketEvent) => {
    if (
      userInfo?.eventID &&
      this.state.socketEvents.find((e) => e === userInfo?.eventID)
    )
      this.setState((curr) => ({
        ...curr,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
    else
      this.setState((curr) => ({
        ...curr,
        users: this.state.users.map((user) => {
          if (user._id === userInfo._id)
            user = {
              ...userInfo,
              score: user.score,
            };

          return user;
        }),
        socketEvents: userInfo?.eventID
          ? [...this.state.socketEvents, userInfo?.eventID]
          : this.state.socketEvents,
        socketStore: socketEvent
          ? [...curr.socketStore, socketEvent]
          : this.state.socketStore,
      }));
  };

  pushSocketEvent = (event) =>
    this.setState((curr) => ({
      ...curr,
      socketStore: [...curr.socketStore, event],
    }));

  updateEmissions = (emissions) => {};
  // this.setState((curr) => ({
  //   ...curr,
  //   emissions: emissions.map((e) => {
  //     if (e.popular)
  //       e.score =
  //         e.views + 25 * e.likes + 50 * e.replies + 75 * e.signalBoosts;
  //     return e;
  //   }),
  // }));

  render() {
    const showPromo = false;
    //  ["localhost"].includes(window.location.hostname);
    return (
      <motion.div
        transition={t.transition}
        exit={t.fade_out_scale_1}
        animate={t.normalize}
        initial={t.fade_out}
        className="pt-4 page-container"
      >
        {showPromo && (
          <p
            className={`${
              this.props.screenDimensions.width < 737 ? "mt-5" : ""
            } px-2`}
          >
            <a
              className="d-block max-w-max-content mx-auto"
              href="https://carbonvalley.win/products"
            >
              <MDBAlert
                open
                color="light"
                className="text-center max-w-max-content"
              >
                Launch your own Filepimps instance for free at
                https://carbonvalley.win/products
              </MDBAlert>
            </a>
          </p>
        )}

        {this.state.loaded ? (
          <MDBContainer fluid id="scroll-main">
            {this.state.emissions.length ? (
              <motion.div
                className="w-100 d-flex feed-container"
                transition={t.transition}
                exit={t.fade_out_scale_1}
                animate={t.normalize}
                initial={t.fade_out}
              >
                <MDBContainer className="feed-emissions">
                  <div className="feed-emission-container">
                    <StaticRouter location={this.state.tab}>
                      <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.tab}>
                          <Route exact path=":tab">
                            <FeedComponent
                              tab={this.state.tab}
                              selectTab={this.selectTab}
                              loaded={this.state.loaded}
                              clickEmissionBody={this.clickEmissionBody}
                              polls={this.state.polls}
                              pollsSubmitting={this.state.pollsSubmitting}
                              copyEmissionLink={this.copyEmissionLink}
                              emissionCopied={this.state.emissionCopied}
                              userInfo={this.props.userInfo}
                              route={this.props.route}
                              updateEmission={this.updateEmission}
                              loadingMore={this.state.loadingMore}
                              totalEnd={this.state.totalEnd}
                              followEnd={this.state.followEnd}
                              filesEnd={this.state.filesEnd}
                              loadMore={this.loadMore}
                              emissions={this.state.emissions}
                              checkingNewer={this.state.checkingNewer}
                              checkNewer={this.checkNewer}
                              popularEnd={this.state.popularEnd}
                              profiles={this.state.users}
                              users={this.state.users}
                              liveEnd={this.state.liveEnd}
                              checkingStreams={this.state.checkingStreams}
                              checkStreams={this.checkStreams}
                              updateEmissions={this.updateEmissions}
                              userEdit={this.userEdit}
                              accountDeleted={this.accountDeleted}
                              ban={this.ban}
                              restoreUser={this.restoreUser}
                              privateProfile={this.privateProfile}
                              unprivate={this.unprivate}
                              blockOther={this.blockOther}
                              unblockOther={this.unblockOther}
                              pushSocketEvent={this.pushSocketEvent}
                              socketStore={this.state.socketStore}
                            />
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </StaticRouter>
                  </div>
                </MDBContainer>
                <div className="feed-nav-stats mb-2">
                  <SideNav tab={this.state.tab} selectTab={this.selectTab} />
                  <hr></hr>
                  {this.state.trending &&
                  this.state.trending.tags &&
                  this.state.trending.tags.length ? (
                    <motion.div
                      className={`feed-nav-trending ${showPromo ? "mt-0" : ""}`}
                      transition={t.transition}
                      exit={t.fade_out_scale_1}
                      animate={t.normalize}
                      initial={t.fade_out}
                    >
                      <MDBCard className="cards-full-width">
                        <MDBCardHeader>
                          <h5 className="m-0">
                            <i className="fas fa-chart-line text-success me-2"></i>
                            Trending
                          </h5>
                        </MDBCardHeader>
                        <MDBCardBody>
                          <MDBListGroup light>
                            {this.state.trending.tags
                              .sort((a, b) => b.count - a.count)
                              .map((tag) => (
                                <MDBListGroupItem key={tag.tag}>
                                  <motion.div
                                    className="d-flex justify-content-between"
                                    transition={t.transition}
                                    initial={t.fade_out}
                                    animate={t.normalize}
                                    exit={t.fade_out_scale_1}
                                  >
                                    <div className="max-w-90">
                                      <Link
                                        onClick={(e) =>
                                          this.clickTag(e, tag.tag)
                                        }
                                        className="text-darkblu m-0 text-break"
                                        to={`/tag/${tag.tag}`}
                                      >
                                        <h6 className="m-0">
                                          #{h.veryShortString(tag.tag)}
                                        </h6>
                                      </Link>
                                    </div>
                                    <p
                                      title={
                                        h.numberWithCommas(tag.count) +
                                        " " +
                                        env[
                                          tag.count === 1
                                            ? "EMISSION_NAME"
                                            : "EMISSION_PLURAL"
                                        ]
                                      }
                                      className="m-0 pe-1"
                                    >
                                      <Count value={tag.count} />
                                    </p>
                                  </motion.div>
                                </MDBListGroupItem>
                              ))}
                          </MDBListGroup>
                        </MDBCardBody>
                      </MDBCard>
                    </motion.div>
                  ) : (
                    <></>
                  )}
                </div>
              </motion.div>
            ) : (
              <MDBContainer>
                <motion.h5
                  transition={t.transition}
                  exit={t.fade_out_scale_1}
                  animate={t.normalize}
                  initial={t.fade_out}
                  className="text-center mt-4 display-6"
                >
                  Welcome to {env.NAME}!
                </motion.h5>
              </MDBContainer>
            )}
          </MDBContainer>
        ) : (
          <MDBContainer>
            <LogoLoader />
          </MDBContainer>
        )}
      </motion.div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, {
  route,
  update_socket_state,
  set_token,
  set_cache,
})(Feed);
