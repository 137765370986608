import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { Collapse } from "@mui/material";
import { connect } from "react-redux";
import Spinner from "../../../../components/Spinner";
import Form from "./userActionModal/Form";
import { StaticRouter, Switch, Route } from "react-router-dom";
import { set_profile, set_token, notify } from "../../../../redux/actions";
import { AnimatePresence, motion } from "framer-motion";
import t from "../../../../utilities/transitions";
import axios from "axios";

class UserActionModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether the action on the form selected is executing
       * formSelected: String - The form selected - "details" | "ban"
       * restoreExpanded: Boolean - Whether the "Restore Banned User" MDBCollapse is expanded
       * restoring: Boolean - Whether the profile is in the process of being restored
       * verifying: Boolean - Whether the profile is in the process of being verified or unverified
       */
      working: false,
      formSelected: "details",
      restoreExpanded: false,
      restoring: false,
      verifying: false,
    };
  }

  /**
   * When the user resizes the page, reset the modal body height
   */
  componentDidMount() {
    window.addEventListener("resize", this.setModalBodyHeight);
  }

  /**
   * When modal is displayed, set body height
   */
  componentDidUpdate(prevProps) {
    if (prevProps.modalShown !== this.props.modalShown) {
      this.setModalBodyHeight();
    }
  }

  /**
   * remove event listeners
   */
  componentWillUnmount() {
    window.removeEventListener("resize", this.setModalBodyHeight);
  }

  /**
   *
   * Close modal only if the user clicks one of the close buttons or the actual backdrop
   * Prevents bug that closes the modal when clicking anywhere while the modal is growing or shrinking
   */
  closeModal = (e) => {
    if (
      e.target.classList.contains("modal") &&
      !this.state.working &&
      this.props.modalShown
    ) {
      this.props.toggleShowModal();
    }
  };

  /**
   * Changes the form
   * Resizes the body after the framer-motion exit animations have completed
   */
  changeForm = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          formSelected:
            this.state.formSelected === "details" ? "ban" : "details",
        }),
        () => setTimeout(this.setModalBodyHeight, 333)
      );
  };

  setWorking = (option) =>
    this.setState((curr) => ({
      ...curr,
      working: option,
    }));

  /**
   * Resets the modal
   *
   */
  reset = () =>
    this.setState(
      (curr) => ({
        ...curr,
        working: false,
        formSelected: "details",
      }),
      this.props.toggleShowModal
    );

  /**
   * Set the body height to that of its immediate child
   * Causes the body to smoothly grow or shrink instead of pop
   */
  setModalBodyHeight = () => {
    const body = document.getElementById("action-modal-body");
    if (body) {
      if (
        this.props.screenDimensions.width >
        this.props.screenDimensions.modalBreak
      ) {
        body.style.height = `${
          document.getElementById("action-modal-body-child").clientHeight
        }px`;
      } else {
        body.style.flexGrow = "1";
        body.style.height = `0`;
      }
    }
  };

  toggleRestore = () =>
    this.setState((curr) => ({
      ...curr,
      restoreExpanded: !this.state.restoreExpanded,
    }));

  /**
   * Triggered when the user restores a banned profile
   *
   * Submit to server
   * Update profile
   * Hide modal
   * Notify user that it was successful
   */
  restoreUser = () => {
    if (!this.state.restoring)
      this.setState(
        (curr) => ({
          ...curr,
          restoring: true,
          restoreExpanded: false,
        }),
        () =>
          axios
            .post(
              process.env.REACT_APP_LAMBDA_SUPPORT_USERS + "/restore",
              {
                userID: this.props.profileInfo?.user?._id,
              },
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.toggleShowModal();
              this.props.set_token(res.data.token);
              this.props.set_profile({
                user: res.data.userInfo,
              });

              this.setState(
                (curr) => ({
                  ...curr,
                  restoring: false,
                }),
                () =>
                  this.props.notify(
                    <i className="fas fa-user-plus text-success me-2"></i>,
                    <>{this.props.profileInfo?.user?.username} Restored</>
                  )
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  restoring: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  /**
   * Triggered when the user clicks the Verify or Unverify buttton
   *
   */
  toggleVerification = () => {
    if (!this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          verifying: true,
        }),
        () =>
          axios
            .get(
              `${process.env.REACT_APP_LAMBDA_SUPPORT_USERS}/toggle-verification/${this.props.profileInfo?.user?._id}`,
              {
                headers: {
                  Authorization: this.props.token,
                },
              }
            )
            .then((res) => {
              this.props.set_token(res.data.token);
              this.setState(
                (curr) => ({
                  ...curr,
                  verifying: false,
                }),
                () =>
                  this.props.set_profile({
                    user: {
                      ...this.props.profileInfo?.user,
                      ...res.data.userInfo,
                    },
                  })
              );
            })
            .catch((err) =>
              this.setState(
                (curr) => ({
                  ...curr,
                  verifying: false,
                }),
                () => {
                  console.log(err);
                  alert("An error occurred. Please try again later.");
                }
              )
            )
      );
  };

  render() {
    const screenDimensions = this.props.screenDimensions;
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            staticBackdrop
            open={this.props.modalShown}
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                screenDimensions.width > screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {this.state.formSelected === "details" ? (
                      <motion.div
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        User Details -{" "}
                        <span className="text-darkblu">
                          @{this.props.profileInfo?.user?.username}
                        </span>
                      </motion.div>
                    ) : (
                      <motion.p
                        className="m-0"
                        transition={t.transition}
                        exit={t.fade_out_minimize}
                        animate={t.normalize}
                        initial={t.fade_out_minimize}
                      >
                        Ban{" "}
                        <span className="text-darkblu">
                          @{this.props.profileInfo?.user?.username}
                        </span>
                      </motion.p>
                    )}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <div
                  id="action-modal-body"
                  className={`transition-25 overflow-y-${
                    this.props.screenDimensions.width >
                    this.props.screenDimensions.modalBreak
                      ? "hidden"
                      : "auto"
                  }`}
                >
                  <MDBModalBody
                    id="action-modal-body-child"
                    className={
                      this.props.screenDimensions.width >
                      this.props.screenDimensions.modalBreak
                        ? ""
                        : "h-100"
                    }
                  >
                    <StaticRouter location={this.state.formSelected}>
                      <AnimatePresence exitBeforeEnter>
                        <Switch key={this.state.formSelected}>
                          <Route exact path=":form">
                            <Form
                              form={this.state.formSelected}
                              googleReCaptchaProps={
                                this.props.googleReCaptchaProps
                              }
                              setSubmit={(f) => (this.submit = f)}
                              modalShown={this.props.modalShown}
                              setShowModal={this.props.setShowModal}
                              toggleShowModal={this.props.toggleShowModal}
                              setWorking={this.setWorking}
                              key={this.state.formSelected}
                              notify={this.props.notify}
                              reset={this.reset}
                              working={this.state.working}
                              token={this.props.token}
                              set_token={this.props.set_token}
                            />
                          </Route>
                        </Switch>
                      </AnimatePresence>
                    </StaticRouter>
                  </MDBModalBody>
                </div>
                <MDBModalFooter
                  id="user-action-footer-mobile"
                  className="d-flex justify-content-between"
                >
                  <div className="d-flex w-100 m-0">
                    {this.state.formSelected === "details" ? (
                      <>
                        {this.state.restoreExpanded ? (
                          <motion.div
                            transition={t.transition}
                            initial={t.fade_out}
                            animate={t.normalize}
                            exit={t.fade_out_scale_1}
                            className="w-50 d-flex justify-content-evenly align-items-center"
                          >
                            <MDBBtn
                              className="d-block"
                              color="link"
                              rippleColor="primary"
                              onClick={this.restoreUser}
                            >
                              Confirm Restore
                            </MDBBtn>
                            <MDBBtn
                              className="d-block"
                              color="link"
                              rippleColor="primary"
                              onClick={this.toggleRestore}
                            >
                              Cancel
                            </MDBBtn>
                          </motion.div>
                        ) : (
                          <motion.section
                            transition={t.transition}
                            animate={t.normalize}
                            exit={t.fade_out_scale_1}
                            className="w-50"
                          >
                            {this.props.profileInfo?.user?.ban?.banned ? (
                              <MDBBtn
                                onClick={this.toggleRestore}
                                color="danger"
                                className="modal-buttons-mobile d-block rounded-0 w-100 h-100"
                                disabled={this.state.restoring}
                              >
                                {this.state.restoring ? (
                                  <>
                                    <Spinner className="me-2" size="sm" />
                                    <span>Restoring</span>
                                  </>
                                ) : (
                                  <>
                                    <i className="fas fa-user-plus me-2"></i>
                                    <span>Restore User</span>
                                  </>
                                )}
                              </MDBBtn>
                            ) : (
                              <MDBBtn
                                onClick={this.changeForm}
                                color="danger"
                                className="modal-buttons-mobile d-block rounded-0 w-100 h-100"
                              >
                                <i className="fas fa-gavel me-2"></i>
                                <span>Ban User</span>
                              </MDBBtn>
                            )}
                          </motion.section>
                        )}
                        <MDBBtn
                          color="primary"
                          disabled={this.state.verifying}
                          className={`w-50 modal-buttons-mobile d-block rounded-0 ${
                            this.props.profileInfo?.user?.verified
                              ? "bg-purple"
                              : ""
                          }`}
                          onClick={
                            this.state.verifying
                              ? () => {}
                              : this.toggleVerification
                          }
                        >
                          {this.props.profileInfo?.user?.verified ? (
                            <>
                              {this.state.verifying ? (
                                <>
                                  <Spinner size="sm" className="me-2" />
                                  <span>Removing Verification</span>
                                </>
                              ) : (
                                <>
                                  <i className="fas fa-user-times me-2"></i>
                                  <span>Remove Verification</span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.verifying ? (
                                <>
                                  <Spinner size="sm" className="me-2" />
                                  <span>Verifying</span>
                                </>
                              ) : (
                                <>
                                  <i className="fas fa-user-check me-2"></i>
                                  <span>Verify</span>
                                </>
                              )}
                            </>
                          )}
                        </MDBBtn>
                      </>
                    ) : (
                      <MDBBtn
                        onClick={this.changeForm}
                        color="primary"
                        className="modal-buttons-mobile d-block rounded-0 w-100"
                      >
                        <i className="fas fa-user me-2"></i>
                        User Details
                      </MDBBtn>
                    )}
                  </div>
                  <div className="d-flex w-100 m-0">
                    <MDBBtn
                      disabled={this.state.working}
                      className={`w-50 modal-buttons-mobile d-block rounded-0 ${
                        screenDimensions.width > screenDimensions.modalBreak
                          ? "border-bottom-left-radius-0p25rem"
                          : ""
                      }`}
                      color="success"
                      onClick={this.state.working ? () => {} : this.submit}
                    >
                      {this.state.working ? (
                        <>
                          <Spinner size="sm" className="me-2" />
                          <span>Working</span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-paper-plane me-2"></i>
                          <span>Submit</span>
                        </>
                      )}
                    </MDBBtn>
                    <MDBBtn
                      onClick={this.props.toggleShowModal}
                      color="dark"
                      className={`w-50 modal-buttons-mobile d-block rounded-0 ${
                        screenDimensions.width > screenDimensions.modalBreak
                          ? "border-bottom-right-radius-0p25rem bg-gray"
                          : ""
                      }`}
                    >
                      <span>Close</span>
                    </MDBBtn>
                  </div>
                </MDBModalFooter>
                <MDBModalFooter
                  id="edit-profile-footer-desktop"
                  className="d-flex justify-content-between edit-profile-footer"
                >
                  {this.state.formSelected === "details" ? (
                    <div className="d-flex align-items-start edit-profile-buttons-actions">
                      {this.props.profileInfo?.user?.ban?.banned ? (
                        <div>
                          {this.state.restoring ? (
                            <MDBBtn disabled color="danger">
                              <Spinner className="me-2" size="sm" />
                              Restoring
                            </MDBBtn>
                          ) : (
                            <MDBBtn onClick={this.toggleRestore} color="danger">
                              <i className="fas fa-user-plus me-2"></i>
                              {this.state.restoreExpanded
                                ? "Are you sure?"
                                : "Restore User"}
                            </MDBBtn>
                          )}
                          <Collapse
                            className="mt-1"
                            in={this.state.restoreExpanded}
                          >
                            <MDBBtn
                              className="d-block"
                              color="link"
                              rippleColor="primary"
                              onClick={this.toggleRestore}
                            >
                              No
                            </MDBBtn>
                            <MDBBtn
                              className="d-block"
                              color="link"
                              rippleColor="primary"
                              onClick={this.restoreUser}
                            >
                              Yes
                            </MDBBtn>
                          </Collapse>
                        </div>
                      ) : (
                        <MDBBtn onClick={this.changeForm} color="danger">
                          <i className="fas fa-gavel me-2"></i>
                          Ban User
                        </MDBBtn>
                      )}

                      {this.props.profileInfo?.user?.verified ? (
                        <>
                          {this.state.verifying ? (
                            <MDBBtn disabled className="ms-2 bg-purple">
                              <Spinner size="sm" className="me-2" />
                              Removing Verification
                            </MDBBtn>
                          ) : (
                            <MDBBtn
                              onClick={this.toggleVerification}
                              className="ms-2 bg-purple"
                            >
                              <i className="fas fa-user-times me-2"></i>
                              Remove Verification
                            </MDBBtn>
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.verifying ? (
                            <MDBBtn color="primary" disabled className="ms-2">
                              <Spinner size="sm" className="me-2" />
                              Verifying
                            </MDBBtn>
                          ) : (
                            <MDBBtn
                              onClick={this.toggleVerification}
                              color="primary"
                              className="ms-2"
                            >
                              <i className="fas fa-user-check me-2"></i>
                              Verify
                            </MDBBtn>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <MDBBtn onClick={this.changeForm} color="primary">
                      <i className="fas fa-user me-2"></i>
                      User Details
                    </MDBBtn>
                  )}
                  <hr className="divider-user-action-modal" />
                  <div className="d-flex edit-profile-buttons-actions">
                    {this.state.working ? (
                      <MDBBtn
                        rippleColor="light"
                        color="success"
                        disabled
                        className="me-2"
                      >
                        <Spinner size="sm" className="me-2" />
                        Working
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        onClick={this.submit}
                        rippleColor="light"
                        color="success"
                        className="me-2"
                      >
                        <i className="fas fa-paper-plane me-2"></i>Submit
                      </MDBBtn>
                    )}
                    <MDBBtn
                      className="bg-gray"
                      color="dark"
                      onClick={this.props.toggleShowModal}
                    >
                      Close
                    </MDBBtn>
                  </div>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { set_profile, set_token, notify })(
  UserActionModal
);
