import env from "../../../../env";
import React from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBValidation,
  MDBValidationItem,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBTooltip,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { connect } from "react-redux";
import TextInput from "../../../../components/textInput/TextInput";
import { motion } from "framer-motion";
import h from "../../../../utilities/helpers";
import t from "../../../../utilities/transitions";
import {
  add_message_profile,
  set_unread_messages,
  set_profile_conversation,
  set_token,
  remove_message_profile,
  remove_conversation_profile,
  leave_conversation_profile,
} from "../../../../redux/actions";
import { v4 as uuid } from "uuid";
import Encrypter from "../../../../utilities/Encrypter";
import { string } from "yup";
import axios from "axios";
import crypto from "crypto-browserify";
import { Buffer } from "safe-buffer";

let loadingMore = false;
const maxChars = 5000;

class MessageModal extends React.Component {
  constructor() {
    super();
    this.state = {
      /**
       * working: Boolean - Whether a private chat is in the process of being sent
       * scrollToBottom: Boolean - Whether an incoming chat message will cause the chatbox to scroll to the bottom
       * cooldown: Number - Seconds left in cooldown before the user can send another message
       * cooldownInterval: false | Interval that decrements cooldown by 1 every second
       * socketConnected: Boolean - Whether the chat socket has connected
       */
      reset: false,
      sending: false,
      working: false,
      scrollToBottom: true,
      cooldown: 0,
      cooldownInterval: false,
      socketConnected: false,
      chatKeyFound: false,
      input: {
        value: "",
        error: "Please enter your password",
      },
      messageHovering: false,
      dropdownOpen: false,
      contentKey: false,
      loadingMore: false,
      enterPressed: false,
      scrollToID: false,
    };
  }

  /**
   * Connect chat socket if main socket is connected
   */
  componentDidMount() {
    if (
      localStorage.getItem("chatKey") &&
      localStorage.getItem("userID") === this.props.userInfo._id
    )
      this.setState((curr) => ({
        ...curr,
        chatKeyFound: true,
      }));
    if (this.props.socket) this.initializeSocket();
  }

  /**
   * If new message is received, scroll to bottom. If modal shown, mark messages as read
   * If modal is shown, scroll to bottom and mark messages as read
   * If diconnected socket becomes reconnected, re-initialize
   */
  componentDidUpdate(prevProps) {
    if (!localStorage.getItem("chatKey") && this.state.chatKeyFound)
      this.setState((curr) => ({
        ...curr,
        chatKeyFound: false,
      }));
    if (localStorage.getItem("chatKey") && !this.state.chatKeyFound)
      this.setState(
        (curr) => ({
          ...curr,
          chatKeyFound: true,
        }),
        this.scrollToBottom
      );
    if (
      prevProps.modalShown !== this.props.modalShown ||
      prevProps.profileInfo.conversation?.messages?.length !==
        this.props.profileInfo.conversation?.messages?.length
    )
      this.scrollToBottom();
    if (this.props.socket) {
      if (
        (!prevProps.modalShown ||
          !prevProps.profileInfo.conversation ||
          (this.props.profileInfo.conversation?.messages &&
            prevProps.profileInfo.conversation?.messages &&
            prevProps.profileInfo.conversation?.messages?.length !==
              this.props.profileInfo.conversation?.messages?.length)) &&
        this.props.modalShown &&
        this.props.profileInfo.conversation
      ) {
        this.props.socket.emit(
          "read-messages",
          this.props.profileInfo.conversation._id
        );
      }

      if (
        !prevProps.profileInfo.conversation &&
        this.props.profileInfo.conversation &&
        this.state.sending
      )
        this.setState((curr) => ({
          ...curr,
          sending: false,
        }));
    }

    if (!prevProps.socket && this.props.socket) this.initializeSocket();
  }

  componentWillUnmount() {
    if (!this.props.socket) return;
    this.props.socket.off("new-message");
    this.props.socket.off("remove-message");
    this.props.socket.off("conversation-left");
    this.props.socket.off("conversation-removed");
    this.props.socket.off("conversation-kicked");
    this.props.socket.off("read-all");
  }

  /**
   * Turn off and then turn back on all socket events
   */
  initializeSocket = () => {
    // console.log("socket", this.props.socket);
    if (!this.props.socket) return;
    this.props.socket.off("new-message");
    this.props.socket.off("remove-message");
    this.props.socket.off("conversation-left");
    this.props.socket.off("conversation-removed");
    this.props.socket.off("conversation-kicked");

    this.props.socket.on(
      "conversation-kicked",
      (conversationID, userID, unread) => {
        try {
          if (this.props.profileInfo.conversation._id === conversationID) {
            this.props.remove_conversation_profile(
              conversationID,
              userID === this.props.userInfo._id ? unread : 0
            );
          } else if (userID === this.props.userInfo._id)
            this.props.set_unread_messages(
              this.props.userInfo.unreadMessages - unread
            );
        } catch (err) {
          console.log("conversation-kicked error", err);
        }
      }
    );

    this.props.socket.on("conversation-removed", (data) => {
      try {
        this.props.remove_conversation_profile(data);
      } catch (err) {
        console.log("conversation-removed error", err, data);
      }
    });
    this.props.socket.on("conversation-left", (data) => {
      try {
        this.props.remove_conversation_profile(data);
      } catch (err) {
        console.log("conversation-left error", err, data);
      }
    });

    this.props.socket.on("remove-message", (data) => {
      try {
        this.props.remove_message_profile(data);
      } catch (err) {
        console.log("remove-message error", err, data);
      }
    });
    this.props.socket.on("new-message", (messageObj) => {
      try {
        if (this.props.profileInfo.conversation) {
          if (
            !this.props.profileInfo.conversation.messages.find(
              (message) => message.id === messageObj.id
            )
          )
            this.props.add_message_profile({
              timestamp: new Date(),
              from: messageObj.from,
              message: h.decryptMessage(
                this.props.userInfo._id,
                this.props.profileInfo.conversation,
                messageObj
              ),
              decrypted: true,
              read: this.props.modalShown,
              id: messageObj.id,
              conversationID: messageObj.conversationID,
            });
          if (this.state.sending && messageObj.from === this.props.userInfo._id)
            this.setState(
              (curr) => ({
                ...curr,
                text: "",
                cooldown: 3,
                reset: !this.state.reset,
                sending: false,
              }),
              () => {
                setTimeout(this.decrementCooldown, 1000);
              }
            );
        } else {
          this.getNewConversation(messageObj.conversationID, messageObj);
        }

        if (
          this.props.profileInfo?.user &&
          messageObj.from === this.props.profileInfo?.user?._id &&
          !this.props.modalShown
        ) {
          this.props.set_unread_messages(
            this.props.userInfo.unreadMessages + 1
          );
        }
      } catch (err) {
        console.log("new-message error", err, messageObj);
      }
    });
  };

  getNewConversation = (conversationID, message) => {
    axios
      .get(`${process.env.REACT_APP_LAMBDA_MESSAGES}/by-id/${conversationID}`, {
        headers: {
          Authorization: this.props.token,
        },
      })
      .then((res) => {
        this.props.set_token(res.data.token);
        if (
          res.data.conversation.parties.length === 2 &&
          res.data.conversation.parties.find(
            (p) => p === this.props.userInfo._id
          ) &&
          res.data.conversation.parties.find(
            (p) => p === this.props.profileInfo?.user?._id
          )
        ) {
          res.data.conversation = h.decryptConversations(
            this.props.userInfo._id,
            localStorage.getItem("chatKey"),
            [res.data.conversation]
          )[0];
          if (!res.data.conversation.messages.find((m) => m.id === message.id))
            res.data.conversation.messages.push(message);
          this.props.set_profile_conversation(res.data.conversation);
          if (this.state.sending) {
            this.setState(
              (curr) => ({
                ...curr,
                text: "",
                cooldown: 3,
                reset: !this.state.reset,
                sending: false,
              }),
              () => {
                setTimeout(this.decrementCooldown, 1000);
                if (this.state.enterPressed)
                  this.setState((curr) => ({
                    ...curr,
                    contentKey: !this.state.contentKey,
                  }));
              }
            );
          }
        }
      })
      .catch((err) => {
        console.log("error getting new conversation", err);
        if (err.response?.status === 404) console.log("Conversation not found");
        else
          setTimeout(
            () => this.getNewConversation(conversationID, message),
            1000
          );
      });
  };

  loadMore = () => {
    this.setState(
      (curr) => ({
        ...curr,
        loadingMore: true,
      }),
      () =>
        axios
          .post(
            `${process.env.REACT_APP_LAMBDA_MESSAGES}/more-messages`,
            {
              conversationID: this.props.profileInfo.conversation._id,
              messageIDs: this.props.profileInfo.conversation.messages.map(
                (m) => m.id
              ),
            },
            {
              headers: {
                Authorization: this.props.token,
              },
            }
          )
          .then((res) => {
            this.props.set_token(res.data.token);
            this.setState(
              (curr) => ({
                ...curr,
                scrollToID: this.props.profileInfo.conversation.messages.sort(
                  (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                )[0].id,
                loadingMore: false,
              }),
              () => {
                this.props.set_profile_conversation({
                  ...this.props.profileInfo.conversation,
                  totalMessages: res.data.totalMessages,
                  messages: [
                    ...this.props.profileInfo.conversation.messages,
                    ...res.data.messages.map((m) => ({
                      ...m,
                      decrypted: true,
                      message: h.decryptMessage(
                        this.props.userInfo._id,
                        this.props.profileInfo.conversation,
                        m
                      ),
                    })),
                  ].sort(
                    (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
                  ),
                });
              }
            );
          })
          .catch((err) => {
            console.log("error loading more", err);
            setTimeout(this.loadMore, 1000);
          })
    );
  };

  /**
   * Submit only if a message isn't already being sent
   * Set working
   * Validate inputs
   * Emit message
   * Add message via redux
   * Put user on 3 second cooldown
   */
  submit = (enterPressed) =>
    this.setState(
      (curr) => ({
        ...curr,
        sending: true,
      }),
      () =>
        setTimeout(() => {
          try {
            if (!this.state.cooldown) {
              this.forceParse();
              const emissionData = document.getElementById("input-message");
              const length = String(emissionData.textContent)
                .split("")
                .filter((c) => {
                  const checkWhiteSpace = c.match(/[\s]/);
                  if (!checkWhiteSpace) return true;
                  else {
                    return [" ", "\n"].indexOf(c) > -1;
                  }
                }).length;
              if (length > maxChars)
                throw `Character limit exceeded (Max: ${maxChars} characters)`;
              if (
                !length ||
                emissionData.innerHTML === "<div><p><br /></p></div>"
              )
                throw "Please enter a message";
              const messageID = uuid();

              if (this.props.profileInfo.conversation) {
                this.props.socket.emit("message", {
                  broadcast: [
                    {
                      to: this.props.profileInfo?.user?._id,
                      message: this.props.profileInfo.conversation.keys
                        .find(
                          (k) =>
                            k[this.props.profileInfo?.user?._id] &&
                            k[this.props.userInfo._id]
                        )
                        [this.props.userInfo._id].encrypt(
                          h.sanitizeHTML(emissionData.innerHTML)
                        ),
                    },
                  ],
                  id: messageID,
                  conversationID: this.props.profileInfo.conversation._id,
                  direct: true,
                });
                this.props.add_message_profile({
                  timestamp: new Date(),
                  from: this.props.userInfo._id,
                  message: h.sanitizeHTML(emissionData.innerHTML),
                  id: messageID,
                });
                this.setState(
                  (curr) => ({
                    ...curr,
                    text: "",
                    cooldown: 3,
                    reset: !this.state.reset,
                    sending: false,
                  }),
                  () => {
                    setTimeout(this.decrementCooldown, 1000);
                    if (enterPressed)
                      this.setState((curr) => ({
                        ...curr,
                        contentKey: !this.state.contentKey,
                      }));
                  }
                );
              } else {
                this.setState(
                  (curr) => ({
                    ...curr,
                    enterPressed,
                  }),
                  () => {
                    const sharedKey = crypto
                      .randomBytes(Number(process.env.REACT_APP_BYTE_LENGTH))
                      .toString("hex");
                    this.props.socket.emit("message", {
                      broadcast: [
                        {
                          to: this.props.profileInfo?.user?._id,
                          senderKey: crypto
                            .publicEncrypt(
                              this.props.userInfo.publicKey,
                              Buffer.from(sharedKey, "binary")
                            )
                            .toString("binary"),
                          receiverKey: crypto
                            .publicEncrypt(
                              this.props.profileInfo?.user?.publicKey,
                              Buffer.from(sharedKey, "binary")
                            )
                            .toString("binary"),
                          message: new Encrypter(sharedKey).encrypt(
                            h.sanitizeHTML(emissionData.innerHTML)
                          ),
                        },
                      ],
                      id: messageID,
                      fromProfile: true,
                      soloKey: crypto
                        .publicEncrypt(
                          this.props.userInfo.publicKey,
                          Buffer.from(
                            crypto
                              .randomBytes(
                                Number(process.env.REACT_APP_BYTE_LENGTH)
                              )
                              .toString("hex"),
                            "binary"
                          )
                        )
                        .toString("binary"),
                    });
                  }
                );
              }
            }
          } catch (err) {
            console.log(err);
            alert("An error occurred. Please try again later");
          }
        }, 200)
    );

  /**
   * If state.scrollToBottom, scroll to bottom
   * scrollBehavior is normal for first scroll, set to smooth for subsequent scrolls
   * Execute after 0.1s
   */
  scrollToBottom = (force) =>
    this.setState(
      (curr) => ({
        ...curr,
        scrollToBottom: force ? true : this.state.scrollToBottom,
      }),
      () => {
        if (this.state.scrollToBottom) {
          const container = document.getElementById("message-container");
          if (container) {
            container.scrollTop = container.scrollHeight;
            container.style.scrollBehavior = "smooth";
          }
        } else if (this.state.scrollToID) {
          const container = document.getElementById("message-container");
          if (container) {
            container.style.scrollBehavior = "auto";
            document
              .getElementById("message-" + this.state.scrollToID)
              .scrollIntoView();
            container.scrollTop -= 30;
            container.style.scrollBehavior = "smooth";
          }
          this.setState((curr) => ({
            ...curr,
            scrollToID: false,
          }));
        }
      }
    );

  /**
   * Fired when the user scrolls through the chatbox
   * If the scrollbar is not at the bottom, set state.scrollToBottom to false so that it won't scroll to the bottom when new messages come in
   */
  scrollHandler = (e) => {
    if (this.props.profileInfo?.conversation?.messages?.length) {
      if (
        e.currentTarget.scrollHeight ===
        e.currentTarget.scrollTop + e.currentTarget.clientHeight
      ) {
        if (!this.state.scrollToBottom)
          this.setState((curr) => ({
            ...curr,
            scrollToBottom: true,
          }));
      } else if (this.state.scrollToBottom)
        this.setState((curr) => ({
          ...curr,
          scrollToBottom: false,
        }));
      else if (
        !loadingMore &&
        e.currentTarget.scrollTop < 50 &&
        Number(document.getElementById("message-list")?.offsetHeight || 0) >
          Number(
            document.getElementById("message-container")?.offsetHeight || 0
          ) &&
        this.props.profileInfo.conversation.messages.length !==
          this.props.profileInfo.conversation.totalMessages &&
        !this.state.loadingMore
      ) {
        loadingMore = true;
        this.loadMore();
        setTimeout(() => (loadingMore = false), 500);
      }
    }
  };

  /**
   * Triggered by the cooldownInterval
   * Decrements the cooldown by 1
   */
  decrementCooldown = () =>
    this.setState(
      (curr) => ({
        ...curr,
        cooldown: this.state.cooldown > 0 ? this.state.cooldown - 1 : 0,
      }),
      () => {
        if (this.state.cooldown > 0) setTimeout(this.decrementCooldown, 1000);
      }
    );

  changeHandler = (e) => {
    let error = "";
    try {
      string()
        .required("Please enter your password")
        .validateSync(e.target.value);
    } catch (err) {
      error = err?.message || "";
    }
    this.setState(
      (curr) => ({
        ...curr,
        input: {
          error,
          value: e.target.value,
        },
      }),
      () =>
        document
          .getElementById("enter-password-profile-input")
          .setCustomValidity(this.state.input.error)
    );
  };

  enterPassword = () => {
    document
      .getElementById("enter-password-profile-modal")
      .classList.add("was-validated");
    if (!this.state.input.error && !this.state.working)
      this.setState(
        (curr) => ({
          ...curr,
          working: true,
        }),
        () =>
          setTimeout(() => {
            try {
              {
                const chatKey = new Encrypter(this.state.input.value).decrypt(
                  this.props.userInfo.chatKey
                );
                localStorage.setItem("userID", this.props.userInfo._id);
                localStorage.setItem("chatKey", chatKey);
                this.setState(
                  (curr) => ({
                    ...curr,
                    working: false,
                    chatKeyFound: true,
                    input: {
                      error: "Please enter your password",
                      value: "",
                    },
                  }),
                  this.scrollToBottom
                );
              }
            } catch (err) {
              console.log("err", err);
              this.setState(
                (curr) => ({
                  ...curr,
                  working: false,
                }),
                () =>
                  alert(
                    "An error occurred. Please check your password and try again."
                  )
              );
            }
          }, 250)
      );
  };

  openDropdown = (messageID) =>
    this.setState((curr) => ({
      ...curr,
      dropdownOpen: messageID,
      messageHovering: messageID ? this.state.messageHovering : false,
    }));

  remove = (messageID) => {
    this.props.remove_message_profile({
      messageID,
      unread: false,
    });
    this.props.socket.emit("remove-message", messageID);
  };

  removeSelect = (e) => {
    const messageID = this.state.dropdownOpen;
    this.setState(
      (curr) => ({
        ...curr,
        dropdownOpen: false,
        messageHovering: false,
      }),
      () => {
        if (e?.value) this.remove(messageID);
      }
    );
  };

  render() {
    return (
      <>
        {typeof window !== "undefined" && window.navigator ? (
          <MDBModal
            open={this.props.modalShown}
            staticBackdrop
            onClosePrevented={() => {
              if (!this.state.working) this.props.toggleShowModal();
            }}
            tabIndex="-1"
          >
            <MDBModalDialog
              size={
                this.props.screenDimensions.width >
                this.props.screenDimensions.modalBreak
                  ? "xl"
                  : "fullscreen"
              }
            >
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>
                    {this.state.chatKeyFound ? (
                      <>
                        Message{" "}
                        <span className="text-darkblu">
                          @{this.props.profileInfo?.user?.username}
                        </span>
                      </>
                    ) : (
                      "Enter your password to continue"
                    )}
                  </MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.props.toggleShowModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody
                  className="overflow-y-hidden"
                  style={{
                    height: this.state.chatKeyFound ? "75vh" : "auto",
                    transition: "0.25s",
                  }}
                >
                  {this.state.chatKeyFound ? (
                    <motion.div
                      transition={t.transition}
                      initial={t.fade_out}
                      animate={t.normalize}
                      exit={t.fade_out_scale_1}
                      className="h-100 d-flex flex-column"
                    >
                      <div className="fg-1 border border-gray d-flex flex-column justify-content-end position-relative">
                        <div
                          onTouchMove={this.scrollHandler}
                          onWheel={this.scrollHandler}
                          style={{ scrollBehavior: "auto" }}
                          id="message-container"
                          className="h-100 overflow-y-auto"
                        >
                          {this.props.profileInfo.conversation?.messages &&
                          this.props.profileInfo.conversation.messages
                            .length ? (
                            <>
                              {Number(
                                document.getElementById("message-list")
                                  ?.offsetHeight || 0
                              ) >
                                Number(
                                  document.getElementById("message-container")
                                    ?.offsetHeight || 0
                                ) &&
                                this.props.profileInfo.conversation?.messages
                                  .length !==
                                  this.props.profileInfo.conversation
                                    ?.totalMessages && (
                                  <div className="py-2">
                                    <MDBBtn
                                      onClick={this.loadMore}
                                      disabled={this.state.loadingMore}
                                      color="link"
                                      rippleColor="primary"
                                      className={`d-block mx-auto ${
                                        this.props.loadingMore
                                          ? "border-transparent"
                                          : ""
                                      }`}
                                    >
                                      {this.state.loadingMore ? (
                                        <motion.div
                                          transition={t.transition}
                                          initial={t.fade_out}
                                          animate={t.normalize}
                                          exit={t.fade_out_scale_1}
                                        >
                                          <MDBSpinner
                                            size="sm"
                                            color="primary"
                                          />
                                        </motion.div>
                                      ) : (
                                        <motion.section
                                          transition={t.transition}
                                          initial={t.fade_out}
                                          animate={t.normalize}
                                          exit={t.fade_out_scale_1}
                                        >
                                          <i className="fas fa-undo-alt"></i>
                                        </motion.section>
                                      )}
                                    </MDBBtn>
                                  </div>
                                )}
                              <ul
                                id="message-list"
                                className="list-group list-group-flush"
                              >
                                {this.props.profileInfo.conversation.messages
                                  .sort(
                                    (a, b) =>
                                      new Date(a.timestamp) -
                                      new Date(b.timestamp)
                                  )
                                  .map((message) => {
                                    if (
                                      message.from === this.props.userInfo._id
                                    )
                                      return (
                                        <motion.li
                                          transition={t.transition}
                                          exit={t.fade_out_minimize}
                                          animate={t.normalize}
                                          initial={t.fade_out_minimize}
                                          key={message.id}
                                          className="list-group-item"
                                          id={"message-" + message.id}
                                          onMouseEnter={() =>
                                            this.setState((curr) => ({
                                              ...curr,
                                              messageHovering: message.id,
                                            }))
                                          }
                                          onMouseLeave={() =>
                                            this.setState((curr) => ({
                                              ...curr,
                                              messageHovering: false,
                                            }))
                                          }
                                        >
                                          <div className="d-flex justify-content-end max-w-100 text-break position-relative">
                                            <div>
                                              <p className="m-0 text-blusteel text-end">
                                                {h.makeDateHR(
                                                  new Date(message.timestamp)
                                                )}{" "}
                                                @{" "}
                                                {h.getTimeHR(
                                                  new Date(message.timestamp)
                                                )}
                                              </p>
                                              <div className="d-flex justify-content-end text-wrap ms-auto">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: message.message,
                                                  }}
                                                  className="text-end"
                                                ></div>
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center square-3 ms-2">
                                              <div
                                                className="fit-images fit-round"
                                                style={{
                                                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${this.props.userInfo.avatar.thumbnail}")`,
                                                  borderRadius: "50%",
                                                }}
                                              ></div>
                                            </div>
                                            {(this.state.messageHovering ===
                                              message.id ||
                                              this.state.dropdownOpen ===
                                                message.id) && (
                                              <motion.div
                                                initial={t.fade_out}
                                                animate={t.normalize}
                                                exit={t.fade_out_scale_1}
                                                style={{ right: "-21px" }}
                                                className="position-absolute d-flex h-100 align-items-center"
                                              >
                                                {this.state.dropdownOpen ===
                                                  message.id && (
                                                  <MDBSelect
                                                    onChange={this.removeSelect}
                                                    inputClassName="remove-select-options"
                                                    className="invis"
                                                    preventFirstSelection
                                                    open={
                                                      this.state
                                                        .dropdownOpen ===
                                                      message.id
                                                    }
                                                    onClose={() =>
                                                      this.openDropdown(false)
                                                    }
                                                    data={[
                                                      {
                                                        text: (
                                                          <div className="text-danger my-2">
                                                            <i className="fas fa-trash-alt me-2" />
                                                            Remove
                                                          </div>
                                                        ),
                                                        value: true,
                                                      },
                                                      {
                                                        text: (
                                                          <div className="my-2">
                                                            Cancel
                                                          </div>
                                                        ),
                                                        value: false,
                                                      },
                                                    ]}
                                                  />
                                                )}
                                                <MDBTooltip
                                                  wrapperProps={{
                                                    color: "link",
                                                    rippleColor: "danger",
                                                    onClick: () => {
                                                      h.hideToolTips();
                                                      this.openDropdown(
                                                        message.id
                                                      );
                                                    },
                                                    size: "sm",
                                                  }}
                                                  wrapperClass="text-danger p-1 bg-transparent"
                                                  title="Remove"
                                                >
                                                  <i className="far fa-trash-alt" />
                                                </MDBTooltip>
                                              </motion.div>
                                            )}
                                          </div>
                                        </motion.li>
                                      );
                                    else
                                      return (
                                        <motion.li
                                          transition={t.transition}
                                          exit={t.fade_out_minimize}
                                          animate={t.normalize}
                                          initial={t.fade_out_minimize}
                                          key={message.id}
                                          className="list-group-item"
                                          id={"message-" + message.id}
                                        >
                                          <div className="d-flex">
                                            <div className="d-flex justify-content-center align-items-center square-3 me-2">
                                              <div
                                                className="fit-images fit-round"
                                                style={{
                                                  backgroundImage: `url("${process.env.REACT_APP_BUCKET_HOST}/${env.INSTANCE_ID}/thumbnails/${this.props.profileInfo?.user?.avatar.thumbnail}")`,
                                                  borderRadius: "50%",
                                                }}
                                              ></div>
                                            </div>
                                            <div>
                                              <p className="m-0 text-blusteel">
                                                {h.makeDateHR(
                                                  new Date(message.timestamp)
                                                )}{" "}
                                                @{" "}
                                                {h.getTimeHR(
                                                  new Date(message.timestamp)
                                                )}
                                              </p>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: message.message,
                                                }}
                                              ></div>
                                            </div>
                                          </div>
                                        </motion.li>
                                      );
                                  })}
                              </ul>
                            </>
                          ) : (
                            <>
                              <i className="fas fa-lock d-block mx-auto mt-5 fa-6x text-center" />
                              <h5 className="mt-4 display-6 text-center">
                                Messages are end-to-end encrypted
                              </h5>
                            </>
                          )}
                        </div>
                        {!this.state.scrollToBottom && (
                          <motion.div
                            transition={t.transition}
                            initial={t.fade_out}
                            animate={t.normalize}
                            exit={t.fade_out_scale_1}
                            className="position-absolute"
                            style={{
                              bottom: "10px",
                              left: "10px",
                            }}
                          >
                            <MDBBtn
                              onClick={() => this.scrollToBottom(true)}
                              color="link"
                              rippleColor="primary"
                            >
                              <i className="fas fa-chevron-down" />
                            </MDBBtn>
                          </motion.div>
                        )}
                      </div>
                      <TextInput
                        submit={this.submit}
                        setForceParse={(e) => (this.forceParse = e)}
                        cooldown={this.state.cooldown}
                        sending={this.state.sending}
                        flavor="message"
                        maxChars={5000}
                        key={this.state.reset}
                        label="Message"
                        contentKey={this.state.contentKey}
                      />
                    </motion.div>
                  ) : (
                    <motion.section
                      transition={t.transition}
                      initial={t.fade_out}
                      animate={t.normalize}
                      exit={t.fade_out_scale_1}
                    >
                      <MDBValidation
                        name="enter-password-profile-modal"
                        onSubmit={this.enterPassword}
                        id="enter-password-profile-modal"
                      >
                        <MDBValidationItem
                          className=" mt-4 pb-4 h-100 h-100-child"
                          feedback={this.state.input.error}
                          invalid={true}
                        >
                          <MDBInput
                            name="enter-password-profile-input"
                            onChange={this.changeHandler}
                            value={this.state.input.value}
                            id="enter-password-profile-input"
                            label="Password"
                            size="lg"
                            className={`${
                              !this.state.input.error ? "mb-0" : ""
                            }`}
                            type="password"
                          />
                        </MDBValidationItem>
                      </MDBValidation>
                    </motion.section>
                  )}
                </MDBModalBody>
                {!this.state.chatKeyFound && (
                  <MDBModalFooter className="justify-content-between">
                    <MDBBtn color="dark" onClick={this.props.toggleShowModal}>
                      <i className="fas fa-chevron-left me-2" />
                      Go Back
                    </MDBBtn>
                    <MDBBtn
                      onClick={this.enterPassword}
                      color={
                        !this.props.userInfo.chatKey ? "primary" : "success"
                      }
                      disabled={
                        this.state.working || !this.props.userInfo.chatKey
                      }
                    >
                      {this.state.working ? (
                        <span id="spinner-enter-password-modal">
                          <MDBSpinner className="me-2" size="sm" />
                          Working
                        </span>
                      ) : (
                        <>
                          {this.props.userInfo.chatKey ? (
                            <>
                              <i className="fas fa-paper-plane me-2" />
                              Submit
                            </>
                          ) : (
                            <span className="opacity-flash">Loading</span>
                          )}
                        </>
                      )}
                    </MDBBtn>
                  </MDBModalFooter>
                )}
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, {
  add_message_profile,
  set_unread_messages,
  set_profile_conversation,
  set_token,
  remove_message_profile,
  remove_conversation_profile,
  leave_conversation_profile,
})(MessageModal);
